<template>
	<div>
		<BlanketOverlayWithPopup v-if="obin && obin.id >= 0" title="Edit Quota Line" width="500" :hastabs="false" v-on:close="closeCancel">
			<template v-slot:main>
				<div class="contained-popup-content">
					<div style="padding: 10px 0 10px 0;">
						<span class="settlbl">Quota ID: {{obin.id}}</span>
					</div>
					<table>
						<tbody>
						<tr>
							<td><span class="settlbl">Quota Name</span></td>
							<td><input type="text" class="saver" v-model="editOb.name"></td>
						</tr>
						<tr>
							<td><span class="settlbl">SQL String</span></td>
							<td><input type="text" class="saver" v-model="editOb.sqlfilterRaw"></td>
						</tr>
						<tr>
							<td><span class="settlbl">Always DQ if True</span></td>
							<td><select class="saver selector" v-model="editOb.alwaysDQ">
								<option v-for="cl in yesNos" :key="cl.val" :value="cl.val">{{ucfirst(cl.name)}}</option>
							</select></td>
						</tr>
						<tr>
							<td><span class="settlbl">Quota Cap</span></td>
							<td><input type="text" class="saver" v-model="editOb.quotacap"></td>
						</tr>
						<tr>
							<td><span class="settlbl">Target (optional)</span></td>
							<td><input type="text" class="saver" v-model="editOb.target"></td>
						</tr>
						<tr>
							<td><span class="settlbl">Notes</span></td>
							<td><input type="text" class="saver" v-model="editOb.notes"></td>
						</tr>
						<tr>
							<td><span class="settlbl">Deleted</span></td>
							<td><select class="saver selector" v-model="editOb.deleted">
								<option v-for="cl in yesNos" :key="cl.val" :value="cl.val">{{ucfirst(cl.name)}}</option>
							</select></td>
						</tr>
						</tbody>
					</table>
				</div>
			</template>
			<template v-slot:buttons>
				<button type="button" class="option-button colbtn" @click="saveInfo">Save Info</button>
				<button type="button" class="option-button" @click="closeCancel">Cancel</button>
			</template>
		</BlanketOverlayWithPopup>
	</div>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'
export default {
	name: "ScreenerQuotaEditPopup",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		obin: Object,
		screenerid: Number,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			editOb: {
				id: 0,
				name: '',
				sqlfilterRaw: '',
				alwaysDQ: 0,
				quotacap: 0,
				target: 0,
				notes: '',
				deleted: 0,
			},
			//statuses: ['active','deleted','disabled'],
			yesNos: [
				{val: 0, name: 'No'},
				{val: 1, name: 'Yes'},
			],

		}
	},
	computed: {

	},
	methods: {
		populateContent() {
			if(this.obin && this.obin.id > 0) {
				this.editOb = {
					id: this.obin.id,
					name: this.obin.name,
					sqlfilterRaw: this.obin.sqlfilterRaw,
					alwaysDQ: this.obin.alwaysDQ,
					quotacap: this.obin.quotacap,
					target: this.obin.target,
					notes: this.obin.notes,
					deleted: this.obin.deleted,
				}
			}
		},
		closeCancel() {
			this.$emit('close', null);
		},
		saveInfo() {
			if (this.isFetching === false && this.screenerid && this.screenerid > 0) {
				this.store.showLoader = true
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/screeners/save-quota-details";
				let sendob = {screenerId: this.screenerid, editQuotaId: this.editOb.id, quotaOb: this.editOb};
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					this.store.showLoader = false
					let screener = null
					if(ret.screener) screener = ret.screener
					this.$emit('close', screener)
				}.bind(this));
			}
		},
	},
	watch: {
		obin() {
			this.populateContent()
		},
	},
	mounted() {
		this.populateContent()
	},
}
</script>
<style scoped>
.saver {
	width: 300px;
}
.selector {
	width: 316px;
}
</style>