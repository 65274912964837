<template>
	<div class="wl-admin-container">

		<DemogroupEditPopup v-if="showEditDemogroupPopup"
			:dgob="activeDemogroup" :demos="demos"
			@close="closeEditDemogroupPopup($event)"
		></DemogroupEditPopup>

		<div>
			<div style="margin-bottom: 20px;">
				<select v-model="activeAnalysisOb" class="selector">
					<option v-for="a in store.analysesList" :key="a.id" :value="a">{{a.title}}</option>
				</select>
				<input type="text" placeholder="Search by name" v-model="filterText" class="wl-input-text wl-input-padding-large" />
				<button @click="addNewDemogroup" style="margin-left: 15px;" type="button" class="option-button colbtn">Add New</button>
			</div>
			<table class="altbl noborderv">
				<thead>
					<tr class='headrow'>
						<td class="headingtext">ID</td>
						<td class="headingtext ctr">DataLoc</td>
						<td class="headingtext">Title</td>
						<td class="headingtext ctr">Enabled</td>
						<td class="headingtext ctr">Reviewed</td>
						<td class="headingtext ctr">Num Demos</td>
						<td class="headingtext ctr">Functions</td>
					</tr>
				</thead>
				<tbody v-if="this.demogroups.length > 0">
					<tr v-for="a in filteredDemogroups" :key="a.id" @click="editDemogroup(a)">
						<td>{{a.id}}</td>
						<td class="ctr">{{a.survid}}</td>
						<td>{{a.title}}</td>
						<td class="ctr">{{boolToYes(a.enabled)}}</td>
						<td class="ctr">{{boolToYes(a.reviewed)}}</td>
						<td class="ctr">{{a.demoIds.length}}</td>
						<td class="ctr">
							<i class="icon mdi mdi-content-copy"
								@click.stop="cloneDemogroup(a, 'samedataloc')"
								title="Clone"
							></i>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import DemogroupEditPopup from "@/components/analysis-admin/DemogroupEditPopup.vue";

export default {
	name: "DemogroupsList",
	components: {
		DemogroupEditPopup,
	},
	props: {},
	mixins: [globalMixin],
	data: function() {
		return {
			store: store,
			filterText: '',

			activeAnalysisOb: null,
			showEditDemogroupPopup: false,
			demogroups: [],
			demos: [],
			activeDemogroup: null,
		}
	},
	computed: {
		filteredDemogroups() {
			if(this.filterText.length > 0) { //either already selected or matching text
				return this.demogroups.filter(item => item.title.toLowerCase().includes(this.filterText.toLowerCase()))
			}
			return this.demogroups
		},
	},
	methods: {
		addNewDemogroup() {
			this.activeDemogroup = null
			this.showEditDemogroupPopup = true
		},
		editDemogroup(anob) {
			this.activeDemogroup = anob
			this.showEditDemogroupPopup = true
		},
		closeEditDemogroupPopup(doUpdate) {
			this.showEditDemogroupPopup = false
			if(doUpdate === true) this.getDemogroupsList()
		},
		cloneDemogroup(dgob, type='samedataloc') {
			let targ = this.store.urlroot + "central/post-vue/analysis/clone-demogroup"
			let sendob = {
				sourcedemogroupid: dgob.id,
				type: type,
			}
			if(type === "newdataloc") { //prompt for new dataloc
				let newdataloc = prompt("Please enter the DATALOC of the new DemoGroup");
				if (newdataloc.length > 0) {
					sendob.destdataloc = newdataloc
				}
			}
			this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
				this.getDemogroupsList()
			}.bind(this))
		},
		getDemogroupsList() {
			this.demogroups = []
			if(this.activeAnalysisOb && this.activeAnalysisOb.survid > 0) {
				let targ = this.store.urlroot + "central/post-vue/analysis/get-demogroups-admin"
				let sendob = {
					survid: this.activeAnalysisOb.survid,
				};
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.demogroups = ret.demogroups
				}.bind(this))
				//also get the demos for the survid
				this.getDemosForSurvid(this.activeAnalysisOb.survid)
			}
		},
		getDemosForSurvid(survid) {
			let targ = this.store.urlroot + "central/post-vue/analysis/get-demos-admin"
			let sendob = {
				survid: survid
			}
			return this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.demos = ret.demos
			}.bind(this))
		},
	},
	watch: {
		activeAnalysisOb() {
			this.getDemogroupsList()
		},
	},
	mounted() {
		if(this.store.analysesList.length > 0) {
			this.activeAnalysisOb = this.store.analysesList[0]
		}
	},
}
</script>

<style scoped>
.icon.mdi {
	cursor: pointer;
	font-size: 16px;
	margin-right: 8px;
}
.altbl.noborderv td {
	cursor: default;
}
.selector {
	padding: 7px 10px;
	font-size: 15px;
	margin: 0 10px 0 0;
}
</style>