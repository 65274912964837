<template>
	<BlanketOverlayWithPopup title="Winner Draw" width="360" v-on:close="closeCancel">
		<template v-slot:main>
			<div style="padding: 20px 0 0;">
				<div>
					<select class="saver selector" v-model="chosenStationCode">
						<option value=""></option>
						<option v-for="st in stnlooks" :key="st.code" :value="st.code">{{st.title}}</option>
					</select>
				</div>
				<div style="padding: 10px 0 0;">
					<div v-for="s in availSurvs" :key="s.sid">
						<input type="checkbox" v-model="chosenSurveyIds" :value="s.sid" :id="'sch'+s.sid" />
						<label :for="'sch'+s.sid" style="margin-left: 5px;">{{s.name}}</label>
					</div>
				</div>
				<div v-if="returnedMsg.length > 0" style="padding: 10px 0;" v-html="returnedMsg">
				</div>
			</div>
		</template>
		<template v-slot:buttons>
			<button type="button" class="option-button colbtn" @click="runWinnerDraw">Run</button>
			<button type="button" class="option-button" @click="closeCancel">Close</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'

export default {
	name: "WinnerDrawScreen",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		stnlooks: Array,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,
			returnedData: [],
			returnedMsg: '',

			chosenStationCode: '',
			chosenSurveyIds: [],
		}
	},
	computed: {
		chosenOb() {
			return this.returnedData.find(item => item.station === this.chosenStationCode);
		},
		availSurvs() {
			if(this.chosenOb) {
				return this.chosenOb.survs;
			}
			return [];
		},
	},
	methods: {
		closeCancel() {
			this.$emit('close');
		},
		runWinnerDraw() {
			if (this.isFetching === false) {
				this.returnedMsg = '';
				// this.store.showLoader = true;
				this.isFetching = true;
				let targ = this.store.urlroot + "central/post-vue/home/draw-winner";
				let sendob = {
					survids: this.chosenSurveyIds,
					stationname: this.chosenStationCode,
				};
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false;
					// this.store.showLoader = false;
					if(ret.msg) {
						this.returnedMsg = ret.msg;
					}
					// this.showKalertSuccess({message: 'Changes saved!'}, true);
					// this.$emit('close');
				}.bind(this));
			}
		},
		getAvailSurvs() {
			if (this.isFetching === false) {
				this.store.showLoader = true;
				this.fetchFinished = false;
				this.isFetching = true;
				let stnlooks = [];
				for(let item of this.stnlooks) {
					stnlooks.push(item.code);
				}
				let targ = this.store.urlroot + "central/post-vue/home/get-recent-surveys-winner";
				let sendob = {stationArr: stnlooks};
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false;
					if (ret.mydata) {
						this.returnedData = ret.mydata;
					}
					this.fetchFinished = true;
					this.store.showLoader = false;
				}.bind(this));
			}
		},
	},
	watch: {
		chosenStationCode() {
			this.chosenSurveyIds = [];
		},
		// 	if(this.chosenOb) {
		// 		this.chosenSurveyId = this.chosenOb.activesurvid;
		// 		this.chosenEndDate = this.chosenOb.prizedate;
		// 	}
		// 	else {
		// 		this.chosenSurveyId = 0;
		// 		this.chosenEndDate = '';
		// 	}
		// },
	},
	mounted() {
		this.getAvailSurvs();
	},
}
</script>

<style scoped>
.selector {
	width: 210px;
}
</style>