<template>
	<div>
		<BlanketOverlayWithPopup v-if="licEditOb && licEditOb.id >= 0 && hasPopulated === true" title="Edit Mobile License" width="500" :hastabs="false" v-on:close="closeCancel">
			<template v-slot:main>
				<div class="contained-popup-content">
					<div style="padding: 10px 0 10px 0;">
						<span class="settlbl">Market ID: {{licEditOb.id}}</span>
					</div>
					<table>
						<tbody>
						<tr>
							<td><span class="settlbl">Name</span></td>
							<td><input type="text" class="saver" v-model="licEditOb.name"></td>
						</tr>
						<tr>
							<td><span class="settlbl">Current Survey</span></td>
							<td><select class="saver selector" v-model="licEditOb.currsurvey">
								<option value=""></option>
								<option v-for="s in store.rrSurveys" :key="s.id" :value="s.id">{{s.name}}</option>
							</select></td>
						</tr>
						<tr>
							<td><span class="settlbl">Previous Survey</span></td>
							<td><select class="saver selector" v-model="licEditOb.prevsurvey">
								<option value=""></option>
								<option v-for="s in store.rrSurveys" :key="s.id" :value="s.id">{{s.name}}</option>
							</select></td>
						</tr>
						<tr>
							<td><span class="settlbl">Demos</span></td>
							<td><input type="text" class="saver" v-model="licEditOb.demoStr"></td>
						</tr>
						<tr>
							<td><span class="settlbl">Statistics</span></td>
							<td><input type="text" class="saver" v-model="licEditOb.statisticStr"></td>
						</tr>
						<tr>
							<td><span class="settlbl">Station List</span></td>
							<td><input type="text" class="saver" v-model="licEditOb.stnlistStr"></td>
						</tr>
						<tr>
							<td><span class="settlbl">Show Previous Survey</span></td>
							<td><select class="saver selector" v-model="licEditOb.showPrev">
								<option :value="0">No</option>
								<option :value="1">Yes</option>
							</select></td>
						</tr>
						<tr>
							<td><span class="settlbl">Active</span></td>
							<td><select class="saver selector" v-model="licEditOb.active">
								<option :value="0">No</option>
								<option :value="1">Yes</option>
							</select></td>
						</tr>
						</tbody>
					</table>
				</div>
			</template>
			<template v-slot:buttons>
				<button type="button" class="option-button colbtn" @click="saveInfo">Save Info</button>
				<button type="button" class="option-button" @click="closeCancel">Cancel</button>
			</template>
		</BlanketOverlayWithPopup>
	</div>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'
export default {
	name: "MobileLicenseEditPopup",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		licobin: Object,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			hasPopulated: false,
			licEditOb: null,
		}
	},
	computed: {

	},
	methods: {
		populateContent() {
			if (this.licobin) {
				this.licEditOb = {}
				for (let pr in this.licobin) {
					this.licEditOb[pr] = this.licobin[pr];
				}
			}
			this.hasPopulated = true
		},
		closeCancel() {
			this.$emit('close', false);
		},
		saveInfo() {
			if (this.isFetching === false) {
				this.store.showLoader = true
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/regional-ratings-admin/save-mobilelic-details";
				let sendob = {licob: this.licEditOb};
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					this.store.showLoader = false
					let retob = null
					if(ret.license) retob = ret.license
					this.$emit('close', retob)
				}.bind(this));
			}
		},
	},
	watch: {
		licobin() {
			this.populateContent()
		},
	},
	mounted() {
		this.populateContent()
	},
}
</script>
<style scoped>
.saver {
	width: 200px;
}
.selector {
	width: 216px;
}
</style>