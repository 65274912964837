<template>
	<div>

		<FreqUserEditPopup v-if="editUserPopupShown" :userobin="selectedUserOb" v-on:close="editUserPopupShown = false" v-on:closerefresh="closePopupAfterSaveAndRefresh"></FreqUserEditPopup>

		<div class="altable-holder">
			<div style="display: flex; align-items: center; justify-content: flex-start; padding: 5px 0;">
				<div class="" style="margin: 5px 0;">
					<input type="text" style="width: 300px;" class="wl-input-text wl-input-padding-large" v-model="filterText" placeholder="Search users..." />
				</div>
				<button @click="addNewUser" style="margin-left: 15px;" type="button" class="option-button colbtn">Add New User</button>
			</div>
			<br/>
			<table class="altbl noborderv">
				<thead>
					<tr>
						<td v-for="col in cols" :key="col.title" class="headingtext" @click="sortColumn(col.fldid)" data-f-bold="true">
							<span>{{col.title}}</span>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="rowitem in rowsForPage" :key="rowitem.id" @click="editUser(rowitem)" style="cursor: pointer;">
						<td>{{rowitem.id}}</td>
						<td>{{rowitem.email}}</td>
						<td>{{rowitem.market}}</td>
						<td>{{ucfirst(rowitem.status)}}</td>
						<td class="ctr">{{rowitem.mobLicIds.length}}</td>
						<td class="ctr">{{rowitem.desktopIds.length}}</td>
						<td class="ctr">{{rowitem.groupIds.length}}</td>
						<td class="ctr">{{intToYes(rowitem.viewCommunities)}}</td>
						<td>
							<span class="action-icon" title="Send password reminder email" @click.stop="emailPassRemind(rowitem)"><i class="mdi mdi-at"></i></span>
							<span class="action-icon" title="Clone user" @click.stop="cloneUserToNew(rowitem)"><i class="mdi mdi-content-copy"></i></span>
						</td>
					</tr>
				</tbody>
			</table>

			<div style="margin: 15px 0; display: flex; align-items: center;">
				<ALTablePaginator
						:paginationpage="paginationPage"
						:paginatedrowscount="paginatedRows.length"
						v-on:nextpage="nextpage" v-on:prevpage="prevpage"
						v-on:setpage="paginationPage = $event"
				></ALTablePaginator>
			</div>

		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import FreqUserEditPopup from "@/components/freq-usrmgr/FreqUserEditPopup";
import ALTablePaginator from "@/components/ALTablePaginator";

export default {
	name: "FreqUserMgrContent",
	mixins: [globalMixin],
	components: {
		ALTablePaginator,
		FreqUserEditPopup
	},
	props: {},
	data: function () {
		return {
			store: store,

			// table stuff
			filterText: '',
			sortColName: null,
			sortColOrder: 'desc',
			paginationRowsPerPage: 20,
			paginationPage: 1,
			cols: [
				{title: 'ID', sortable: true, fldid: 'id'},
				{title: 'Email', sortable: true, fldid: 'email'},
				{title: 'Market', sortable: true, fldid: 'market'},
				{title: 'Status', sortable: true, fldid: 'status'},
				{title: 'Mob Licenses', sortable: false, fldid: ''},
				{title: 'Full Licenses', sortable: false, fldid: ''},
				{title: 'Groups', sortable: false, fldid: ''},
				{title: 'View Comm', sortable: false, fldid: ''},
				{title: 'Functions', sortable: false, fldid: ''},
			],
			//end table stuff

			editUserPopupShown: false,
			selectedUserOb: {},

		}
	},
	computed: {
		//table stuff
		rows() {
			return this.tblPrepareFilteredRows(this.store.rrUsers, this.filterText, this.sortColName, this.sortColOrder)
		},
		paginatedRows() {
			return this.tblGetPaginatedRows(this.rows, this.paginationRowsPerPage)
		},
		rowsForPage() {
			if(!this.paginationRowsPerPage || !this.paginationPage) return [this.rows]
			return this.paginatedRows[this.paginationPage-1]
		},
		//end table stuff
	},
	methods: {
		//table stuff
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
		prevpage() {
			if(this.paginationPage > 1) this.paginationPage--
		},
		nextpage() {
			if(this.paginationPage < this.paginatedRows.length) this.paginationPage++
		},
		//end table stuff
		addNewUser() {
			let p = window.prompt('Please enter user email address');
			if(p.length > 0 && p.includes("@")) {
				let emval = p.toLowerCase().trim();
				let matches = this.store.rrUsers.find(item => item.email.toLowerCase() === emval);
				if(!matches) {
					this.selectedUserOb = {
						id: 0,
						email: emval,
					}
					this.editUserPopupShown = true;
				}
				else alert("Email address already has an account")
			}
			else {
				alert("Please enter a valid email address")
			}
		},
		editUser(userob) {
			this.selectedUserOb = userob;
			this.editUserPopupShown = true;
		},
		closePopupAfterSaveAndRefresh() {
			this.selectedUserOb = null;
			this.editUserPopupShown = false;
			this.getRRAdminInfo(); //from mixin - refreshes admin content for lists etc
		},
		emailPassRemind(userob) {
			this.store.showLoader = true;
			let targ = this.store.urlroot + "central/post-freq-vue/resend-password"; //this uses the Freq post target not WL directory like the rest
			this.simplePost(targ, {email: userob.email}).then(function (ret) { //use ret in function as returned data
				this.store.showLoader = false
				if(ret.success && ret.success === 1) this.showKalertSuccess(ret.successob, true)
				else this.showKalertError({message: 'Reminder successfully emailed'}, true)
			}.bind(this));
		},
		cloneUserToNew(srcuserob) {
			let p = window.prompt('Please enter user email address for new user');
			if(p.length > 0 && p.includes("@")) {
				let emval = p.toLowerCase().trim();
				let matches = this.store.rrUsers.find(item => item.email.toLowerCase() === emval);
				if(!matches) {
					let tempNewOb = {};
					for(let pr in srcuserob) {
						tempNewOb[pr] = srcuserob[pr]
					}
					tempNewOb.id = 0;
					tempNewOb.email = emval;
					tempNewOb.isClone = true;
					this.selectedUserOb = tempNewOb;
					this.editUserPopupShown = true;
				}
				else alert("Email address already has an account")
			}
			else {
				alert("Please enter a valid email address")
			}
		},

	},
	watch: {
		rows() { //whenever filter text or sort is changed, go back to Page 1 in pagination
			this.paginationPage = 1
		},
	},
	mounted() {

	}
}
</script>

<style scoped>

</style>