<template>
	<BlanketOverlayWithPopup title="Manage Matrix Flow" width="800" v-on:close="closeCancel">
		<template v-slot:main>
			<div class="brancheditor">
				<p>Used for example for when a list of talent is options in a checkbox parent, and the same list is used as cases in a matrix.<br/>
					Eg if a person in the checkbox is ticked as unfamiliar, you may want to hide their line in the matrix.
				</p>
				<br/>

				<p>Choose Matrix</p>
				<div class="select-holder">
					<select v-model="matrixChoice" class="selector">
						<option v-for="q in matrixQuestions" :key="q.qid" :value="q.qid">
							{{q.qid}}: {{q.qtext.split('|')[0].replace(/<[^>]+>/g, '')}}
						</option>
					</select>
				</div>
				<p>Matrix Case Affected</p>
				<div class="select-holder">
					<select v-model="matrixCase" class="selector">
						<option v-for="q in matrixCases" :key="q.qid" :value="q.qid">
							{{q.qtext_stripped}}
						</option>
					</select>
				</div>
				<p>Parent Question</p>
				<div class="select-holder">
					<select v-model="parentQid" class="selector">
						<option v-for="q in checkboxParents" :key="q.qid" :value="q.qid">
							{{q.qid}}: {{q.qtext_stripped}}
						</option>
					</select>
				</div>
				<p>Parent Option</p>
				<div class="select-holder">
					<select v-model="parentOption" class="selector">
						<option v-for="q in parentOptions" :key="q.optid" :value="q.optid">
							{{q.opttext}}
						</option>
					</select>
				</div>
				<p>Hide If...</p>
				<div class="select-holder">
					<select v-model="hideIfBool" class="selector">
						<option :value="null"></option>
						<option :value="true">Checked</option>
						<option :value="false">Unchecked</option>
					</select>
				</div>
			</div>
		</template>
		<template v-slot:buttons>
			<button type="button" class="option-button colbtn" @click="saveMatrixFlow">Submit</button>
			<button type="button" class="option-button" @click="closeCancel">Close</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup.vue";

export default {
	name: 'MatrixFlowEditor',
	components: {BlanketOverlayWithPopup},
	mixins: [globalMixin],
	props: {
		survob: {
			type: Object,
			required: false,
			default: null,
		},
		editingmatrixflow: {
			type: Object,
			required: false,
			default: null,
		},
	},
	data() {
		return {
			store: store,
			isFetching: false,

			mflowid: 0,
			matrixChoice: null,
			matrixCase: null,
			parentQid: null,
			parentOption: null,
			hideIfBool: null,
		}
	},
	computed: {
		matrixQuestions() {
			if(this.survob && this.survob.questions) {
				return this.survob.questions.filter(q => ['MatrixHeadCheck','MatrixHeadRadio'].includes(q.type))
			}
			return []
		},
		matrixCases() {
			if(this.survob && this.survob.questions) {
				let casequestions = []
				let foundstart = false
				let allIds = []
				let mtypes = ['MatrixHeadCheck','MatrixSubCheck','MatrixEndCheck','MatrixHeadRadio','MatrixSubRadio','MatrixEndRadio']
				for(let q of this.survob.questions) {
					if(q.qid === this.matrixChoice) foundstart = true
					if(foundstart === true) {
						if(mtypes.includes(q.type)) {
							if(['MatrixHeadCheck','MatrixHeadRadio'].includes(q.type)) {
								let qtarr = q.qtext.split('|')
								q.qtext_stripped = qtarr[1]
							}
							casequestions.push(q)
							allIds.push(q.qid)
						}
						if(['MatrixEndCheck','MatrixEndRadio'].includes(q.type)) {
							break
						}
					}
				}
				if(casequestions.length > 0) {
					casequestions.push({qid: allIds.join('|'), qtext_stripped: '--ALL--'})
				}
				return casequestions
			}
			return []
		},
		checkboxParents() {
			if(this.survob && this.survob.questions) {
				return this.survob.questions.filter(q => q.type === 'Checkbox')
			}
			return []
		},
		parentOptions() {
			if(this.survob && this.survob.questions && this.parentQid) {
				let alloptIds = []
				let myopts = []
				let parentQ = this.survob.questions.find(q => q.qid === this.parentQid)
				if(parentQ) {
					for(let opt of parentQ.options) {
						alloptIds.push(opt.optid)
						myopts.push({optid: opt.optid, opttext: opt.opttext})
					}
					myopts.push({optid: alloptIds.join('|'), opttext: '--ALL--'})
					return myopts
				}
			}
			return []
		},
	},
	methods: {
		closeCancel() {
			this.$emit('close')
		},
		saveMatrixFlow() {
			if(this.matrixChoice && this.matrixCase && this.parentQid && this.parentOption && this.hideIfBool !== null && !this.isFetching) {
				let targ = this.store.urlroot + "central/post-vue/questions/save-matrixflow"
				let sendob = {
					survid: this.survob.survid,
					mflowid: this.mflowid,
					matrixID: this.matrixChoice,
					caseID: this.matrixCase,
					parentID: this.parentQid,
					parentOpt: this.parentOption,
					checked: this.hideIfBool,
				}
				this.isFetching = true
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					this.$emit('update', ret)
				}.bind(this))
			}
		},
	},
	watch: {

	},
	mounted() {
		if(this.editingmatrixflow) {
			this.mflowid = this.editingmatrixflow.mflowid
			this.matrixChoice = this.editingmatrixflow.matrixID
			this.matrixCase = this.editingmatrixflow.caseQID
			this.parentQid = this.editingmatrixflow.parentID
			this.parentOption = this.editingmatrixflow.parentOpt
			let hib = null
			if(this.editingmatrixflow.checked === 1) hib = true
			if(this.editingmatrixflow.checked === 0) hib = false
			this.hideIfBool = hib
		}
	},
}
</script>

<style scoped>
.brancheditor p {
	line-height: 1.3;
}
.select-holder {
	margin: 5px 0 10px;
}
.selector {
	width: 100%;
}
</style>
