<template>
	<div>
		<BlanketOverlayWithPopup v-if="srvobin && (srvobin.id > 0 || srvobin.survid) && hasPopulated === true" title="Assign Survey Questions" width="800" :hastabs="false" v-on:close="closeCancel">
			<template v-slot:main>
				<div class="contained-popup-content">
					<div style="padding: 10px 0 10px 0;">
						<span class="settlbl">Survey ID: {{srvobin.dataloc}}</span>
					</div>
					<table>
						<tbody v-if="isRRAMS === true">
							<tr v-for="prop in propsToUse" :key="prop.id">
								<td><span class="settlbl">{{prop.name}}</span></td>
								<td><select class="saver selector" v-model="assignRR[prop.id]">
									<option :value="0"></option>
									<option v-for="q in survQuestions" :key="q.qid" :value="q.qid">{{q.qtext_stripped}}</option>
								</select></td>
							</tr>
						</tbody>
						<tbody v-if="isRRAMS === false">
						<tr v-for="prop in propsToUse" :key="prop.id">
							<td><span class="settlbl">{{prop.name}}</span></td>
							<td><select class="saver selector" v-model="assignStd[prop.id]">
								<option :value="0"></option>
								<option v-for="q in survQuestions" :key="q.qid" :value="q.qid">{{q.qtext_stripped}}</option>
							</select></td>
						</tr>
						</tbody>
					</table>
				</div>
			</template>
			<template v-slot:buttons>
				<button type="button" class="option-button colbtn" @click="saveInfo">Save Info</button>
				<button type="button" class="option-button" @click="closeCancel">Cancel</button>
			</template>
		</BlanketOverlayWithPopup>
	</div>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'
export default {
	name: "QuestionAssignPopup",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		srvobin: Object,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			hasPopulated: false,
			fullSurvob: null,

			assignRR: {
				statmost: 0,
				breakfast: 0,
				morning: 0,
				afternoon: 0,
				drive: 0,
				night: 0,
				workday: 0,
				weekend: 0,
				cumestart: 0,
				cumeend: 0,
				area: 0,
				gender: 0,
				tsllevel: 0,
				tsl_wknd: 0,
				agebroad: 0,
				agesub: 0,
				bfcumestart: 0,
				bfcumeend: 0,
				morncumestart: 0,
				morncumeend: 0,
				arvocumestart: 0,
				arvocumeend: 0,
				drivecumestart: 0,
				drivecumeend: 0,
				nightcumestart: 0,
				nightcumeend: 0,
				wkndcumestart: 0,
				wkndcumeend: 0,
				workcumestart: 0,
				workcumeend: 0,
				mostYN: 0,
				bfYN: 0,
				mornYN: 0,
				arvYN: 0,
				drvYN: 0,
				nightYN: 0,
				workYN: 0,
				wkndYN: 0,
				mostOtherYN: 0,
				bfOtherYN: 0,
				mornOtherYN: 0,
				arvOtherYN: 0,
				drvOtherYN: 0,
				nightOtherYN: 0,
				workOtherYN: 0,
				wkndOtherYN: 0,
				comments: 0,
				phoneNum: 0,
				children: 0,
				grocbuyer: 0,
				occstatus: 0,
				occgroup: 0,
				occindustry: 0,
				bestMusic: 0,
				bestComp: 0,
				bestCommunity: 0,
				bestNewMusic: 0,
				mostEntBfast: 0,
				bestEarlyDrive: 0,
				bestDrive: 0,
				bestEvening: 0,
				ethnicity: 0,
				iscallback: 0,
				isquotacall: 0,
			},
			assignStd: {
				gender: 0,
				age: 0,
				gb: 0,
				kids: 0,
				p1station: 0,
				cume: 0,
				fname: 0,
				lname: 0,
				email: 0,
				optin: 0,
			},

			survQuestions: [],

		}
	},
	computed: {
		isRRAMS() {
			if(this.fullSurvob && this.fullSurvob.isRegRatings && this.fullSurvob.isRegRatings === 1) return true
			return false
		},
		survDataLoc() {
			if(this.srvobin && this.srvobin.dataloc) return this.srvobin.dataloc
			else if(this.srvobin && this.srvobin.survid) return this.srvobin.survid
			return null
		},
		propsToUse() {
			let f = []
			if(this.isRRAMS === true) {
				for(let propname in this.assignRR) {
					f.push({id: propname, name: propname})
				}
			}
			else {
				for(let propname in this.assignStd) {
					f.push({id: propname, name: propname})
				}
			}
			return f
		},
	},
	methods: {
		populateContent() {
			if (this.srvobin) {
				if (this.isFetching === false) {
					this.isFetching = true
					let targ = this.store.urlroot + "central/post-vue/home/get-survey-questions";
					let sendob = {
						isRegRatings: this.isRRAMS,
						survid: this.survDataLoc
					};
					this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
						if(ret.survey) {
							this.fullSurvob = ret.survey
							if(ret.survey.isRegRatings === 1) {
								for(let propname in this.assignRR) {
									if(ret.survey.rrAssignedQ[propname]) this.assignRR[propname] = ret.survey.rrAssignedQ[propname]
								}
							}
							else {
								for(let propname in this.assignStd) {
									if(ret.survey[propname+'q']) this.assignStd[propname] = ret.survey[propname+'q']
								}
							}
							this.survQuestions = ret.survey.questions
						}
						this.isFetching = false
						this.store.showLoader = false
					}.bind(this));
				}
			}
			this.hasPopulated = true
		},
		closeCancel() {
			this.$emit('close', false);
		},
		saveInfo() {
			if (this.isFetching === false) {
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/home/assign-questions-saver";
				let sendob = {
					isRegRatings: this.isRRAMS,
					survid: this.survDataLoc,
					assignRR: this.assignRR,
					assignStd: this.assignStd,
				};
				this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
					this.isFetching = false
					this.$emit('close', true)
				}.bind(this));
			}
		},
	},
	watch: {
		srvobin() {
			if(this.srvobin.id || this.srvobin.survid) {
				this.populateContent()
			}
		},
	},
	mounted() {
		this.populateContent()
	},
}
</script>
<style scoped>
.saver {
	width: 200px;
}
.selector {
	width: 590px;
}
</style>