<template>
	<div v-if="store.sessionOk">
		<div id="container" :class="{pageLeftMenuTransposed : store.leftmenuhidden}">
			<LeftMenuWL></LeftMenuWL>
			<div id="bg">
				<div id="maincontent" class="noTopMenuBar">
					<div id="analysis" class="wl-admin-container">
						<p class="wl-main-headline">
							{{pageTitle}}
							<span v-if="store.user.superUser === 1" style="margin-left: 10px; font-size: 12px; font-family: 'Roboto';">
								<router-link to="/analysis-admin">Admin</router-link>
							</span>
						</p>
						<div v-if="analysisid && analysisid > 0">
							<div id="analysissurvdiv">
								<div style="width: 315px">
									<Autocompleter :searchlist="questions"
										idfldname="id" textfldname="label" :incomingval="chosenQuestionId"
										v-on:update="chosenQuestionId = $event.id"
									></Autocompleter>
								</div>
								<select class="wl-input-select" v-model="chosenDemogroupId">
									<option v-for="c in demogroups" :value="c.id" :key="c.id">{{c.title}}</option>
								</select>
								<div class="linkouts">
									<label>
										<input type="checkbox" v-model="showHighlightCells" id="showhighlights" />
										<span class="spanner">Highlights</span>
									</label>
									<span @click="exportToExcel('thetable')" class="excel-link" style="margin-left: 10px;">
										<i class="mdi mdi-file-excel-outline"></i>
									</span>

								</div>
							</div>
							<div id="ajaxResults" v-if="returnedData && returnedData.headings && returnedData.headings.length > 0">
								<p v-for="(h, i) in returnedData.headings" :key="i" class='tbl-heading'>{{h}}</p>
								<table class='restable' id='thetable'>
									<thead>
										<tr class='row1'>
											<td></td>
											<td v-for="(colhead, colind) in colheaders" :key="colhead.title"
												class='survheadline ctr' :class="{boldme : activeDataColInd === colind}"
												@click.stop="activeDataColInd = colind"
											>{{colhead}}</td>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(rk, rowind) in rowKeys" :key="rowind">
											<td class='stnNameGet'>
												<div class='tbl-bg'><span class='tbl-bg-fill' :style="'width:'+getCellWidth(rowind)+';'"></span></div>
												<span class='stnName'>{{getRowTitle(rowind)}}</span>
											</td>
											<td class="ctr vals" v-for="(ck, colInd) in colheaders" :key="ck"
												:class="getCellHighlightClass(rk,colInd)" data-t="n">
												{{getCellVal(rk, colInd)}}
											</td>
										</tr>
										<tr class="nosort"><td class='stnNameGet' data-nosort='1'>Number of People</td>
											<td v-for="(ck, colind) in colheaders" :key="ck" class='ctr vals' data-t="n">{{getCustomVal(colind, 'sampleSize')}}</td>
										</tr>
										<tr v-if="showMedianScore" class="nosort"><td class='stnNameGet' data-nosort='1'>Median</td>
											<td v-for="(ck, colind) in colheaders" :key="ck" class='ctr vals' data-t="n">{{getCustomVal(colind, 'medianScore')}}</td>
										</tr>
										<tr v-if="showMeanScore" class="nosort"><td class='stnNameGet' data-nosort='1'>Mean</td>
											<td v-for="(ck, colind) in colheaders" :key="ck" class='ctr vals' data-t="n">{{getCustomVal(colind, 'meanScore')}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div v-else>
							<p class='tbl-heading'>Select an Analysis Run to view</p>
							<br/><br/>
							<a v-for="analysis in analyses" :key="analysis.id" href="javascript:void(0);"
								class="analysis-lister" @click="analysisid = analysis.id">{{analysis.title}}
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import LeftMenuWL from "@/components/LeftMenuWL"
import {globalMixin} from "@/mixins"
import Autocompleter from "@/components/Autocompleter.vue";
import TableToExcel from "@linways/table-to-excel";

export default {
	name: "AnalysisPage",
	mixins: [globalMixin],
	components: {
		Autocompleter,
		LeftMenuWL
	},
	props: {},
	data: function () {
		return {
			store: store,
			isFetching: false,

			analysisid: null,
			analyses: [],
			analysisTitle: null,

			chosenQuestionId: 0,
			chosenDemogroupId: null,

			activeDataColInd: 0,
			showHighlightCells: false,

			questions: [],
			demogroups: [],

			returnedData: [],
			showMeanScore: false,
			showMedianScore: false,
		}
	},
	computed: {
		highlightDataCol() {
			let arr = []
			let rr = this.resultRows[this.activeDataColInd]
			if(rr.results) {
				for(let myres of rr.results) {
					arr.push(parseFloat(myres.val))
				}
			}
			return arr
		},
		pageTitle() {
			if(this.analysisid === null) return 'No analysis selected'
			return this.analysisTitle
		},
		colheaders() {
			if(this.returnedData && this.returnedData.colKeys) {
				return this.returnedData.colKeys
			}
			return null
		},
		rowKeys() {
			if(this.returnedData && this.returnedData.rowKeys) {
				return this.returnedData.rowKeys
			}
			return null
		},
		resultRows() {
			if(this.returnedData && this.returnedData.resultSets) {
				return this.returnedData.resultSets
			}
			return null
		},
	},
	methods: {
		getCellHighlightClass(rk, colind) {
			if(this.showHighlightCells === true) {
				let cellVal = this.getCellVal(rk, colind)
				let valarr = this.resultRows[colind].results.map(item => parseFloat(item.val))
				valarr.sort((a, b) => b - a)
				let labels = ['high', 'high2', 'high3', 'high4']
				for(let i=0; i<4; i++) {
					if(cellVal >= valarr[i]) return labels[i]
				}
			}
			return ''
		},
		getCellWidth(rowind) {
			if(this.highlightDataCol[rowind]) {
				return this.highlightDataCol[rowind] + '%'
			}
			return 0
		},
		getAnalysesList() {
			if(!this.isFetching) {
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/analysis/get-analysis-list"
				let sendob = {}
				return this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					if(ret.analyses) this.analyses = ret.analyses
					this.store.showLoader = false
				}.bind(this))
			}
		},
		getInitialData() {
			if(!this.isFetching) {
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/analysis/get-analysis-initial"
				let sendob = {
					aid: this.analysisid
				}
				return this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					if(ret.questions) this.questions = ret.questions
					if(ret.demogroups) this.demogroups = ret.demogroups
					if(ret.title) this.analysisTitle = ret.title
					setTimeout(function() {
						if(!this.chosenDemogroupId) this.chosenDemogroupId = this.demogroups[0].id
						this.chosenQuestionId = this.questions[0].id
					}.bind(this), 50)

					this.store.showLoader = false
				}.bind(this))
			}
		},
		getResultData() {
			if(!this.isFetching && this.chosenQuestionId && this.chosenDemogroupId && this.chosenQuestionId > 0 && this.chosenDemogroupId > 0) {
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/analysis/get-analysis-results"
				let sendob = {
					analysisid: this.analysisid,
					qid: this.chosenQuestionId,
					dgid: this.chosenDemogroupId,
				}
				return this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					this.showMeanScore = false
					this.showMedianScore = false
					if(ret.table) {
						this.returnedData = ret.table
						if(ret.table.showMeanScore) this.showMeanScore = ret.table.showMeanScore
						if(ret.table.showMedianScore) this.showMedianScore = ret.table.showMedianScore
					}
					this.store.showLoader = false
				}.bind(this))
			}
		},
		getCellVal(rowkey, colInd) {
			if(this.resultRows[colInd] && this.resultRows[colInd].results) {
				let rowdata = this.resultRows[colInd].results.find(item => item.key === rowkey)
				if(rowdata) return parseFloat(rowdata.val)
			}
			return ''
		},
		getCustomVal(colInd, valtype) {
			if(this.resultRows[colInd]) {
				if(valtype === 'sampleSize') return parseInt(this.resultRows[colInd].sampleSize)
				else if(valtype === 'medianScore') {
					if(this.resultRows[colInd].medianScore) {
						return parseFloat(this.resultRows[colInd].medianScore)
					}
				}
				else if(valtype === 'meanScore') {
					if(this.resultRows[colInd].meanScore) {
						return parseFloat(this.resultRows[colInd].meanScore)
					}
				}
			}
			return ''
		},
		getRowTitle(rowind) {
			if(this.resultRows[0].results[rowind]) {
				return this.resultRows[0].results[rowind].title
			}
			return ''
		},
		exportToExcel(tblid, filename = 'export') {
			if(this.excelfilename && this.excelfilename.length > 0) {
				filename = this.excelfilename;
			}
			TableToExcel.convert(document.getElementById(tblid), {
				name: filename+".xlsx",
				sheet: {
					name: 'Sheet 1'
				}
			});
		},
	},
	watch: {
		analysisid() {
			if(!this.analysisid) this.getAnalysesList()
			else if(this.analysisid > 0) {
				this.$router.push('/analysis/'+this.analysisid)
				this.getInitialData()
			}
		},
		'$route.fullPath'() {
			if(this.$route.fullPath === '/analysis') {
				this.analysisid = null
				this.analysisTitle = null
			}
		},
		chosenDemogroupId() {
			this.getResultData()
		},
		chosenQuestionId() {
			this.getResultData()
		},
	},
	mounted() {
		if(!this.analysisid) this.getAnalysesList()
	},
	created() {
		document.title = "Analysis"
		this.store.activeLmenuLink = 'analysis'
		let urlparams = this.$route.params
		if(urlparams.analysisid !== undefined) {
			this.analysisid = parseInt(urlparams.analysisid)
		}
	}
}
</script>

<style scoped>
#analysis .tbl-heading {
	font-size: 15px;
	font-weight: bold;
	font-family: 'Roboto Slab';
	padding-top: 10px;
}
#analysissurvdiv {
	display: flex;
	margin-bottom: 10px;
}
#analysissurvdiv select {
	width: 250px;
	margin-top: 0;
	margin-left: 10px;
}
#ajaxResults {
	line-height: 1.3;
}
#ajaxResults table {
	margin-top: 10px;
}
#ajaxResults table .survheadline {
	text-transform: uppercase;
	font-size: 10px;
	padding: 5px 10px;
	cursor: default;
}
#ajaxResults table tr:nth-of-type(odd) {
	background-color: #F9F9F9;
}
#ajaxResults table thead tr:nth-of-type(1), .row1 {
	background-color: #EFEFEF;
}
#ajaxResults table {
	border-collapse: collapse;
	border: 1px solid #CCC;
}
#ajaxResults table td {
	padding: 6px 5px 3px;
	cursor: default;
	border-left: 1px solid #EFEFEF;
	border-bottom: 1px solid #EFEFEF;
}
#ajaxResults table tr td:first-of-type {
	border-left: 1px solid #CCC;
	min-width: 150px;
	white-space: nowrap;
}
#ajaxResults table tr:last-of-type td {
	border-bottom: 1px solid #CCC;
}
#ajaxResults table .boldme {
	font-family: Roboto,'Arial';
	font-weight:bold;
}
#ajaxResults table .ctr {
	text-align: center;
}
#ajaxResults table .bordleft {
	border-left: 1px solid #CCC;
}
#thetable tbody tr:hover {
	background: #EEE;
}
.high {
	background-color: #23B723;
}
.high2 {
	background-color: #48DC48;
}
.high3 {
	background-color: #7FE881;
}
.high4 {
	background-color: #C0F5C1;
}
.stnNameGet {
	position: relative;
}
.tbl-bg {
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
}
.tbl-bg-fill {
	display: block;
	position: absolute;
	height: 100%;
	width: 0;
	background: rgba(35,166,238,0.3);
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}
.stnName {
	z-index: 10;
	position: relative;
}
.ui-front {
	z-index: 991;
}
#qselection_autocomplete {
	border: 1px solid #CCC;
	border-radius: 5px;
	padding: 5px;
	width: 240px;
	margin-right: 10px;
}
.analysis-lister {
	display: block;
	margin-bottom: 5px;
	text-decoration: none;
}
.linkouts {
	display: flex;
	align-items: center;
}
.linkouts label {
	display: flex;
	align-items: center;
}
.linkouts input {
	padding: 0;
	margin: 0;
}
.linkouts .spanner {
	margin-left: 5px;
}
.excel-link {
	cursor: pointer;
	padding: 4px 0;
}
.excel-link i {
	font-size: 20px;
}
</style>