<template>
	<BlanketOverlayWithPopup title="Survey Entry Link" width="800" v-on:close="closeCancel">
		<template v-slot:main>
			<p style="padding: 20px 0 15px;">{{elink}}</p>
		</template>
		<template v-slot:buttons>
			<button type="button" class="option-button" @click="closeCancel">Close</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {store} from '@/store.js'

export default {
	name: "EntryLinkScreen",
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		survid: Number,
		surveys: Array,
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		survobj() {
			return this.surveys.find(s => s.id === this.survid)
		},
		elink() {
			let source = ''
			let survpan = this.survobj.panel
			if(['ninerewards','teg'].includes(survpan)) source = 'teg'
			else if(survpan === 'purespectrum') source = 'purespectrum'
			else if(survpan === 'walr') source = 'walr'
			else if(['pureprof','pureprofile'].includes(survpan)) source = 'pureprof'
			return "https://survey.yourwavelength.com/"+this.survid+"?source="+source+"&id=testid"
		},
	},
	methods: {
		closeCancel() {
			this.$emit('close');
		},

	},
	watch: {

	},
	mounted() {

	},
}
</script>

<style scoped>

</style>