<template>
	<div v-if="store.sessionOk">
		<div id="container" :class="{pageLeftMenuTransposed : store.leftmenuhidden}">
			<LeftMenuWL></LeftMenuWL>
			<div id="bg">
				<div id="maincontent" class="noTopMenuBar">
					<div class="tabdiv">
						<a class="tab" @click="changeTab('analysis')" :class="{active: activeTab === 'analysis'}"><span>Analysis</span></a>
						<a class="tab" @click="changeTab('demogroups')" :class="{active: activeTab === 'demogroups'}"><span>DemoGroups</span></a>
						<a class="tab" @click="changeTab('demos')" :class="{active: activeTab === 'demos'}"><span>Demos</span></a>
						<a class="tab" @click="changeTab('editor')" :class="{active: activeTab === 'editor'}"><span>Editor</span></a>
					</div>

					<AnalysisList v-if="activeTab === 'analysis'"></AnalysisList>
					<DemogroupsList v-if="activeTab === 'demogroups'"></DemogroupsList>
					<DemosList v-if="activeTab === 'demos'" @editdemos="goToDemoEditor($event)"></DemosList>
					<DemoEditor v-if="activeTab === 'editor'" :demoids="editingDemoIds"></DemoEditor>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import LeftMenuWL from "@/components/LeftMenuWL";
import {globalMixin} from "@/mixins";
import AnalysisList from "@/components/analysis-admin/AnalysisList";
import DemogroupsList from "@/components/analysis-admin/DemogroupsList";
import DemosList from "@/components/analysis-admin/DemosList.vue";
import DemoEditor from "@/components/analysis-admin/DemoEditor.vue";

export default {
	name: "AnalysisAdminPage",
	mixins: [globalMixin],
	components: {
		DemoEditor,
		DemosList,
		LeftMenuWL,
		AnalysisList,
		DemogroupsList,
	},
	props: {},
	data: function () {
		return {
			store: store,
			activeTab: 'analysis',

			editingDemoIds: [],
		}
	},
	computed: {},
	methods: {
		goToDemoEditor(idArr) {
			this.editingDemoIds = idArr
			this.activeTab = 'editor'
		},
		changeTab(newtabname) {
			if(this.activeTab !== newtabname) this.activeTab = newtabname;
		},
	},
	watch: {},
	mounted() {

	},
	created() {
		document.title = "Analysis Admin";
		this.store.activeLmenuLink = 'analysis';
	}
}
</script>

<style scoped>

</style>