<template>
	<div>
		<div>

			<StationEditPopup v-if="showEditStationPopup" :stnobin="activeEditStation"
				:forcenewstation="forceNewStation" @close="closeEditStationPopup($event)"
			></StationEditPopup>

			<div v-if="activeEditSurvey" class="">
				<span class="wl-paginate-btn" @click="$emit('close', false)"><i class="mdi mdi-chevron-left"></i> Back</span>

				<p style="font-size: 18px; margin-top: 10px;">
					{{activeEditSurvey.name}} ({{activeEditSurvey.dataloc}})
					<button @click="saveMappings" style="margin-left: 15px;" type="button" class="option-button colbtn">Save Mappings</button>
				</p>

				<div>

					<div>
						<span style="margin-right: 10px;">Edit Station</span>
						<select v-model="activeEditStation">
							<option :value="null"></option>
							<option v-for="s in stationsInMarket" :key="s.stid" :value="s">
								{{s.name}} ({{s.stid}}) {{getCommunityFlag(s)}}
							</option>
						</select>
						<button style="margin: 10px;" @click="editStation(activeEditStation)">Edit</button>
						<button @click="addNewStation">Add New Station</button>
					</div>

					<p style="margin: 10px 0;">
						<span style="margin-right: 10px;">DQ Vals</span><input class="dqinp" type="text" v-model="dqvals" />
					</p>

					<div class="assignshold" style="margin-top: 20px;">
						<div class="assign-left">
							<p style="font-size: 14px; font-weight: bold; margin: 10px 0 15px;">Questionnaire/Alpha Order</p>
							<div>
								<p class="assigner" v-for="i in 40" :key="i" style="margin: 5px 0;">
									<span style="width: 80px; display: inline-block;">Station {{i}}</span>
									<select v-model="stationMapsQuestionnaire[i-1]">
										<option :value="0"></option>
										<option v-for="s in stationsInMarket" :key="s.stid" :value="s.stid">
											{{s.name}} ({{s.stid}}) {{getCommunityFlag(s)}}
										</option>
									</select>
								</p>
							</div>
						</div>
						<div class="assign-right">
							<p style="font-size: 14px; font-weight: bold; margin: 10px 0 15px;">Publish Order</p>
							<div>
								<p class="assigner" v-for="i in 40" :key="i" style="margin: 5px 0;">
									<span style="width: 80px; display: inline-block;">Station {{i}}</span>
									<select v-model="stationMapsPublishOrder[i-1]">
										<option :value="0"></option>
										<option v-for="s in stationsInMarket" :key="s.stid" :value="s.stid">
											{{s.name}} ({{s.stid}}) {{getCommunityFlag(s)}}
										</option>
									</select>
								</p>
							</div>
						</div>

					</div>


				</div>
			</div>

		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import StationEditPopup from "@/components/regional-ratings-admin/StationEditPopup.vue";

export default {
	name: "MapStationsPage",
	components: {
		StationEditPopup

	},
	props: {
		srvob: Object,
	},
	mixins: [globalMixin],
	data: function() {
		return {
			store: store,
			filterText: '',

			activeEditSurvey: null,
			activeEditStation: null,
			showEditStationPopup: false,
			forceNewStation: false,

			dqvals: null, //pipe separated in database
			stationMapsQuestionnaire: [], //array
			stationMapsPublishOrder: [], //comma separated in database

		}
	},
	computed: {
		stationsInMarket() {
			return this.store.rrStations.filter(s => s.mktid === this.activeEditSurvey.mktid)
		},
	},
	methods: {
		closeEditStationPopup(doRefresh) {
			this.showEditStationPopup = false
			if(doRefresh === true) this.getRRAdminInfo()
		},
		addNewStation() {
			this.forceNewStation = true
			this.activeEditStation = null
			this.showEditStationPopup = true
		},
		editStation(stnob) {
			this.forceNewStation = false
			this.activeEditStation = stnob
			this.showEditStationPopup = true
		},
		getCommunityFlag(stob) 	{
			if(stob.community === 1) return '***'
			else return ''
		},
		saveMappings() {
			let targ = this.store.urlroot + "central/post-vue/regional-ratings-admin/save-station-maps"
			let sendob = {
				survid: this.activeEditSurvey.dataloc,
				dqvals: this.dqvals,
				pubOrder: this.stationMapsPublishOrder,
				stnQuestionOrder: this.stationMapsQuestionnaire,
			}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				this.store.showLoader = false
				this.getMappings()
				if(ret.saved === true) {
					this.showKalert({success: true, message: 'Mappings saved.'})
				}
			}.bind(this));
		},
		getMappings() {
			let targ = this.store.urlroot + "central/post-vue/regional-ratings-admin/get-station-maps"
			let sendob = {survid: this.activeEditSurvey.dataloc}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				this.store.showLoader = false
				if(ret.dqVals) this.dqvals = ret.dqVals
				if(ret.pubOrder) {
					let puborder = [] //clean mappings, remove any stations that are not in the market
					for(let stid of ret.pubOrder) {
						if(this.stationsInMarket.find(s => s.stid === stid)) puborder.push(stid)
					}
					this.stationMapsPublishOrder = puborder
				}
				if(ret.stationMapsQuestionnaire) {
					let stnquestorder = [] //clean mappings, remove any stations that are not in the market
					for(let stid of ret.stationMapsQuestionnaire) {
						if(this.stationsInMarket.find(s => s.stid === stid)) stnquestorder.push(stid)
					}
					this.stationMapsQuestionnaire = stnquestorder
				}
			}.bind(this));
		},
	},
	watch: {
		activeEditSurvey() {
			if(this.activeEditSurvey && this.activeEditSurvey.id > 0) {
				this.getMappings()
			}
		}
	},
	mounted() {
		this.activeEditSurvey = JSON.parse(JSON.stringify(this.srvob))
	},
}
</script>

<style scoped>
.assignshold {
	display: flex;
	justify-content: flex-start;
	gap: 60px;
}
.dqinp {
	border: 1px solid #888;
	padding: 5px 8px;
}
</style>