<template>
	<div>

		<RRSurveyEditPopup v-if="activeEditSurvey && showEditSurveyPopup === true"
			:srvobin="activeEditSurvey" v-on:close="closeEditSurveyPopup($event)"
		></RRSurveyEditPopup>

		<WeightingEditPopup v-if="activeEditSurvey && showEditWeightingFigsPopup === true"
			:survobin="activeEditSurvey" v-on:close="finishEditWeightingFigs($event)"
		></WeightingEditPopup>

		<QuestionAssignPopup v-if="activeEditSurvey && showAssignQuestionsPopup === true"
			:srvobin="activeEditSurvey" v-on:close="closeAssignQuestionsPopup($event)"
		></QuestionAssignPopup>

		<div>

			<ValidatorPage v-if="activeEditSurvey && pageView === 'validate'"
				:srvob="activeEditSurvey" v-on:close="closeValidationView($event)"
			></ValidatorPage>

			<MapStationsPage v-if="activeEditSurvey && pageView === 'mapStations'"
				:srvob="activeEditSurvey" v-on:close="closeMapStationsView($event)"
			></MapStationsPage>

			<div v-if="activeEditSurvey && pageView === 'main'" class="radminresults">
				<span class="wl-paginate-btn" @click="$emit('close')"><i class="mdi mdi-chevron-left"></i> Back</span>

				<p style="font-size: 18px; margin-top: 30px;">{{activeEditSurvey.name}} ({{activeEditSurvey.dataloc}})</p>
				<ul>
					<li>
						<span class='rrsp'>Data Exists: </span>
						<i v-if="checkResults.dataExist === true" class="rricon mdi mdi-check-bold greenish"></i>
						<i v-else class="rricon mdi mdi-close-thick reddish"></i>
					</li>
					<li>
						<span class='rrsp'>Questions Assigned: </span>
						<i v-if="checkResults.qassign === true" class="rricon mdi mdi-check-bold greenish"></i>
						<i v-else class="rricon mdi mdi-close-thick reddish"></i>
						<a href='javascript:void(0);' @click='goToAssign'>Assign Now</a>
					</li>
					<li>
						<span class='rrsp'>Stations Mapped: </span>
						<i v-if="checkResults.stnmaps === true" class="rricon mdi mdi-check-bold greenish"></i>
						<i v-else class="rricon mdi mdi-close-thick reddish"></i>
						<a href='javascript:void(0);' @click='mapStations()'>Assign Now</a>
					</li>
					<li>
						<span class='rrsp'>Market Population Set: </span>
						<i v-if="activeEditSurvey.mktpop > 0" class="rricon mdi mdi-check-bold greenish"></i>
						<i v-else class="rricon mdi mdi-close-thick reddish"></i>
						<a href='javascript:void(0);' @click='editSurvey'>Edit</a>
					</li>
					<li>
						<span class='rrsp'>Validate for Gold Standard: </span>
						<i v-if="activeEditSurvey.isGSValidated === 1" class="rricon mdi mdi-check-bold greenish"></i>
						<i v-else class="rricon mdi mdi-close-thick reddish"></i>
						<a href='javascript:void(0);' @click='goToValidate'>Validate Now</a>
					</li>
					<li>
						<span class='rrsp'>Weighting Targets Set: </span>
						<i v-if="checkResults.wtFigs === true" class="rricon mdi mdi-check-bold greenish"></i>
						<i v-else class="rricon mdi mdi-close-thick reddish"></i>
						<a href='javascript:void(0);' @click='addWeights()'>Add Weights</a>
					</li>
					<li>
						<span class='rrsp'>Weighting Calculated: </span>
						<i v-if="checkResults.wtGen === true" class="rricon mdi mdi-check-bold greenish"></i>
						<i v-else class="rricon mdi mdi-close-thick reddish"></i>
						<a v-if="checkResults.wtFigs === true" href='javascript:void(0);' @click='runWeighting()'>Weight Now</a>
					</li>
					<li>
						<span class='rrsp'>Processing Complete: </span>
						<i v-if="activeEditSurvey.hasBeenProcessed === 1" class="rricon mdi mdi-check-bold greenish"></i>
						<i v-else class="rricon mdi mdi-close-thick reddish"></i>
						<a href='javascript:void(0);' @click='editSurvey'>Edit</a>
					</li>
					<li>
						<span class='rrsp'>Desktop Active: </span>
						<i v-if="activeEditSurvey.enabledDesktop === 1" class="rricon mdi mdi-check-bold greenish"></i>
						<i v-else class="rricon mdi mdi-close-thick reddish"></i>
						<a href='javascript:void(0);' @click='editSurvey'>Edit</a>
					</li>
					<li>
						<span class='rrsp'>Mobile Active: </span>
						<i v-if="activeEditSurvey.enabledMobile === 1" class="rricon mdi mdi-check-bold greenish"></i>
						<i v-else class="rricon mdi mdi-close-thick reddish"></i>
						<a href='javascript:void(0);' @click='editSurvey'>Edit</a>
					</li>
					<li>
						<span class='rrsp'>Agency Active: </span>
						<i v-if="activeEditSurvey.enabledAgency === 1" class="rricon mdi mdi-check-bold greenish"></i>
						<i v-else class="rricon mdi mdi-close-thick reddish"></i>
						<a href='javascript:void(0);' @click='editSurvey'>Edit</a>
					</li>
					<li>
						<span class='rrsp'>Delayed/Late User Active: </span>
						<i v-if="activeEditSurvey.enabledLate === 1" class="rricon mdi mdi-check-bold greenish"></i>
						<i v-else class="rricon mdi mdi-close-thick reddish"></i>
						<a href='javascript:void(0);' @click='editSurvey'>Edit</a>
					</li>
				</ul>

				<div v-if="weightingHtmlReturn" style="margin: 30px 0;" v-html="weightingHtmlReturn"></div>
			</div>

		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import RRSurveyEditPopup from "@/components/regional-ratings-admin/RRSurveyEditPopup.vue";
import WeightingEditPopup from "@/components/regional-ratings-admin/WeightingEditPopup.vue";
import QuestionAssignPopup from "@/components/home/QuestionAssignPopup.vue";
import ValidatorPage from "@/components/regional-ratings-admin/ValidatorPage.vue";
import MapStationsPage from "@/components/regional-ratings-admin/MapStationsPage.vue";

export default {
	name: "ValidationPage",
	components: {
		MapStationsPage,
		ValidatorPage,
		QuestionAssignPopup,
		WeightingEditPopup,
		RRSurveyEditPopup

	},
	props: {
		srvob: Object,
	},
	mixins: [globalMixin],
	data: function() {
		return {
			store: store,
			filterText: '',

			pageView: 'main', //main, validate, mapStations
			activeEditSurvey: null,
			showEditSurveyPopup: false,
			showEditWeightingFigsPopup: false,
			showAssignQuestionsPopup: false,
			checkResults: {
				dataExist: false,
				qassign: false,
				wtFigs: false,
				wtGen: false,
				stnmaps: false,
			},
			weightingHtmlReturn: null,
		}
	},
	computed: {

	},
	methods: {
		mapStations() {
			this.pageView = 'mapStations'
		},
		closeMapStationsView(doRefresh) {
			this.pageView = 'main'
			if(doRefresh === true) this.getSurveyValidationChecks()
		},
		goToValidate() {
			this.pageView = 'validate'
		},
		closeValidationView(doRefresh) {
			this.pageView = 'main'
			if(doRefresh === true) this.getRRAdminInfo()
		},
		addWeights() {
			this.showEditWeightingFigsPopup = true
		},
		finishEditWeightingFigs(doRefresh) {
			this.showEditWeightingFigsPopup = false
			if(doRefresh === true) this.getSurveyValidationChecks() //refresh checks
		},
		runWeighting() {
			let targ = this.store.urlroot + "central/post-vue/home/generate-weights"
			let sendob = {survid: this.activeEditSurvey.dataloc}
			this.weightingHtmlReturn = null
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				this.store.showLoader = false
				if(ret.success) this.getSurveyValidationChecks() //refresh checks
				if(ret.rethtml) this.weightingHtmlReturn = ret.rethtml
			}.bind(this));
		},
		editSurvey() {
			this.showEditSurveyPopup = true
		},
		closeEditSurveyPopup(doRefresh) {
			this.showEditSurveyPopup = false
			if(doRefresh === true) this.getRRAdminInfo()
		},
		getSurveyValidationChecks() {
			let targ = this.store.urlroot + "central/post-vue/regional-ratings-admin/check-survey-info"
			let sendob = {survid: this.activeEditSurvey.dataloc}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				this.store.showLoader = false
				if(ret.results) this.checkResults = ret.results
			}.bind(this));
		},
		goToAssign() {
			this.showAssignQuestionsPopup = true
		},
		closeAssignQuestionsPopup(doRefresh) {
			this.showAssignQuestionsPopup = false
			if(doRefresh === true) this.getSurveyValidationChecks() //refresh checks
		},
	},
	watch: {
		activeEditSurvey() {
			if (this.activeEditSurvey && this.activeEditSurvey.id > 0) this.getSurveyValidationChecks()
		},
		'store.rrSurveys':{
			deep: true,
			handler() {
				if(this.activeEditSurvey && this.activeEditSurvey.id > 0) {
					let srvob = this.store.rrSurveys.find(item => item.id === this.activeEditSurvey.id)
					if(srvob) this.activeEditSurvey = JSON.parse(JSON.stringify(srvob))
				}
			}
		},
	},
	mounted() {
		this.activeEditSurvey = JSON.parse(JSON.stringify(this.srvob))
	},
}
</script>

<style scoped>
.radminresults ul {
	list-style: none;
	margin: 20px 0;
	padding: 0;
}
.radminresults ul li {
	height: 40px;
	border-bottom: 1px #CCC solid;
	width: 460px;
	display: flex;
	align-items: center;
}
.rrsp {
	font-size: 16px;
	width: 260px;
}
.rricon {
	color: #0A0;
	font-size: 30px;
	width: 60px;
	text-align: center;
	margin-right: 20px;
}
.reddish {
	color: #A00;
}
.greenish {
	color: #0A0;
}
</style>