<template>
	<div v-if="store.sessionOk">
		<div id="container" :class="{pageLeftMenuTransposed : store.leftmenuhidden}">
			<LeftMenuWL></LeftMenuWL>
			<div id="bg">
				<div id="maincontent" class="noTopMenuBar">
					<div class="tabdiv">
						<a class="tab" @click="changeTab('surveys')" :class="{active: activeTab === 'surveys'}"><span>Surveys</span></a>
						<a class="tab" @click="changeTab('markets')" :class="{active: activeTab === 'markets'}"><span>Markets</span></a>
						<a class="tab" @click="changeTab('stations')" :class="{active: activeTab === 'stations'}"><span>Stations</span></a>
						<a class="tab" @click="changeTab('moblic')" :class="{active: activeTab === 'moblic'}"><span>Mobile Licenses</span></a>
						<a class="tab" @click="changeTab('aud_domains')" :class="{active: activeTab === 'aud_domains'}"><span>Audology Domains</span></a>
					</div>

					<MarketsPage v-if="activeTab === 'markets'"></MarketsPage>
					<SurveysPage v-if="activeTab === 'surveys'"></SurveysPage>
					<StationsPage v-if="activeTab === 'stations'"></StationsPage>
					<MobileLicencesPage v-if="activeTab === 'moblic'"></MobileLicencesPage>
					<AudologyDomains v-if="activeTab === 'aud_domains'"></AudologyDomains>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import LeftMenuWL from "@/components/LeftMenuWL";
import {globalMixin} from "@/mixins";
import MarketsPage from "@/components/regional-ratings-admin/MarketsPage";
import SurveysPage from "@/components/regional-ratings-admin/SurveysPage.vue";
import StationsPage from "@/components/regional-ratings-admin/StationsPage.vue";
import MobileLicencesPage from "@/components/regional-ratings-admin/MobileLicencesPage.vue";
import AudologyDomains from "@/components/regional-ratings-admin/AudologyDomains.vue";

export default {
	name: "RegionalRatingsAdmin",
	mixins: [globalMixin],
	components: {
		AudologyDomains,
		MobileLicencesPage,
		StationsPage,
		SurveysPage,
		MarketsPage,
		LeftMenuWL
	},
	props: {},
	data: function () {
		return {
			store: store,
			activeTab: 'surveys',
		}
	},
	computed: {},
	methods: {
		changeTab(newtabname) {
			if(this.activeTab !== newtabname) this.activeTab = newtabname;
		},
	},
	watch: {},
	mounted() {

	},
	created() {
		document.title = "Regional Ratings Admin";
		this.store.activeLmenuLink = 'ratingsAdmin';
	}
}
</script>

<style scoped>

</style>