<template>
	<div class="wl-admin-container">

		<BlanketOverlayWithPopup v-if="showPopupEditor" title="Edit Domain" width="1000" v-on:close="closeCancel">
			<template v-slot:main>
				<table>
					<tbody>
					<tr>
						<td><span class="settlbl">Domain</span></td>
						<td><input class="saver" type="text" v-model="activeEditDomain.domain"></td>
					</tr>
					<tr>
						<td><span class="settlbl">Active</span></td>
						<td>
							<select v-model="activeEditDomain.active">
								<option :value="0">No</option>
								<option :value="1">Yes</option>
							</select>
						</td>
					</tr>
					<tr>
						<td><span class="settlbl">Is Agency</span></td>
						<td>
							<select v-model="activeEditDomain.is_agency">
								<option :value="0">No</option>
								<option :value="1">Yes</option>
							</select>
						</td>
					</tr>
					<tr>
						<td colspan="2"><span class="settlbl">SurvIdArr String</span></td>
					</tr>
					<tr>
						<td colspan="2">
							<textarea style="height: 300px;" v-model="activeEditDomain.survidarr"></textarea>
						</td>
					</tr>
					<tr>
						<td colspan="2"><span class="settlbl">Inherit</span></td>
					</tr>
					<tr>
						<td colspan="2">
							<textarea style="height: 100px;" v-model="activeEditDomain.inherit"></textarea>
						</td>
					</tr>
					</tbody>
				</table>
			</template>
			<template v-slot:buttons>
				<button type="button" class="option-button colbtn" @click="saveDomainInfo">Save Info</button>
				<button type="button" class="option-button" @click="closeCancel">Cancel</button>
			</template>
		</BlanketOverlayWithPopup>

		<div>
			<table class="altbl noborderv">
				<thead>
					<tr class='headrow'>
						<td class="headingtext">Domain</td>
						<td class="headingtext">Active</td>
						<td class="headingtext">Is Agency</td>
						<td class="headingtext">Inherit</td>
						<td class="headingtext">Functions</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="d in domains" :key="d.domain">
						<td>{{d.domain}}</td>
						<td>{{d.active}}</td>
						<td>{{d.is_agency}}</td>
						<td>{{d.inherit}}</td>
						<td>
							<i class="mdi mdi-pencil" title="Edit" style="cursor: pointer; margin-right: 10px; font-size: 14px;" @click="editDomain(d)"></i>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup.vue";

export default {
	name: "AudologyDomains",
	components: {
		BlanketOverlayWithPopup

	},
	props: {},
	mixins: [globalMixin],
	data: function() {
		return {
			store: store,
			domains: [],
			activeEditDomain: null,
			showPopupEditor: false,

			auth: 'jaidAi3ladgpdad93-04dalkf9ad',
			tok: '823klad90f-da98dnald83Da-mnz',
		}
	},
	computed: {

	},
	methods: {
		editDomain(domOb) {
			this.activeEditDomain = JSON.parse(JSON.stringify(domOb))
			this.showPopupEditor = true
		},
		closeCancel() {
			this.activeEditDomain = null
			this.showPopupEditor = false
		},
		saveDomainInfo() {
			let targ = 'https://platform.audology.app/wl-post/save-domain.php'
			let sendob = {auth: this.auth, token: this.tok, domOb: this.activeEditDomain}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				if(ret.success) this.showKalertSuccess({message: 'Changes saved!'}, true)
				else if(ret.error) this.showKalertError({message: 'Error: '+ret.error}, true)
				this.closeCancel()
				this.getDomainsInfo()
			}.bind(this));
		},
		getDomainsInfo() {
			let targ = 'https://platform.audology.app/wl-post/get-domain-assignations.php'
			let sendob = {auth: this.auth, token: this.tok}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				if(ret.domains) this.domains = ret.domains
			}.bind(this));
		},
	},
	mounted() {
		this.getDomainsInfo()
	},
}
</script>

<style scoped>
textarea {
	width: 930px;
}
</style>