<template>
	<BlanketOverlayWithPopup title="Earn Codes" width="500" v-on:close="closeCancel">
		<template v-slot:main>
			<table>
				<tbody>
				<tr>
					<td><span class="settlbl">Survey ID</span></td>
					<td id="survid" style="padding: 10px 0;">{{survid}}</td>
				</tr>
				<tr v-if="store.user.superUser === 1 || store.user.perms.panelUser === 1">
					<td><span class="settlbl">Screen Out Code</span></td>
					<td><input class="earncodes saver" type="text" id="screenoutcode" v-model="screenoutcode"></td>
				</tr>
				<tr v-if="store.user.superUser === 1 || store.user.perms.panelUser === 1">
					<td><span class="settlbl">Completer Code</span></td>
					<td><input class="earncodes saver" type="text" id="completecode" v-model="completecode"></td>
				</tr>
				<tr v-if="store.user.superUser === 1 || store.user.perms.panelUser === 1">
					<td><span class="settlbl">Quota Full Code</span></td>
					<td><input class="earncodes saver" type="text" id="fullcode" v-model="fullcode"></td>
				</tr>
				<tr v-if="store.user.superUser === 1 || store.user.perms.panelUser === 1">
					<td><span class="settlbl">Project ID</span></td>
					<td><input class="earncodes saver" type="text" id="panelprojectid" v-model="panelprojectid"></td>
				</tr>
				</tbody>
			</table>
		</template>
		<template v-slot:buttons>
			<button type="button" class="option-button colbtn" @click="saveSurvInfo">Save Info</button>
			<button type="button" class="option-button" @click="closeCancel">Cancel</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'

export default {
	name: "EditEarnCodesScreen",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		survid: Number,
		surveys: Array,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			screenoutcode: '',
			fullcode: '',
			completecode: '',
			panelprojectid: '',

		}
	},
	computed: {
		mysurv() {
			return this.surveys.find(item => item.id === this.survid);
		},
	},
	methods: {
		closeCancel() {
			this.$emit('close');
		},
		saveSurvInfo() {
			if (this.isFetching === false && this.survid > 0) {
				this.store.showLoader = true;
				this.isFetching = true;
				let targ = this.store.urlroot + "central/post-vue/home/save-panel-earncodes";
				let sendob = {
					survid: this.survid,
					screenoutcode: this.screenoutcode,
					fullcode: this.fullcode,
					completecode: this.completecode,
					panelprojectid: this.panelprojectid,
				};
				this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
					this.isFetching = false;
					this.store.showLoader = false;
					this.showKalertSuccess({message: 'Changes saved!'}, true);
					this.$emit('closerefresh');
				}.bind(this));
			}
		},
		prepareForm() {
			if(this.mysurv.id === this.survid) {
				this.screenoutcode = this.mysurv.panelcodes.screenout;
				this.fullcode = this.mysurv.panelcodes.full;
				this.completecode = this.mysurv.panelcodes.complete;
				this.panelprojectid = this.mysurv.panelcodes.panelprojectid;
			}
		},
	},
	watch: {
		survid() {
			this.prepareForm();
		},
	},
	mounted() {
		this.prepareForm();
	},
}
</script>

<style scoped>
.saver {
	width: 320px;
}
#survid {
	padding-left: 13px;
}
</style>