<template>
	<div>
		<div v-if="pageview === 'questionlist'">
			<div id="qajaxhold">
				<div v-for="q in questions" :key="q.qid" class="cli" @click="editQid(q.qid)">
					<span class="cjqtext">
						<p>{{q.qtext_stripped}}</p>
					</span>
				</div>
			</div>
		</div>
		<div v-if="pageview === 'answeredit' && activeQuestion">
			<p class="boldme" style="margin: 20px 0;">{{activeQuestion.qtext_stripped}}</p>
			<div v-if="activeQuestion.type === 'Radio'">
				<div v-for="opt in activeQuestion.options" :key="opt.optid" class='edqopt'>
					<input v-model="activeAnswer" :value='opt.optid' type='radio' :id="'radio'+opt.optid" />
					<label :for="'radio'+opt.optid">{{opt.opttext}}</label>
				</div>
			</div>
			<div v-if="activeQuestion.type === 'Checkbox'">
				<div v-for="opt in activeQuestion.options" :key="opt.optid" class='edqopt'>
					<input v-model="activeAnswerArr" :value='opt.optid' type='checkbox' :id="'check'+opt.optid" />
					<label :for="'check'+opt.optid">{{opt.opttext}}</label>
				</div>
			</div>
			<div v-if="['Text','Number'].includes(activeQuestion.type)">
				<textarea id='qtarea' v-model="activeAnswer"></textarea>
			</div>
			<div v-if="activeQuestion.type === 'Select-One'">
				<div v-if="survob.ageq === activeQuestion.qid">
					<select id='edqsel' v-model="activeAnswer">
						<option v-for="ag in ageOptions" :key="ag.val" :value='ag.val'>{{ag.label}}</option>
					</select>
				</div>
				<div v-else>
					<select id='edqsel' v-model="activeAnswer">
						<option v-for="opt in activeQuestion.options" :key="opt.optid" :value='opt.optid'>{{opt.opttext}}</option>
					</select>
				</div>
			</div>
			<div v-if="activeQuestion.oTextOpts && activeQuestion.oTextOpts > 0">
				<div id="edqOTextHold">
					Other Text Input
					<input type='text' id='edqOText' v-model="otextAnswer" />
				</div>
			</div>
			<div class="wl-popup-buttons-section">
				<button type="button" class="option-button colbtn" @click="saveAnswer">Save</button>
				<button type="button" class="option-button" @click="clearSelections">Clear Selections</button>
				<button type="button" class="option-button" @click="backToQList">Cancel</button>
			</div>

		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: 'CATIResponseEditor',
	mixins: [globalMixin],
	props: {
		respid: {
			type: [Number,String],
			required: true,
			default: null,
		},
		survid: {
			type: Number,
			required: true,
			default: null,
		},
		survob: {
			type: Object,
			required: true,
			default: null,
		},
	},
	data() {
		return {
			store: store,

			pageview: 'questionlist', //questionlist, answeredit
			activeQuestionId: null,

			activeAnswer: null,
			otextAnswer: null,
			activeAnswerArr: null,
		}
	},
	computed: {
		ageOptions() {
			let a = [{val: 9, label: 'Under 10'}]
			for (let i = 10; i < 100; i++) {
				a.push({val: i, label: i.toString()})
			}
			a.push({val: 100, label: '100 or over'})
			return a
		},
		questions() {
			return this.survob.questions
		},
		activeQuestion() {
			return this.questions.find(q => q.qid === this.activeQuestionId)
		},
	},
	methods: {
		backToQList() {
			this.pageview = 'questionlist'
			this.activeQuestionId = null
			this.activeAnswer = null
			this.otextAnswer = null
			this.activeAnswerArr = null
		},
		clearSelections() {
			this.activeAnswer = ''
			this.activeAnswerArr = []
		},
		saveAnswer() {
			let targ = this.store.urlroot + "central/post-vue/cati-launch/save-respondent-answer"
			let sendob = {survid: this.survid, respid: this.respid, qid: this.activeQuestionId}
			sendob.answer = this.activeAnswer
			if(['Checkbox'].includes(this.activeQuestion.type)) {
				sendob.answer = this.activeAnswerArr.join("|")
			}
			if(this.otextAnswer) sendob.answer_otext = this.otextAnswer
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				if (ret.success) {
					this.backToQList()
				}
			}.bind(this))
		},
		editQid(qid) {
			this.activeQuestionId = qid
			if(this.activeQuestionId && this.respid) {
				let targ = this.store.urlroot + "central/post-vue/cati-launch/get-respondent-answer"
				let sendob = {survid: this.survid, respid: this.respid, qid: this.activeQuestionId}
				this.activeAnswer = null
				this.otextAnswer = null
				this.activeAnswerArr = null
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					if (ret.answer) {
						if(['Radio','Select-One','Number'].includes(this.activeQuestion.type)) {
							this.activeAnswer = parseInt(ret.answer.val)
						}
						else if(['Checkbox'].includes(this.activeQuestion.type)) {
							this.activeAnswerArr = []
							for(let r of ret.answer.arr) {
								this.activeAnswerArr.push(parseInt(r))
							}
						}
						else { //text types
							this.activeAnswer = ret.answer.val
						}
					}
					if (ret.answer_otext) this.otextAnswer = ret.answer_otext.val
					this.pageview = 'answeredit'
				}.bind(this))
			}
		},
	},
}
</script>

<style scoped>
#qajaxhold {
	text-align: left;
	margin: 0;
	max-height: 450px;
	overflow-y: auto;
	color: #000;
	line-height: 1.4;
	font-size: 13px;
}
#qtarea {
	width: 700px;
	height: 100px;
}
.edqopt {
	transition: all 0.3s ease-in-out;
	padding: 0 5px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #EEE;
	font-size: 14px;
}
.edqopt:hover {
	background: #D8D8D8;
}
.edqopt:first-of-type {
	border-top: 1px solid #EEE;
}
.edqopt input[type=radio], .edqopt input[type=checkbox] {
	margin: 0;
	padding: 0;
}
.edqopt label {
	padding: 12px 10px 10px;
	width: 100%;
	cursor: pointer;
}
#edqOTextHold #edqOText {
	width: 500px;
	margin-left: 10px;
}
#qajaxhold .cli {
	border-bottom: 1px solid #DDD;
	padding: 13px 10px 11px;
	cursor: default;
	overflow: hidden;
	transition: all 0.3s ease-in-out;
}
#qajaxhold .cli:first-of-type {
	border-top: 1px solid #DDD;
}
#qajaxhold .cli p {
	margin-block-start: 0;
	margin-block-end: 0;
}
#qajaxhold .cli:hover {
	background-color: #D8D8D8;
}
.wl-popup-buttons-section {
	background-color: #F1F2F6;
	border-top: 1px solid #DDD;
	margin-top: 10px;
	padding: 10px 30px 10px;
}
</style>
