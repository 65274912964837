<template>
	<div>
		<div style="display: flex; align-items: center;">
			<p>Song selection</p>
			<select v-model="chosenHookId" class="wl-input-select" style="margin-left: 10px;">
				<option v-for="h in results" :key="h.hookid" :value="h.hookid">{{h.artist}} - {{h.title}}</option>
			</select>
		</div>
		<div style="padding: 30px; border: 1px solid #CCC; margin-bottom: 20px; height: 450px;">
			<ApexLineTrend :chartdata="apexdata" filename="Song score history" :fetching="isFetching" :forcetocategory="true"></ApexLineTrend>
		</div>
	</div>
</template>
<script>
import {globalMixin} from "@/mixins";
import {store} from "@/store";
import ApexLineTrend from "@/components/charts/ApexLineTrend.vue";

export default {
	name: "DashMusicHistoryChart",
	mixins: [globalMixin],
	components: {
		ApexLineTrend
	},
	props: {
		results: {
			type: Array,
			default: () => []
		},
		surveys: {
			type: Array,
			default: () => []
		},
		incominghookid: {
			type: Number,
			default: null
		},
	},
	data: function () {
		return {
			store: store,

			chosenHookId: null,
			isFetching: false,
		}
	},
	computed: {
		apexdata() {
			if(this.chosenHookId) {
				let hobr = this.results.find(item => item.hookid === this.chosenHookId)
				if(hobr) {
					let resdata = []
					for(let sid in hobr.resultsBySurveyId) {
						let mysurv = this.surveys.find(item => item.id === parseInt(sid))
						if(mysurv) {
							resdata.push({x: mysurv.name, y: hobr.resultsBySurveyId[sid]})
						}
					}
					return [{name: hobr.artist + ' - ' + hobr.title, data: resdata}]
				}
			}
			return null
		},
	},
	methods: {

	},
	watch: {
		incominghookid() {
			if(this.incominghookid) this.chosenHookId = this.incominghookid
		},
	},
	created() {
		if(this.incominghookid) this.chosenHookId = this.incominghookid
	},
}
</script>
<style scoped></style>