<template>
	<div v-if="store.sessionOk">

		<BlanketOverlayWithPopup v-if="showCallEditorPopup" title="Edit question response"
			width="800" :hastabs="false" v-on:close="endCallEdit" :nobuttons="true"
		>
			<template v-slot:main>
				<CATIResponseEditor :survid="chosenSurveyId" :respid="callEditorRespId" :survob="activeSurvOb"></CATIResponseEditor>
			</template>
		</BlanketOverlayWithPopup>

		<BlanketOverlayWithPopup v-if="quotaEditPopupShown" title="Set Call Quotas"
			width="1000" :hastabs="false" v-on:close="quotaEditPopupShown = false"
		>
			<template v-slot:main>
				<p v-if="store.user.superUser === 1" style="margin-bottom: 10px;"><span @click="importQuotas" class="text-link">Import</span></p>

				<div>
					<table id="quotaSetHold">
						<tr class='trh'>
							<td></td>
							<td>Quota Name</td>
							<td>Logic</td>
							<td><span class="catlinkNo2" @click="importQuotaFigs('pops')">Popn %</span></td>
							<td><span class="catlinkNo2" @click="importQuotaFigs('targs')">Call Target</span></td>
						</tr>
						<tr v-for="i in 60" :key="i">
							<td>{{i}}</td>
							<td><input type='text' class='qsaves' v-model="quotasEdit.names[i-1]" /></td>
							<td><input type='text' class='qsaves' v-model="quotasEdit.sql[i-1]" /></td>
							<td><input type='text' class='qsaves qnums poptarg' v-model="quotasEdit.pcts[i-1]" /></td>
							<td><input type='text' class='qsaves qnums' v-model="quotasEdit.targets[i-1]" /></td>
						</tr>
					</table>
				</div>

				<div>
					<br/>
					Target sum is n={{quotaTargetTotal}}, {{quotaPctTotal}}%
				</div>
			</template>
			<template v-slot:buttons>
				<button type="button" class="option-button colbtn" @click="saveQuotas">Save</button>
				<button type="button" class="option-button" @click="quotaEditPopupShown = false">Cancel</button>
			</template>
		</BlanketOverlayWithPopup>

		<div id="container" :class="{pageLeftMenuTransposed : store.leftmenuhidden}">
			<LeftMenuWL></LeftMenuWL>
			<div id="bg">
				<TopMenuBar>
					<p style="color: #FFF; font-size: 16px; font-weight: bold; margin: 28px 0 0 10px;" v-if="activeSurvOb">{{activeSurvOb.survname_internal}} ({{store.user.fname}})</p>
				</TopMenuBar>
				<div id="maincontent" class="">
					<div class="tabdiv">
						<div class="tab" :class="{active : activetab === 'overview'}" @click="activetab = 'overview'">Survey Overview</div>
						<div class="tab" :class="{active : activetab === 'mycalls'}" @click="activetab = 'mycalls'">My Calls</div>
						<div class="tab" :class="{active : activetab === 'callstatus'}" @click="activetab = 'callstatus'">Call Status</div>
						<div v-if="user.perms.CATISuper === 1 || user.superUser === 1" class="tab" :class="{active : activetab === 'validation'}" @click="activetab = 'validation'">Validation</div>
					</div>

					<div v-if="activetab === 'overview'" class="wl-admin-container">
						<p style="font-weight: bold; font-size: 14px; padding-bottom: 15px;">
							Quota Summary
							<span v-if="store.user.superUser === 1 || store.user.perms.CATISuper === 1" @click="editQuotas" class="opta">Manage</span>
							<span v-if="store.user.superUser === 1 || store.user.perms.CATISuper === 1" @click="exportQuotas" class="opta">Export</span>
						</p>

						<table id="quotaholdtable" v-if="quotaSummaries">
							<tbody>
								<tr v-for="q in quotaSummaries" :key="q.name">
									<td style="text-align:left;">{{q.name}}</td>
									<td>
										<div class="innerholder">
											<div :class="'inner innerColor'+q.colorClass" :style="'width: '+q.pctCATI+'%'"></div>
											<div class="inner innerColorOnline" :style="'width: '+q.pctOnline+'%'"></div>
										</div>
									</td>
									<td style="text-align:right; width: 150px;" :class="{green : q.count === q.target, red: q.count > q.target}">
										{{q.count}} of {{q.target}}  (C:{{q.countCATI}}, O:{{q.countOnline}})
									</td>
								</tr>
								<tr>
									<td colspan="3" style="text-align: right;">Total completes {{totals.totalCompletes}} of target {{totals.totalTarget}}. Average complete call duration: {{totals.avgCompleteTime}}</td>
								</tr>
								<tr><td colspan="3" class="boldme">By Source</td></tr>
								<tr v-for="s in sourceTotals" :key="s.name">
									<td style="text-align:left;">{{s.name}}</td>
									<td><div class="innerholder"><div class="inner" :style="'width: '+s.pct+'%'"></div></div></td>
									<td style="text-align:right; width: 150px;">{{s.val}} ({{s.pct}}%)</td>
								</tr>
								<tr><td colspan="3" class="boldme">By Source (Under 55s)</td></tr>
								<tr v-for="s in sourceU55" :key="s.name">
									<td style="text-align:left;">{{s.name}}</td>
									<td><div class="innerholder"><div class="inner" :style="'width: '+s.pct+'%'"></div></div></td>
									<td style="text-align:right; width: 150px;">{{s.val}} ({{s.pct}}%)</td>
								</tr>
								<tr><td colspan="3" class="boldme">By Source (55+)</td></tr>
								<tr v-for="s in source55Plus" :key="s.name">
									<td style="text-align:left;">{{s.name}}</td>
									<td><div class="innerholder"><div class="inner" :style="'width: '+s.pct+'%'"></div></div></td>
									<td style="text-align:right; width: 150px;">{{s.val}} ({{s.pct}}%)</td>
								</tr>
								<tr><td colspan="3"><div class="spacer" style="height:20px;"></div></td></tr>
								<tr>
									<td style="text-align:left;">Callbacks</td>
									<td><div class="innerholder"><div class="inner" :style="'width: '+callbacks.pct+'%'"></div></div></td>
									<td style="text-align:right; width: 150px;">{{callbacks.val}} ({{callbacks.pct}}%)</td>
								</tr>
							</tbody>
						</table>

						<br/><br/>
						<p v-if="callsByOperator" style="font-weight: bold; font-size: 14px; padding-bottom: 10px;">Calls by operator ({{callsByOperator.timezoneCatiRoom}})</p>
						<table id="catiUsrCallNums" v-if="callsByOperator && callsByOperator.data.length > 0">
							<tbody>
								<tr class="boldme">
									<td></td>
									<td v-for="d in callsByOperator.data[0].data" :key="d.date">{{d.date}}</td>
								</tr>
								<tr v-for="(item, ind) in callsByOperator.data" :key="ind">
									<td style="text-align:left;">{{item.name}}</td>
									<td v-for="c in item.data" :key="c.date">{{c.val}}</td>
								</tr>
								<tr class="boldme">
									<td style="text-align:left;">Total</td>
									<td v-for="d in callsByOperator.totals" :key="d.date">{{d.val}}</td>
								</tr>
							</tbody>
						</table>

						<br/><br/>
						<p v-if="callsByOperator" style="font-weight: bold; font-size: 14px; padding-bottom: 10px;">Demographics by date - CATI Only ({{callsByOperator.timezoneCatiRoom}})</p>
						<table id="dembydaytbl" v-if="demosByDate">
							<tbody>
								<tr class="boldme">
									<td></td>
									<td v-for="d in demosByDate.totals" :key="d.date">{{d.date}}</td>
								</tr>
								<tr v-for="(item, ind) in demosByDate.data" :key="ind" :class="{boldme : item.subtotal}">
									<td style="text-align:left;">{{item.name}}</td>
									<td v-for="c in item.data" :key="c.date">{{c.val}}</td>
								</tr>
								<tr class="boldme">
									<td style="text-align:left;">Total</td>
									<td v-for="d in demosByDate.totals" :key="d.date">{{d.val}}</td>
								</tr>
							</tbody>
						</table>

						<br/><br/>
						<p v-if="callsByOperator" style="font-weight: bold; font-size: 14px; padding-bottom: 10px;">Calls by daypart ({{callsByOperator.timezoneSurvey}})</p>
						<table v-if="callsByDaypart" id="dembydaytbl">
							<tbody>
								<tr class="boldme">
									<td></td>
									<td v-for="d in callsByDaypart.totals" :key="d.date">{{d.date}}</td>
									<td>Total Pct</td>
								</tr>
								<tr v-for="(item, ind) in callsByDaypart.data" :key="ind">
									<td style="text-align:left;">{{item.name}}</td>
									<td v-for="c in item.data" :key="c.date">{{c.val}}</td>
								</tr>
								<tr class="boldme">
									<td style="text-align:left;">Total</td>
									<td v-for="d in callsByDaypart.totals" :key="d.date">{{d.val}}</td>
									<td></td>
								</tr>
							</tbody>
						</table>

					</div>
					<div v-if="activetab === 'mycalls'" class="wl-admin-container">
						<div v-if="activeSurvOb">
							<div v-if="activeSurvOb.status === 'working'">
								<p>This survey is in the setup stage, no calls can be made until it is set to Published.</p>
							</div>
							<div v-if="activeSurvOb.status === 'closed'">
								<p>This survey is closed, no more calls can be made.</p>
							</div>
							<div v-if="activeSurvOb.status === 'publish'">
								<p id="newcallbtn" class="option-button colbtn" @click="goToCall">Start New Call</p>
								<p style="font-weight: bold; font-size: 14px; padding-bottom: 15px;">My summary for this survey</p>
								<table v-if="myCallsStats" id="summview">
									<tbody>
										<tr><td class="centre">Calls initiated</td><td>{{myCallsStats.callsStarted}}</td></tr>
										<tr><td class="centre">Successful completes</td><td>{{myCallsStats.nSuccess}} ({{myCallsStats.pctSuccess}}%)</td></tr>
										<tr><td class="centre">Disqualified</td><td>{{myCallsStats.nDisqualified}}</td></tr>
									</tbody>
								</table>
								<br/><br/>
								<p style="font-weight: bold; font-size: 14px; padding-bottom: 10px;">Calls that can be resumed (dropped out or incomplete)</p>
								<ul v-if="continueCalls" id="continuecalls">
									<li v-for="c of continueCalls" :key="c.respID" @click="resumeCall(c.respID)">
										{{c.source}} ({{c.timestamp}}) [{{c.respID}}]
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div v-if="activetab === 'callstatus'" class="wl-admin-container">
						<p v-if="callStatusOptions" style="font-weight: bold; font-size: 14px; padding-bottom: 10px;">Call List (using timezone {{callStatusOptions.catiRoomTimezone}})</p>
						<br/>
						<table v-if="callStatusCalls" class="altbl noborderv callstatustable">
							<thead>
							<tr>
								<td class="headingtext" data-f-bold="true"><span>Status</span></td>
								<td class="headingtext" data-f-bold="true"><span>Operator</span></td>
								<td class="headingtext" data-f-bold="true"><span>Time</span></td>
								<td class="headingtext" data-f-bold="true"><span>Gender</span></td>
								<td class="headingtext" data-f-bold="true"><span>Age</span></td>
								<td class="headingtext" data-f-bold="true"><span>Area</span></td>
								<td v-if="callStatusOptions.showCallbackCol === true" class="headingtext" data-f-bold="true"><span>Callback</span></td>
								<td v-if="callStatusOptions.showQuotaCallCol === true" class="headingtext" data-f-bold="true"><span>Quota Call</span></td>
								<td class="headingtext" data-f-bold="true"><span>ID</span></td>
								<td class="headingtext" data-f-bold="true"><span>Options</span></td>
							</tr>
							</thead>
							<tbody>
							<tr v-for="call in callStatusCalls" :key="call.respid">
								<td>{{call.status}}</td>
								<td>{{call.operatorName}}</td>
								<td>{{call.timestamp}}</td>
								<td>{{call.gender}}</td>
								<td>{{call.age}}</td>
								<td>{{call.area}}</td>
								<td v-if="callStatusOptions.showCallbackCol === true">{{boolToYes(call.callback)}}</td>
								<td v-if="callStatusOptions.showQuotaCallCol === true">{{boolToYes(call.quotaCall)}}</td>
								<td>{{call.respid}}</td>
								<td>
									<span v-if="call.allowReopen === true" @click="callReopen(call.respid)" title="Reopen call">
										<i class="mdi mdi-open-in-app"></i>
									</span>
									<span v-if="call.allowResume === true" @click="callResume(call.respid)" title="Resume call">
										<i class="mdi mdi-phone-forward"></i>
									</span>
									<span v-if="call.allowEdit === true" @click="callEdit(call.respid)" title="Edit call">
										<i class="mdi mdi-circle-edit-outline"></i>
									</span>
									<span v-if="call.allowRemove === true" @click="callRemove(call.respid)" title="Remove call">
										<i class="mdi mdi-trash-can-outline"></i>
									</span>
									<span v-if="call.allowReEnable === true" @click="callReEnable(call.respid)" title="Re-Enable call">
										<i class="mdi mdi-restore"></i>
									</span>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
					<div v-if="activeSurvOb && activetab === 'validation'" class="wl-admin-container">
						<ValidatorPage :srvob="activeSurvOb" :fromcatimgr="true"
							v-on:catiedit="callEdit($event)"
						></ValidatorPage>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import LeftMenuWL from "@/components/LeftMenuWL";
import {globalMixin} from "@/mixins";
import TopMenuBar from "@/components/TopMenuBar";
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup.vue";
import CATIResponseEditor from "@/components/CATIResponseEditor.vue";
import ValidatorPage from "@/components/regional-ratings-admin/ValidatorPage.vue";

export default {
	name: "CATILaunchPage",
	mixins: [globalMixin],
	components: {
		ValidatorPage,
		CATIResponseEditor,
		BlanketOverlayWithPopup,
		TopMenuBar,
		LeftMenuWL
	},
	props: {},
	data: function () {
		return {
			store: store,

			chosenSurveyId: null,
			activeSurvOb: null,
			activetab: null,

			quotaSummaries: null,
			totals: null,
			sourceTotals: null,
			source55Plus: null,
			sourceU55: null,
			callbacks: null,
			callsByOperator: null,
			demosByDate: null,
			callsByDaypart: null,

			continueCalls: null,
			myCallsStats: null,

			callStatusCalls: null,
			callStatusOptions: null,

			showCallEditorPopup: false,
			callEditorRespId: null,

			quotaEditPopupShown: false,
			quotas: {
				names: [],
				sql: [],
				pcts: [],
				targets: [],
			},
			quotasEdit: {
				names: [],
				sql: [],
				pcts: [],
				targets: [],
			},
		}
	},
	computed: {
		user() {
			return this.store.user
		},
		quotaTargetTotal() {
			let n = 0
			for(let q of this.quotasEdit.targets) {
				n += parseInt(q)
			}
			return n
		},
		quotaPctTotal() {
			let n = 0
			for(let q of this.quotasEdit.pcts) {
				n += parseFloat(q)
			}
			return n
		},
	},
	methods: {
		importQuotaFigs(type) { //type will be pops or targs
			let imp = prompt("Paste from Excel")
			if(imp !== false) {
				let newArr = []
				let imparr = imp.split("\n")
				let counter = 1
				for(let i=0; i<imparr.length; i++) {
					imparr[i] = imparr[i].replace("%","");
					if(type === "pops") {
						newArr.push(imparr[i])
					}
					else if(type === "targs") {
						newArr.push(parseInt(imparr[i]))
					}
					counter++;
					if(counter > 59) break;
				}
				if(type === 'pops') this.quotasEdit.pcts = newArr
				else if(type === 'targs') this.quotasEdit.targets = newArr
			}
		},
		goToCall() {
			if(this.activeSurvOb) {
				let proceed = false
				let targ = this.store.urlroot + "central/post-vue/cati-launch/call-interstitial"
				let sendob = {survid: this.chosenSurveyId, fn: 'checkRespondent'}
				//check if we already have a live RespID in progress (ie is there a respondent already open)
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					if(ret.respExists && ret.respExists === 1) {
						let c = confirm("There is a Respondent ID active at the moment.  This may be in another tab or a call that ended incorrectly.  If you continue, that session will be ended, are you sure?");
						if(c === true) proceed = true
					}
					else { //no resp exists yet
						proceed = true
					}
					if(proceed === true) this.getNewRespondentIdAndStartCall()
				}.bind(this))
			}
		},
		getNewRespondentIdAndStartCall() {
			let targ = this.store.urlroot + "central/post-vue/cati-launch/call-interstitial"
			let sendob = {survid: this.chosenSurveyId, fn: 'newcall'}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				if(ret.respIdNew) {
					window.location = this.store.collectionRoot + this.chosenSurveyId +"?source=CATI&stream=" + this.store.user.id
						+ "&id=" + ret.respIdNew + "&sesstoken=" + this.store.wlsesstoken
				}
			}.bind(this))
		},
		resumeCall(respId) {
			if(respId.length > 0) {
				let targ = this.store.urlroot + "central/post-vue/cati-launch/call-interstitial"
				let sendob = {survid: this.chosenSurveyId, fn: 'resumeCall', resumeRespid: respId}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					if (ret.respId) {
						window.location = this.store.collectionRoot + this.chosenSurveyId + "?source=CATI&stream=" + this.store.user.id
							+ "&id=" + ret.respId + "&sesstoken=" + this.store.wlsesstoken
					}
				}.bind(this))
			}
		},
		getOverviewData() {
			if(this.chosenSurveyId) {
				let targ = this.store.urlroot + "central/post-vue/cati-launch/get-overview"
				let sendob = {survid: this.chosenSurveyId}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					if (ret.survey) this.activeSurvOb = ret.survey
					if (ret.quotaSummaries) this.quotaSummaries = ret.quotaSummaries
					if (ret.totals) this.totals = ret.totals
					if (ret.sources) this.sourceTotals = ret.sources
					if (ret.sources55Plus) this.source55Plus = ret.sources55Plus
					if (ret.sourcesU55) this.sourceU55 = ret.sourcesU55
					if (ret.callbacks) this.callbacks = ret.callbacks
					if (ret.callsByOperator) this.callsByOperator = ret.callsByOperator
					if (ret.demosByDate) this.demosByDate = ret.demosByDate
					if (ret.callsByDaypart) this.callsByDaypart = ret.callsByDaypart
					if (ret.quotas) {
						this.quotas = ret.quotas
						this.quotasEdit = JSON.parse(JSON.stringify(ret.quotas))
					}
				}.bind(this))
			}
		},
		getMyCalls() {
			if(this.chosenSurveyId) {
				let targ = this.store.urlroot + "central/post-vue/cati-launch/get-my-calls"
				let sendob = {survid: this.chosenSurveyId}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					if (ret.survey) this.activeSurvOb = ret.survey
					if (ret.continueCalls) this.continueCalls = ret.continueCalls
					if (ret.stats) this.myCallsStats = ret.stats
				}.bind(this))
			}
		},
		getCallListStatus() {
			if(this.chosenSurveyId) {
				let targ = this.store.urlroot + "central/post-vue/cati-launch/get-call-list-status"
				let sendob = {survid: this.chosenSurveyId}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					if (ret.survey) this.activeSurvOb = ret.survey
					if (ret.calls) this.callStatusCalls = ret.calls
					if (ret.options) this.callStatusOptions = ret.options
				}.bind(this))
			}
		},
		setInitialTab() {
			if(this.store.sessionOk === true && this.activetab === null) {
				if(this.store.user.perms.CATISuper === 1 || this.store.user.superUser === 1) {
					this.activetab = 'overview'
				}
				else {
					this.activetab = 'mycalls'
				}
			}
		},
		callReopen(respid) {
			if(respid !== "") {
				let c = confirm("Would you like to re-open this call to allow further editing?")
				if(c === true) {
					let targ = this.store.urlroot + "central/post-vue/cati-launch/call-actions"
					let sendob = {survid: this.chosenSurveyId, respid: respid, fn: 'reopen'}
					this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
						this.isFetching = false
						this.getCallListStatus()
					}.bind(this))
				}
			}
		},
		callResume(respId) {
			let c = confirm("Are you sure you'd like to edit this call?")
			if(c === true) {
				if(respId.length > 0) {
					let proceed = false
					let targ = this.store.urlroot + "central/post-vue/cati-launch/call-interstitial"
					let sendob = {survid: this.chosenSurveyId, fn: 'checkRespondent'}
					//check if we already have a live RespID in progress (ie is there a respondent already open)
					this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
						if(ret.respExists && ret.respExists === 1) {
							let c = confirm("There is a Respondent ID active at the moment.  This may be in another tab or a call that ended incorrectly.  If you continue, that session will be ended, are you sure?");
							if(c === true) proceed = true
						}
						else { //no resp exists yet
							proceed = true
						}
						if(proceed === true) this.resumeCall(respId)
					}.bind(this))
				}
			}
		},
		callRemove(respId) {
			let c = confirm("Would you like to REMOVE this call?")
			if(c === true && respId && respId.length > 0) {
				let targ = this.store.urlroot + "central/post-vue/cati-launch/call-actions"
				let sendob = {survid: this.chosenSurveyId, respid: respId, fn: 'removeCall'}
				this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
					this.isFetching = false
					this.getCallListStatus()
				}.bind(this))
			}
		},
		callReEnable(respId) {
			let c = confirm("Would you like to RE-ENABLE this - it will be set back to COMPLETE")
			if(c === true && respId && respId.length > 0) {
				let targ = this.store.urlroot + "central/post-vue/cati-launch/call-actions"
				let sendob = {survid: this.chosenSurveyId, respid: respId, fn: 'resetToComplete'}
				this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
					this.isFetching = false
					this.getCallListStatus()
				}.bind(this))
			}
		},
		callEdit(respid) { //allows manager to edit this call
			this.callEditorRespId = respid
			this.showCallEditorPopup = true
		},
		endCallEdit() {
			this.showCallEditorPopup = false
			this.callEditorRespId = null
		},
		exportQuotas() {
			let authstr = 'wlsesstoken='+this.store.wlsesstoken+'&uid='+this.store.user.id
			window.location = this.store.urlroot+'central/post-vue/cati-launch/export-quota-report?survid='+this.activeSurvOb.survid+'&'+authstr
		},
		editQuotas() {
			this.quotasEdit = JSON.parse(JSON.stringify(this.quotas))
			this.quotaEditPopupShown = true
		},
		importQuotas() {
			let agesplits = [] //array of objects
			let gin = prompt("Enter gender splits with pipe eg M|F where M=1 and F=2 etc", "M|F")
			if(gin !== false) {
				let genders = gin.split("|"); //array starts at 0, values should be 1, handle later
				let agein = prompt("Enter age splits with pipe eg 10-24|25-39|40-54","10-17|18-24|25-39|40-54|55-64|65+")
				if(agein !== false) {
					let agesplitnames = agein.split("|")
					for(let i=0; i<agesplitnames.length; i++) {
						let agering = agesplitnames[i]
						let agelow = parseInt(agering)
						let agehigh = 99
						if(agesplitnames[i].indexOf("-") > 0) {
							agering = agesplitnames[i].split("-")
							agelow = agering[0];
							agehigh = agering[1];
						}
						agesplits[i] = {'agesplitname': agesplitnames[i],
							'agelow': agelow,
							'agehigh': agehigh
						}
					}
					let regin = prompt("Enter region splits with pipe in order eg Region1|Region2|Region3");
					if(regin !== false) {
						let regions = regin.split("|"); //array starts at 0, values should be 1, handle later
						this.quotasEdit = {
							targets: [],
							pcts: [],
							sql: [],
							names: [],
						}
						for(let r=0; r<regions.length; r++) {
							let rtext = regions[r]
							let rval = r+1
							for(let g=0; g<genders.length; g++) {
								let gtext = genders[g]
								let gval = g+1
								for(let a=0; a<agesplits.length; a++) {
									let atext = agesplits[a].agesplitname
									let aval = "(ageq>='"+agesplits[a].agelow+"'"
									if(agesplits[a].agehigh) {
										aval = aval + " and ageq<='"+agesplits[a].agehigh+"')"
									}
									else {
										aval = aval + ")"
									}
									let dnval = gtext+atext+" "+rtext
									this.quotasEdit.names.push(dnval)
									let dfval = "gendq='"+gval+"' and areaq='"+rval+"' and "+aval
									this.quotasEdit.sql.push(dfval)
								}
							}
						}
					}
				}
			}
		},
		saveQuotas() {
			let targ = this.store.urlroot + "central/post-vue/cati-launch/save-quotas"
			let sendob = {survid: this.chosenSurveyId, quotaData: this.quotasEdit}
			this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
				this.isFetching = false
				this.quotaEditPopupShown = false
				this.getOverviewData()
			}.bind(this))
		},
	},
	watch: {
		'store.sessionOk'() {
			if(this.store.sessionOk === true) this.setInitialTab()
		},
		activetab() {
			if(this.activetab === 'overview') this.getOverviewData()
			if(this.activetab === 'mycalls') this.getMyCalls()
			if(this.activetab === 'callstatus') this.getCallListStatus()

		},
	},
	mounted() {
		this.setInitialTab()
	},
	created() {
		document.title = "Wavelength CATI"
		this.store.activeLmenuLink = ''
		let urlparams = this.$route.params
		if(urlparams.surveyid !== undefined) {
			this.chosenSurveyId = parseInt(urlparams.surveyid)
		}
	}
}
</script>

<style scoped>
#quotaholdtable, #summview, #catiUsrCallNums {
	border-collapse: collapse;
	font-size: 13px;
}
#continuecalls {
	list-style-type: none;
	list-style-position: outside;
	-webkit-margin-before: 0;
	-webkit-padding-start: 0;
	-moz-padding-start: 0;
	padding-start: 0;
	width: 600px;
	font-size: 13px;
}
#continuecalls li {
	padding: 8px 10px;
	background-color: #EFEFEF;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}
#continuecalls li:hover {
	background-color: #DDD;
}
#quotaholdtable td, td {
	border: 1px solid #CCC;
	padding: 6px 15px;
	vertical-align: middle;
}
#quotaholdtable td {
	border: none;
	border-bottom: 1px solid #DDD;
}
#quotaholdtable .innerholder {
	width: 300px;
	height: 16px;
	padding: 0;
	background-color: #E0E0E0;
	display: flex;
	justify-content: flex-start;
}
.inner {
	background: #23A6EE;
	position: relative;
	height: 100%;
	height: 16px;
}
.innerColor1, .innerColor3, .innerColor1-1 {
	background: #23A6EE;
}
.innerColor2, .innerColor4, .innerColor1-2 {
	background: #1D1F38;
}
.innerColor2-2 {
	background: #6b5b95;
}
.innerColor2-1 {
	background: #feb236;
}
.innerColor3-2 {
	background: #d64161;
}
.innerColor3-1 {
	background: #ff7b25;
}
.innerColor4-2 {
	background: #a2b9bc;
}
.innerColor4-1 {
	background: #b2ad7f;
}
.innerColor5-2 {
	background: #878f99;
}
.innerColor5-1 {
	background: #eca1a6;
}
.innerColorOnline {
	background: #0C7C59;
}
.green {
	color: #0A0;
}
.red {
	color: #A00;
}
#dembydaytbl, #catiUsrCallNums {
	text-align: center;
}
#dembydaytbl tr:nth-of-type(odd), #catiUsrCallNums tr:nth-of-type(odd) {
	background: #F8F8F8;
}
#dembydaytbl tr:nth-of-type(1), #catiUsrCallNums tr:nth-of-type(1) {
	background: #23A6EE;
	color: #FFF;
}
#dembydaytbl td, #catiUsrCallNums td {
	border: 1px solid #DDD;
	padding: 5px 5px 5px;
}
#dembydaytbl .leftme, #catiUsrCallNums .leftme {
	text-align: left;
	padding: 2px 5px;
}
#newcallbtn {
	width: 200px;
	font-size: 16px;
	text-align: center;
	padding: 16px 30px;
	color: #FFF;
	margin: 10px 0 30px;
	display: block;
}
.callstatustable td i {
	margin-right: 10px;
	font-size: 16px;
	cursor: pointer;
}
.opta {
	font-size: 11px;
	margin-left: 10px;
	cursor: pointer;
	font-weight: normal;
	color: #888;
}
.opta:hover {
	text-decoration: underline;
	color: #23A6EE;
}
#quotaSetHold .qsaves {
	width: 250px;
	margin: 2px 5px;
	padding: 6px 7px;
	border: none;
}
#quotaSetHold .qnums {
	width: 50px;
	text-align: right;
}
.trh td {
	text-align: left;
	padding: 4px 10px;
}
.catlinkNo2 {
	text-decoration:none;
	color: inherit;
	cursor: pointer;
}
</style>