<template>
	<BlanketOverlayWithPopup title="Active Survey Select" width="420" v-on:close="closeCancel">
		<template v-slot:main>
			<table>
				<tbody>
				<tr>
					<td><span class="settlbl">Station</span></td>
					<td><select class="saver selector" v-model="chosenStationCode">
						<option value=""></option>
						<option v-for="st in stnlooks" :key="st.code" :value="st.code">{{st.title}}</option>
					</select></td>
				</tr>
				<tr>
					<td><span class="settlbl">Survey</span></td>
					<td><select class="saver selector" v-model="chosenSurveyId">
						<option value="0"></option>
						<option v-for="st in availSurvs" :key="st.sid" :value="st.sid">{{st.name}}</option>
					</select></td>
				</tr>
				<tr>
					<td><span class="settlbl">End Date</span></td>
					<td><date-picker v-model:value="chosenEndDate" valueType="format" format="DD-MM-YYYY"></date-picker></td>
				</tr>
				</tbody>
			</table>
		</template>
		<template v-slot:buttons>
			<button type="button" class="option-button colbtn" @click="saveInfo">Save</button>
			<button type="button" class="option-button" @click="closeCancel">Cancel</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css';

export default {
	name: "ChooseActiveSurveyScreen",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
		DatePicker,
	},
	props: {
		stnlooks: Array,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,
			returnedData: [],

			chosenStationCode: '',
			chosenSurveyId: 0,
			chosenEndDate: '',
		}
	},
	computed: {
		chosenOb() {
			return this.returnedData.find(item => item.station === this.chosenStationCode);
		},
		availSurvs() {
			if(this.chosenOb) {
				return this.chosenOb.survs;
			}
			return [];
		},
	},
	methods: {
		closeCancel() {
			this.$emit('close');
		},
		saveInfo() {
			if (this.isFetching === false) {
				this.store.showLoader = true;
				this.isFetching = true;
				let targ = this.store.urlroot + "central/post-vue/home/assign-live-survey";
				let sendob = {
					activesurvid: this.chosenSurveyId,
					prizedate: this.chosenEndDate,
					stationname: this.chosenStationCode,
				};
				this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
					this.isFetching = false;
					this.store.showLoader = false;
					this.showKalertSuccess({message: 'Changes saved!'}, true);
					this.$emit('close');
				}.bind(this));
			}
		},
		getActiveInfo() {
			if (this.isFetching === false) {
				this.store.showLoader = true;
				this.fetchFinished = false;
				this.isFetching = true;
				let stnlooks = [];
				for(let item of this.stnlooks) {
					stnlooks.push(item.code);
				}
				let targ = this.store.urlroot + "central/post-vue/home/get-active-surveys";
				let sendob = {stationArr: stnlooks};
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false;
					if (ret.mydata) {
						this.returnedData = ret.mydata;
					}
					this.fetchFinished = true;
					this.store.showLoader = false;
				}.bind(this));
			}
		},
	},
	watch: {
		chosenStationCode() {
			if(this.chosenOb) {
				this.chosenSurveyId = this.chosenOb.activesurvid;
				this.chosenEndDate = this.chosenOb.prizedate;
			}
			else {
				this.chosenSurveyId = 0;
				this.chosenEndDate = '';
			}
		},
	},
	mounted() {
		this.getActiveInfo();
	},
}
</script>

<style scoped>
.selector {
	width: 210px;
}
</style>