<template>
	<div class="wl-admin-container">

		<RRSurveyEditPopup v-if="activeEditSurvey && showEditSurveyPopup === true"
			:srvobin="activeEditSurvey" v-on:close="closeEditSurveyPopup($event)"
		></RRSurveyEditPopup>

		<FreqSurvUserAssignPopup v-if="activeEditSurvey && showEditUserPermsPopup === true"
			:survobin="activeEditSurvey" v-on:close="closeUserPermView()"
		></FreqSurvUserAssignPopup>

		<div v-if="pageView === 'main'">
			<div style="margin-bottom: 20px;">
				<input type="text" placeholder="Search by name" v-model="filterText" class="wl-input-text wl-input-padding-large" />
			</div>
			<table class="altbl noborderv">
				<thead>
				<tr class='headrow'>
					<td class="headingtext">ID</td>
					<td class="headingtext">Survey</td>
					<td class="headingtext">CATILoc</td>
					<td class="headingtext">CollectOnly</td>
					<td class="headingtext">Released</td>
					<td class="headingtext">MktPop</td>
					<td class="headingtext ctr">Processed</td>
					<td class="headingtext ctr">Enabled</td>
					<td class="headingtext">Functions</td>
				</tr>
				</thead>
				<tbody>
				<tr v-for="srv in filteredSurveys" :key="srv.id">
					<td>{{srv.id}}</td>
					<td>{{srv.name}} {{getMktCode(srv)}}</td>
					<td>{{srv.dataloc}}</td>
					<td class="ctr">{{intToYes(srv.collectionOnly)}}</td>
					<td>{{srv.reldate}}</td>
					<td class="rt">{{srv.mktpop}}</td>
					<td class="ctr" title="Processing complete">{{intToYes(srv.hasBeenProcessed)}}</td>
					<td class="ctr" title="Enabled desktop">{{intToYes(srv.enabledDesktop)}}</td>
					<td>
						<i class="mdi mdi-pencil" title="Edit" style="cursor: pointer; margin-right: 10px; font-size: 14px;" @click="editSurvey(srv)"></i>
						<i class="mdi mdi-content-copy" title="Clone to new" style="cursor: pointer; margin-right: 10px; font-size: 14px;" @click="cloneSurvey(srv)"></i>
						<i class="mdi mdi-check-all" title="Validate" style="cursor: pointer; margin-right: 10px; font-size: 14px;" @click="showValidationView(srv)"></i>
						<i class="mdi mdi-account-multiple" title="Assign users" style="cursor: pointer; margin-right: 10px; font-size: 14px;" @click="showUserPermView(srv)"></i>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
		<ValidationPage v-if="pageView === 'validate'" :srvob="activeEditSurvey" v-on:close="closeValidationView"></ValidationPage>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import RRSurveyEditPopup from "@/components/regional-ratings-admin/RRSurveyEditPopup.vue";
import ValidationPage from "@/components/regional-ratings-admin/ValidationPage.vue";
import FreqSurvUserAssignPopup from "@/components/regional-ratings-admin/FreqSurvUserAssignPopup.vue";

export default {
	name: "SurveysPage",
	components: {
		FreqSurvUserAssignPopup,
		ValidationPage,
		RRSurveyEditPopup

	},
	props: {},
	mixins: [globalMixin],
	data: function() {
		return {
			store: store,
			filterText: '',

			activeEditSurvey: null,
			showEditSurveyPopup: false,
			showEditUserPermsPopup: false,

			pageView: 'main', //main or validate
		}
	},
	computed: {
		filteredSurveys() {
			if(this.filterText.length > 0) { //either already selected or matching text
				return this.store.rrSurveys.filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()))
			}
			return this.store.rrSurveys
		},
	},
	methods: {
		showUserPermView(srv) {
			if(srv) {
				this.activeEditSurvey = srv
				this.showEditUserPermsPopup = true
			}
		},
		closeUserPermView() {
			this.showEditUserPermsPopup = false
			this.activeEditSurvey = null
		},
		showValidationView(srv) {
			if(srv) {
				this.activeEditSurvey = srv
				this.pageView = 'validate'
			}
		},
		closeValidationView() {
			this.pageView = 'main'
			this.getRRAdminInfo()
			this.activeEditSurvey = null
		},
		getMktCode(srv) {
			if(srv && srv.mktid) {
				let mkt = this.store.rrMarkets.find(item => item.id === srv.mktid)
				if(mkt && mkt.shortcode) return '(' + mkt.shortcode + ')'
			}
			return ''
		},
		editSurvey(srvob) {
			this.activeEditSurvey = srvob
			this.showEditSurveyPopup = true
		},
		closeEditSurveyPopup(doRefresh) {
			this.showEditSurveyPopup = false
			if(doRefresh === true) this.getRRAdminInfo()
		},
		cloneSurvey(srvob) {
			let newname = prompt("Please enter name of new survey");
			if(newname.length > 0) {
				let newdataloc = prompt("Please enter the DATALOC of the new survey");
				if(newdataloc.length > 0) {
					let targ = this.store.urlroot + "central/post-vue/regional-ratings-admin/clone-rrsurvey"
					let sendob = {sourcerrsurvid: srvob.id, newname: newname, destdataloc: newdataloc}
					this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
						this.isFetching = false
						this.store.showLoader = false
						this.getRRAdminInfo()
					}.bind(this));
				}
			}
		},
	},
	mounted() {
		this.getRRAdminInfo()
	},
}
</script>

<style scoped>

</style>