<template>
	<div v-if="store.sessionOk">
		<div id="container" :class="{pageLeftMenuTransposed : store.leftmenuhidden}">
			<LeftMenuWL></LeftMenuWL>
			<div id="bg">
				<div id="maincontent" class="noTopMenuBar">

					<EditEDMScreen v-if="editingCampaign"
						:campob="activeCampaignOb" :incominglistid="chosenListId" :availsurvs="availsurvs"
						:availsegs="segmentsForList" :availlists="contactlistlist"
						v-on:close="closeCampaignEditor($event)"
					></EditEDMScreen>

					<div v-if="myView === 'list'" class="wl-admin-container">
						<p class="wl-main-headline">Edit EDM Campaigns</p>
						<select class="wl-input-select" v-model="chosenListId">
							<option v-for="c in contactlistlist" :value="c.id" :key="c.id">{{c.name}}</option>
						</select>
						<input placeholder="Search campaigns..." class="wl-input-text wl-input-padding-large" type="text" v-model="filterText">
						<span class="wl-icon-btn wl-btn-border-grey" @click="editCampaign(null)" style="margin: 0 10px;"><i class="mdi mdi-plus"></i>Add New</span>
						<table class="altbl noborderv" style="margin-top: 20px;">
							<thead>
							<tr>
								<td v-for="col in cols" :key="col.title" class="headingtext" @click="sortColumn(col.fldid)" data-f-bold="true">
									<span>{{col.title}}</span>
								</td>
							</tr>
							</thead>
							<tbody>
							<tr v-for="rowitem in rowsForPage" :key="rowitem.id" :class="rowitem.status">
								<td>{{rowitem.id}}</td>
								<td>{{getListNameFromId(rowitem.listId)}}</td>
								<td>{{rowitem.title}}</td>
								<td>{{rowitem.sendDate}}</td>
								<td>{{getSegmentNameFromId(rowitem.segmentId)}}</td>
								<td>{{ucfirst(rowitem.status)}}</td>
								<td>{{rowitem.subject}}</td>
								<td>
									<i class="mdi mdi-pencil" style="cursor: pointer; margin-right: 10px; font-size: 14px;" @click="editCampaign(rowitem)" title="Edit campaign"></i>
									<i class="mdi mdi-content-copy" style="cursor: pointer; margin-right: 10px; font-size: 14px;" @click="cloneCampaign(rowitem)" title="Clone campaign"></i>
									<i class="mdi mdi-chart-line" style="cursor: pointer; margin-right: 10px; font-size: 14px;" @click="viewAnalytics(rowitem)" title="Campaign Analytics"></i>
									<i class="mdi mdi-timer-sand" style="cursor: pointer; font-size: 14px;" @click="campaignHistory(rowitem)" title="Campaign History"></i>
								</td>
							</tr>
							</tbody>
						</table>
						<div style="margin: 20px 0; display: flex; align-items: center;">
							<ALTablePaginator
								:paginationpage="paginationPage"
								:paginatedrowscount="paginatedRows.length"
								v-on:nextpage="nextpage" v-on:prevpage="prevpage"
								v-on:setpage="paginationPage = $event"
							></ALTablePaginator>
						</div>
					</div>

					<div v-if="myView === 'history'" class="wl-admin-container">
						<a @click="myView = 'list'" class="wl-paginate-btn"><i class="fa fa-chevron-left"></i> Back</a>
						<p class="wl-main-headline">Recent Campaign Activity</p>
						<table class="altbl noborderv">
							<thead>
							<tr>
								<td class="headingtext"><span>Time</span></td>
								<td class="headingtext"><span>Event</span></td>
								<td class="headingtext"><span>Email</span></td>
								<td class="headingtext"><span>Notes</span></td>
							</tr>
							</thead>
							<tbody>
							<tr v-for="rowitem in mailgunhistory" :key="rowitem.id">
								<td>{{rowitem.timestamp}}</td>
								<td>{{rowitem.event}}</td>
								<td>{{rowitem.email}}</td>
								<td>{{rowitem.notes}}</td>
							</tr>
							</tbody>
						</table>
					</div>

					<div v-if="myView === 'analytics'" class="wl-admin-container">
						<a @click="myView = 'list'" class="wl-paginate-btn"><i class="fa fa-chevron-left"></i> Back</a>
						<p class="wl-main-headline">Campaign Analytics</p>
						<p class="boldme wl-p">{{activeCampaignOb.title}}</p>
						<p class="wl-p">{{activeCampaignOb.subject}}</p>
						<br/><br/>
						<CampaignAnalytics :campaign="activeCampaignOb" :analyticsdata="analyticsData"></CampaignAnalytics>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import LeftMenuWL from "@/components/LeftMenuWL";
import {globalMixin} from "@/mixins";
import ALTablePaginator from "@/components/ALTablePaginator.vue";
import CampaignAnalytics from "@/components/contacts/CampaignAnalytics.vue";
import EditEDMScreen from "@/components/contacts/EditEDMScreen.vue";

export default {
	name: "EDMsPage",
	mixins: [globalMixin],
	components: {
		EditEDMScreen,
		CampaignAnalytics,
		ALTablePaginator,
		LeftMenuWL
	},
	props: {},
	data: function () {
		return {
			store: store,
			isFetching: false,

			contactlistlist: [],
			chosenListId: null,
			campaigns: [],
			segmentsForList: [],
			availsurvs: [],

			mailgunhistory: [],
			analyticsData: {},
			activeCampaignOb: null,
			editingCampaign: false,

			myView: 'list', //history, analytics

			// table stuff
			filterText: '',
			sortColName: null,
			sortColOrder: 'desc',
			paginationRowsPerPage: 50,
			paginationPage: 1,
			cols: [
				{title: 'ID', fldid: 'id'},
				{title: 'List', sortable: true, fldid: 'listId'},
				{title: 'Title', sortable: true, fldid: 'title'},
				{title: 'Send Date', sortable: true, fldid: 'sendDate'},
				{title: 'Segment', sortable: true, fldid: 'segmentId'},
				{title: 'Status', sortable: true, fldid: 'status'},
				{title: 'Subject', sortable: true, fldid: 'subject'},
				{title: 'Functions', fldid: null},
			],
			//end table stuff
		}
	},
	computed: {
		//table stuff
		rows() {
			return this.tblPrepareFilteredRows(this.campaigns, this.filterText, this.sortColName, this.sortColOrder)
		},
		paginatedRows() {
			return this.tblGetPaginatedRows(this.rows, this.paginationRowsPerPage)
		},
		rowsForPage() {
			if(!this.paginationRowsPerPage || !this.paginationPage) return [this.rows]
			return this.paginatedRows[this.paginationPage-1]
		},
		//end table stuff
	},
	methods: {
		//table stuff
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
		prevpage() {
			if(this.paginationPage > 1) this.paginationPage--
		},
		nextpage() {
			if(this.paginationPage < this.paginatedRows.length) this.paginationPage++
		},
		//end table stuff
		getListNameFromId(listId) {
			if(!listId) return ''
			let listob = this.contactlistlist.find(item => item.id === listId)
			if(listob) return listob.name
			return ''
		},
		getSegmentNameFromId(segId) {
			if(!segId) return ''
			let segob = this.segmentsForList.find(item => item.id === segId)
			if(segob) return segob.name
			return ''
		},
		getAvailableLists() {
			let targ = this.store.urlroot + "central/post-vue/contact-lists/get-contactlists"
			let sendob = {}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				if(ret.contactlists) this.contactlistlist = ret.contactlists
				this.store.showLoader = false
				if(this.chosenListId === null && this.contactlistlist.length > 0) {
					this.chosenListId = this.contactlistlist[0].id
				}
			}.bind(this))
		},
		getCampaignList() {
			if(this.isFetching === false && this.chosenListId && this.chosenListId > 0) {
				this.campaigns = []
				this.segmentsForList = []
				this.isFetching = true
				this.store.showLoader = true
				let targ = this.store.urlroot + "central/post-vue/campaigns/get-campaigns-list"
				let sendob = {
					listId: this.chosenListId,
				}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					if(ret.campaigns) this.campaigns = ret.campaigns
					if(ret.segmentsForList) this.segmentsForList = ret.segmentsForList
					if(ret.survlist) this.availsurvs = ret.survlist
					this.store.showLoader = false
				}.bind(this))
			}
		},
		editCampaign(campob) {
			if(campob !== null) {
				this.activeCampaignOb = campob
				this.editingCampaign = true
			}
		},
		closeCampaignEditor(doRefresh) {
			this.editingCampaign = false
			if(doRefresh) this.getCampaignList()
		},
		cloneCampaign(campob) {
			if(this.isFetching === false) {
				this.isFetching = true
				this.store.showLoader = true
				let targ = this.store.urlroot + "central/post-vue/campaigns/clone-campaign"
				let sendob = {
					edmid: campob.id,
				}
				this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
					this.isFetching = false
					this.getCampaignList()
				}.bind(this))
			}
		},
		campaignHistory(campob) {
			if(this.isFetching === false && campob) {
				this.isFetching = true
				this.store.showLoader = true
				let targ = this.store.urlroot + "central/post-vue/contact-lists/get-mailgun-history"
				let sendob = {
					lookup_id: campob.id,
					type: 'edm',
				}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					if(ret.mailgunhistory) this.mailgunhistory = ret.mailgunhistory
					this.historyView = true
					this.store.showLoader = false
				}.bind(this))
			}
		},
		viewAnalytics(campob) {
			if(this.isFetching === false) {
				this.isFetching = true
				this.store.showLoader = true
				this.activeCampaignOb = campob
				let targ = this.store.urlroot + "central/post-vue/campaigns/get-analytics"
				let sendob = {
					edmid: campob.id,
				}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					this.myView = 'analytics'
					if(ret.analytics) this.analyticsData = ret.analytics
					console.log(ret)
					this.store.showLoader = false
				}.bind(this))
			}
		},
	},
	watch: {
		'store.sessionOk'() {
			if(store.sessionOk && store.user.superUser !== 1 && store.user.perms.edms !== 1) {
				this.$router.push('/error/no-access')
			}
		},
		chosenListId() {
			if(this.chosenListId) this.getCampaignList()
		},
	},
	mounted() {
		if(store.sessionOk && store.user.superUser !== 1 && store.user.perms.edms !== 1) {
			this.$router.push('/error/no-access')
		}
		this.getAvailableLists()
	},
	created() {
		document.title = "EDMs";
		this.store.activeLmenuLink = 'edms';
	}
}
</script>

<style scoped>
.altbl tr.working {
	background-color: rgba(80,231,100, 0.3);
}
.altbl tr.scheduled, .altbl tr.sending {
	background-color: rgba(35,166,238,0.3);
}
.wl-p {
	line-height: 1.4;
	margin-bottom: 10px;
}
</style>