<template>
	<div v-if="store.sessionOk">
		<div id="container" :class="{pageLeftMenuTransposed : store.leftmenuhidden}">
			<LeftMenuWL></LeftMenuWL>
			<div id="bg">

				<QuestionAssignPopup v-if="showAssignQsPopup" :srvobin="surveyOb"
					v-on:close="showAssignQsPopup = false"
				></QuestionAssignPopup>

				<StationListUpdater v-if="showStationListUpdater" :survob="surveyOb"
					v-on:close="showStationListUpdater = false" v-on:update="handleQuestionsReturn($event)"
				></StationListUpdater>

				<BranchManager v-if="showBranchManager" :survob="surveyOb" v-on:editbranch="editBranch($event)"
					v-on:close="showBranchManager = false" v-on:createnew="createNewBranch"
				></BranchManager>

				<BranchEditor v-if="showBranchEditor" :survob="surveyOb" :editingbranch="editingBranch"
					v-on:close="showBranchEditor = false" v-on:update="finishBranchSave($event)"
				></BranchEditor>

				<MatrixFlowManager v-if="showMatrixFlowManager" :survob="surveyOb" v-on:editmatrixflow="editMatrixFlow($event)"
					v-on:close="showMatrixFlowManager = false" v-on:createnew="createNewMatrixFlow" v-on:update="finishMatrixFlowSave($event)"
				></MatrixFlowManager>

				<MatrixFlowEditor v-if="showMatrixFlowEditor" :survob="surveyOb" :editingmatrixflow="editingMatrixFlow"
					v-on:close="showMatrixFlowEditor = false" v-on:update="finishMatrixFlowSave($event)"
				></MatrixFlowEditor>

				<TopMenuBar>
					<TopMenuGroup label="Tools" :hasdropdown="true">
						<TopMenuItem label="Assign Questions" @click="showAssignQs"></TopMenuItem>
						<TopMenuItem label="Branching" @click="showBranchManager = true"></TopMenuItem>
						<TopMenuItem label="Matrix Flows" @click="showMatrixFlowManager = true"></TopMenuItem>
						<TopMenuItem label="Prepare CATI Pages" @click="prepareCATIPages"></TopMenuItem>
						<TopMenuItem label="Update Station Lists" @click="updateStationListsStart"></TopMenuItem>
					</TopMenuGroup>
				</TopMenuBar>
				<div id="maincontent" class="">

					<EditQuestionScreen v-if="editingQuestion === true" :qob="editingQob"
						:positionafterqid="positionAfterQid" :survob="surveyOb"
						v-on:close="closeQuestionEditor" v-on:update="handleQuestionsReturn($event)"
					></EditQuestionScreen>

					<div class="qtbl-hold">
						<div id="qtable">
							<div class="headrow">
								<span class="minwid">PID</span>
								<span class="minwid">QPos</span>
								<span class="minwid">QID</span>
								<span class="minwid">Mand</span>
								<span class="midwid">QType</span>
								<span class="tleft fullwid">QText</span>
								<span class="midwid">Skips</span>
								<span class="midwid">Option1</span>
								<span class="midwid">Option2</span>
								<span class="midwid">Option3</span>
								<span class="midwid funcheader">Functions</span>
							</div>
							<div class="" style="width: 100%; height: 30px;"></div>

							<div v-for="(q, ind) in questionList" :key="ind" :class="getRowClass(q, ind)">
								<div v-if="q.type === 'PageBreak'" class="qrow-sub pagebreak-inside">
									<span style='padding-right: 20px;'>
										<span>PAGE BREAK - PAGE {{q.pageNo}}</span>
										<span v-if="q.branchText.length > 0" @click.stop="editBranch(q.branch)" class="branchtext">
											{{q.branchText}}
										</span>
									</span>
									<div class='pagebreak-line'></div>
									<span class='delpb' style="cursor:pointer;" @click.stop="deletePageBreak(q.pageNo)">Delete Page Break</span>
								</div>
								<div v-else-if="!['MatrixSubRadio','MatrixEndRadio','MatrixSubCheck','MatrixEndCheck'].includes(q.type)"
									:title="q.qtext_stripped" @click.stop="editQuestion(q)"
									class="qrow-sub" :class="getMovingClass(q.qid)"
								>
									<span class="minwid">{{q.pageNo}}</span>
									<span class="minwid">{{q.qpos}}</span>
									<span class="minwid">{{q.qid}}</span>
									<span class="minwid">{{q.mandatory}}</span>
									<span class="midwid">{{q.type.replace('Head','')}}</span>
									<span class="tleft fullwid">{{q.qtext_stripped}}</span>
									<span class="midwid" :class="checkSkipErrorsForQ(q)">{{q.skipouttext}}</span>
									<span class="midwid">{{q.opt1text}}</span>
									<span class="midwid">{{q.opt2text}}</span>
									<span class="midwid">{{q.opt3text}}</span>
									<span class="midwid funcs" :class="{hidey : movingQid !== null}">
										<i class="icn mdi mdi-content-copy" @click.stop="cloneQuestion(q)" title="Clone question"></i>
										<i class="icn mdi mdi-book-open-variant" @click.stop="addPageBreakAfter(q.qid)" title="Add page break after this question"></i>
										<i class="icn mdi mdi-plus" @click.stop="editQuestion(null, q.qid)" title="Insert new question after this one"></i>
										<i class="icn mdi mdi-drag" @click.stop="selectQuestionToMove(q.qid)" title="Move this question"></i>
										<i class="icn mdi mdi-close" @click.stop="deleteQuestion(q.qid)" title="Delete question"></i>
									</span>
								</div>
							</div>
						</div>
					</div>

					<div>
						<button type="button" class="option-button" @click="editQuestion(null)">Insert Question</button>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import LeftMenuWL from "@/components/LeftMenuWL";
import {globalMixin} from "@/mixins";
import TopMenuBar from "@/components/TopMenuBar";
import EditQuestionScreen from "@/components/questions/EditQuestionScreen.vue";
import TopMenuGroup from "@/components/TopMenuGroup.vue";
import TopMenuItem from "@/components/TopMenuItem.vue";
import QuestionAssignPopup from "@/components/home/QuestionAssignPopup.vue";
import StationListUpdater from "@/components/questions/StationListUpdater.vue";
import BranchManager from "@/components/questions/BranchManager.vue";
import BranchEditor from "@/components/questions/BranchEditor.vue";
import MatrixFlowManager from "@/components/questions/MatrixFlowManager.vue";
import MatrixFlowEditor from "@/components/questions/MatrixFlowEditor.vue";

export default {
	name: "QuestionBuilderPage",
	mixins: [globalMixin],
	components: {
		MatrixFlowEditor,
		MatrixFlowManager,
		BranchEditor,
		BranchManager,
		StationListUpdater,
		QuestionAssignPopup,
		TopMenuItem,
		TopMenuGroup,
		EditQuestionScreen,
		TopMenuBar,
		LeftMenuWL,
	},
	props: {},
	data: function () {
		return {
			store: store,

			chosenSurveyId: 0,
			questionList: [],
			surveyOb: null,

			errors: [],

			editingQuestion: false,
			editingQob: null,
			positionAfterQid: null,
			movingQid: null,

			showAssignQsPopup: false,

			showStationListUpdater: false,

			showBranchManager: false,
			showBranchEditor: false,
			editingBranch: null,

			showMatrixFlowManager: false,
			showMatrixFlowEditor: false,
			editingMatrixFlow: null,

			questionBlockGroups: [],
			allBlockQids: [],
		}
	},
	computed: {},
	methods: {
		handleQuestionsReturn(ret) {
			this.store.kalert.shown = false
			if(ret.errors) {
				this.errors = ret.errors
				let errtext = ''
				if(ret.errors.length > 0) {
					for(let er of ret.errors) {
						errtext += er.error + "<br/>"
					}
					this.showKalert({error: true, message: errtext, type: 'error'})
				}
			}
			if(ret.survey) {
				this.surveyOb = ret.survey
				this.questionList = []
				let branches = ret.survey.branches
				this.questionBlockGroups = ret.survey.questionBlocks
				let qblockgrps = ret.survey.questionBlocks
				let allBlockQids = []
				for(let qb of qblockgrps) {
					for(let qblock of qb.blocks) {
						for(let qid of qblock.qids) {
							if(!allBlockQids.includes(qid)) {
								allBlockQids.push(qid)
							}
						}
					}
				}
				this.allBlockQids = allBlockQids
				if (ret.survey.questions) {
					let prevpageid = 1
					let qpos = 1
					for (let q of ret.survey.questions) {
						let mypageid = q.pageNo
						let brancher = branches.find(b => b.attachToPageID === q.pageNo)
						let branchtext = ''
						if(brancher) {
							branchtext = '{Branch Show If: '+brancher.showIfRaw+'}'
						}
						if (mypageid > prevpageid) {
							let pb = {
								type: "PageBreak",
								pageNo: mypageid,
								branchText: branchtext,
								branch: brancher,
							}
							this.questionList.push(pb)
							prevpageid = mypageid
						}
						q.errors = [];
						q.skipoutarr = []
						if(q.qtext_stripped.includes('|')) {
							q.qtext_stripped = q.qtext_stripped.split('|')[0]
						}
						for (let s of q.skips) {
							let targq = s.skipToQidTarget
							if (!q.skipoutarr.includes(targq)) {
								q.skipoutarr.push(targq)
							}
						}
						q.skipoutarr.sort()
						q.skipouttext = q.skipoutarr.join(",")
						q.qpos = qpos
						q.opt1text = ''
						q.opt2text = ''
						q.opt3text = ''
						if (q.options[0]) q.opt1text = q.options[0].opttext
						if (q.options[1]) q.opt2text = q.options[1].opttext
						if (q.options[2]) q.opt3text = q.options[2].opttext
						q.errors = this.errors.filter(item => item.qid === q.qid)
						this.questionList.push(q)
						qpos++
					}
				}
			}
		},
		getQuestions() {
			this.isFetching = true
			let targ = this.store.urlroot + "central/post-vue/questions/get-questions"
			let sendob = {
				survid: this.chosenSurveyId,
			}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				this.handleQuestionsReturn(ret)
			}.bind(this))
		},
		getRowClass(q, ind) {
			let classes = ''
			if(!['MatrixSubRadio','MatrixEndRadio','MatrixSubCheck','MatrixEndCheck'].includes(q.type)) {
				classes = 'qrow'
				if(!this.questionList[ind+1] || this.questionList[ind+1].type === 'PageBreak') { //next question
					classes += ' no-qrow-bottom'
				}
			}
			if(this.allBlockQids.includes(q.qid)) {
				classes += ' blockq'
				let counter = 0
				for(let blgrp of this.questionBlockGroups) {
					for(let bl of blgrp.blocks) {
						let internalIndex = 0
						for(let qid of bl.qids) {
							if(qid === q.qid) {
								classes += ' blockq-'+counter
								if(internalIndex === 0) classes += ' blockq-first'
								if(internalIndex === bl.qids.length-1) classes += ' blockq-last'
							}
							internalIndex++
						}
						counter++
					}
				}
			}
			if(q.type === 'PageBreak') {
				classes += ' pagebreak'
			}
			return classes
		},
		getMovingClass(qid) {
			if(this.movingQid !== null) {
				if(this.movingQid === qid) return 'moving-active'
				else return 'moving-target'
			}
			return ''
		},
		checkSkipErrorsForQ(q) {
			if(q.errors && q.errors.length > 0) {
				for(let er of q.errors) {
					if (er.error.toLowerCase().includes('skip')) return 'skipError'
				}
			}
			return ''
		},
		editQuestion(qob, positionAfterQid = null) {
			if(this.movingQid === null) { //if moving a question don't let the edit click get caught
				this.editingQob = qob
				this.editingQuestion = true
				this.positionAfterQid = positionAfterQid
			}
			else { //this is to catch a move rather than edit
				if(this.movingQid === qob.qid) {
					this.movingQid = null //cancel the move
				}
				else { //finish the move
					this.isFetching = true
					let targ = this.store.urlroot + "central/post-vue/questions/move-question"
					let sendob = {
						survid: this.chosenSurveyId,
						movingQid: this.movingQid,
						moveToAfterQid: qob.qid,
					}
					this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
						this.isFetching = false
						this.movingQid = null
						this.handleQuestionsReturn(ret)
					}.bind(this))
				}
			}
		},
		closeQuestionEditor() {
			this.editingQuestion = false
		},
		cloneQuestion(qob) {
			let newob = JSON.parse(JSON.stringify(qob))
			newob.qid = 0
			newob.pageNo = 0
			this.editQuestion(newob)
		},
		showAssignQs() {
			this.showAssignQsPopup = true
		},
		prepareCATIPages() {
			let c = confirm("This will add page breaks after every question in preparation for CATI collection - you will need to check your skip logic afterwards.  Are you sure?");
			if(c === true) {
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/questions/cati-add-page-breaks"
				let sendob = {
					survid: this.chosenSurveyId,
				}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					this.handleQuestionsReturn(ret)
				}.bind(this))
			}
		},
		updateStationListsStart() {
			this.showStationListUpdater = true
		},
		createNewBranch() {
			this.showBranchManager = false
			this.editingBranch = null
			this.showBranchEditor = true
		},
		editBranch(branch) {
			this.editingBranch = branch
			this.showBranchManager = false
			this.showBranchEditor = true
		},
		finishBranchSave(ret) {
			this.showBranchEditor = false
			this.handleQuestionsReturn(ret)
			this.showBranchManager = true
			this.editingBranch = null
		},
		createNewMatrixFlow() {
			this.showMatrixFlowManager = false
			this.editingMatrixFlow = null
			this.showMatrixFlowEditor = true
		},
		editMatrixFlow(mflow) {
			this.editingMatrixFlow = mflow
			this.showMatrixFlowManager = false
			this.showMatrixFlowEditor = true
		},
		finishMatrixFlowSave(ret) {
			this.showMatrixFlowEditor = false
			this.handleQuestionsReturn(ret)
			this.showMatrixFlowManager = true
			this.editingMatrixFlow = null
		},
		selectQuestionToMove(qid) {
			if(qid === this.movingQid) this.movingQid = null
			else this.movingQid = qid
		},
		deleteQuestion(qid) {
			let c = confirm("You are about to delete this item (QID: "+qid+") from the survey, are you sure?");
			if(c === true) {
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/questions/delete-question"
				let sendob = {
					survid: this.chosenSurveyId,
					delQid: qid,
				}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					this.handleQuestionsReturn(ret)
				}.bind(this))
			}
		},
		deletePageBreak(pageid) {
			this.isFetching = true
			let targ = this.store.urlroot + "central/post-vue/questions/delete-page-break"
			let sendob = {
				survid: this.chosenSurveyId,
				pageId: pageid,
			}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				this.handleQuestionsReturn(ret)
			}.bind(this))
		},
		addPageBreakAfter(qid) {
			this.isFetching = true
			let targ = this.store.urlroot + "central/post-vue/questions/add-page-break"
			let sendob = {
				survid: this.chosenSurveyId,
				qid: qid,
			}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				this.handleQuestionsReturn(ret)
			}.bind(this))
		},
	},
	watch: {
		chosenSurveyId() {
			if(this.chosenSurveyId > 0) {
				this.$router.push('/question-builder/'+this.chosenSurveyId)
				this.getQuestions()
			}
		},
	},
	mounted() {

	},
	created() {
		document.title = "Question Builder"
		this.store.activeLmenuLink = ''
		let urlparams = this.$route.params
		if(urlparams.surveyid !== undefined) {
			this.chosenSurveyId = parseInt(urlparams.surveyid)
		}
	}
}
</script>

<style scoped>
#maincontent {
	min-height: 900px;
	margin: 30px;
	padding-top: 45px;
}
#qtable {
	text-align: left;
	margin: 0 0 30px 0;
	border: none;
	width: 1070px;
	padding: 0 5px;
}
.qrow {
	overflow: hidden;
	list-style-type: none;
	cursor: default;
	border-bottom: 1px #F1F1F1 solid;
}
.qrow.no-qrow-bottom {
	border-bottom: 1px transparent solid;
}
.qrow-sub {
	padding: 6px 5px 5px;
	height: 18px;
	display: inline-flex;
	align-items: center;
	width: calc(100% - 13px);
	border: 1px solid transparent;
}
.headrow span,
.qrow span {
	display: inline-block;
	overflow: hidden;
	white-space: nowrap;
	text-align: center;
}
.headrow span.tleft,
.qrow span.tleft {
	text-align: left;
}
.qrow:hover {
	background-color: #D8D8D8;
	background-image: none;
}
.headrow {
	background: #EEE;
	border-bottom: 2px solid #d9d9d9;
	position: fixed;
	margin-top: -5px;
	cursor: auto;
	width: 1070px;
	color: #666;
	font-family: Roboto,'Arial';
	font-weight:bold;
	padding: 10px 5px 4px;
}
.headrow:hover {
	background: #EEE;
}
.qrow.pagebreak {
	border-bottom: 1px solid #FFF;
	border-top: 1px solid #FFF;
	width: 100%;
	/*margin-top: -1px;*/
}
.qrow.pagebreak:hover {
	background: #FFF;
}
.pagebreak-inside {
	font-family: Roboto, 'Arial';
	font-size: 11px;
	font-weight: bold;
	color: #2BAEF6;
	text-align: left;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.pagebreak-line {
	height: 2px;
	min-width: 100px;
	background-color: #2BAEF6;
	flex-grow: 1;
}
.delpb {
	text-align: left;
	margin: 0 10px;
}
.minwid {
	width: 30px;
	max-width: 30px;
	text-align: center;
}
.midwid {
	width: 100px;
	max-width: 100px;
}
.midwid.funcheader, .midwid.funcs {
	width: 120px;
	max-width: 120px;
}
.fullwid {
	width: 300px;
	max-width: 300px;
}
.skipError {
	font-weight: bold;
	color: #F00;
}
.wl-popup {
	margin-top: 30px;
}
.icn {
	font-size: 18px;
	cursor: pointer;
	padding: 0px 3px;
}
.icn:hover {
	color: #2BAEF6;
}
.icn.mdi-close {
	margin-left: 7px;
}
.icn.mdi-close:hover {
	color: #F00;
}
.moving-active {
	background-color: #FFD700;
	border: 1px solid #FFD700;
}
.moving-target {
	border: 1px dashed #FFD700;
}
.midwid.funcs {
	display: none;
}
.qrow:hover .midwid.funcs {
	display: inline-block;
}
.qrow .midwid.funcs.hidey {
	display: none;
}
.branchtext {
	font-size: 10px;
	color: #551A8B;
	cursor: pointer;
	margin-left: 10px;
}
.qrow.blockq {
	border-left: 2px solid #000;
}
.qrow.blockq-first {
	border-top: 2px solid #000;
}
.qrow.blockq-last {
	border-bottom: 2px solid #000;
}
</style>