<template>
	<div>
		<div v-if="isFetching === true">Fetching...please wait</div>
		<div v-if="!isFetching" class="altable-holder">
			<div>
				<span style='margin-bottom: 20px;' class='wl-icon-btn wl-btn-border-grey' @click='exportToExcel'><i class='mdi mdi-file-excel'></i> Export this report</span>
			</div>
			<table class="altbl noborderv" id="bauer-history">
				<thead>
				<tr>
					<td class="headingtext" data-f-bold="true">
						<span>Artist</span>
					</td>
					<td class="headingtext" data-f-bold="true">
						<span>Title</span>
					</td>
					<td class="headingtext" data-f-bold="true">
						<span>Year</span>
					</td>
					<td class="headingtext" data-f-bold="true">
						<span>Score</span>
					</td>
					<td class="headingtext" data-f-bold="true">
						<span>%Pos</span>
					</td>
					<td class="headingtext" data-f-bold="true">
						<span>%Love</span>
					</td>
					<td class="headingtext" data-f-bold="true">
						<span>%Like</span>
					</td>
					<td class="headingtext" data-f-bold="true">
						<span>%OK</span>
					</td>
					<td class="headingtext" data-f-bold="true">
						<span>%Dislike</span>
					</td>
					<td class="headingtext" data-f-bold="true">
						<span>%Hate</span>
					</td>
					<td class="headingtext" data-f-bold="true">
						<span>%Tired</span>
					</td>
					<td class="headingtext" data-f-bold="true">
						<span>%Unfam</span>
					</td>
					<td class="headingtext" data-f-bold="true">
						<span>Test Date</span>
					</td>
				</tr>
				</thead>
				<tbody>
				<tr v-for="(rowitem, ind) in hookResultRows" :key="ind" style="cursor: default;">
					<td>{{rowitem.artist}}</td>
					<td>{{rowitem.title}}</td>
					<td>{{rowitem.year}}</td>
					<td class="ctr" data-t="n">{{numdisplayNdp(rowitem.popindex,2)}}</td>
					<td class="ctr" data-t="n">{{numdisplayNdp(rowitem.totpos,2)}}</td>
					<td class="ctr" data-t="n">{{numdisplayNdp(rowitem.love,2)}}</td>
					<td class="ctr" data-t="n">{{numdisplayNdp(rowitem.like,2)}}</td>
					<td class="ctr" data-t="n">{{numdisplayNdp(rowitem.ok,2)}}</td>
					<td class="ctr" data-t="n">{{numdisplayNdp(rowitem.dislike,2)}}</td>
					<td class="ctr" data-t="n">{{numdisplayNdp(rowitem.hate,2)}}</td>
					<td class="ctr" data-t="n">{{numdisplayNdp(rowitem.tired,2)}}</td>
					<td class="ctr" data-t="n">{{numdisplayNdp(rowitem.unfam,2)}}</td>
					<td class="ctr">{{rowitem.testdate}}</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
import {globalMixin} from "@/mixins";
import {store} from "@/store";
import TableToExcel from "@linways/table-to-excel";

export default {
	name: "DashBauerHistoryTable",
	mixins: [globalMixin],
	components: {

	},
	props: {
		demoid: {
			type: [Number, String],
			default: 0
		},
		weighting: {
			type: Boolean,
			default: false
		},
		results: {
			type: Array,
			default: () => []
		},
		survob: {
			type: Object,
			default: () => {}
		},
	},
	data: function () {
		return {
			store: store,

			isFetching: false,
			hookResultRows: [],
		}
	},
	computed: {

	},
	methods: {
		exportToExcel() {
			TableToExcel.convert(document.getElementById('bauer-history'), {
				name: "HistoryReport.xlsx",
				sheet: {
					name: 'Sheet 1'
				}
			});
		},
		getHistory() {
			if(this.isFetching === false && this.survob) {
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/dashboard/bauer-song-history-report"
				let weightvar = 0
				if(this.weighting === true) weightvar = 1
				let sendob = {
					survid: this.survob.survid,
					demo: this.demoid,
					weightVar: weightvar,
				}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					if (ret.hookResultRows) this.hookResultRows = ret.hookResultRows
				}.bind(this))
			}
		}
	},
	watch: {

	},
	mounted() {
		this.getHistory()
	},
}
</script>
<style scoped></style>