import { reactive } from 'vue'

let isDevServer = false;
let urlroot = 'https://yourwavelength.com/'; //api call (WL main) url
let collectionRoot = 'https://survey.yourwavelength.com/'
if (window.location.origin.indexOf('localhost') > -1) {
	isDevServer = true;
	urlroot = 'http://wavelength.test/';
	collectionRoot = 'http://wave-collect.test/'
}

export const store = reactive({
	urlroot: urlroot,
	isDevServer: isDevServer,
	collectionRoot: collectionRoot,

	sessionOk: false,
	user: {
		id: 0,
		email: '',
		fname: '',
		lname: '',
		superUser: 0,
		status: '',
		showFrequencyInWLLeft: 0,
		station: '',
		perms: {
			CATISuper: 0,
			CATIUser: 0,
			analysis: 0,
			contactlists: 0,
			contacts: 0,
			dash: 0,
			edms: 0,
			hookcentral: 0,
			panelUser: 0,
			publisher: 0,
			qb: 0,
			siteAdmin: 0,
			rrusersOwned: 0,
			rrusersFull: 0,
		},
		rruserid: null,
	},
	wlsesstoken: '',

	kalert: {
		shown: false,
		message: null,
		type: 'danger',
	},

	leftmenuhidden: false,
	activeLmenuLink: '',
	showLoader: false,
	forceMenusClose: 0,
	clearSearchAndReloadCollection: false,
	mouseClickAnywhere: false,

	// frequency / RRAMS admin object arrays
	rrMarkets: [],
	rrSurveys: [],
	rrMobileLicenses: [],
	rrUsers: [],
	rrUserGroups: [],
	rrStations: [],

	// //analysis admin object arrays
	analysesList: [],
	activeAnalysis: null,

})