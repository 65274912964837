<template>
	<div v-if="store.sessionOk">
		<div id="container" :class="{pageLeftMenuTransposed : store.leftmenuhidden}">
			<LeftMenuWL></LeftMenuWL>
			<div id="bg">
				<div id="maincontent" class="noTopMenuBar">
					<div class="tabdiv">
						<div class="tab" :class="{active : activetab === 'Users'}" @click="activetab = 'Users'">Users</div>
						<div class="tab" :class="{active : activetab === 'Groups'}" @click="activetab = 'Groups'">Groups</div>
						<div v-if="store.user.superUser === 1" class="tab" :class="{active : activetab === 'Tools'}" @click="activetab = 'Tools'">Tools</div>
					</div>
					<div v-if="activetab === 'Users'" class="wl-admin-container">
						<FreqUserMgrContent v-if="store.rrUsers.length > 0"></FreqUserMgrContent>
					</div>
					<div v-if="activetab === 'Groups'" class="wl-admin-container">
						<FreqUserGroupContent v-if="store.rrUserGroups.length > 0"></FreqUserGroupContent>
					</div>
					<div v-if="activetab === 'Tools'" class="wl-admin-container tools-section">
						<p class="wl-main-headline">Agency Usage</p>
						<p>Select Month</p>
						<date-picker v-model:value="dateForTools" valueType="format" format="YYYY-MM-DD"></date-picker>
						<button id="emsubbtn" class="option-button colbtn" type="button" @click="getAgencyUsage">Run</button>
						<div v-html="dateReturnHtml"></div>
						<br/><br/>
						<button class="option-button colbtn" type="button" @click="getAgencyByUser">By User</button>

						<br/><br/>
						<button class="option-button colbtn" type="button" @click="getSCAUsers">SCA User Report</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import LeftMenuWL from "@/components/LeftMenuWL";
import {globalMixin} from "@/mixins";
import FreqUserMgrContent from "@/components/freq-usrmgr/FreqUserMgrContent";
import FreqUserGroupContent from "@/components/freq-usrmgr/FreqUserGroupContent";
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css';

export default {
	name: "FrequencyUserManagerPage",
	mixins: [globalMixin],
	components: {
		FreqUserGroupContent,
		FreqUserMgrContent,
		LeftMenuWL,
		DatePicker,
	},
	props: {},
	data: function () {
		return {
			store: store,
			activetab: 'Users',

			dateForTools: '',
			dateReturnHtml: null,
		}
	},
	computed: {},
	methods: {
		getAgencyUsage() {
			let targ = this.store.urlroot + "central/post-vue/freq/agency-usage";
			let sendob = {dater: this.dateForTools};
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.dateReturnHtml = ret.html;
			}.bind(this));
		},
		getAgencyByUser() {
			window.location = this.store.urlroot + "central/post-vue/freq/agency-usage-by-user?dater="+this.dateForTools+"&uid="+this.store.user.id+"&wlsesstoken="+this.store.wlsesstoken;
		},
		getSCAUsers() {
			window.location = this.store.urlroot + "central/post-vue/freq/sca-usage-by-user?uid="+this.store.user.id+"&wlsesstoken="+this.store.wlsesstoken;
		},
	},
	watch: {
		'store.sessionOk'() {
			if(store.sessionOk && store.user.superUser !== 1 && store.user.id !== 4190) { //jenny m also allowed
				this.$router.push('/error/no-access');
			}
		},
	},
	mounted() {
		if(store.sessionOk && store.user.superUser !== 1 && store.user.id !== 4190) { //jenny m also allowed
			this.$router.push('/error/no-access');
		}
		this.store.showLoader = true
		this.getRRAdminInfo(); //from mixin
	},
	created() {
		document.title = "Frequency User Admin";
		this.store.activeLmenuLink = 'frequsers';
	}
}
</script>

<style>
	.mx-datepicker .mx-input {
		background-color: #f1f2f6;
		padding: 7px 8px;
	}
</style>