<template>
	<div v-if="store.sessionOk">

		<EditSurveyScreen v-if="popupEditSurvShown" :survid="selectedSurvId" v-on:close="popupEditSurvShown = false" v-on:closerefresh="closePopupAfterSaveAndRefresh"></EditSurveyScreen>
		<EntryLinkScreen v-if="popupEntryLinkShown" :survid="selectedSurvId" :surveys="surveys" v-on:close="popupEntryLinkShown = false"></EntryLinkScreen>
		<EditEarnCodesScreen v-if="popupPanelEarnCodesShown" :survid="selectedSurvId" :surveys="surveys" v-on:close="popupPanelEarnCodesShown = false" v-on:closerefresh="closePopupAfterSaveAndRefresh"></EditEarnCodesScreen>
		<ChooseActiveSurveyScreen v-if="popupChooseActiveSurvShown" :stnlooks="stationsForDropdown" v-on:close="popupChooseActiveSurvShown = false"></ChooseActiveSurveyScreen>
		<WinnerDrawScreen v-if="popupWinnerDrawShown" :stnlooks="stationsForDropdown" v-on:close="popupWinnerDrawShown = false"></WinnerDrawScreen>

		<div id="container" :class="{pageLeftMenuTransposed : store.leftmenuhidden}">
			<LeftMenuWL></LeftMenuWL>
			<div id="bg">
				<TopMenuBar>
					<TopMenuGroup v-if="store.user.superUser === 1" label="Tools" :hasdropdown="true">
						<TopMenuItem label="Create New" @click="showcreatenew"></TopMenuItem>
						<TopMenuItem routerlink="/manage-screeners" :targblank="false" label="Manage Screeners" ></TopMenuItem>
						<TopMenuItem label="Set Active Survey" @click="setActiveSurvey"></TopMenuItem>
						<TopMenuItem label="Winner Draw" @click="winnerShowPopup"></TopMenuItem>
<!--						<TopMenuItem label="Hook Analysis" @click="hookAnalysisEntry"></TopMenuItem>-->
						<TopMenuItem extlink="https://gfk.arnconnect.com.au/admin/load-data.php" label="GFK Ratings Load" ></TopMenuItem>
					</TopMenuGroup>
				</TopMenuBar>

				<CollectionScreen v-if="collectionScreenShown" :survid="selectedSurvId" :surveys="surveys"
					v-on:close="collectionScreenShown = false"
					v-on:getrespstatus="getRespondentStatus(selectedSurvId)"
				></CollectionScreen>

				<div id="maincontent" :class="{displaynone : collectionScreenShown}">
					<div class="wl-admin-container">
						<p class="wl-main-headline">{{title}}</p>
						<p class='wl-sub-headline' v-html="subtitle"></p>

						<div id="survSearchHold">
							<input type="text" style="width: 300px;" v-model="filterText" @keyup.enter="getSurveyList" class="wl-input-text wl-input-padding-large" />
							<button @click="getSurveyList" style="margin-left: 5px;" type="button" class="option-button">Search</button>
						</div>

						<div v-if="surveys.length > 0">
							<table id="restbl">
								<tr><td colspan='2'></td></tr>
								<tr v-for="su in surveys" :key="su.id" :class='{actrow : selectedSurvId === su.id, inactrow : selectedSurvId !== su.id}' @click="selectedSurvId = su.id">
									<td :class="{boldgreen : su.status === 'publish', workgreen : su.status === 'working'}">
										<div class='initDet'>
											<div class='initDetTop'>
												<div class='restbl-column'>
													<div class='client-avatar' :style='"background-image: url(&apos;"+su.clientAvatarUrl+"&apos;);"'></div>
													<span class='stitle' :title='su.survname_internal'>
														<span v-if="store.user.perms.panelUser === 1 || store.user.superUser === 1" style="cursor: pointer;" @click="showEntryLink(su.id)">{{su.survname_internal}}</span>
														<span v-else>{{su.survname_internal}}</span>
														<span class='ssubt' style='line-height:1.4;'>Created: {{su.dateCreated}}<br/>ID: {{su.id}}</span>
													</span>
												</div>
												<div class='restbl-column'>
													<span class='w170'>{{su.type}}
														<span class='ssubt' style='line-height: 1.2;'>Ps: {{su.npages}}, Qs: {{su.nquestions}}, Hs:
															<span v-if="su.nhooksA !== su.nhooksQ" class='herr' title='Different number of hooks in Hooklist and Answer table'>{{su.nhooksA}} ({{su.nhooksQ}})</span>
															<span v-else>{{su.nhooksA}}</span>
														</span>
													</span>
													<span class='w140'>{{su.statusNice}}<br/>
														<span v-if="store.user.superUser === 1" class='ssubt' style='line-height: 1.2;'>{{su.clientShortcode}}</span>
													</span>
													<div class='w170' style="display: inline;">{{su.ncompletes}}<span style='font-size: 13px; font-weight: normal;'> complete</span>
														<div class='ghold'>
															<div class='ginner' :style='"width: "+su.completeWidth'></div>
														</div>
														<span class='ssubt'>Quota: {{su.quota}}, Starts: {{su.nstarts}}</span>
													</div>
												</div>
												<div class='fnholders'>
													<div v-if="store.user.perms.panelUser === 1 && su.status === 'publish'" @click="deleteIds(su.id)" class="fndiv">
														<span class="icnhold"><i class="mdi mdi-eraser"></i></span><span class="icntxt">Clear Test Data</span>
													</div>
													<div v-if="store.user.perms.panelUser === 1 && su.status === 'publish'" @click="delSelectedResponses(su.id)" class="fndiv" title='Delete selected responses for this survey'>
														<span class='icnhold'><i class='mdi mdi-content-cut'></i></span><span class='icntxt'>Del IDs</span>
													</div>
													<div v-if="(store.user.perms.panelUser === 1 || store.user.superUser === 1) && su.status === 'publish' && [15,28].includes(store.user.id)" @click="showEarn(su.id)" class="fndiv">
														<span class="icnhold"><i class="mdi mdi-code-json"></i></span><span class="icntxt">Earn Codes</span>
													</div>
													<div v-if="store.user.perms.panelUser === 1 && su.status === 'publish' && su.type === 'OMT'" @click="simulate(su.id)" class="fndiv">
														<span class="icnhold"><i class="mdi mdi-play-circle-outline"></i></span><span class="icntxt">Simulate</span>
													</div>
													<div v-if="store.user.perms.panelUser === 1" @click="report('panel', su.id)" class="fndiv">
														<span class="icnhold"><i class="mdi mdi-cloud-download-outline"></i></span><span class="icntxt">Download</span>
													</div>
													<div v-if="store.user.perms.panelUser === 0 && store.user.perms.CATIUser !== 1 && store.user.superUser !== 1 && store.user.id !== 4063" @click="report('winners', su.id)" class="fndiv">
														<span class="icnhold"><i class="mdi mdi-cloud-download-outline"></i></span><span class="icntxt">Winners</span>
													</div>
													<div v-if="store.user.perms.publisher === 1 && su.status === 'working'" @click="publish(su.id)" class="fndiv">
														<span class='icnhold'><i class='mdi mdi-play-circle-outline'></i></span><span class='icntxt'>Publish</span>
													</div>
													<div v-if="store.user.perms.dash === 1 || store.user.perms.panelUser === 1 || store.user.superUser === 1" @click="showCollection" class="fndiv">
														<span class='icnhold'><i class='mdi mdi-file-tree'></i></span><span class='icntxt'>Collection</span>
													</div>
													<router-link v-if="store.user.perms.dash === 1 || store.user.perms.panelUser === 1 || store.user.superUser === 1" :to="'/dashboard/'+su.id">
														<div class="fndiv">
															<span class='icnhold'><i class='mdi mdi-gauge'></i></span><span class='icntxt'>Dashboard</span>
														</div>
													</router-link>
													<router-link v-if="su.defaultAnalysisId > 0 && (store.user.perms.analysis === 1 || store.user.superUser === 1)" :to="'/analysis/'+su.defaultAnalysisId">
														<div class="fndiv">
															<span class='icnhold'><i class='mdi mdi-chart-line'></i></span><span class='icntxt'>Analysis</span>
														</div>
													</router-link>
													<router-link v-if="catiTypes.includes(su.type) && (store.user.perms.CATIUser === 1 || store.user.superUser === 1)" :to="'/cati-launch/'+su.id">
														<div class="fndiv">
															<span class='icnhold'><i class='mdi mdi-phone-classic'></i></span><span class='icntxt'>{{catiTitle}}</span>
														</div>
													</router-link>
													<div v-if="store.user.perms.siteAdmin === 1 || store.user.superUser === 1" @click="editsurv(su.id)" class="fndiv" title='Edit the settings for this survey'>
														<span class='icnhold'><i class='mdi mdi-cogs'></i></span><span class='icntxt'>Settings</span>
													</div>
													<div v-if="store.user.perms.siteAdmin === 1 || store.user.superUser === 1" class="fndiv" title='Download an Excel file with the raw responses' @click="downloadExcelCsv(su.id)">
														<span class='icnhold'><i class='mdi mdi-cloud-download-outline'></i></span><span class='icntxt'>Download</span>
													</div>
													<div v-if="store.user.superUser === 1" @click="report('panel', su.id)" class="fndiv">
														<span class="icnhold"><i class="mdi mdi-cloud-download-outline"></i></span><span class="icntxt">Panel</span>
													</div>
													<div v-if="store.user.perms.siteAdmin === 1 || store.user.superUser === 1" @click="delsurv(su.id)" class="fndiv" title='Delete this survey'>
														<span class='icnhold'><i class='mdi mdi-trash-can-outline'></i></span><span class='icntxt'>Delete</span>
													</div>
													<div v-if="store.user.perms.siteAdmin === 1 || store.user.superUser === 1" @click="cloneSurv(su.id)" class="fndiv" title='Clone this survey to a new survey'>
														<span class='icnhold'><i class='mdi mdi-content-copy'></i></span><span class='icntxt'>Clone</span>
													</div>
													<div v-if="store.user.superUser === 1" @click="delresponses(su.id)" class="fndiv" title='Clear all responses for this survey'>
														<span class='icnhold'><i class='mdi mdi-eraser'></i></span><span class='icntxt'>Clear</span>
													</div>
													<div v-if="store.user.superUser === 1" @click="delSelectedResponses(su.id)" class="fndiv" title='Delete selected responses for this survey'>
														<span class='icnhold'><i class='mdi mdi-content-cut'></i></span><span class='icntxt'>Del IDs</span>
													</div>
													<div v-if="store.user.superUser === 1 || store.user.perms.panelUser === 1" @click="getRespondentStatus(su.id)" class="fndiv" title='Returns status of selected Respondent ID for this survey'>
														<span class='icnhold'><i class='mdi mdi-account'></i></span><span class='icntxt'>Status</span>
													</div>
<!--													<a v-if="store.user.superUser === 1 || store.user.perms.qb === 1" :href="store.urlroot+'central/question-builder?edqsurvid='+su.id">-->
<!--														<div class="fndiv">-->
<!--															<span class='icnhold'><i class='mdi mdi-chart-bar'></i></span><span class='icntxt'>Q Editor</span>-->
<!--														</div>-->
<!--													</a>-->
													<router-link v-if="store.user.superUser === 1 || store.user.perms.qb === 1" :to="'/question-builder/'+su.id">
														<div class="fndiv">
															<span class='icnhold'><i class='mdi mdi-chart-bar'></i></span><span class='icntxt'>Q Editor</span>
														</div>
													</router-link>
													<div v-if="store.user.superUser === 1 && su.type === 'RegRatings' && su.linked_online_dataloc === 0" @click="mergeRespondentDataIn(su.id)" class="fndiv" title='Merge in collected data from other survey'>
														<span class='icnhold'><i class='mdi mdi-account-multiple-plus'></i></span><span class='icntxt'>Merge</span>
													</div>

												</div>
											</div>
										</div>
									</td>
								</tr>
							</table>

							<div style="margin: 30px 0;">
								<span v-if="startRow > 0" class='wl-paginate-btn' @click="getPrev"><i class='mdi mdi-chevron-left'></i> Prev</span>
								<span v-if="surveys.length >= 30" class='wl-paginate-btn' @click='getNext'>Next <i class='mdi mdi-chevron-right'></i></span>
							</div>

						</div>
						<div v-else>
							<span class='secspan'>You have no projects to view at the moment.</span>
							<div style="margin: 30px 0;">
								<span v-if="startRow > 0" class='wl-paginate-btn' @click="getPrev"><i class='mdi mdi-chevron-left'></i> Prev</span>
							</div>
						</div>

					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import LeftMenuWL from "@/components/LeftMenuWL";
import {globalMixin} from "@/mixins";
import TopMenuBar from "@/components/TopMenuBar";
import TopMenuGroup from "@/components/TopMenuGroup";
import TopMenuItem from "@/components/TopMenuItem";
import axios from "axios";
import EditSurveyScreen from "@/components/home/EditSurveyScreen";
import EntryLinkScreen from "@/components/home/EntryLinkScreen";
import EditEarnCodesScreen from "@/components/home/EditEarnCodesScreen";
import ChooseActiveSurveyScreen from "@/components/home/ChooseActiveSurveyScreen";
import WinnerDrawScreen from "@/components/home/WinnerDrawScreen";
import CollectionScreen from "@/components/home/CollectionScreen";

export default {
	name: "CollectionHomePage",
	mixins: [globalMixin],
	components: {
		CollectionScreen,
		WinnerDrawScreen,
		ChooseActiveSurveyScreen,
		EditEarnCodesScreen,
		EntryLinkScreen,
		EditSurveyScreen,
		TopMenuItem,
		TopMenuGroup,
		TopMenuBar,
		LeftMenuWL
	},
	props: {},
	data: function () {
		return {
			store: store,

			surveys: [],
			hooklists: [],

			startRow: 0,
			filterText: '',
			isFetching: false,
			selectedSurvId: 0,

			catiTypes: ['CATI','RegRatings','CATI_External'],
			stationsForDropdown: [
				{code: 'BauerPlace', title: 'Bauer Hits'},
				{code: 'BauerAbsolute', title: 'Bauer Absolute'},
				{code: 'BauerMagic', title: 'Bauer Magic'},
				{code: 'KissUk', title: 'Bauer Kiss'},
				{code: 'GreatestHits', title: 'Bauer GHR'},
				{code: 'Cruise1323', title: 'ARN Cruise'},
				{code: 'BauerCountry', title: 'Bauer Country'},
				{code: 'Backstage', title: 'Backstage'},
				// {code: 'BackstageKIIS', title: 'Backstage KIIS'},
				// {code: 'BackstagePG', title: 'Backstage Pure Gold'},
			],

			popupEditSurvShown: false,
			popupEntryLinkShown: false,
			popupPanelEarnCodesShown: false,
			popupChooseActiveSurvShown: false,
			popupWinnerDrawShown: false,

			collectionScreenShown: false,
		}
	},
	computed: {
		title() {
			if(this.store.user.perms.panelUser === 1) return "View your panel data";
			return 'Live data for your projects';
		},
		subtitle() {
			if(this.store.user.perms.panelUser === 1) {
				let href = this.store.urlroot+"central/gets/panel-teg-export-list?wlsesstoken="+this.store.wlsesstoken+"&uid="+this.store.user.id;
				return "This is a list of the projects associated with the panel. <a href='"+href+"'>Export list to CSV</a>";
			}
			return 'This is a list of the projects associated with your account.';
		},
		uAuthStr() {
			return 'wlsesstoken='+this.store.wlsesstoken+'&uid='+this.store.user.id;
		},
		catiTitle() { //just for some space management
			if(this.store.user.superUser === 1) return 'CATI'
			return 'Manage CATI'
		},
	},
	methods: {
		//top menu functions
		showcreatenew() {
			this.closeAllMenus();
			setTimeout(function() { //the prompt blocks everything so give closeAllMenus time to work
				let sname = window.prompt('Enter the name of the new survey');
				if(sname && !this.isFetching) {
					this.isFetching = true;
					let targ = this.store.urlroot + "central/post-vue/home/create-new-survey";
					let sendob = { newsurvname: sname };
					this.simplePost(targ, sendob).then(function() { //use ret in function as returned data
						this.selectedSurvId = 0;
						this.isFetching = false;
						this.getSurveyList();
					}.bind(this));
				}
			}.bind(this), 50);
		},
		setActiveSurvey() {
			this.popupChooseActiveSurvShown = true;
		},
		winnerShowPopup() {
			this.popupWinnerDrawShown = true;
		},
		// hookAnalysisEntry() {
		// 	let pr = prompt("Enter the Hook ID to analyse");
		// 	if(pr !== null && pr != '') {
		// 		let hookid = parseInt(pr);
		// 		window.location = this.store.urlroot + "central/hook-analysis?func=analyse&hookid="+hookid; //old PHP site
		// 	}
		// },

		//survey tr functions
		mergeRespondentDataIn(dest_suid) {
			let pr = prompt("Please enter the SurvIds to merge responses in (separated by pipe if more than one) - should be CATI & Online usually")
			if(pr !== null) {
				let c = confirm("This will merge data in to this survey - are you sure?")
				let temp = pr.split('|')
				let src_survid_arr = []
				for(let t of temp) {
					let tt = parseInt(t)
					if(tt > 0 && !isNaN(tt) && src_survid_arr.indexOf(tt) === -1) {
						src_survid_arr.push(tt)
					}
				}
				if(c === true && src_survid_arr.length > 0) {
					this.isFetching = true
					let targ = this.store.urlroot + "central/post-vue/home/merge-responses-in"
					let sendob = {dest_survid: dest_suid, survidsIncoming: src_survid_arr}
					this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
						this.isFetching = false
						if (ret.success) {
							this.showKalertSuccess(ret)
						}
						this.getSurveyList()
					}.bind(this))
				}
			}
		},
		deleteIds(suid) {
			this.isFetching = true;
			let targ = this.store.urlroot + "central/post-vue/home/panel-delete-test-ids";
			let sendob = {survid: suid};
			this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
				this.selectedSurvId = 0;
				this.isFetching = false;
				this.getSurveyList();
			}.bind(this));
		},
		delSelectedResponses(suid) {
			let pr = prompt("Please enter the RespIDs to delete (separated by pipe if more than one)");
			if(pr !== null) {
				let c = confirm("This will permanently delete these IDs from this survey - are you sure?");
				if(c === true) {
					this.isFetching = true;
					let targ = this.store.urlroot + "central/post-vue/home/delete-selected-responses";
					let sendob = {survid: suid, idsToDelete: pr};
					this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
						this.isFetching = false;
						if (ret.success) {
							this.showKalertSuccess(ret);
						}
						this.getSurveyList();
					}.bind(this));
				}
			}
		},
		showEntryLink(survid) { //popup with below URL displayed
			let mysurv = this.surveys.find(s => s.id === parseInt(survid));
			let survpanel = mysurv.panel
			if(!survpanel.includes('gmi')) { //do not show any GMI/Bauer links
				this.selectedSurvId = parseInt(survid)
				this.popupEntryLinkShown = true
			}
		},
		showEarn(survid) { //earn codes popup handler for Panel Manager
			this.selectedSurvId = parseInt(survid);
			this.popupPanelEarnCodesShown = true;
		},
		editsurv(suid) {
			this.selectedSurvId = parseInt(suid);
			this.popupEditSurvShown = true;
		},
		showCollection() {
			this.collectionScreenShown = true;
			window.scrollTo(0,0);
		},
		simulate(suid) {
			let getrespid = prompt("Please enter the Respondent UUID to simulate with.  Make sure they're up to the hooks section of the study");
			if(getrespid.length > 0 && parseInt(getrespid) !== 0 && getrespid !== false) {
				this.isFetching = true;
				let targ = this.store.urlroot + "central/post-vue/home/simulate-dmt-complete";
				let sendob = {survid: suid, testRespID: getrespid};
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false;
					if (ret.success) {
						this.showKalertSuccess(ret);
					}
					this.getSurveyList();
				}.bind(this));
			}
		},
		publish(suid) {
			let c = confirm("This will finalise the study and send to the panel - you will NOT be able to change the hook list after you do this - are you sure?");
			if(c === true) {
				this.isFetching = true;
				let targ = this.store.urlroot + "central/post-vue/home/save-survey-info";
				let sendob = {survid: suid, publisher: 1};
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false;
					if (ret.success) {
						this.showKalertSuccess(ret);
					}
					this.getSurveyList();
				}.bind(this));
			}
		},
		report(rpttype, suid) {
			window.location = this.store.urlroot+'central/gets/createcsv?survid='+suid+'&demo=0&completes=all&report='+rpttype+'&'+this.uAuthStr
		},
		delsurv(suid) {
			var c = confirm("This will delete the survey with ID: " + suid + ", are you sure?");
			if (c === true) {
				this.isFetching = true;
				let targ = this.store.urlroot + "central/post-vue/home/delete-survey";
				let sendob = {delsurvid: suid};
				this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
					this.selectedSurvId = 0;
					this.isFetching = false;
					this.getSurveyList();
				}.bind(this));
			}
		},
		cloneSurv(suid) {
			this.isFetching = true;
			let targ = this.store.urlroot + "central/post-vue/home/clone-survey";
			let sendob = {sourcesurvid: suid};
			this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
				this.selectedSurvId = 0;
				this.isFetching = false;
				this.getSurveyList();
			}.bind(this));
		},
		delresponses(suid) {
			let c = confirm("This will delete responses for survey with ID: "+suid+", are you sure?");
			if(c === true) {
				let pr = prompt("Please type 'confirm' in this box to confirm deletion of RESPONSES");
				if (pr === "confirm") {
					this.isFetching = true;
					let targ = this.store.urlroot + "central/post-vue/home/delete-responses";
					let sendob = {survid: suid};
					this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
						this.isFetching = false;
						if(ret.success) {
							this.showKalertSuccess(ret);
						}
						this.getSurveyList();
					}.bind(this));
				}
			}
		},
		getRespondentStatus(suid) {
			var pr = prompt("Please enter the RespID to check their status");
			if(pr !== null) {
				this.isFetching = true;
				let targ = this.store.urlroot + "central/post-vue/home/get-respondent-status";
				let sendob = {survid: suid, respid: pr};
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false;
					if(ret.success) {
						this.showKalertSuccess(ret);
					}
				}.bind(this));
			}
		},
		downloadExcelCsv(suid) {
			window.location = this.store.urlroot+'central/gets/createcsv?demo=0&report=flatfile&survid='+suid+'&'+this.uAuthStr;
		},

		//standard functions
		getSurveyList() {
			if(!this.isFetching) {
				this.isFetching = true;
				let self = this;
				axios.post(
					self.store.urlroot + "central/post-vue/home/get-survey-list",
					JSON.stringify({
						uid: this.store.user.id,
						wlsesstoken: this.store.wlsesstoken,
						startRow: this.startRow,
						searchText: this.filterText,
					})
				).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.error) {
						self.store.showKalertError(ret.error);
					}
					if (ret.surveys) {
						self.surveys = ret.surveys;
						if(ret.surveys.length > 0 && (self.selectedSurvId === 0 || !ret.surveys.find(item => item.id === self.selectedSurvId))) { //no selection or selected Id not in current list
							self.selectedSurvId = ret.surveys[0].id;
						}
					}
					if (ret.hooklists) {
						self.hooklists = ret.hooklists;
					}
					self.isFetching = false;
					window.scrollTo(0,0);
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		getNext() {
			if(!this.isFetching) {
				this.startRow = this.startRow + 30;
				this.getSurveyList();
			}
		},
		getPrev() {
			if(!this.isFetching) {
				this.startRow = this.startRow - 30;
				if(this.startRow < 0) this.startRow = 0;
				this.getSurveyList();
			}
		},
		closePopupAfterSaveAndRefresh() {
			this.popupEditSurvShown = false;
			this.popupPanelEarnCodesShown = false;
			this.getSurveyList();
		},
	},
	watch: {
		'store.clearSearchAndReloadCollection': {
			handler() {
				if(this.store.clearSearchAndReloadCollection === true) {
					this.filterText = ''
					this.startRow = 0
					this.store.clearSearchAndReloadCollection = false
					this.getSurveyList()
				}
			}
		},
	},
	mounted() {
		this.getSurveyList();
	},
	created() {
		document.title = "Wavelength";
		this.store.activeLmenuLink = 'home';
	}
}
</script>

<style scoped>
.displaynone, #maincontent.displaynone {
	display: none;
}
/** surveys table */
.client-avatar {
	border: 1px solid #EEE;
	background-color: #FFF;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	width: 80px;
	margin: 0px 10px 0 20px;
	float: left;
	display: inline-block;
	height: 80px;
	border-radius: 40px;
}
.fndiv {
	display: inline;
}
#restbl .boldgreen {
	font-family: Roboto,'Arial';
	font-weight:bold;
	color: #23A6EE;
}
#restbl .workgreen {
	font-family: Roboto,'Arial';
	font-weight:bold;
	color: #00BC6C;
}
.fndiv {
	color: #666;
	background-color: #FFF;
	padding: 8px 7px;
	margin: 5px 0;
	display: flex;
	align-items: center;
	float: left;
	transition: 0.2s all linear;
	cursor: pointer;
}
.fndiv:first-of-type {
	padding-left: 12px;
}
.fndiv:last-of-type {
	padding-right: 12px;
}
.fndiv:hover {
	background: #23A6EE;
	color: #FFF;
}
.fndiv .icnhold {
	margin: 0;
	display: block;
	float: left;
	font-size: 15px;
}
#restbl {
	font-size: 13px;
	padding: 2px;
	text-align: left;
	margin: 15px 0px;
	color: #666;
	border: none;
	z-index: 701;
	position: relative;
}
.fndiv .icntxt {
	display: block;
	float: right;
	padding: 6px 6px 6px 7px;
	font-size: 11px;
	font-family: Roboto, Arial;
	font-weight:normal;
}
#restbl .stitle {
	font-size: 17px;
	margin: 10px;
	display: block;
	float: left;
	width: 410px;
	overflow: hidden;
	font-family: "Montserrat";
	font-weight: bold;
}
#restbl .w170, #restbl .w140 {
	width: 170px;
	overflow: hidden;
	display: block;
	float: left;
	margin: 10px;
	font-size: 17px;
	font-family: "Montserrat";
	font-weight: bold;
}
#restbl .w140 {
	width: 140px;
}
.stitle a {
	color: inherit;
}
#restbl .ssubt {
	margin: 10px 10px 0 0;
	display: block;
	font-size: 13px;
	color: #666;
	font-family: Roboto, Arial;
	font-weight:normal;
}
#restbl .initDet {
	float: left;
	min-height: 100px;
}
#restbl .inactrow .fnholders {
	display: none;
}
#restbl .inactrow {
	background-image: none;
	border-top: 1px solid #E4E4E4;
	border-bottom: 1px solid #E4E4E4;
}
#restbl .inactrow:hover {
	background-color: #F1F2F6;
	opacity: 1;
}
#restbl .actrow {
	background-image: none;
	background: #ecedf1;
	border-top: 1px solid #E4E4E4;
	border-bottom: 1px solid #E4E4E4;
	opacity: 1;
}
#restbl tr {
	cursor: default;
}
#restbl .restbl-column {
	display: inline-block;
	overflow: hidden;
}
#restbl .fnholders {
	padding: 20px 0 0px 20px;
	overflow: hidden;
	width: 100%;
}
#restbl .initDet .initDetTop {
	display: block;
	overflow: hidden;
	padding-bottom: 20px;
}
#restbl .ghold {
	border-radius: 2px;
	background-color: #E0E0E0;
	width: 140px;
	height: 16px;
	margin-top: 10px;
}
#restbl .ginner {
	border-radius: 2px;
	background-color: #666666;
	height: 16px;
}
#restbl .boldgreen .ginner {
	background-color: #23A6EE;
}
#restbl tr:nth-of-type(1) {
	display: none;
}
#restbl tr:nth-of-type(2) {
	border-top: 1px solid #e4e4e4;
}
#restbl tr {
	border-bottom: 1px solid #e4e4e4;
	background-color: #FFF;
}
#restbl td {
	height: 20px;
	min-height: 20px;
	max-height: 20px;
	vertical-align: middle;
	padding: 30px 20px 10px;
}

@media screen and (max-width: 1450px) {
	#restbl .stitle {
		width: 280px;
	}
}

@media screen and (max-width: 1320px) {
	#restbl .restbl-column .w140 {
		width: 125px;
	}
	#restbl .restbl-column .w170:first-of-type {
		width: 135px;
	}
}
</style>