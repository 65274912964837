<template>
	<BlanketOverlayWithPopup title="Manage Branches" width="670" v-on:close="closeCancel">
		<template v-slot:main>
			<div class="">
				<ul>
					<li style='font-weight: bold;'>Source QID: Logic</li>
					<li v-for="branch in branches" :key="branch.id" class="clickable" @click="$emit('editbranch', branch)">
						Q{{branch.attachToQID}}: {{branch.showIfRaw}}
					</li>
				</ul>

			</div>
		</template>
		<template v-slot:buttons>
			<button type="button" class="option-button colbtn" @click="$emit('createnew')">New</button>
			<button type="button" class="option-button" @click="closeCancel">Close</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup.vue";

export default {
	name: 'BranchManager',
	components: {BlanketOverlayWithPopup},
	mixins: [globalMixin],
	props: {
		survob: {
			type: Object,
			required: false,
			default: null,
		},
	},
	data() {
		return {
			store: store,


		}
	},
	computed: {
		branches() {
			if(this.survob && this.survob.branches) {
				return this.survob.branches
			}
			return []
		},
	},
	methods: {
		closeCancel() {
			this.$emit('close');
		},
	},
	watch: {

	},
	mounted() {

	},
}
</script>

<style scoped>
li {
	line-height: 1.3;
}
li.clickable {
	cursor: pointer;
}
</style>
