<template>
	<BlanketOverlayWithPopup title="Edit Segment" width="500" v-on:close="closeCancel">
		<template v-slot:main>
			<table>
				<tbody>
				<tr>
					<td><span class="settlbl">Segment ID</span></td>
					<td><input class="saver" type="text" v-model="editSegmentOb.id"></td>
				</tr>
				<tr>
					<td><span class="settlbl">List ID</span></td>
					<td><input class="saver" type="text" v-model="editSegmentOb.listId"></td>
				</tr>
				<tr>
					<td><span class="settlbl">Title</span></td>
					<td><input class="saver" type="text" v-model="editSegmentOb.title"></td>
				</tr>
				<tr>
					<td><span class="settlbl">Filter String</span></td>
					<td><input class="saver" type="text" v-model="editSegmentOb.sqlFilterString"></td>
				</tr>
				<tr>
					<td><span class="settlbl">Deleted</span></td>
					<td>
						<select v-model="editSegmentOb.deleted">
							<option :value="0">No</option>
							<option :value="1">Yes</option>
						</select>
					</td>
				</tr>
				</tbody>
			</table>
		</template>
		<template v-slot:buttons>
			<button type="button" class="option-button colbtn" @click="saveSegInfo">Save Info</button>
			<button type="button" class="option-button" @click="closeCancel">Cancel</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'

export default {
	name: "EditSegmentScreen",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		incomingsegob: {
			type: [Object, null],
			default: null,
		},
		incominglistid: {
			type: [Number, null],
			default: null,
		},
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			editSegmentOb: {
				id: 0,
				listId: null,
				deleted: 0,
				sqlFilterString: '',
				title: '',
			},

		}
	},
	computed: {

	},
	methods: {
		closeCancel() {
			this.$emit('close');
		},
		saveSegInfo() {
			if (this.isFetching === false) {
				this.store.showLoader = true
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/contacts/save-segment-info";
				let sendob = {
					segmentOb: this.editSegmentOb,
				};
				this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
					this.isFetching = false
					this.store.showLoader = false
					this.showKalertSuccess({message: 'Changes saved!'}, true)
					this.$emit('close', true)
				}.bind(this));
			}
		},
		prepareForm() {
			if(this.incomingsegob && this.incomingsegob.id > 0) {
				this.editSegmentOb = JSON.parse(JSON.stringify(this.incomingsegob))
			}
		},
	},
	watch: {

	},
	mounted() {
		if(this.incominglistid && this.incominglistid > 0) {
			this.editSegmentOb.listId = this.incominglistid
		}
		this.prepareForm()
	},
}
</script>

<style scoped>
.saver {
	width: 320px;
}
#survid {
	padding-left: 13px;
}
</style>