<template>
	<BlanketOverlayWithPopup title="Contact Editor" width="800" v-on:close="closeCancel">
		<template v-slot:main>
			<table>
				<tbody>
				<tr>
					<td><span class="settlbl">Contact ID</span></td>
					<td style="padding: 10px 0;">{{shownContactId}}</td>
					<td><span class="settlbl">Opted In</span></td>
					<td>
						<select v-model="editContactOb.optedin">
							<option :value="0">No</option>
							<option :value="1">Yes</option>
						</select>
					</td>
				</tr>
				<tr>
					<td><span class="settlbl">List ID</span></td>
					<td><input type="text" class="saver" v-model="editContactOb.listId"></td>
					<td><span class="settlbl">Double Opted In</span></td>
					<td>
						<select v-model="editContactOb.doubleoptin">
							<option :value="0">No</option>
							<option :value="1">Yes</option>
						</select>
					</td>
				</tr>
				<tr>
					<td><span class="settlbl">First Name</span></td>
					<td><input type="text" class="saver" v-model="editContactOb.fname"></td>
					<td><span class="settlbl">Unsubscribed</span></td>
					<td>
						<select v-model="editContactOb.unsubscribed">
							<option :value="0">No</option>
							<option :value="1">Yes</option>
						</select>
					</td>
				</tr>
				<tr>
					<td><span class="settlbl">Last Name</span></td>
					<td><input type="text" class="saver" v-model="editContactOb.lname"></td>
					<td><span class="settlbl">Bounced Count</span></td>
					<td><input type="text" class="saver" v-model="editContactOb.bouncedCount"></td>
				</tr>
				<tr>
					<td><span class="settlbl">Email</span></td>
					<td><input type="text" class="saver" v-model="editContactOb.email"></td>
					<td><span class="settlbl">Status</span></td>
					<td>
						<select v-model="editContactOb.status">
							<option value=""></option>
							<option value="active">Active</option>
							<option value="inactive">Inactive</option>
							<option value="bounced">Bounced</option>
							<option value="cleaned">Cleaned</option>
							<option value="unsubscribed">Unsubscribed</option>
						</select>
					</td>
				</tr>
				<tr>
					<td><span class="settlbl">Year Born</span></td>
					<td><input type="text" class="saver" v-model="editContactOb.yearBorn"></td>
					<td><span class="settlbl">Deleted</span></td>
					<td>
						<select v-model="editContactOb.deleted">
							<option :value="0">No</option>
							<option :value="1">Yes</option>
						</select>
					</td>
				</tr>
				<tr>
					<td><span class="settlbl">City</span></td>
					<td><input type="text" class="saver" v-model="editContactOb.city"></td>
					<td><span class="settlbl">Token</span></td>
					<td><input type="text" class="saver" v-model="editContactOb.token"></td>
				</tr>
				<tr>
					<td><span class="settlbl">Gender</span></td>
					<td>
						<select v-model="editContactOb.gender">
							<option :value="0"></option>
							<option :value="1">Male</option>
							<option :value="2">Female</option>
						</select>
					</td>
					<td><span class="settlbl">Custom 1</span></td>
					<td><input type="text" class="saver" v-model="editContactOb.custom1"></td>
				</tr>
				<tr>
					<td><span class="settlbl">Home Station</span></td>
					<td><input type="text" class="saver" v-model="editContactOb.homeStation"></td>
					<td><span class="settlbl">Custom 2</span></td>
					<td><input type="text" class="saver" v-model="editContactOb.custom2"></td>
				</tr>
				<tr>
					<td><span class="settlbl">P1 Station</span></td>
					<td><input type="text" class="saver" v-model="editContactOb.p1Station"></td>
					<td><span class="settlbl">Custom 3</span></td>
					<td><input type="text" class="saver" v-model="editContactOb.custom3"></td>
				</tr>
				<tr>
					<td><span class="settlbl">Timezone</span></td>
					<td><input type="text" class="saver" v-model="editContactOb.timezone"></td>
					<td><span class="settlbl">Custom 4</span></td>
					<td><input type="text" class="saver" v-model="editContactOb.custom4"></td>
				</tr>
				<tr>
					<td><span class="settlbl">Segment ID</span></td>
					<td><input type="text" class="saver" v-model="editContactOb.segmentId"></td>
					<td><span class="settlbl">Custom 5</span></td>
					<td><input type="text" class="saver" v-model="editContactOb.custom5"></td>
				</tr>

				</tbody>
			</table>
		</template>
		<template v-slot:buttons>
			<button type="button" class="option-button colbtn" @click="saveContactInfo">Save Info</button>
			<button type="button" class="option-button" @click="closeCancel">Cancel</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'

export default {
	name: "EditContactScreen",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		contactob: {
			type: [Object, null],
			default: null,
		},
		incominglistid: Number,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			editContactOb: {
				id: null,
				listId: null,
				email: '',
				fname: '',
				lname: '',
				segmentId: '',
				yearBorn: '',
				gender: 0,
				city: '',
				homeStation: '',
				p1Station: '',
				timezone: '',
				token: '',
				custom1: '',
				custom2: '',
				custom3: '',
				custom4: '',
				custom5: '',
				optedin: 0,
				doubleoptin: 0,
				unsubscribed: 0,
				bouncedCount: 0,
				deleted: 0,
			},

		}
	},
	computed: {
		shownContactId() {
			if(this.contactob && this.contactob.id) return this.contactob.id
			else return 'NEW'
		},
	},
	methods: {
		closeCancel() {
			this.$emit('close', false);
		},
		saveContactInfo() {
			if (this.isFetching === false && this.editContactOb.email.length > 0) {
				this.store.showLoader = true
				let targ = this.store.urlroot + "central/post-vue/contacts/save-contact-info";
				let sendob = {contactob: this.editContactOb}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.store.showLoader = false
					if(ret.error) {
						this.showKalertError(ret.error)
					}
					else this.$emit('close', true)
				}.bind(this));
			}
		},
	},
	watch: {

	},
	mounted() {
		if(this.contactob) {
			this.editContactOb = JSON.parse(JSON.stringify(this.contactob))
		}
		else {
			this.editContactOb.listId = this.incominglistid
		}
	},
}
</script>

<style scoped>
.saver {
	width: 200px;
}
table tr td:nth-of-type(2) {
	padding-right: 50px;
}
.wl-popup select {
	width: 216px;
}
.mx-datepicker {
	width: 216px;
}
</style>