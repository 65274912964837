<template>
	<div v-if="surveyob && surveyob.questions">
		<DashPerceptualSingle v-for="(q, ind) in surveyob.questions" :key="q.qid"
			:resultsdata="resultsdata" :surveyob="surveyob" :demoid="demoid"
			:weighted="weighted" :question="q" :qindex="ind" :isexporting="isexporting"
			:totalsampleoverridepct="totalsampleoverridepct"
			:hiderawvals="hiderawvals" v-on:filterby="$emit('filterby', q)"
			:trenddata="trenddata" :trendsurvs="trendsurvs" :forcehidetrend="forcehidetrend"
			v-on:gridtable="$emit('gridtable', q)"
			v-on:gettrenddata="$emit('gettrenddata', $event)"
		></DashPerceptualSingle>
	</div>
</template>

<script>
import {globalMixin} from "@/mixins";
import {store} from "@/store";
import DashPerceptualSingle from "@/components/dashboard/DashPerceptualSingle.vue";

export default {
	name: 'DashPerceptuals',
	mixins: [globalMixin],
	props: {
		resultsdata: {
			type: Array,
			default: () => []
		},
		surveyob: {
			type: Object,
			default: () => {}
		},
		demoid: {
			type: [Number, String],
			default: 0
		},
		weighted: {
			type: Boolean,
			default: true
		},
		isexporting: {
			type: Boolean,
			default: false
		},
		totalsampleoverridepct: {
			type: String,
			default: 'answered'
		},
		hiderawvals: {
			type: Boolean,
			default: false
		},
		trenddata: {
			type: Object,
			default: () => {}
		},
		trendsurvs: {
			type: Array,
			default: () => []
		},
		forcehidetrend: {
			type: Boolean,
			default: false
		},
	},
	components: {DashPerceptualSingle},
	data() {
		return {
			store: store,

		}
	},
	computed: {

	},
	methods: {

	},
	watch: {

	},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>

</style>
