<template>
	<div class="blanket">
		<div class="loadholdfixed">
			<div class="loadholder"><p>Loading...please wait...</p></div>
		</div>
	</div>
</template>

<script>
export default {
	name: "LoaderOverlay",
	components: {},
	props: {},
	data: function () {
		return {}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.blanket {
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	overflow-y: auto;
	opacity: 1;
}
.loadholdfixed {
	width: 100%;
	position: fixed;
	height: 100%;
	z-index: -5; /* behind popups - check this is ok */
}
.loadholder {
	background: url('~@/assets/loadingB.gif') no-repeat scroll 50% 50% #FFF;
	height: 200px;
	width: 300px;
	margin: 200px auto;
	border-radius: 10px;
	color: #111;
	z-index: 1002;
}
.loadholder p {
	text-align: center;
	margin: 40px 0 0 0;
	font-size: 14px;
	padding: 35px;
}
</style>