<template>
	<div>
		<div>
			<div v-if="activeEditSurvey" class="">
				<div v-if="!fromcatimgr">
					<span class="wl-paginate-btn" @click="$emit('close', true)"><i class="mdi mdi-chevron-left"></i> Back</span>
					<p style="font-size: 18px; margin-top: 30px;">{{activeEditSurvey.name}} ({{activeEditSurvey.dataloc}})</p>
					<p>Make sure you do a backup first <a href="javascript:void(0)" @click="doAtblBackup">Click here to run</a></p>
					<p v-if="backupUrl"><a :href="backupUrl">Download</a></p>
				</div>

				<p>
					<a v-if="isFetching === false" href="javascript:void(0)" @click="getSurveyValidationChecks">Re-run Validation</a>
				</p>
				<p>
					<a v-if="isFetching === false && store.user.id === 1" href="javascript:void(0)" @click="getSurveyValidationChecks(true)">Re-run Validation & Force Cume Response Updates</a>
				</p>
				<br/>

				<div v-if="results">
					<p class="valp" v-if="results.message">{{results.message}}</p>
					<p class="valp" v-if="results.status" :class="{reddish : results.status.error === 1}">{{results.status.message}}</p>

					<p class="valp" v-if="results.dqvals" :class="{reddish : results.dqvals.error === 1}">{{results.dqvals.message}}</p>
					<p class="valp" v-if="results.numcompletes" :class="{reddish : results.numcompletes.error === 1}">{{results.numcompletes.message}}</p>
					<p class="valp" v-if="results.ncompsEqQ1" :class="{reddish : results.ncompsEqQ1.error === 1}">{{results.ncompsEqQ1.message}}</p>
					<p class="valp" v-if="results.ncompsEqGdr" :class="{reddish : results.ncompsEqGdr.error === 1}">{{results.ncompsEqGdr.message}}</p>
					<p class="valp" v-if="results.ncompsEqAge" :class="{reddish : results.ncompsEqAge.error === 1}">{{results.ncompsEqAge.message}}</p>

					<div v-if="results.qResponseCounts && results.qResponseCounts.length > 0">
						<p class="valp" v-for="(qr, ind) in results.qResponseCounts" :key="ind" :class="{reddish : qr.error === 1}">{{qr.message}}</p>
					</div>

					<div class="failedits" v-if="results.fails && results.fails.length > 0">
						<div class="failedit" v-for="(fail, ind) in results.fails" :key="ind">
							{{fail.message}}
							<div class="cati-edit" @click="catiEditPopupResp(fail.respid)">
								{{getOperatorName(fail.operatorid)}}
								<i class="mdi mdi-pencil-outline"></i>
							</div>
							<div v-if="!fromcatimgr" class="admin-edit">
								<input type="text" :id="'f_'+fail.key" class="finp" :value="fail.mostq" />
								<button type="button" @click="saveVal(fail.respid, fail.qid, 'f_'+fail.key)">Save</button>
							</div>
						</div>
					</div>

					<div class="failedits" v-if="results.failsdqcume && results.failsdqcume.length > 0">
						<div class="failedit" v-for="(fail, ind) in results.failsdqcume" :key="ind">
							{{fail.message}}
							<div class="cati-edit" @click="catiEditPopupResp(fail.respid)">
								{{getOperatorName(fail.operatorid)}}
								<i class="mdi mdi-pencil-outline"></i>
							</div>
							<div v-if="!fromcatimgr" class="admin-edit">
								<input type="text" :id="'fdq_'+fail.key" :value="fail.mostq" class="finp" />
								<button type="button" @click="saveVal(fail.respid, fail.qid, 'fdq_'+fail.key)">Update</button>
								<input type="text" :id="'fdqc_'+fail.cumekey" :value="fail.cumeq" class="finp" />
								<button type="button" @click="saveVal(fail.respid, fail.cumeqid, 'fdqc_'+fail.cumekey)">Update</button>
							</div>
						</div>
					</div>

					<div class="failedits" v-if="results.failsdaypartcume && results.failsdaypartcume.length > 0">
						<div class="failedit" v-for="(fail, ind) in results.failsdaypartcume" :key="ind">
							{{fail.message}}
							<div class="cati-edit" @click="catiEditPopupResp(fail.respid)">
								{{getOperatorName(fail.operatorid)}}
								<i class="mdi mdi-pencil-outline"></i>
							</div>
							<div v-if="!fromcatimgr" class="admin-edit">
								<input type="text" :id="'fdp_'+fail.key" :value="fail.overallcumeq" class="finp" />
								<button type="button" @click="saveVal(fail.respid, fail.qid, 'fdp_'+fail.key)">Update</button>
							</div>
						</div>
					</div>

				</div>
			</div>

		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "ValidatorPage",
	components: {

	},
	props: {
		srvob: Object,
		fromcatimgr: {
			type: Boolean,
			default: false,
		},
		fromdashboard: {
			type: Boolean,
			default: false,
		},

	},
	mixins: [globalMixin],
	data: function() {
		return {
			store: store,
			filterText: '',

			activeEditSurvey: null,
			results: null,
			operators: [],
			backupUrl: null,

			isFetching: false,
		}
	},
	computed: {

	},
	methods: {
		saveVal(respid, qid, key) {
			let val = document.getElementById(key).value
			let targ = this.store.urlroot + "central/post-vue/regional-ratings-admin/update-response-val"
			let sendob = {
				survid: this.activeEditSurvey.dataloc,
				respid: respid,
				qid: qid,
				val: val,
			}
			this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
				this.isFetching = false
				this.store.showLoader = false
				document.getElementById(key).style.display = 'none'
			}.bind(this));
		},
		getOperatorName(id) {
			if(this.operators[id]) return this.operators[id]
			return ''
		},
		getSurveyValidationChecks(forceUpdateCumeForRespondents = false) {
			this.isFetching = true
			let targ = this.store.urlroot + "central/post-vue/regional-ratings-admin/validate-gs"
			let sendob = {
				survid: this.activeEditSurvey.dataloc,
				doUpdateUserCumeAnswers: forceUpdateCumeForRespondents,
			}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				this.store.showLoader = false
				if(ret.results) this.results = ret.results
				if(ret.operators) this.operators = ret.operators
				let items = document.getElementsByClassName('finp')
				for(let it of items) {
					it.style.display = 'inline'
				}
			}.bind(this));
		},
		doAtblBackup() {
			let targ = this.store.urlroot + "central/post-vue/regional-ratings-admin/do-atable-backup"
			let sendob = {survid: this.activeEditSurvey.dataloc}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				this.store.showLoader = false
				if(ret.dlpath) this.backupUrl = ret.dlpath
			}.bind(this));
		},
		catiEditPopupResp(respid) {
			this.$emit('catiedit', respid)
		},
	},
	watch: {
		activeEditSurvey() {
			if(this.activeEditSurvey && this.activeEditSurvey.id > 0) {
				this.getSurveyValidationChecks()
			}
		}
	},
	mounted() {
		this.activeEditSurvey = JSON.parse(JSON.stringify(this.srvob))
		if(this.fromcatimgr === true || this.fromdashboard === true) this.getSurveyValidationChecks()
	},
}
</script>

<style scoped>
.failedit {

}
.failedit input {
	border: 1px solid #888;
}
.reddish {
	color: #A00;
}
.valp {
	font-size: 14px;
	line-height: 1.4;
}
.greenish {
	color: #0A0;
}
.admin-edit {
	display: inline;
	margin-left: 10px;
}
.cati-edit {
	display: inline;
	margin-left: 10px;
	cursor: pointer;
	color: #0f75a5;
}
.cati-edit:hover {
	text-decoration: underline;
}
</style>