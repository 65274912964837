<template>
	<div class="wl-admin-container">

		<StationEditPopup v-if="(activeEditStation || forceNewStation === true) && showEditStationPopup === true"
			:stnobin="activeEditStation" :forcenewstation="forceNewStation" v-on:close="closeEditStationPopup($event)"
		></StationEditPopup>

		<div>
			<div style="margin-bottom: 20px;">
				<input type="text" placeholder="Search by name" v-model="filterText" class="wl-input-text wl-input-padding-large" />
				<button @click="addNewStation" style="margin-left: 15px;" type="button" class="option-button colbtn">Add New Station</button>
			</div>
			<table class="altbl noborderv">
				<thead>
				<tr class='headrow'>
					<td class="headingtext">ID</td>
					<td class="headingtext">Station Name</td>
					<td class="headingtext">Market</td>
					<td class="headingtext ctr">Commty</td>
					<td class="headingtext ctr">Other</td>
					<td class="headingtext ctr">Commercial</td>
					<td class="headingtext ctr">OutsideMkt</td>
					<td class="headingtext ctr">Band</td>
					<td class="headingtext ctr">NetOwn</td>
					<td class="headingtext ctr">SubOwn</td>
					<td class="headingtext ctr">RepNet</td>
					<td class="headingtext ctr">RepSub</td>
				</tr>
				</thead>
				<tbody>
				<tr v-for="stn in filteredStations" :key="stn.stid" @click="editStation(stn)">
					<td>{{stn.stid}}</td>
					<td>{{stn.name}}</td>
					<td>{{getMarketNameFromId(stn.mktid)}}</td>
					<td class="ctr">{{intToYes(stn.community)}}</td>
					<td class="ctr">{{intToYes(stn.isOther)}}</td>
					<td class="ctr">{{intToYes(stn.isCommercial)}}</td>
					<td class="ctr">{{intToYes(stn.outsidemkt)}}</td>
					<td class="ctr">{{stn.band.toUpperCase()}}</td>
					<td class="ctr">{{stn.netgroupown}}</td>
					<td class="ctr">{{stn.subnetown}}</td>
					<td class="ctr">{{stn.repnetgroup}}</td>
					<td class="ctr">{{stn.repsubnet}}</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import StationEditPopup from "@/components/regional-ratings-admin/StationEditPopup.vue";
export default {
	name: "StationsPage",
	components: {
		StationEditPopup
	},
	props: {},
	mixins: [globalMixin],
	data: function() {
		return {
			store: store,
			filterText: '',

			activeEditStation: null,
			showEditStationPopup: false,
			forceNewStation: false,
		}
	},
	computed: {
		filteredStations() {
			if(this.filterText.length > 0) { //either already selected or matching text
				return this.store.rrStations.filter(
					item =>
						item.name.toLowerCase().includes(this.filterText.toLowerCase())
						|| item.marketName.toLowerCase().includes(this.filterText.toLowerCase())
				)
			}
			return this.store.rrStations
		},
	},
	methods: {
		getMarketNameFromId(mktid) {
			let m = this.store.rrMarkets.find(item => item.id === mktid)
			if(m) return m.name
			return mktid
		},
		addNewStation() {
			this.forceNewStation = true
			this.activeEditStation = null
			this.showEditStationPopup = true
		},
		editStation(stnob) {
			this.forceNewStation = false
			this.activeEditStation = stnob
			this.showEditStationPopup = true
		},
		closeEditStationPopup(doRefresh) {
			this.showEditStationPopup = false
			if(doRefresh === true) this.getRRAdminInfo()
		},
	},
	mounted() {
		this.getRRAdminInfo()
	},
}
</script>

<style scoped>

</style>