<template>
	<div class="wl-admin-container">

		<MobileLicenseEditPopup v-if="activeEditLic && showEditLicPopup === true"
			:licobin="activeEditLic" v-on:close="closeEditLicPopup($event)"
		></MobileLicenseEditPopup>

		<div>
			<div style="margin-bottom: 20px;">
				<input type="text" placeholder="Search by name" v-model="filterText" class="wl-input-text wl-input-padding-large" />
			</div>
			<table class="altbl noborderv">
				<thead>
				<tr class='headrow'>
					<td class="headingtext">ID</td>
					<td class="headingtext">License Name</td>
				</tr>
				</thead>
				<tbody>
				<tr v-for="lic in filteredLicenses" :key="lic.id" @click="editLicense(lic)">
					<td>{{lic.id}}</td>
					<td>{{lic.name}}</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import MobileLicenseEditPopup from "@/components/regional-ratings-admin/MobileLicenseEditPopup.vue";
export default {
	name: "MobileLicencesPage",
	components: {
		MobileLicenseEditPopup

	},
	props: {},
	mixins: [globalMixin],
	data: function() {
		return {
			store: store,
			filterText: '',

			activeEditLic: null,
			showEditLicPopup: false,
		}
	},
	computed: {
		filteredLicenses() {
			if(this.filterText.length > 0) { //either already selected or matching text
				return this.store.rrMobileLicenses.filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()))
			}
			return this.store.rrMobileLicenses
		},
	},
	methods: {
		editLicense(licob) {
			this.activeEditLic = licob
			this.showEditLicPopup = true
		},
		closeEditLicPopup(licob) {
			this.showEditLicPopup = false
			if(licob && licob !== false) {
				let ind = this.store.rrMobileLicenses.findIndex(item => item.id === licob.id)
				if(ind > -1) this.store.rrMobileLicenses[ind] = licob
			}
		},
	},
	mounted() {
		this.getRRAdminInfo()
	},
}
</script>

<style scoped>

</style>