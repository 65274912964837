<template>
	<div class="bgpage" style="min-height: 100vh;">
		<div id="app-container">

			<div style="padding-top: 50px;">

				<div id="logo-holder">
					<a href="https://app.yourwavelength.com">
						<img src="@/assets/wavelength_logo.png" alt="Logo" />
					</a>
				</div>

			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: 'LogoutPage',
	components: {

	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
		}
	},
	computed: {

	},
	methods: {

	},
	watch: {

	},
	props: {

	},
	mounted() {
		document.title = "Logging out...";
	},
	created() {
		this.clearLoginCredsAndSession();
		setTimeout(function() {
			this.$router.push('/?loggedout=1');
		}.bind(this), 500);
	},
}
</script>

<style scoped>
.bgpage {
	background-image: url('~@/assets/feature-background.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
#app-container {
	width: 100%;
	max-width: 500px;
	margin: 0 auto;
}
#logo-holder {
	text-align: center;
	background-color: #0f75a5;
	padding: 30px 0;
}
#logo-holder img {
	height: 60px;
}
#form-wrapper {
	padding: 60px;
	margin-bottom: 80px;
	box-shadow: 10px 0 40px 6px rgba(0,0,0,.08);
	background: #FFFFFF;
	text-align: left;
}
#form-wrapper input {
	border: 1px solid #BBB;
	width: calc(100% - 20px);
	display: block;
	padding: 10px;
	font-size: 18px;
}

</style>
