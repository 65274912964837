<template>
	<div>
		<select class="selector" v-model="chosenQuestionId">
			<option v-for="q in lister" :key="q.qid" :value="q.qid">
				{{q.qid}}: {{q.qtext_stripped}}
			</option>
		</select>
		<button type="button" class="option-button colbtn" @click="saveSelection">Save</button>
		<button type="button" class="option-button" @click="cancelSelection">Cancel</button>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: 'QuestionSelector',
	mixins: [globalMixin],
	props: {
		lookuptype: {
			type: String,
			required: false,
			default: 'all',
		},
		survob: {
			type: Object,
			required: false,
			default: null,
		},
		currentqid: {
			type: Number,
			required: false,
			default: null,
		},
		currentchoiceqid: {
			type: Number,
			required: false,
			default: 0,
		},
	},
	data() {
		return {
			store: store,

			chosenQuestionId: 0,
		}
	},
	computed: {
		lister() {
			let ret = [{qid: 0, qtext_stripped: 'None'}]
			if(this.lookuptype === 'all') {
				ret = this.survob.questions
			}
			else if(this.lookuptype === 'parentOptions') { //must be mandatory checkbox, not current question
				for(let q of this.survob.questions) {
					if(q.qid !== this.currentqid && q.type === 'Checkbox' && q.mandatory === 'Yes') {
						ret.push(q)
					}
				}
			}
			return ret
		},
	},
	methods: {
		saveSelection() {
			this.$emit('save', this.chosenQuestionId)
		},
		cancelSelection() {
			this.$emit('cancel')
		},
	},
	watch: {

	},
	mounted() {
		this.chosenQuestionId = this.currentchoiceqid
	},
}
</script>

<style scoped>
.selector {
	display: block;
	width: 90%;
	margin: 10px 0;
}
</style>
