<template>
	<div>
		<BlanketOverlayWithPopup v-if="editOb && editOb.id >= 0 && hasPopulated === true" title="Edit Analysis" width="1000" :hastabs="false" v-on:close="closeCancel">
			<template v-slot:main>
				<div class="contained-popup-content">
					<span class="label">Analysis ID</span><input class="usaver" v-model="editOb.id" readonly type="text" /><br/>
					<span class="label">Analysis Name</span><input class="usaver" v-model="editOb.title" type="text" /><br/>
					<span class="label">Data Location</span><input class="usaver" v-model="editOb.survid" type="text" /><br/>
					<span class="label">Enabled</span>
					<select class="usaver" v-model="editOb.enabled">
						<option :value="false">No</option>
						<option :value="true">Yes</option>
					</select><br/>
					<span class="label">Default For Survey</span>
					<select class="usaver" v-model="editOb.defaultforsurvey">
						<option :value="true">Yes</option>
						<option :value="false">No</option>
					</select><br/>
					<span class="label">Deleted</span>
					<select class="usaver" v-model="editOb.deleted">
						<option :value="false">No</option>
						<option :value="true">Yes</option>
					</select><br/>
					<span class="label">Config File</span><input class="usaver" v-model="editOb.configfile" type="text" /><br/>
					<br/>
					<div id="listSelectorAn" class="search-parent">
						<div>
							<div class="searchhold">
								Search <input type="text" v-model="filterText" />
							</div>
						</div>
						<div>
							<div class="available-list">
								<div id="availselect-ajax-an">
									<div v-for="dg in filteredUnusedDemogroups" :key="dg.id" class="analyitem">
										{{dg.title}}
										<span class="listadder" @click="addDemogroup(dg)">Add</span>
										<span class="listediter" @click="editDemoGroup(dg)">Edit</span>
									</div>
								</div>
							</div>
							<div class="active-list">
								<div id="activeselect-ajax-an">
									<draggable v-model="editOb.demogroupObs" item-key="demogroup">
										<template #item="{element, index}">
											<div class="analyitem" :title="index">
												{{element.title}}
												<span class="listadder" @click="removeDemogroup(element)">Del</span>
												<span class="listediter" @click="editDemoGroup(element)">Edit</span>
											</div>
										</template>
									</draggable>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:buttons>
				<button type="button" class="option-button colbtn" @click="saveInfo">Save Info</button>
				<button type="button" class="option-button" @click="closeCancel">Cancel</button>
			</template>
		</BlanketOverlayWithPopup>

		<DemogroupEditPopup v-if="showEditDemogroupPopup"
			:dgob="activeDemogroup" :demos="demos"
			@close="closeEditDemogroupPopup($event)"
		></DemogroupEditPopup>

	</div>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'
import draggable from "vuedraggable";
import DemogroupEditPopup from "@/components/analysis-admin/DemogroupEditPopup.vue";
export default {
	name: "AnalysisEditPopup",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
		draggable,
		DemogroupEditPopup,
	},
	props: {

	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			hasPopulated: false,
			editOb: {
				id: 0,
				title: '',
				survid: 0,
				enabled: false,
				defaultforsurvey: true,
				deleted: false,
				configfile: '',
				demogrouporder: [], //just for initial retrieval from db, not for editing
				demogroupObs: [],
			},

			filterText: '',
			demogroups: [],

			activeDemogroup: null,
			showEditDemogroupPopup: false,
			demos: [],
		}
	},
	computed: {
		unusedDemogroups() {
			return this.demogroups.filter(item => !this.editOb.demogroupObs.includes(item))
		},
		filteredUnusedDemogroups() {
			if(this.filterText.length > 0) { //either already selected or matching text
				return this.unusedDemogroups.filter(item => item.title.toLowerCase().includes(this.filterText.toLowerCase()))
			}
			return this.unusedDemogroups
		},
	},
	methods: {
		populateContent() {
			if (this.store.activeAnalysis) {
				this.editOb = {}
				for (let pr in this.store.activeAnalysis) {
					this.editOb[pr] = this.store.activeAnalysis[pr];
				}
				this.getDemogroupsForAnalysis()
				this.getDemosForSurvid(this.editOb.survid)
			}
			else { //will be a new one
				this.hasPopulated = true
			}
		},
		getDemogroupsForAnalysis() {
			this.demogroups = []
			if(this.editOb && this.editOb.survid > 0) {
				let targ = this.store.urlroot + "central/post-vue/analysis/get-demogroups-admin"
				let sendob = {
					survid: this.editOb.survid,
				};
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.demogroups = ret.demogroups
					let arr = this.demogroups.filter(item => this.editOb.demogrouporder.includes(item.id))
					let arrOrdered = []
					for(let dgid of this.editOb.demogrouporder) {
						let found = arr.find(item => item.id === dgid)
						if(found) arrOrdered.push(found)
					}
					this.editOb.demogroupObs = arrOrdered
					this.hasPopulated = true
				}.bind(this));
			}
		},
		closeCancel() {
			this.$emit('close', false);
		},
		saveInfo() {
			if (this.isFetching === false) {
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/analysis/save-analysis"
				let dgorder = []
				for(let dg of this.editOb.demogroupObs) {
					dgorder.push(dg.id)
				}
				this.editOb.demogrouporder = dgorder
				let sendob = {
					analysis: this.editOb,
				};
				this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
					this.isFetching = false
					this.$emit('close', true)
				}.bind(this));
			}
		},
		addDemogroup(dgob) {
			this.editOb.demogroupObs.push(dgob)
		},
		removeDemogroup(dgob) {
			let ind = this.editOb.demogroupObs.findIndex(item => item.id === dgob.id)
			if(ind > -1) this.editOb.demogroupObs.splice(ind, 1)
		},
		editDemoGroup(dgob) {
			this.activeDemogroup = dgob
			this.showEditDemogroupPopup = true
		},
		getDemosForSurvid(survid) {
			let targ = this.store.urlroot + "central/post-vue/analysis/get-demos-admin"
			let sendob = {
				survid: survid
			}
			return this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.demos = ret.demos
			}.bind(this))
		},
		closeEditDemogroupPopup() {
			this.showEditDemogroupPopup = false
			this.activeDemogroup = null
		},
	},
	watch: {
		'store.activeAnalysis'() {
			this.populateContent()
		},
	},
	mounted() {
		this.populateContent()
	},
}
</script>
<style scoped>
#listSelector .available-list, #listSelector .active-list,
#listSelectorAn .available-list, #listSelectorAn .active-list {
	width: 47%;
	border: 1px solid #CCC;
	display: inline-block;
	height: 400px;
	padding: 1%;
	overflow-y: auto;
}
#listSelector .available-list, #listSelectorAn .available-list {
	margin-right: 1%;
	float: left;
}
#listSelector .analyitem, #listSelectorAn .analyitem {
	padding: 7px 8px 6px;
	width: 93%;
	background: #EEE;
	margin: 2px 0;
}
#listSelector .active-list .analyitem, #listSelectorAn .active-list .analyitem {
	cursor: move;
}
#listSelector .analyitem .listadder, #listSelectorAn .analyitem .listadder,
#listSelectorAn .analyitem .listediter {
	cursor: pointer;
	color: #00BC6C;
	float: right;
	margin: 0 5px;
}
#listSelector .active-list .analyitem .listadder,
#listSelectorAn .active-list .analyitem .listadder {
	color: #F00;
}
.label {
	width: 185px;
	display: inline-block;
}
.usaver {
	margin-bottom: 3px;
	width: 400px;
}
select.usaver {
	width: 416px;
}
</style>