<template>
	<div v-if="store.sessionOk">
		<div id="container" :class="{pageLeftMenuTransposed : store.leftmenuhidden}">
			<LeftMenuWL></LeftMenuWL>
			<div id="bg">
				<ScreenerQuotaEditPopup :obin="activeScreenerLine" v-if="activeScreenerLine" :screenerid="activeScreener.id" @close="closePopup($event)"></ScreenerQuotaEditPopup>
				<div id="maincontent" class="noTopMenuBar">
					<div class="wl-admin-container">
						<p class="wl-main-headline" style="margin-bottom: 10px;">Edit Screener</p>
						<div>
							<select v-model="selectedScreenerId" class="wl-input-select">
								<option :value="null"></option>
								<option v-for="screener in screeners" :key="screener.id" :value="screener.id">{{screener.name}}</option>
							</select>
							<br/><br/><br/>
						</div>
						<div v-if="activeScreener">
							<p class="boldme">{{activeScreener.id}}: {{activeScreener.name}}</p>
							<br/>
							<p class="lbl">Notes</p>
							<textarea v-model="activeScreener.notes" class="mainTextareas"></textarea>
							<p class="lbl">Panel Test SQL (if this only applies to a portion of the sample, list the SQL chunk here)</p>
							<textarea v-model="activeScreener.paneltest" class="mainTextareas"></textarea>
							<br/>
							<p class="boldme">Quotas</p>
							<br/>
							<table class="altbl noborderv">
								<thead>
									<tr>
										<td class="headingtext" data-f-bold="true"><span>ID</span></td>
										<td class="headingtext" data-f-bold="true"><span>Public Title</span></td>
										<td class="headingtext" data-f-bold="true"><span>SQL String</span></td>
										<td class="headingtext" data-f-bold="true"><span>Always DQ if True</span></td>
										<td class="headingtext" data-f-bold="true"><span>Quota Cap</span></td>
										<td class="headingtext" data-f-bold="true"><span>Target</span></td>
										<td class="headingtext" data-f-bold="true"><span>Notes</span></td>
									</tr>
								</thead>
								<tbody>
									<tr v-for="rowitem in activeScreener.quotas" :key="rowitem.id" @click="editScreenLine(rowitem)" style="cursor: pointer;">
										<td>{{rowitem.id}}</td>
										<td>{{rowitem.name}}</td>
										<td>{{rowitem.sqlfilterRaw}}</td>
										<td>{{rowitem.alwaysDQ}}</td>
										<td class="ctr">{{rowitem.quotacap}}</td>
										<td class="ctr">{{rowitem.target}}</td>
										<td class="ctr">{{rowitem.notes}}</td>
									</tr>
								</tbody>
							</table>
							<br/>
							<span class="text-link" style="text-align: left; width: auto;" @click="newQuota">Add New Quota</span>
							<br/><br/>
							<span class="option-button colbtn" @click="saveScreener">Save Screener</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import LeftMenuWL from "@/components/LeftMenuWL";
import {globalMixin} from "@/mixins";
import ScreenerQuotaEditPopup from "@/components/home/ScreenerQuotaEditPopup.vue";

export default {
	name: "ManageScreenersPage",
	mixins: [globalMixin],
	components: {
		ScreenerQuotaEditPopup,
		LeftMenuWL
	},
	props: {},
	data: function () {
		return {
			store: store,

			selectedScreenerId: null,
			screeners: [],
			isFetching: false,
			activeScreenerLine: null,
		}
	},
	computed: {
		activeScreener() {
			if(this.selectedScreenerId === null) return null
			return this.screeners.find(screener => screener.id === this.selectedScreenerId)
		},
	},
	methods: {
		newQuota() {
			this.activeScreenerLine = {
				id: 0,
				name: '',
				sqlfilterRaw: '',
				alwaysDQ: 0,
				quotacap: 0,
				target: 0,
				notes: '',
				deleted: 0,
			}
		},
		getScreeners() {
			let targ = this.store.urlroot + "central/post-vue/screeners/get-screeners"
			let sendob = {}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				if(ret.screeners) this.screeners = ret.screeners
				this.store.showLoader = false
			}.bind(this));
		},
		saveScreener() {
			if(this.isFetching === false && this.activeScreener !== null) {
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/screeners/save-screener"
				let sendob = {
					screenerId: this.activeScreener.id,
					notes: this.activeScreener.notes,
					paneltest: this.activeScreener.paneltest,
				}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					this.store.showLoader = false
					if(ret.screener) {
						let scrind = this.screeners.findIndex(item => item.id === ret.screener.id)
						if(scrind !== -1) {
							this.screeners[scrind] = ret.screener
						}
					}
				}.bind(this));
			}
		},
		editScreenLine(rowitem) {
			this.activeScreenerLine = rowitem
		},
		closePopup(scr) {
			if(scr !== null) {
				let scrind = this.screeners.findIndex(item => item.id === scr.id)
				if(scrind !== -1) this.screeners[scrind] = scr
			}
			this.activeScreenerLine = null
		},
	},
	watch: {
		'store.sessionOk'() {
			if(store.sessionOk && store.user.superUser !== 1) {
				this.$router.push('/error/no-access');
			}
		},
	},
	mounted() {
		if(store.sessionOk && store.user.superUser !== 1) {
			this.$router.push('/error/no-access');
		}
		this.getScreeners()
	},
	created() {
		document.title = "Manage Screeners";
		this.store.activeLmenuLink = null;
	}
}
</script>

<style scoped>
.mainTextareas {
	width: 600px;
	border: none;
	background: #f1f2f6;
	font-family: Roboto;
	padding: 10px;
	margin-bottom: 20px;
}
.lbl {
	margin-bottom: 10px;
}
</style>