<template>
	<div v-if="store.sessionOk">
		<div id="container" :class="{pageLeftMenuTransposed : store.leftmenuhidden}">
			<LeftMenuWL></LeftMenuWL>
			<div id="bg">
				<div id="maincontent" class="noTopMenuBar">
					<HookEditorPopup v-if="hookEditorViewShown" :hookobin="activeHookOb" :artists="artists" v-on:close="closeHookEditor($event)" :allowupload="true"></HookEditorPopup>
					<div class="wl-admin-container">
						<p class="wl-main-headline" style="margin-bottom: 0; margin-top: 0;">
							Your custom hooks
							<button @click="newHook" style="margin-left: 20px;" type="button" class="option-button colbtn"><i class="mdi mdi-plus"></i> Upload New Hook</button>
						</p>
						<div v-if="customHooks.length === 0">
							<p  class="secspan">You do not have any custom hooks yet.</p>
						</div>
						<div v-else>
							<div class="" style="margin: 5px 0;">
								<input type="text" style="width: 300px;" class="wl-input-text wl-input-padding-large" v-model="filterText" placeholder="Search hooks..." />
							</div>
							<table class="altbl noborderv">
								<thead>
								<tr>
									<td v-for="col in cols" :key="col.title" class="headingtext" @click="sortColumn(col.fldid)" data-f-bold="true">
										<span>{{col.title}}</span>
									</td>
								</tr>
								</thead>
								<tbody>
								<tr v-for="rowitem in rowsForPage" :key="rowitem.id">
									<td>{{rowitem.artist}}</td>
									<td>{{rowitem.title}}</td>
									<td>{{rowitem.year}}</td>
									<td>
										<span class="action-icon" title="Delete hook" @click.stop="deleteCustomHook(rowitem)"><i class="mdi mdi-trash-can-outline"></i></span>
									</td>
								</tr>
								</tbody>
							</table>
							<div style="margin: 15px 0; display: flex; align-items: center;">
								<ALTablePaginator
									:paginationpage="paginationPage"
									:paginatedrowscount="paginatedRows.length"
									v-on:nextpage="nextpage" v-on:prevpage="prevpage"
									v-on:setpage="paginationPage = $event"
								></ALTablePaginator>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import LeftMenuWL from "@/components/LeftMenuWL";
import {globalMixin} from "@/mixins";
import ALTablePaginator from "@/components/ALTablePaginator.vue";
import HookEditorPopup from "@/components/hooks-admin/HookEditorPopup.vue";

export default {
	name: "CustomHooksPage",
	mixins: [globalMixin],
	components: {
		HookEditorPopup,
		ALTablePaginator,
		LeftMenuWL
	},
	props: {},
	data: function () {
		return {
			store: store,
			// table stuff
			filterText: '',
			sortColName: null,
			sortColOrder: 'desc',
			paginationRowsPerPage: 20,
			paginationPage: 1,
			cols: [
				{title: 'Artist', sortable: true, fldid: 'artist'},
				{title: 'Title', sortable: true, fldid: 'title'},
				{title: 'Year', sortable: true, fldid: 'year'},
				{title: 'Functions', sortable: false, fldid: ''},
			],
			//end table stuff
			customHooks: [],
			artists: [],
			hookEditorViewShown: false,
			activeHookOb: {
				id: 0,
				artist: '',
				title: '',
				year: '',
			},
		}
	},
	computed: {
		//table stuff
		rows() {
			return this.tblPrepareFilteredRows(this.customHooks, this.filterText, this.sortColName, this.sortColOrder)
		},
		paginatedRows() {
			return this.tblGetPaginatedRows(this.rows, this.paginationRowsPerPage)
		},
		rowsForPage() {
			if(!this.paginationRowsPerPage || !this.paginationPage) return [this.rows]
			return this.paginatedRows[this.paginationPage-1]
		},
		//end table stuff
	},
	methods: {
		//table stuff
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
		prevpage() {
			if(this.paginationPage > 1) this.paginationPage--
		},
		nextpage() {
			if(this.paginationPage < this.paginatedRows.length) this.paginationPage++
		},
		//end table stuff
		newHook() {
			this.activeHookOb = {
				id: 0,
				artist: '',
				title: '',
				year: '',
			}
			this.hookEditorViewShown = true
		},
		closeHookEditor() {
			this.hookEditorViewShown = false
			this.getInitialData()
		},
		deleteCustomHook(rowitem) {
			let c = confirm("Are you sure you want to delete this hook?")
			if(c === true) {
				let targ = this.store.urlroot + "central/post-vue/hooks/delete-custom-hook"
				let sendob = {hookid: rowitem.hookid}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					if(ret.deleteHookId) {
						let listIndex = this.customHooks.findIndex(x => x.hookid === ret.deleteHookId)
						this.customHooks.splice(listIndex, 1)
					}
				}.bind(this));
			}
		},
		handleClose(usrob) {
			this.editUserPopupShown = false
			if(usrob && usrob.id) {
				let idx = this.userlist.findIndex(x => x.id === usrob.id)
				if(idx > -1) this.userlist[idx] = usrob
				else this.userlist.push(usrob)
			}
		},
		getInitialData() {
			let targ = this.store.urlroot + "central/post-vue/hooks/custom-hook-prep"
			let sendob = {};
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				if(ret.hooks) this.customHooks = ret.hooks
				if(ret.artists) this.artists = ret.artists
				this.store.showLoader = false
			}.bind(this));
		},
	},
	watch: {
		'store.sessionOk'() {
			if(store.sessionOk && store.user.superUser !== 1 && store.user.perms.hookcentral !== 1) {
				this.$router.push('/error/no-access');
			}
		},
		rows() { //whenever filter text or sort is changed, go back to Page 1 in pagination
			this.paginationPage = 1
		},
	},
	mounted() {
		if(store.sessionOk && store.user.superUser !== 1 && store.user.perms.hookcentral !== 1) {
			this.$router.push('/error/no-access');
		}
		this.getInitialData()
	},
	created() {
		document.title = "Custom Hooks";
		this.store.activeLmenuLink = 'customhooks';
	}
}
</script>

<style scoped>

</style>