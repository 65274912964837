<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata && chartdata.length > 0 && chartoptions.labels.length > 0" width="99%" height="100%" type="pie"
			:options="chartoptions" :series="chartdata"></apexchart>
		<ChartLoaderInsert v-if="fetching"></ChartLoaderInsert>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
export default {
	name: "ApexPie",
	components: {
		ChartLoaderInsert
	},
	props: {
		fetching: Boolean,
		chartdata: Array,
		labels: Array,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			chartoptions: {
				chart: {
					type: 'pie',
					animations: {
						enabled: false
					},
					toolbar: {
						show: true
					},
				},
				dataLabels: {
					enabled: true,
					distributed: true,
					formatter: function(val, opts) {
						const name = opts.w.globals.labels[opts.seriesIndex]
						return [name, val.toFixed(1) + '%']
					}
				},
				colors: ['#0f75a5','#0b1b3f','#33B6FE'],
				labels: [],
				legend: {
					position: 'bottom',
					show: true,
				},
			}
		}
	},
	computed: {

	},
	methods: {
		updateLabels() { //need to do this manually as data is nested
			this.chartoptions = {...this.chartoptions, ...{
				labels: this.labels,
			}};
		},
	},
	watch: {
		labels() {
			this.updateLabels();
		},
	},
	mounted() {
		this.updateLabels();
	}
}
</script>

<style scoped>

</style>