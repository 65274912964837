<template>

	<div @click="catchMouseClickAnywhere">
		<LoaderOverlay v-if="store.showLoader"></LoaderOverlay>
		<AlertTop v-if="store.kalert.shown"></AlertTop>
		<router-view></router-view>
	</div>

</template>

<script>
import axios from "axios";
import {store} from '@/store.js'
import AlertTop from "@/components/AlertTop";
import LoaderOverlay from "@/components/LoaderOverlay";
import {globalMixin} from "@/mixins";

export default {
	name: 'App',
	components: {
		LoaderOverlay,
		AlertTop
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
		}
	},
	methods: {
		checkSessionOk() {
			let self = this;
			axios.post(
				self.store.urlroot + "central/post-vue/sesscheck",
				JSON.stringify({
					uid: this.store.user.id,
					wlsesstoken: this.store.wlsesstoken,
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if(ret.sessionok !== true) { //transfer to login screen
					window.localStorage.removeItem('wlSessToken');
					window.localStorage.removeItem('wlUserId');
					if(self.$router.currentRoute.value.path !== '/') {
						if(window.location.pathname.length > 2 && window.location.pathname !== "/" && !window.location.pathname.includes('logout')) {
							self.$router.push("/?ref="+encodeURIComponent(window.location.pathname));
						}
						else self.$router.push("/"); //to login page
					}
				}
				else { //we are OK
					self.store.user = ret.user;
					self.store.sessionOk = true;
					if(self.$router.currentRoute.value.path === '/') { //if we are on the login page, go to home
						self.$router.push("/home"); //to home landing page
					}
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		catchMouseClickAnywhere() {
			if(this.store.mouseClickAnywhere === false) {
				this.store.mouseClickAnywhere = true
				setTimeout(function () {
					this.store.mouseClickAnywhere = false
				}.bind(this), 50)
			}
		},
	},
	mounted() {
		if(this.store.wlsesstoken.length === 0 && window.localStorage.getItem('wlSessToken')) {
			this.store.wlsesstoken = window.localStorage.getItem('wlSessToken');
		}
		if(this.store.user.id === 0 && window.localStorage.getItem('wlUserId')) {
			this.store.user.id = parseInt(window.localStorage.getItem('wlUserId'));
		}
		if(this.store.user.id > 0 && this.store.wlsesstoken.length > 0) {
			this.checkSessionOk();
		}
		else if(window.location.pathname !== '/') {
			if(window.location.pathname.length > 2 && window.location.pathname !== "/" && !window.location.pathname.includes('logout')) {
				this.$router.push("/?ref="+encodeURIComponent(window.location.pathname));
			}
			else {
				this.$router.push("/"); //to login page
			}
		}
	},
}
</script>

<style>
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
	-webkit-padding-end: 0;
	-webkit-margin-after: 0;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
body {
	font-family: 'Roboto', Arial, sans-serif;
	margin: 0;
	text-align: center;
	height: 100%;
	font-size: 13px;
	color: #444;
}
a {
	cursor: pointer;
	outline: 0;
}
input {
	font-family: inherit;
	border: none;
	padding: 5px 8px;
}
button {
	outline: 0;
	font-family: inherit;
}
.form-input-group {

}
.form-input-fail {
	color: #F00;
	font-size: 12px;
}
/** end working/loading buttons */
.button-is-working {
	cursor: not-allowed !important;
	color: transparent !important;
}
@keyframes xui-loader-animation {
	0%,80%,100% {transform:scale(0.5) rotate(0deg)}
	40%{transform:scale(1) rotate(0.02deg)}
}
/** end working/loading buttons */
#container {
	height: 100%;
	margin: 0 auto;
	text-align: left;
	position: relative;
	padding: 0;
	background-color: transparent;
}
#bg {
	min-height: 100%;
	margin-left: 200px;
	position: relative;
	background: #FFFFFF;
	z-index: 990;
}
#leftmenu, #bg {
	transition: all 0.5s;
}
.pageLeftMenuTransposed #bg {
	margin-left: 60px;
}
#maincontent {
	display: block;
	height: 100%;
	padding: 70px 0 0 0;
	background-color: #FFFFFF;
	position: relative;
}
#maincontent.noTopMenuBar {
	padding-top: 0;
}
/** tabs **/
.toptabdiv {
	display: block;
	font-weight:bold;
	font-family: 'Roboto Slab', 'Roboto', 'Arial';
	border: none;
	font-size: 18px;
	padding: 25px 10px 5px 25px;
	color: #0C0D18;
	background-color: #F1F2F6;
	border-radius: 10px 10px 0 0;
}
.tabdiv {
	padding: 10px 0 0 60px;
	display: block;
	overflow: hidden;
	margin: 0;
	border-bottom: none;
	background-color: #d2d5da;
}
.tab {
	display: block;
	float: left;
	width: 147px;
	text-align: center;
	padding: 14px 4px 13px;
	text-decoration: none;
	color: #222;
	font-family: Roboto,'Arial';
	font-weight:bold;
	font-size: 11px;
	border-radius: 5px 5px 0 0;
	margin-right: 5px;
	text-transform: uppercase;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}
.active {
	background-color: #FFF;
	border: none;
}
.inactive {
	background-color: transparent;
	color: #222;
	border: none;
}
.tab:not(.active):hover {
	background-color: #23A6EE;
	color: #FFF;
}
/** end tabs **/
/** 2019 updated components **/
.wl-admin-container {
	margin: 0;
	padding: 30px 50px;
	background: #FFF;
}
.wl-main-headline {
	display: block;
	font-size: 20px;
	font-weight: bold;
	padding: 0 0 10px;
	color: #0a1940;
	font-family: "Roboto Slab";
	margin: 20px 0;
}
.wl-sub-headline {
	padding: 0 0 10px;
	font-size: 14px;
	margin: 10px 0;
}
.wl-paginate-btn {
	border: 1px solid #CCC;
	font-size: 12px;
	padding: 9px 13px;
	display: inline-block;
	color: #888;
	transition: all 0.3s ease-in-out;
	border-radius: 5px;
	margin-right: 20px;
	text-decoration: none;
	cursor: pointer;
}
.wl-paginate-btn:hover {
	background: #EEE;
}
.wl-btn-border-grey {
	border: 1px solid #CCC;
}
.wl-icon-btn {
	background: #FFF;
	color: #666;
	padding: 13px 20px;
	transition: all 0.3s ease-in-out;
	display: inline-block;
	border-radius: 5px;
	text-decoration: none;
	position: relative;
}
.wl-icon-btn:hover {
	background: #EEE;
	cursor: pointer;
}
.wl-icon-btn:after {
	content: "";
	background: rgba(140,140,140,0.5);
	display: block;
	position: absolute;
	padding-top: 60%;
	padding-left: 100%;
	opacity: 0;
	transition: all 0.3s;
	top: 0;
	left: 0;
}
.wl-icon-btn:active:after {
	padding-top: 0;
	margin: 0;
	opacity: 1;
	transition: 0s;
}
.wl-text-left-of-fa {
	padding-left: 7px;
}
.boldme, .bold {
	font-weight: bold;
}
.wl-ul {
	line-height: 1.3;
	list-style-type: disc;
}
.wl-p {
	font-family: 'Roboto';
	line-height: 1.2;
	font-size: 14px;
}
.wl-input-text {
	border: none;
	padding: 5px;
	background: #f1f2f6;
}
.wl-input-select {
	margin: 5px 30px 5px 0;
	font-size: 15px;
	padding: 10px 5px;
	border: none;
	background: #f1f2f6;
}
.wl-input-padding-large {
	padding: 12px;
}
.wl-popup textarea {
	border: none;
	padding: 5px;
	background: #f1f2f6;
}
.wl-text-align-left {
	text-align: left;
}
.wl-bg-white {
	background: #FFF;
}
.wl-popup input[type=text],	.wl-popup input[type=email],
.wl-popup input[type=number] {
	background-color: #f1f2f6;
	padding: 7px 8px;
}
.wl-popup select {
	background-color: #f1f2f6;
	padding: 7px 8px;
	border: none;
}
.wl-popup td {
	padding: 2px 10px 2px 0;
}
.wl-popup td span {
	display: flex;
	align-items: center;
}
.mx-datepicker {
	width: 216px;
}
.mx-datepicker .mx-input {
	border-radius: 0;
	box-shadow: none;
	border: none;
}
.secspan {
	font-size: 13px;
	color: #676767;
	padding: 15px 8px 15px 25px;
	display: block;
}
.option-button {
	color: #FFF;
	font-family: Roboto,'Arial';
	font-weight:bold;
	text-decoration: none;
	text-transform: uppercase;
	padding: 14px 21px 13px;
	font-size: 12px;
	background-color: #a5a9b2;
	margin: 10px 10px 10px 0;
	display: inline-block;
	text-align: center;
	cursor: pointer;
	border-radius: 0;
	border: none;
	transition: background 0.3s ease-in-out;
}
.option-button:hover {
	text-decoration: none;
	background-color: #888;
	background-image: none;
}
.option-button.colbtn {
	color: #FFF;
	background-color: #2BAEF6;
}
.option-button.colbtn:hover {
	background-color: #1B8EEF;
}
/** 2022 AL Tables **/
.altable-holder {
	width: 100%;
	margin: 0;
}
.altable .table {
	font-size: 14px;
}
.altbl-container {
	/*overflow-x: auto;*/
}
.altbl {

}
.altbl td {
	border-left: 1px solid #DDD;
	border-right: 1px solid #DDD;
	padding: 8px;
}
.altbl.noborderv td {
	border-left: none;
	border-right: none;
}
.altbl thead {
	border-top: 1px solid #888;
	border-bottom: 1px solid #888;
}
.altbl thead tr {
	background-color: #FAFAFA;
}
.altbl thead tr td {
	padding: 10px 8px 9px;
	cursor: pointer;
}
.altbl tbody tr {
	background-color: transparent;
	transition: background-color 0.2s ease-in-out;
	border-bottom: 1px solid #DDD;
}
.altbl.striped tbody tr:nth-of-type(odd) {
	background-color: rgba(0,0,0,0.05);
	border-bottom: none;
}
.altbl tbody tr:hover {
	background-color: rgba(0,0,0,0.075);
}
.altbl tbody tr {
	background-color: transparent;
}
.altbl .subtitlerow {
	background-color: #EEE;
	font-weight: bold;
	border-top: 1px solid #888;
	border-bottom: 1px solid #888;
}
.altbl tr.subtitlerow td {
	padding: 10px 8px 9px;
}
.altbl .headingtext {
	font-weight: bold;
}
.altbl .rt {
	text-align: right;
}
.altbl .ctr {
	text-align: center;
}
.action-icon {
	font-size: 16px;
	margin: 5px;
	cursor: pointer;
}
.action-icon:hover {
	opacity: 0.6;
}
/** End 2022 AL Tables **/
.popup-tabs {
	display: flex;
	justify-content: flex-start;
	margin-bottom: 10px;
	font-weight: 500;
	font-size: 11px;
	font-family: 'Roboto';
	text-transform: uppercase;
	background-color: #F1F2F6;
}
.popup-tab {
	padding: 12px 12px;
	margin-right: 5px;
	cursor: pointer;
	border-radius: 2px;
}
.popup-tab.active {
	background: #FFF;
}
.popup-tab:not(.active):hover {
	background-color: #EEE;
}
.contained-popup-content {
	//max-height: 500px;
	max-height: 60vh;
	min-height: 200px;
	overflow-y: auto;
	position: relative;
}
.selectable-item {
	display: flex;
	justify-content: space-between;
	padding: 10px 10px;
	/*background: #F1F1F1;*/
	margin: 4px 0;
	border: 1px solid #DAD8DE;
	align-items: center;
}
.selectable-item:hover {
	background-color: #FAFAFA;
}
.selectable-item .add {
	cursor: pointer;
	color: #00BC6C;
	float: right;
	font-size: 12px;
	font-weight: 500;
}
.selectable-item .rem {
	cursor: pointer;
	color: #E65554;
	float: right;
	font-weight: 500;
}
.selectable-header {
	font-weight: bold;
	margin: 5px 0 10px 0;
}
.wl-popup input[type=text].selectable-searchbox {
	border: 1px solid #EEE;
	font-size: 11px;
	padding: 5px 5px;
}
.text-link {
	cursor: pointer;
	width: fit-content;
	padding-bottom: 1px;
	/*margin: 0 auto;*/
	border-bottom: 1px dotted transparent;
	transition: ease-in-out 0.5s;
}
.text-link:hover {
	border-bottom: 1px dotted #666;
}
.red {
	color: #E65554;
}
.menubar .menu-inside .top-menu-group:nth-of-type(2) {
	margin-left: 100px;
}
.menubar .menu-inside .top-menu-group:nth-of-type(3) {
	margin-left: 200px;
}
.menubar .menu-inside .top-menu-group:nth-of-type(4) {
	margin-left: 300px;
}
.menubar .menu-inside .top-menu-group:nth-of-type(5) {
	margin-left: 400px;
}
</style>
