<template>
	<div>
		<BlanketOverlayWithPopup v-if="userobin && userobin.id >= 0 && hasPopulated === true" title="Edit User" width="500" :hastabs="false" v-on:close="closeCancel">
			<template v-slot:main>
				<div class="contained-popup-content">
					<div style="padding: 10px 0 10px 0;">
						<span class="settlbl">User ID: {{userobin.id}}</span>
					</div>
					<table>
						<tbody>
						<tr>
							<td><span class="settlbl">Email</span></td>
							<td><input type="text" class="saver" v-model="userEditOb.email"></td>
						</tr>
						<tr>
							<td><span class="settlbl">First Name</span></td>
							<td><input type="text" class="saver" v-model="userEditOb.fname"></td>
						</tr>
						<tr>
							<td><span class="settlbl">Last Name</span></td>
							<td><input type="text" class="saver" v-model="userEditOb.lname"></td>
						</tr>
						<tr>
							<td><span class="settlbl">Password/Token</span></td>
							<td><input type="text" class="saver" v-model="userEditOb.password"></td>
						</tr>
						<tr>
							<td><span class="settlbl">Status</span></td>
							<td><select class="saver selector" v-model="userEditOb.status">
								<option value=""></option>
								<option v-for="cl in statuses" :key="cl" :value="cl">{{ucfirst(cl)}}</option>
							</select></td>
						</tr>
						<tr>
							<td>
								<span class="settlbl">Station Perms <i class="mdi mdi-pencil" style="margin-left: 5px;cursor: pointer;" @click="toggleview('stn')"></i></span>
								<div v-if="permsExpanded" class="expander">
									<p class="opt" v-for="st in clientsandpanels" :key="st.shortcode" :class="{chosen : userEditOb.permArr.includes(st.shortcode)}"
										@click="updatePerm('stn', st.shortcode)">{{st.title}}</p>
								</div>
							</td>
							<td><input type="text" class="saver" v-model="userEditOb.permStr"></td>
						</tr>
						<tr>
							<td>
								<span class="settlbl">Survey Perms <i class="mdi mdi-pencil" style="margin-left: 5px;cursor: pointer;" @click="toggleview('surv')"></i></span>
								<div v-if="survPermsExpanded" class="expander">
									<p class="opt" v-for="st in survsforperm" :key="st.survid" :class="{chosen : userEditOb.surveyPermArr.includes(st.survid)}"
										@click="updatePerm('surv', st.survid)">{{st.title}} ({{st.survid}})</p>
								</div>
							</td>
							<td><input type="text" class="saver" v-model="userEditOb.surveyPermStr"></td>
						</tr>
						<tr>
							<td>
								<span class="settlbl">ContactList Perms <i class="mdi mdi-pencil" style="margin-left: 5px;cursor: pointer;" @click="toggleview('cl')"></i></span>
								<div v-if="contactListPermsExpanded" class="expander">
									<p class="opt" v-for="st in contactlists" :key="st.id" :class="{chosen : userEditOb.contactListsAllowed.includes(st.id)}"
										@click="updatePerm('cl', st.id)">{{st.title}} ({{st.id}})</p>
								</div>
							</td>
							<td><input type="text" class="saver" v-model="userEditOb.contactListsAllowedStr"></td>
						</tr>
						<tr>
							<td><span class="settlbl" title="This is for display purposes only - does not affect permissions">Site/Station</span></td>
							<td>
								<input v-if="store.user.superUser === 1" type="text" class="saver" v-model="userEditOb.station">
								<select v-else v-model="userEditOb.station">
									<option value=""></option>
									<option v-for="st in userEditOb.permArr" :key="st" :value="st">{{st}}</option>
								</select>
							</td>
						</tr>
						<tr v-if="store.user.superUser === 1">
							<td><span class="settlbl">Question Build</span></td>
							<td><select class="saver selector" v-model="userEditOb.perms.qb">
								<option v-for="cl in yesNos" :key="cl.val" :value="cl.val">{{ucfirst(cl.name)}}</option>
							</select></td>
						</tr>
						<tr v-if="store.user.superUser === 1">
							<td><span class="settlbl">Dashboard</span></td>
							<td><select class="saver selector" v-model="userEditOb.perms.dash">
								<option v-for="cl in yesNos" :key="cl.val" :value="cl.val">{{ucfirst(cl.name)}}</option>
							</select></td>
						</tr>
						<tr v-if="store.user.superUser === 1">
							<td><span class="settlbl">Hook Central</span></td>
							<td><select class="saver selector" v-model="userEditOb.perms.hookcentral">
								<option v-for="cl in yesNos" :key="cl.val" :value="cl.val">{{ucfirst(cl.name)}}</option>
							</select></td>
						</tr>
						<tr v-if="store.user.superUser === 1">
							<td><span class="settlbl">CATI User</span></td>
							<td><select class="saver selector" v-model="userEditOb.perms.CATIUser">
								<option v-for="cl in yesNos" :key="cl.val" :value="cl.val">{{ucfirst(cl.name)}}</option>
							</select></td>
						</tr>
						<tr v-if="store.user.superUser === 1">
							<td><span class="settlbl">CATI Supervisor</span></td>
							<td><select class="saver selector" v-model="userEditOb.perms.CATISuper">
								<option v-for="cl in yesNos" :key="cl.val" :value="cl.val">{{ucfirst(cl.name)}}</option>
							</select></td>
						</tr>
						<tr v-if="store.user.superUser === 1">
							<td><span class="settlbl">Site Admin</span></td>
							<td><select class="saver selector" v-model="userEditOb.perms.siteAdmin">
								<option v-for="cl in yesNos" :key="cl.val" :value="cl.val">{{ucfirst(cl.name)}}</option>
							</select></td>
						</tr>
						<tr v-if="store.user.superUser === 1">
							<td><span class="settlbl">Analysis</span></td>
							<td><select class="saver selector" v-model="userEditOb.perms.analysis">
								<option v-for="cl in yesNos" :key="cl.val" :value="cl.val">{{ucfirst(cl.name)}}</option>
							</select></td>
						</tr>
						<tr v-if="store.user.superUser === 1">
							<td><span class="settlbl">EDMs</span></td>
							<td><select class="saver selector" v-model="userEditOb.perms.edms">
								<option v-for="cl in yesNos" :key="cl.val" :value="cl.val">{{ucfirst(cl.name)}}</option>
							</select></td>
						</tr>
						<tr v-if="store.user.superUser === 1">
							<td><span class="settlbl">Contact Lists</span></td>
							<td><select class="saver selector" v-model="userEditOb.perms.contactlists">
								<option v-for="cl in yesNos" :key="cl.val" :value="cl.val">{{ucfirst(cl.name)}}</option>
							</select></td>
						</tr>
						<tr v-if="store.user.superUser === 1">
							<td><span class="settlbl">Contact Details</span></td>
							<td><select class="saver selector" v-model="userEditOb.perms.contacts">
								<option v-for="cl in yesNos" :key="cl.val" :value="cl.val">{{ucfirst(cl.name)}}</option>
							</select></td>
						</tr>
						</tbody>
					</table>
				</div>
			</template>
			<template v-slot:buttons>
				<button type="button" class="option-button colbtn" @click="saveUserInfo">Save Info</button>
				<button type="button" class="option-button" @click="closeCancel">Cancel</button>
			</template>
		</BlanketOverlayWithPopup>
	</div>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'
export default {
	name: "WLUserPopup",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		userobin: Object,
		clientsandpanels: Array,
		survsforperm: Array,
		contactlists: Array,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			activeTab: 'main',

			grsrchtext: '',
			desktopsrchtext: '',
			toplinesrchtext: '',
			moblicsrchtext: '',
			userEditOb: {},
			statuses: ['active','deleted','disabled'],
			yesNos: [
				{val: 0, name: 'No'},
				{val: 1, name: 'Yes'},
			],
			hasPopulated: false,

			permsExpanded: false,
			survPermsExpanded: false,
			contactListPermsExpanded: false,
		}
	},
	computed: {

	},
	methods: {
		toggleview(type) {
			if(type === 'stn') this.permsExpanded = !this.permsExpanded
			else if(type === 'surv') this.survPermsExpanded = !this.survPermsExpanded
			else if(type === 'cl') this.contactListPermsExpanded = !this.contactListPermsExpanded
		},
		updatePerm(type, val) {
			if(type === 'stn') {
				if(this.userEditOb.permArr.includes(val)) this.userEditOb.permArr.splice(this.userEditOb.permArr.indexOf(val), 1)
				else this.userEditOb.permArr.push(val)
				this.userEditOb.permStr = this.userEditOb.permArr.join('|')
			}
			else if(type === 'surv') {
				if(this.userEditOb.surveyPermArr.includes(val)) this.userEditOb.surveyPermArr.splice(this.userEditOb.surveyPermArr.indexOf(val), 1)
				else this.userEditOb.surveyPermArr.push(val)
				this.userEditOb.surveyPermStr = this.userEditOb.surveyPermArr.join('|')
			}
			else if(type === 'cl') {
				if(this.userEditOb.contactListsAllowed.includes(val)) this.userEditOb.contactListsAllowed.splice(this.userEditOb.contactListsAllowed.indexOf(val), 1)
				else this.userEditOb.contactListsAllowed.push(val)
				this.userEditOb.contactListsAllowedStr = this.userEditOb.contactListsAllowed.join('|')
			}
		},
		populateContent() {
			if (this.userobin) {
				for (let pr in this.userobin) {
					this.userEditOb[pr] = this.userobin[pr];
				}
			}
			this.hasPopulated = true
		},
		closeCancel() {
			this.$emit('close');
		},
		saveUserInfo() {
			if (this.isFetching === false) {
				this.store.showLoader = true
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/wavelength-user-admin/save-user-details";
				let myob = {
					email: this.userEditOb.email,
					password: this.userEditOb.password,
					fname: this.userEditOb.fname,
					lname: this.userEditOb.lname,
					station: this.userEditOb.station,
					phone: this.userEditOb.phone,
					status: this.userEditOb.status,

					perm_qb: this.userEditOb.perms.qb,
					perm_dash: this.userEditOb.perms.dash,
					perm_hookcentral: this.userEditOb.perms.hookcentral,
					perm_CATIUser: this.userEditOb.perms.CATIUser,
					perm_CATISuper: this.userEditOb.perms.CATISuper,
					perm_siteAdmin: this.userEditOb.perms.siteAdmin,
					perm_analysis: this.userEditOb.perms.analysis,
					perm_edms: this.userEditOb.perms.edms,
					perm_contactlists: this.userEditOb.perms.contactlists,
					perm_contacts: this.userEditOb.perms.contacts,

					permissions: this.userEditOb.permStr,
					survperms: this.userEditOb.surveyPermStr,
					contactlistperms: this.userEditOb.contactListsAllowedStr,
				};
				let sendob = {editeduid: this.userEditOb.id, userob: myob};
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					this.store.showLoader = false
					let retusr = null
					if(ret.user) retusr = ret.user
					this.$emit('close', retusr)
				}.bind(this));
			}
		},
	},
	watch: {
		userobin() {
			this.populateContent()
		},
	},
	mounted() {
		this.populateContent()
	},
}
</script>
<style scoped>
.saver {
	width: 200px;
}
.selector {
	width: 216px;
}
.expander {
	padding: 5px 0;
}
.expander .opt {
	color: #888;
	font-style: italic;
	margin-block-start: 0;
	margin-block-end: 0;
	line-height: 1.2;
	cursor: pointer;
}
.expander .opt.chosen {
	font-style: normal;
	color: #888;
	text-decoration: underline;
	font-weight: bold;
}
</style>