<template>
	<BlanketOverlayWithPopup v-if="fetchFinished" title="Question Editor" :width="popupWidth" v-on:close="closeCancel" :nobuttons="true">
		<template v-slot:main>

			<div v-if="showingFileUpload" style="margin: 20px 0;">
				<UploadFile @file-changed="file_changed" />
				<button type="button" class="option-button colbtn" @click="saveFileUpload">Save File</button>
				<button type="button" class="option-button" @click="cancelshowFileUploader">Cancel</button>
			</div>

			<QuestionSelector v-if="showingParentSelector" lookuptype="parentOptions"
				:survob="survob" :currentchoiceqid="editingQuestion.parentID" :currentqid="editingQuestion.qid"
				@save="saveParentId($event)" @cancel="cancelParentId"
			/>

			<div v-if="!hideMainScreenForPopup && popupType === 'matrix'" class="matrix-editor">
				<p style="margin-bottom: 10px;">Head QID: {{editingQuestion.qid}}</p>
				<div style="margin-bottom: 10px;">
					<span class="label block" style="margin-bottom: 5px; display: block;">Header Text</span>
					<ckeditor :editor="editorBody" v-model="editingQuestion.qtext"></ckeditor>
				</div>
				<div class="half-parent">
					<div class="left-half">
						<div>
							<span class="label">Mandatory</span>
							<select v-model="editingQuestion.mandatory">
								<option value="No">No</option>
								<option value="Yes">Yes</option>
							</select>
						</div>
						<div>
							<span class="label">OText Value</span>
							<select v-model="editingQuestion.matrixOTextOptid">
								<option value=""></option>
								<option v-for="(opttext, ind) in matrixOTextOptions" :key="opttext" :value="ind+1">{{opttext}}</option>
							</select>
						</div>
						<div>
							<span @click="showingFileUpload = true" class="label text-link" title="Click to assign media">File Link</span>
								<input v-model="editingQuestion.filelink" type="text" readonly="readonly" disabled="disabled" title="Click to play" />
								<span @click="playAudio('matrix')" style="cursor:pointer;" title="Play">
								<i class="mdi mdi-play"></i>
							</span>
							<span @click="editingQuestion.filelink = ''" class="uhovred" title="Click to remove media from question">
								<i class="mdi mdi-close"></i>
							</span>
						</div>
						<div>
							<p>Scoring Options (columns - one per line)</p>
							<textarea v-model="editingQuestion.matrixOpts"></textarea>
						</div>
					</div>
					<div class="right-half">
						<div>
							<span class="label">Matrix Type</span>
							<select v-model="editingQuestion.type">
								<option value="MatrixHeadRadio">Radio</option>
								<option value="MatrixHeadCheck">Checkbox</option>
							</select>
						</div>
						<div>
							<span class="label text-link" @click="showingParentSelector = true" title="Select parent question">
								Parent Q
							</span>
							<input type="text" readonly="readonly" disabled="disabled" :value="editingQuestion.parentID" />
							<span class="uhovred" title="Click to remove assigned parent question" @click="editingQuestion.parentID = 0">
								<i class="mdi mdi-close"></i>
							</span>
						</div>
						<div style="height: 30px; width: 100%;"></div>
						<div>
							<p>Cases (rows - one per line)</p>
							<textarea v-model="editingQuestion.matrixCases"></textarea>
						</div>
					</div>
				</div>
				<div class="button-section">
					<button type="button" class="option-button colbtn" @click="saveQuestion">Save Info</button>
					<button type="button" class="option-button" @click="closeCancel">Cancel</button>
					<audio src="" controls="" id="playermatrix" style="margin-left: 10px;"></audio>
				</div>
			</div>

			<div v-if="!hideMainScreenForPopup && ['narrow','standard'].includes(popupType)" class="pholder">
				<ul id="qinfUL">
					<li class="">
						<span class="label">QuestionID:</span>
						{{editingQuestion.qid}}
					</li>
					<li title="Is the respondent forced to answer this question?">
						<span class="label">Mandatory</span>
						<select v-model="editingQuestion.mandatory">
							<option value="No">No</option>
							<option value="Yes">Yes</option>
						</select>
					</li>
					<li title="Which type of question is this?">
						<span class="label">Type</span>
						<select v-model="editingQuestion.type">
							<option v-for="qtype in qtypeoptions" :key="qtype.value" :value="qtype.value">{{qtype.text}}</option>
						</select>
					</li>
					<li v-if="popupType !== 'narrow'" title="Do you wish to randomise the order of the options for respondents?" class="randhide">
						<label for="qrand">Randomise</label><input type="checkbox" v-model="editingQuestion.randopts" />
					</li>
					<li title="For integration with other systems, is there a custom column code required?">
						<label for="colcode">Col Code</label><input type="text" v-model="editingQuestion.colcode" />
					</li>
					<li class="stacked">
						<span class="label block" style="margin-bottom: 5px;">Question Text</span>
						<ckeditor :editor="editorBody" v-model="editingQuestion.qtext"></ckeditor>
					</li>
					<li v-if="popupType !== 'narrow'" title="Select Yes if this question uses the master station list"><label for="isStnLst">Station List</label>
						<select v-model="editingQuestion.isStationListQ">
							<option :value="false">No</option>
							<option :value="true">Yes</option>
						</select>
					</li>
					<li title="Does this question have a media file to link to it?">
						<span @click="showingFileUpload = true" class="label text-link" title="Click to assign media">File Link</span>
						<input v-model="editingQuestion.filelink" type="text" readonly="readonly" disabled="disabled" title="Click to play" />
						<span @click="playAudio" style="cursor:pointer;" title="Play">
							<i class="mdi mdi-play"></i>
						</span>
						<span @click="editingQuestion.filelink = ''" class="uhovred" title="Click to remove media from question">
							<i class="mdi mdi-close"></i>
						</span>
					</li>
					<li v-if="popupType !== 'narrow'" title="Tick this box to show the Paste options (eg paste from Excel)" class="non_text_opts">
						<span class="label">Paste Options</span>
						<input type="checkbox" v-model="showingPasterView" />
					</li>
					<li v-if="popupType !== 'narrow'" title="Does this question have a parent question that changes which answer options are displayed to the user?" class="non_text_opts parSelect">
						<span class="label text-link" @click="showingParentSelector = true" title="Select parent question">
							Parent Q
						</span>
						<input type="text" readonly="readonly" disabled="disabled" :value="editingQuestion.parentID" />
						<span class="uhovred" title="Click to remove assigned parent question" @click="editingQuestion.parentID = 0">
							<i class="mdi mdi-close"></i>
						</span>
					</li>
					<li v-if="popupType === 'narrow'">
						<span class="label">Force Skip To</span>
						<input v-model="editingQuestion.textForceSkipQid" type="text" />
					</li>
					<li>
						<div class="optionlist">
							<div style="margin:20px 0;">
								<audio src="" controls="" id="player"></audio>
							</div>
						</div>
					</li>
					<li>
						<button type="button" class="option-button colbtn" @click="saveQuestion">Save Info</button>
						<button type="button" class="option-button" @click="closeCancel">Cancel</button>
					</li>
				</ul>

				<div v-if="popupType === 'standard'" id="qtblhold">
					<table v-if="showingPasterView === true" id="pastetable">
						<tr><td>Options (one per line)<br/></td></tr>
						<tr><td><textarea v-model="excelPasteRawText" ref="excelpastearea" cols="60" rows="20" class="excelpastearea"></textarea></td></tr>
					</table>
					<table v-else id="opt_tbl">
						<tr>
							<td></td>
							<td class='showmore'><span class="text-link" @click="toggleShowMoreOptions">+ Show More +</span></td>
							<td class='ctr'>OText</td>
							<td class='ctr'>NoRand</td>
							<td class='ctr sk'>Skip</td>
						</tr>
						<tr v-for="i in numOptionsToShow" :key="i">
							<td>Opt{{i}}</td><td><input v-if="editingQuestion.options[i-1]" v-model="editingQuestion.options[i-1].opttext" type='text' class='optsin' /></td>
							<td class='ctr'><input v-if="editingQuestion.options[i-1]" type='checkbox' v-model="editingQuestion.options[i-1].isOText" class='' /></td>
							<td class='ctr'><input v-if="editingQuestion.options[i-1]" type='checkbox' v-model="editingQuestion.options[i-1].dontRand" class='' /></td>
							<td class='sk'><input v-if="editingQuestion.options[i-1]" class='skinput' v-model="editingQuestion.options[i-1].skipToQidTarget" type='text' /></td>
						</tr>
					</table>
				</div>
			</div>
		</template>
		<template v-slot:buttons>

		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UploadFile from "@/components/UploadFile.vue";
import axios from "axios";
import QuestionSelector from "@/components/questions/QuestionSelector.vue";

export default {
	name: "EditQuestionScreen",
	mixins: [globalMixin],
	components: {
		QuestionSelector,
		UploadFile,
		BlanketOverlayWithPopup,

	},
	props: {
		survob: {
			type: [Object, null],
			default: null,
		},
		qob: {
			type: [Object, null],
			default: null,
		},
		positionafterqid: {
			type: [Number, null],
			default: null,
		},
	},
	data: function () {
		return {
			store: store,
			isFetching: false,
			fetchFinished: true,

			editingQuestion: {
				qid: 0,
				qtext: '',
				mandatory: 'No',
				type: 'HeaderText',
				randopts: false,
				colcode: '',
				isStationListQ: false,
				filelink: '',
				parentID: 0,
				textForceSkipQid: null,
				options: [],

				matrixOTextOptid: '',
				matrixOpts: '',
				matrixCases: '',
				originalMatrixQids: [],
			},

			qtypes: [
				{value: 'HeaderText', text: '-- HeaderText --'},
				{value: 'Text', text: 'Text Input'},
				{value: 'Textarea', text: 'Text Area'},
				{value: 'Number', text: 'Number'},
				{value: 'Radio', text: 'Radio'},
				{value: 'Checkbox', text: 'Checkbox'},
				{value: 'Select-One', text: 'Select-One'},
				{value: 'MatrixHeadRadio', text: 'Matrix-Radio'},
				{value: 'MatrixHeadCheck', text: 'Matrix-Checkbox'},
			],

			showingPasterView: false,
			excelPasteRawText: '',
			showAll40Options: false, //user manual override
			editorBody: ClassicEditor,
			uploadfile: null,
			showingFileUpload: false,
			showingParentSelector: false,

		}
	},
	computed: {
		popupType() {
			if(['HeaderText','Text','Textarea','Number'].includes(this.editingQuestion.type)) {
				return 'narrow'
			}
			else if(['MatrixHeadRadio','MatrixHeadCheck'].includes(this.editingQuestion.type)) {
				return 'matrix'
			}
			return 'standard'
		},
		popupWidth() {
			if(this.popupType === 'narrow') {
				return '360'
			}
			return '1040'
		},
		hideMainScreenForPopup() {
			if(this.showingFileUpload === true) return true
			if(this.showingParentSelector === true) return true
			return false
		},
		qtypeoptions() {
			let disallowmatrixtypes = ['MatrixHeadRadio','MatrixHeadCheck']
			if(this.editingQuestion.qid > 0) { //if editing an existing question, don't allow changing to matrix types
				return this.qtypes.filter(qt => !disallowmatrixtypes.includes(qt.value))
			}
			return this.qtypes //new questions can choose matrix
		},
		isCATI() {
			if(this.survob) {
				let survtype = this.survob.type
				if(['RegRatings','CATI','CATI_External'].includes(survtype)) {
					return true
				}
			}
			return false
		},
		showAllOptions() {
			if(this.showAll40Options === true) return true
			let activeOptions = 0
			for(let opt of this.editingQuestion.options) {
				if(opt.opttext !== '') activeOptions++
			}
			if(activeOptions > 20) return true
			return false
		},
		numOptionsToShow() {
			if(this.showAllOptions === true) return 40
			return 20
		},
		matrixOTextOptions() {
			if(this.editingQuestion.matrixOpts.length > 0) {
				return this.editingQuestion.matrixOpts.split('\n')
			}
			return []
		},
	},
	methods: {
		file_changed(value) {
			this.uploadfile = value
		},
		saveFileUpload() {
			if (this.isFetching === false) {
				let self = this
				let form_data = new FormData()
				form_data.append('uid', this.store.user.id)
				form_data.append('wlsesstoken', this.store.wlsesstoken)
				if(this.uploadfile) {
					form_data.append('uploadfile', this.uploadfile)
				}
				if(!this.uploadfile) {
					alert("Please select an MP3, JPG or PNG file.")
					return false;
				}
				this.store.showLoader = true
				this.isFetching = true
				axios({
					url: this.store.urlroot + "central/post-vue/questions/upload-attachment",
					method: 'POST',
					data: form_data
				}, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(function (response) {
					self.store.showLoader = false
					self.isFetching = false
					let ret = response.data //response.data is returned info
					if(ret.error) {
						self.showKalertError(ret.error)
					}
					else if(ret.success) {
						self.editingQuestion.filelink = ret.success.new_filename
						self.uploadfile = null
						self.showingFileUpload = false
					}
				}.bind(this)).catch(function (error) {
					console.log(error)
				});
			}
		},
		closeCancel() {
			this.$emit('close');
		},
		saveParentId(qid) {
			this.editingQuestion.parentID = qid
			this.showingParentSelector = false
		},
		cancelParentId() {
			this.showingParentSelector = false
		},
		saveQuestion() {
			if (this.isFetching === false && this.survob && this.survob.survid > 0 && this.editingQuestion.qtext.length > 0) {
				this.store.showLoader = true
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/questions/save-question";
				let sendob = {
					survid: this.survob.survid,
					positionNewAfterQid: this.positionafterqid,
					qob: this.editingQuestion,
				}
				this.isFetching = false
				this.store.showLoader = false
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					this.store.showLoader = false
					this.$emit('update', ret)
					setTimeout(function() {
						this.$emit('close')
					}.bind(this), 100)
				}.bind(this));
			}
		},
		cancelshowFileUploader() {
			this.showingFileUpload = false
			this.uploadfile = null
		},
		convertPasteToOptions() {
			let exinput = this.excelPasteRawText.split('\n')
			if(exinput.length > 0) {
				let myoptions = []
				if (exinput.length > 40) exinput = exinput.slice(0, 40) //limit to 40 options
				for (let i = 0; i<40; i++) {
					let opttext = ''
					if(exinput[i]) opttext = exinput[i].trim()
					myoptions.push(this.createNewOption(i, opttext))
				}
				this.editingQuestion.options = myoptions
			}
		},
		toggleShowMoreOptions() {
			this.showAll40Options = !this.showAll40Options
		},
		createNewOption(ind, opttext = '') {
			let newopt = {
				optid: ind + 1,
				opttext: opttext,
				dontRand: false,
				isOText: false,
				skipToQidTarget: '',
			}
			return newopt
		},
		playAudio(target = null) {
			if(this.editingQuestion.filelink && this.editingQuestion.filelink.length > 0) {
				let cachebuster = Math.random().toString(12).substring(2, 6) + Math.random().toString(12).substring(2, 6)
				let audio = document.getElementById('player')
				if(target === 'matrix') {
					audio = document.getElementById('playermatrix')
				}
				audio.src = "https://www.yourwavelength.com/surveys/media/"+this.editingQuestion.filelink+"?cb="+cachebuster
				audio.play()
			}
		},
	},
	watch: {
		excelPasteRawText() {
			this.convertPasteToOptions()
		},
		editingQuestion: {
			deep: true,
			handler() {

			}
		},
		showingPasterView() {
			if(this.showingPasterView === true) {
				setTimeout(function() {
					const g = this.$refs.excelpastearea
					if(g) g.focus()
				}.bind(this), 50)

			}
		},
		// 'editingQuestion.type': {
		// 	handler: function (newVal, oldVal) {
		// 		let mats = ['MatrixHeadRadio','MatrixHeadCheck']
		// 		//if the new value is a matrix type and the old value wasn't, assign settings for new ones
		// 		if(mats.includes(newVal) && !mats.includes(oldVal) && this.editingQuestion.qid === 0) {
		// 			this.editingQuestion.matrixOpts = ''
		// 			this.editingQuestion.matrixCases = ''
		// 			this.editingQuestion.matrixOTextOptid = ''
		// 		}
		// 	},
		// }
	},
	mounted() {
		let myq = this.editingQuestion
		if(this.qob && (this.qob.qid > 0 || this.qob.qtext.length > 0)) { //check qtext for cloning questions (has id=0)
			myq = JSON.parse(JSON.stringify(this.qob))
			if(['MatrixHeadRadio','MatrixHeadCheck'].includes(myq.type)) {
				let originalMatrixQids = [myq.qid]
				let qt = myq.qtext.split("|")
				myq.qtext = qt[0]
				let mc = []
				if(qt[1]) {
					mc = [qt[1]]
				}
				//get the rest of the cases
				let qpos = this.survob.questions.findIndex(item => item.qid === myq.qid)
				qpos++
				while(qpos < this.survob.questions.length) {
					let nextq = this.survob.questions[qpos]
					if(nextq && ['MatrixSubRadio','MatrixSubCheck','MatrixEndRadio','MatrixEndCheck'].includes(nextq.type)) {
						mc.push(nextq.qtext)
						originalMatrixQids.push(nextq.qid)
						if(['MatrixEndRadio','MatrixEndCheck'].includes(nextq.type)) {
							break
						}
					}
					else {
						break
					}
					qpos++
				}
				myq.matrixCases = mc.join('\n')
				myq.matrixOTextOptid = myq.oTextOpts
				myq.originalMatrixQids = originalMatrixQids
			}
			let mo = []
			for(let opt of myq.options) {
				if(opt.opttext.length > 0) mo.push(opt.opttext)
			}
			myq.matrixOpts = mo.join('\n')
		}
		if(!myq.matrixOTextOptid) myq.matrixOTextOptid = ''
		if(!myq.matrixOpts) myq.matrixOpts = ''
		if(!myq.matrixCases) myq.matrixCases = ''
		if(!myq.originalMatrixQids) myq.originalMatrixQids = []
		for (let i = 0; i<40; i++) {
			if(!myq.options[i]) myq.options.push(this.createNewOption(i))
			else {
				if(myq.options[i].skipToQidTarget === 0) myq.options[i].skipToQidTarget = ''
			}
		}
		let cktext = myq.qtext //add this after a short delay to handle ckeditor
		this.editingQuestion = myq
		setTimeout(function() {
			this.editingQuestion.qtext = cktext
		}.bind(this), 100)
	},
}
</script>

<style scoped>
.pholder {
	display: flex;
	gap: 30px;
}
#qinfUL {
	width: 300px;
	text-align: left;
	font-size: 13px;
}
#qinfUL li {
	padding: 5px 0;
	display: flex;
	justify-content: flex-start;
	gap: 5px;
	align-items: center;
}
#qinfUL li.stacked {
	display: block;
}
#qinfUL li label,
#qinfUL li .label {
	width: 90px;
}
#qinfUL li .label.block {
	width: 100%;
	display: block;
	padding: 5px 0 2px;
}
#qinfUL li #qtext, #qinfUL li #CATIQText {
	width: 292px;
	margin-top: 5px;
	height: 90px;
	padding: 4px;
	font-family: 'Roboto', 'Arial', sans-serif;
	font-size: 13px;
	line-height: 1.2;
}
#qinfUL select, #qinfUL input {
	padding: 4px;
}
#qinfUL #qrand, #qinfUL #showTog, #qinfUL #showExcel {
	margin-top: 6px;
}
#pastetable td {
	padding: 0;
}
#pastetable textarea, #matrixopts, #matrixcases {
	background-size: 100% 22px;
	line-height: 22px;
	width: 500px;
	font-size: 13px;
	border: 1px solid #CCC;
	font-family: 'Roboto', 'Arial', sans-serif;
}
.wl-popup td {
	padding: unset;
}
.wl-popup #qtblhold input {
	padding: 3px 5px;
}
#matrixhead {
	width: 500px;
	font-family: 'Roboto', 'Arial', sans-serif;
	font-size: 13px;
}
#cke_matrixhead {
	width: 760px;
}
#cke_2_contents {
	height: 50px;
}
#matrixopts, #matrixcases {
	height: 350px;
	width: 350px;
}
#matrixcases {
	margin-left: 20px;
}
#opt_tbl .optsin {
	width: 392px;
	border-radius: 0;
	border: none;
	padding: 3px 5px;
	margin-left: 14px;
	margin-top: 2px;
	margin-bottom: 2px;
}
.skinput {
	width: 50px;
	text-align: center;
	border: none;
	padding: 0 3px;
}
.skiplinker {
	font-size: 11px;
}
.uhovred {
	cursor: pointer;
}
.uhovred:hover {
	color: #F00;
}
.text-link {
	border-bottom: none;
}
.text-link:hover {
	border-bottom: none;
	text-decoration: underline;
}
.ctr {
	text-align: center;
}
#opt_tbl .ctr {
	width: 55px;
}
.half-parent {
	display: flex;
	gap: 20px;
}
.half-parent textarea {
	line-height: 22px;
	width: 460px;
	height: 250px;
	font-family: 'Roboto', sans-serif;
	font-size: 13px;
	border: 1px solid #CCC;
}
.left-half > div, .right-half > div {
	margin-bottom: 10px;
}
.half-parent p {
	margin-bottom: 5px;
}
.half-parent .label {
	margin-right: 5px;
}
.button-section {
	display: flex;
	align-items: center;
}
</style>