<template>
	<div>
		<BlanketOverlayWithPopup v-if="mktEditOb && mktEditOb.id >= 0 && hasPopulated === true" title="Edit Market" width="500" :hastabs="false" v-on:close="closeCancel">
			<template v-slot:main>
				<div class="contained-popup-content">
					<div style="padding: 10px 0 10px 0;">
						<span class="settlbl">Market ID: {{mktEditOb.id}}</span>
					</div>
					<table>
						<tbody>
						<tr>
							<td><span class="settlbl">Name</span></td>
							<td><input type="text" class="saver" v-model="mktEditOb.name"></td>
						</tr>
						<tr>
							<td><span class="settlbl">State</span></td>
							<td><select class="saver selector" v-model="mktEditOb.state">
								<option value=""></option>
								<option value="NSW">NSW</option>
								<option value="VIC">VIC</option>
								<option value="QLD">QLD</option>
								<option value="SA">SA</option>
								<option value="WA">WA</option>
								<option value="TAS">TAS</option>
								<option value="NT">NT</option>
							</select></td>
						</tr>
						<tr>
							<td><span class="settlbl">Surveyed</span></td>
							<td><select class="saver selector" v-model="mktEditOb.surveyed">
								<option :value="0">No</option>
								<option :value="1">Yes</option>
							</select></td>
						</tr>
						<tr>
							<td><span class="settlbl">Indicative Population</span></td>
							<td><input type="text" class="saver" v-model="mktEditOb.indpop"></td>
						</tr>
						</tbody>
					</table>
				</div>
			</template>
			<template v-slot:buttons>
				<button type="button" class="option-button colbtn" @click="saveInfo">Save Info</button>
				<button type="button" class="option-button" @click="closeCancel">Cancel</button>
			</template>
		</BlanketOverlayWithPopup>
	</div>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'
export default {
	name: "MarketEditPopup",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		mktobin: Object,
		forcenewmarket: {
			type: Boolean,
			default: false,
		},
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			hasPopulated: false,
			mktEditOb: null,
		}
	},
	computed: {

	},
	methods: {
		populateContent() {
			if (this.mktobin && this.forcenewmarket === false) {
				this.mktEditOb = {}
				for (let pr in this.mktobin) {
					this.mktEditOb[pr] = this.mktobin[pr];
				}
			}
			else if(this.forcenewmarket === true) {
				this.mktEditOb = {
					id: 0,
					name: '',
					state: '',
					surveyed: 0,
					indpop: 0,
				}
			}
			this.hasPopulated = true
		},
		closeCancel() {
			this.$emit('close', false);
		},
		saveInfo() {
			if (this.isFetching === false) {
				this.store.showLoader = true
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/regional-ratings-admin/save-market-details";
				let myob = {
					id: this.mktEditOb.id,
					name: this.mktEditOb.name,
					state: this.mktEditOb.state,
					surveyed: this.mktEditOb.surveyed,
					indpop: this.mktEditOb.indpop,
				};
				let sendob = {mktob: myob};
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					this.store.showLoader = false
					let retmkt = null
					if(ret.market) retmkt = ret.market
					this.$emit('close', retmkt)
				}.bind(this));
			}
		},
	},
	watch: {
		mktobin() {
			this.populateContent()
		},
	},
	mounted() {
		this.populateContent()
	},
}
</script>
<style scoped>
.saver {
	width: 200px;
}
.selector {
	width: 216px;
}
</style>