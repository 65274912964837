<template>
	<div>
		<BlanketOverlayWithPopup v-if="srvobin && srvobin.id >= 0 && hasPopulated === true" title="Edit Survey" width="500" :hastabs="false" v-on:close="closeCancel">
			<template v-slot:main>
				<div class="contained-popup-content">
					<div style="padding: 10px 0 10px 0;">
						<span class="settlbl">Survey ID: {{srvEditOb.id}}</span>
					</div>
					<table>
						<tbody>
						<tr>
							<td><span class="settlbl">Name</span></td>
							<td><input type="text" class="saver" v-model="srvEditOb.name"></td>
						</tr>
						<tr>
							<td><span class="settlbl">Market</span></td>
							<td><select class="saver selector" v-model="srvEditOb.mktid">
								<option value=""></option>
								<option v-for="mkt in store.rrMarkets" :key="mkt.id" :value="mkt.id">{{mkt.name}} ({{mkt.id}})</option>
							</select></td>
						</tr>
						<tr>
							<td><span class="settlbl">Collection Only?</span></td>
							<td><select class="saver selector" v-model="srvEditOb.collectionOnly">
								<option :value="0">No</option>
								<option :value="1">Yes</option>
							</select></td>
						</tr>
						<tr>
							<td><span class="settlbl">Data Location</span></td>
							<td><input type="text" class="saver" v-model="srvEditOb.dataloc"></td>
						</tr>
						<tr>
							<td><span class="settlbl">Market Popn</span></td>
							<td><input type="text" class="saver" v-model="srvEditOb.mktpop"></td>
						</tr>
						<tr>
							<td><span class="settlbl">Calc Base Factor</span></td>
							<td><input type="text" class="saver" v-model="srvEditOb.calcBaseFactor"></td>
						</tr>
						<tr>
							<td><span class="settlbl">Release Date YYYYMMDD</span></td>
							<td><input type="text" class="saver" v-model="srvEditOb.reldate"></td>
						</tr>
						<tr>
							<td><span class="settlbl">CRA Gold Std</span></td>
							<td><select class="saver selector" v-model="srvEditOb.is_cra_gs">
								<option :value="0">No</option>
								<option :value="1">Yes</option>
							</select></td>
						</tr>
						<tr>
							<td><span class="settlbl">Processed</span></td>
							<td><select class="saver selector" v-model="srvEditOb.hasBeenProcessed">
								<option :value="0">No</option>
								<option :value="1">Yes</option>
							</select></td>
						</tr>
						<tr>
							<td><span class="settlbl">Desktop Enabled</span></td>
							<td><select class="saver selector" v-model="srvEditOb.enabledDesktop">
								<option :value="0">No</option>
								<option :value="1">Yes</option>
							</select></td>
						</tr>
						<tr>
							<td><span class="settlbl">Mobile Enabled</span></td>
							<td><select class="saver selector" v-model="srvEditOb.enabledMobile">
								<option :value="0">No</option>
								<option :value="1">Yes</option>
							</select></td>
						</tr>
						<tr>
							<td><span class="settlbl">Agency Enabled</span></td>
							<td><select class="saver selector" v-model="srvEditOb.enabledAgency">
								<option :value="0">No</option>
								<option :value="1">Yes</option>
							</select></td>
						</tr>
						<tr>
							<td><span class="settlbl">Late Enabled</span></td>
							<td><select class="saver selector" v-model="srvEditOb.enabledLate">
								<option :value="0">No</option>
								<option :value="1">Yes</option>
							</select></td>
						</tr>
						</tbody>
					</table>
				</div>
			</template>
			<template v-slot:buttons>
				<button type="button" class="option-button colbtn" @click="saveSurveyInfo">Save Info</button>
				<button type="button" class="option-button" @click="closeCancel">Cancel</button>
			</template>
		</BlanketOverlayWithPopup>
	</div>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'
export default {
	name: "RRSurveyEditPopup",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		srvobin: Object,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			hasPopulated: false,
			srvEditOb: null,
		}
	},
	computed: {

	},
	methods: {
		populateContent() {
			if (this.srvobin) {
				this.srvEditOb = {}
				for (let pr in this.srvobin) {
					this.srvEditOb[pr] = this.srvobin[pr];
				}
			}
			this.hasPopulated = true
		},
		closeCancel() {
			this.$emit('close', false);
		},
		saveSurveyInfo() {
			if (this.isFetching === false) {
				this.store.showLoader = true
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/regional-ratings-admin/save-survey-details";
				let sendob = {srvob: this.srvEditOb};
				this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
					this.isFetching = false
					this.store.showLoader = false
					this.$emit('close', true)
				}.bind(this));
			}
		},
	},
	watch: {
		srvobin() {
			this.populateContent()
		},
	},
	mounted() {
		this.populateContent()
	},
}
</script>
<style scoped>
.saver {
	width: 200px;
}
.selector {
	width: 216px;
}
</style>