<template>
	<div v-if="store.sessionOk">
		<div id="container" :class="{pageLeftMenuTransposed : store.leftmenuhidden}">
			<LeftMenuWL></LeftMenuWL>
			<div id="bg">
				<div id="maincontent" class="noTopMenuBar">
					<div class="wl-admin-container">
						<div style="display: flex; gap: 30px; align-items: center;">
							<p class="wl-main-headline" style="margin: 0; padding: 0">Song History Report</p>
							<select v-model="statisticChoice" class="wl-input-select">
								<option value="popindex">Score / Pop Index</option>
								<option value="pctPos">Pct Positive</option>
								<option value="lovepct">Pct Love</option>
								<option value="tired">Pct Burn</option>
							</select>
							<div>
								<label class="radio-inline">
									<input type="radio" value="single" v-model="lookupTypeChoice"> Single Song
								</label>
								<label class="radio-inline">
									<input type="radio" value="hooklist" v-model="lookupTypeChoice"> Hooklist
								</label>
								<div v-if="lookupTypeChoice === 'hooklist'" style="display: inline;">
									<input style="border: 1px solid #CCC;" type="text" v-model="surveySearchText">
									<button @click="getUserSurveys">Search</button>
								</div>
							</div>
						</div>

						<div v-if="lookupTypeChoice === 'single'" style="margin: 20px 0;">
							<div style="display: flex; gap: 10px; align-items: center;">
								<span>Song Lookup</span>
								<div style="width: 315px">
									<Autocompleter v-if="hooks" :searchlist="hooks"
										idfldname="id" textfldname="name" v-on:update="selectedHookOb = $event"
									></Autocompleter>
								</div>
							</div>
						</div>
						<div v-if="lookupTypeChoice === 'hooklist'" style="margin: 20px 0">
							<div>
								<label v-for="s in completedSurveys" :key="s.id" class="checkbox-inline">
									<input type="checkbox" :value="s.id" v-model="selectedSurvIds"> {{s.survname_internal}}
								</label>
							</div>
							<div>
								<span v-if="selectedSurvIds.length > 0" @click="getHookHistoryResults" class="option-button colbtn">Run</span>
							</div>
						</div>

						<div class="results-section">
							<DashMusicHistoryTable v-if="!isFetching && hookHistoryResults.length > 0" :results="hookHistoryResults" :surveys="hookHistorySurveys" :showchartcol="false"></DashMusicHistoryTable>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import LeftMenuWL from "@/components/LeftMenuWL";
import {globalMixin} from "@/mixins";
import Autocompleter from "@/components/Autocompleter.vue";
import DashMusicHistoryTable from "@/components/dashboard/DashMusicHistoryTable.vue";

export default {
	name: "SongReportsPage",
	mixins: [globalMixin],
	components: {
		DashMusicHistoryTable,
		Autocompleter,
		LeftMenuWL
	},
	props: {},
	data: function () {
		return {
			store: store,

			statisticChoice: 'popindex',
			lookupTypeChoice: 'single', //single or hooklist
			hooks: [],
			surveys: [],

			selectedHookOb: null,
			selectedSurvIds: [],
			isFetching: false,
			surveySearchText: '',

			hookHistorySurveys: [],
			hookHistoryResults: [],
		}
	},
	computed: {
		completedSurveys() {
			return this.surveys.filter(item => item.status === 'closed' && ['OMT','Callout'].includes(item.type))
		}
	},
	methods: {
		getInitialData() {
			let targ = this.store.urlroot + "central/post-vue/hooks/get-all-hooks"
			let sendob = {};
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				if(ret.hooks) {
					let h = []
					for(let hob of ret.hooks) {
						hob.name = hob.artist + ' - ' + hob.title
						h.push(hob)
					}
					this.hooks = h
				}
				this.store.showLoader = false
			}.bind(this));
		},
		getUserSurveys() {
			let targ = this.store.urlroot + "central/post-vue/home/get-survey-list"
			let sendob = {
				startRow: 0,
				limitRows: 100,
				searchText: this.surveySearchText,
			}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				if(ret.surveys) this.surveys = ret.surveys
				this.store.showLoader = false
			}.bind(this));
		},
		getHookHistoryResults() {
			if(!this.isFetching) {
				this.isFetching = true
				this.store.showLoader = true
				let targ = this.store.urlroot + "central/post-vue/dashboard/run-song-report"
				let sendob = null
				if (this.lookupTypeChoice === 'single' && this.selectedHookOb) {
					sendob = {
						singlehookid: this.selectedHookOb.id,
						demo: 0,
						stat: this.statisticChoice,
					}
				}
				else if (this.lookupTypeChoice === 'hooklist' && this.selectedSurvIds.length > 0) {
					sendob = {
						survs: this.selectedSurvIds.join("|"),
						demo: 0,
						stat: this.statisticChoice,
					}
				}
				if (sendob !== null) {
					this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
						this.isFetching = false
						this.store.showLoader = false
						if (ret.surveys) this.hookHistorySurveys = ret.surveys
						if (ret.hookResults) this.hookHistoryResults = ret.hookResults
					}.bind(this))
				}
			}
		},
	},
	watch: {
		selectedHookOb() {
			if(this.selectedHookOb.id > 0) this.getHookHistoryResults()
		},
		'store.sessionOk'() {
			if(store.sessionOk && store.user.superUser !== 1 && store.user.perms.dash !== 1) {
				this.$router.push('/error/no-access');
			}
		},
	},
	mounted() {
		if(store.sessionOk && store.user.superUser !== 1 && store.user.perms.dash !== 1) {
			this.$router.push('/error/no-access');
		}
		let urlparams = this.$route.query //GET params
		if(urlparams.hooklist) {
			this.lookupTypeChoice = 'hooklist'
			//this.selectedSurvIds = [urlparams.hooklist]
		}
		this.getInitialData()
		this.getUserSurveys()
	},
	created() {
		document.title = "Song Reports";
		this.store.activeLmenuLink = 'songreports';
	}
}
</script>

<style scoped>
.radio-inline {
	margin-right: 10px;
}
.radio-inline input[type="radio"] {
	margin: 0;
}
.checkbox-inline {
	margin-right: 10px;
}
</style>