<template>
	<BlanketOverlayWithPopup v-if="fetchFinished" title="Survey Settings Editor" width="800" v-on:close="closeCancel">
		<template v-slot:main>
			<table>
				<tbody>
				<tr>
					<td><span class="settlbl">Survey ID</span></td>
					<td id="survid" style="padding: 10px 0;">{{survid}}</td>
				</tr>
				<tr>
					<td><span class="settlbl">Internal Name</span></td>
					<td><input type="text" id="survinternalname" class="saver" v-model="surveyOb.survinternalname">
					</td>
					<td><span class="settlbl">Client</span></td>
					<td><select id="client" class="saver selector" v-model="surveyOb.client">
						<option value=""></option>
						<option v-for="cl in clientList" :key="cl" :value="cl">{{cl}}</option>
					</select></td>
				</tr>
				<tr>
					<td><span class="settlbl">Public Name</span></td>
					<td><input type="text" id="survname" class="saver" v-model="surveyOb.survname"></td>
					<td>Panel</td>
					<td><select id="panelChoice" class="saver selector" v-model="surveyOb.panel">
						<option value=""></option>
						<option v-for="pa in panels" :key="pa.shortcode" :value="pa.shortcode">{{pa.title}}</option>
					</select></td>
				</tr>
				<tr>
					<td><span class="settlbl">Type</span></td>
					<td><select id="type" class="saver selector" v-model="surveyOb.type">
						<option v-for="cl in survtypes" :key="cl" :value="cl">{{cl}}</option>
					</select></td>
					<td><span class="settlbl">CATI Room</span></td>
					<td><select id="catiroomid" class="saver selector" v-model="surveyOb.catiroomid">
						<option value=""></option>
						<option v-for="pa in catirooms" :key="pa.id" :value="pa.id">{{pa.title}}</option>
					</select></td>
				</tr>
				<tr>
					<td><span class="settlbl">Result Date</span></td>
					<td><date-picker v-model:value="surveyOb.resultdate" valueType="format" format="DD-MM-YYYY"></date-picker></td>
					<td><span class="settlbl">Market</span></td>
					<td><input type="text" id="market" class="saver" v-model="surveyOb.market"></td>
				</tr>
				<tr></tr>
				<tr>
					<td>Hook List</td>
					<td><select id="hooklist" class="saver selector" v-model="surveyOb.hooklist">
						<option value="0"></option>
						<option v-for="hl in hooklists" :key="hl.id" :value="hl.id">{{hl.ufname}} {{hl.ulname}}: (ListID={{hl.id}}) {{hl.name}}</option>
					</select></td>
					<td><span class="settlbl">Stream (blank if GET)</span></td>
					<td><input type="text" id="stream" class="saver" v-model="surveyOb.stream"></td>
				</tr>
				<tr>
					<td><span class="settlbl">Panel Q Ignore</span></td>
					<td><input type="text" id="panelqignore" class="saver" v-model="surveyOb.panelqignore"></td>
					<td><span class="settlbl">Finish Prize Tag</span></td>
					<td><input type="text" class="saver" id="finishprizetag" v-model="surveyOb.finishprizetag"></td>
				</tr>
				<tr>
					<td><span class="settlbl">Status</span></td>
					<td><select id="status" class="saver selector" v-model="surveyOb.status">
						<option value=""></option>
						<option value="working">Working</option>
						<option value="publish" selected="selected">Publish</option>
						<option value="closed">Closed</option>
						<option value="deleted">Deleted</option>
					</select></td>
					<td><span class="settlbl">Exit URL</span></td>
					<td><input type="text" id="exiturl" class="saver" v-model="surveyOb.exiturl"></td>
				</tr>
				<tr>
					<td><span class="settlbl">End Date</span></td>
					<td><date-picker v-model:value="surveyOb.enddate" valueType="format" format="DD-MM-YYYY"></date-picker></td>
					<td><span class="settlbl">T&amp;C Source</span></td>
					<td><input type="text" id="tcsource" class="saver" v-model="surveyOb.tcsource"></td>
				</tr>
				<tr>
					<td><span class="settlbl" title="For use with CATI and timing estimates">Fieldwork Start</span></td>
					<td><date-picker v-model:value="surveyOb.fieldworkstartdate" valueType="format" format="DD-MM-YYYY"></date-picker></td>
					<td><span class="settlbl" title="For use with CATI and timing estimates">Fieldwork End</span></td>
					<td><date-picker v-model:value="surveyOb.fieldworkenddate" valueType="format" format="DD-MM-YYYY"></date-picker></td>
				</tr>
				<tr>
					<td><span class="settlbl">Quota</span></td>
					<td><input type="text" id="quota" class="saver" v-model="surveyOb.quota"></td>
					<td><span class="settlbl">Timezone</span></td>
					<td><select class="saver selector" id="survtzone" v-model="surveyOb.survtzone">
						<option value="">Blank (Server default to Sydney)</option>
						<option value="Australia/Sydney" selected="selected">Sydney</option>
						<option value="Australia/Brisbane">Brisbane</option>
						<option value="Australia/Adelaide">Adelaide</option>
						<option value="Australia/Perth">Perth</option>
						<option value="Europe/London">London</option>
						<option value="Asia/Singapore">Singapore</option>
						<option value="Pacific/Auckland">Auckland</option>
						<option value="America/Vancouver">Canada Pacific (Vancouver)</option>
						<option value="America/Edmonton">Canada Mountain (Edmonton)</option>
						<option value="America/Winnipeg">Canada Central (Winnipeg)</option>
						<option value="America/Toronto">Canada Eastern (Toronto)</option>
						<option value="America/Halifax">Canada Atlantic (Halifax)</option>
						<option value="America/St_Johns">Canada Newfoundland (St Johns)</option>
					</select></td>
				</tr>
				<tr v-if="this.store.user.superUser === 1">
					<td><span class="settlbl">Integer Responses</span></td>
					<td><select id="integerResponses" class="saver selector" v-model="surveyOb.integerResponses">
						<option value="1">Yes</option>
						<option value="0">No</option>
					</select></td>
					<td><span class="settlbl">Screener Name</span></td>
					<td><input type="text" id="screener" class="saver" v-model="surveyOb.screener"></td>
				</tr>
				<tr v-if="this.store.user.superUser === 1">
					<td><span class="settlbl">Screen Out Code</span></td>
					<td><input class="earncodes saver" type="text" id="screenoutcode" v-model="surveyOb.screenoutcode"></td>
					<td><span class="settlbl">Header Source</span></td>
					<td><input type="text" id="headersource" class="saver" v-model="surveyOb.headersource"></td>
				</tr>
				<tr v-if="this.store.user.superUser === 1">
					<td><span class="settlbl">Completer Code</span></td>
					<td><input class="earncodes saver" type="text" id="completecode" v-model="surveyOb.completecode"></td>
					<td><span class="settlbl">Footer Source</span></td>
					<td><input type="text" id="footsource" class="saver" v-model="surveyOb.footsource"></td>
				</tr>
				<tr v-if="this.store.user.superUser === 1">
					<td><span class="settlbl">Quota Full Code</span></td>
					<td><input class="earncodes saver" type="text" id="fullcode" v-model="surveyOb.fullcode"></td>
					<td><span class="settlbl">Custom Music File</span></td>
					<td><input class="saver" type="text" id="musicfile" v-model="surveyOb.musicfile"></td>
				</tr>
				<tr v-if="this.store.user.superUser === 1">
					<td><span class="settlbl">Project ID</span></td>
					<td><input class="earncodes saver" type="text" id="panelprojectid" v-model="surveyOb.panelprojectid"></td>
					<td><span class="settlbl" title="DMT, if status=Publish">Resume Only</span></td>
					<td><select id="returnOnly" class="saver selector" v-model="surveyOb.returnOnly">
						<option value="0">No</option>
						<option value="1">Yes</option>
					</select></td>
				</tr>
				<tr v-if="this.store.user.superUser === 1">
					<td><span class="settlbl" title="If this survey is RR CATI, is there a linked Online collection SurvId">Linked Online Dataloc</span></td>
					<td><input class="saver" type="text" v-model="surveyOb.linked_online_dataloc"></td>
					<td><span class="settlbl" title="Is this the online component of a RegRatings study">RR Online</span></td>
					<td><select id="returnOnly" class="saver selector" v-model="surveyOb.is_online_rr">
						<option value="0">No</option>
						<option value="1">Yes</option>
					</select></td>
				</tr>
				</tbody>
			</table>
		</template>
		<template v-slot:buttons>
			<button type="button" class="option-button colbtn" @click="saveSurvInfo">Save Info</button>
			<button type="button" class="option-button" @click="closeCancel">Cancel</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css';

export default {
	name: "EditSurveyScreen",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
		DatePicker,
	},
	props: {
		survid: Number,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,
			fetchFinished: false,
			atestdate: null,
			surveyOb: {
				survinternalname: '',
				survname: '',
				type: '',
				hooklist: '',
				status: '',
				enddate: '',
				fieldworkstartdate: '',
				fieldworkenddate: '',
				quota: 0,
				integerResponses: 0,
				client: '',
				panel: '',
				catiroomid: 0,
				market: '',
				stream: '',
				panelqignore: 0,
				finishprizetag: '',
				resultdate: '',
				exiturl: '',
				tcsource: '',
				survtzone: '',
				screener: '',
				headersource: '',
				footsource: '',
				musicfile: '',
				returnOnly: 0,
				screenoutcode: '',
				fullcode: '',
				completecode: '',
				panelprojectid: '',
				is_online_rr: 0,
				linked_online_dataloc: 0,
			},
			clientList: [],
			panels: [],
			catirooms: [],
			hooklists: [],
			survtypes: ["OMT","Callout","Perceptual","CATI","CATI_External","RegRatings"],
		}
	},
	computed: {},
	methods: {
		closeCancel() {
			this.$emit('close');
		},
		saveSurvInfo() {
			if (this.isFetching === false && this.survid > 0) {
				this.store.showLoader = true;
				this.fetchFinished = false;
				this.isFetching = true;
				let targ = this.store.urlroot + "central/post-vue/home/save-survey-info";
				let sendob = {survid: this.survid, survob: this.surveyOb};
				this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
					this.isFetching = false;
					this.fetchFinished = true;
					this.store.showLoader = false;
					this.$emit('closerefresh');
				}.bind(this));
			}
		},
		getSurvInfo() {
			if (this.isFetching === false && this.survid > 0) {
				this.store.showLoader = true;
				this.fetchFinished = false;
				this.isFetching = true;
				let targ = this.store.urlroot + "central/post-vue/home/get-survey-info";
				let sendob = {survid: this.survid};
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false;
					if (ret.survey) {
						this.hooklists = ret.hooklists;
						this.clientList = ret.clientList;
						this.panels = ret.panels;
						this.catirooms = ret.catirooms;
						this.surveyOb = ret.survey;
					}
					this.fetchFinished = true;
					this.store.showLoader = false;
				}.bind(this));
			}
		},
	},
	watch: {
		survid() {
			this.getSurvInfo();
		},
	},
	mounted() {
		this.getSurvInfo();
	},
}
</script>

<style scoped>
.saver {
	width: 200px;
}
table tr td:nth-of-type(2) {
	padding-right: 50px;
}
.selector {
	width: 216px;
}
#survid {
	padding-left: 13px;
}
.mx-datepicker {
	width: 216px;
}
</style>