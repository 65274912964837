<template>
    <div class="file-upload">
        <input type="file" @change="handle_file">
        <div v-if="file" class="label w-file">
            <span>{{ file.name }}</span>
        </div>
        <div v-else class="label no-file">
            <h5>Upload a file</h5>
            <p>Drag and drop files here</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "UploadFile",
    data() {
        return {
            file: null
        }
    },
    methods: {
        handle_file( evt ) {
            if( evt.target.files.length ) {
                this.file = evt.target.files[0]
                this.$emit( 'file-changed', evt.target.files[0] )
            }
        }
    },
    created() {

    }
}
</script>

<style scoped>
.file-upload {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 30px;
    border: 2px dashed #000;
}

.file-upload input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.label h5 {
    font-weight: 700;
}

.label p {
    margin: 0;
}
</style>