<template>
	<div class="wl-admin-container">

		<AnalysisEditPopup v-if="showEditAnalysisPopup" @close="closeEditAnalysisPopup($event)"></AnalysisEditPopup>

		<div>
			<div style="margin-bottom: 20px;">
				<input type="text" placeholder="Search by name" v-model="filterText" class="wl-input-text wl-input-padding-large" />
				<button @click="addNewAnalysis" style="margin-left: 15px;" type="button" class="option-button colbtn">Add New</button>
			</div>
			<table class="altbl noborderv">
				<thead>
					<tr class='headrow'>
						<td class="headingtext">ID</td>
						<td class="headingtext">Title</td>
						<td class="headingtext ctr">DataLoc</td>
						<td class="headingtext ctr">DemoGroups</td>
						<td class="headingtext ctr">Enabled</td>
						<td class="headingtext ctr">Survey Default</td>
						<td class="headingtext ctr">Config File</td>
						<td class="headingtext ctr">Functions</td>
					</tr>
				</thead>
				<tbody v-if="this.store.analysesList.length > 0">
					<tr v-for="a in filteredAnalyses" :key="a.id" @click="editAnalysis(a)">
						<td>{{a.id}}</td>
						<td>{{a.title}}</td>
						<td class="ctr">{{a.survid}}</td>
						<td class="ctr">{{a.demogrouporder.length}}</td>
						<td class="ctr">{{boolToYes(a.enabled)}}</td>
						<td class="ctr">{{boolToYes(a.defaultforsurvey)}}</td>
						<td class="ctr">{{a.configfile}}</td>
						<td class="ctr">
							<i class="icon mdi mdi-content-copy"
								@click.stop="cloneAnalysis(a)"
								title="Clone"
							></i>
							<i class="icon mdi mdi-office-building-outline"
								@click.stop="runWeightingforAnalysis(a)"
								title="Generate Weights"
							></i>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import AnalysisEditPopup from "@/components/analysis-admin/AnalysisEditPopup.vue";

export default {
	name: "AnalysisList",
	components: {
		AnalysisEditPopup

	},
	props: {},
	mixins: [globalMixin],
	data: function() {
		return {
			store: store,
			filterText: '',

			showEditAnalysisPopup: false,


		}
	},
	computed: {
		filteredAnalyses() {
			if(this.filterText.length > 0) { //either already selected or matching text
				return this.store.analysesList.filter(item => item.title.toLowerCase().includes(this.filterText.toLowerCase()))
			}
			return this.store.analysesList
		},
	},
	methods: {
		addNewAnalysis() {
			this.store.activeAnalysis = null
			this.showEditAnalysisPopup = true
		},
		editAnalysis(anob) {
			this.store.activeAnalysis = anob
			this.showEditAnalysisPopup = true
		},
		closeEditAnalysisPopup(doUpdate) {
			this.showEditAnalysisPopup = false
			if(doUpdate === true) this.getAnalysisList()
		},
		runWeightingforAnalysis(anob) {
			this.store.showLoader = true
			let targ = this.store.urlroot + "central/post-vue/analysis/generate-weights"
			let sendob = {
				analysisid: anob.id,
			};
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.store.showLoader = false
				if(ret.error) {
					this.showKalertError(ret.error)
				}
				else if(ret.success) {
					this.showKalertSuccess(ret.success)
				}
			}.bind(this))
		},
		cloneAnalysis(anob) {
			let newaddress = prompt("Please enter title of new Analysis");
			if(newaddress.length > 0) {
				let newdataloc = prompt("Please enter the DATALOC of the new Analysis");
				if(newdataloc.length > 0) {
					let targ = this.store.urlroot + "central/post-vue/analysis/clone-analysis"
					let sendob = {
						sourceanalysisid: anob.id,
						title: newaddress,
						destdataloc: newdataloc,
					};
					this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
						this.getAnalysisList()
					}.bind(this))
				}
			}
		},
	},
	mounted() {
		this.getAnalysisList()
	},
}
</script>

<style scoped>
.icon.mdi {
	cursor: pointer;
	font-size: 16px;
	margin-right: 8px;
}
.altbl.noborderv td {
	cursor: default;
}
</style>