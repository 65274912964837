<template>
	<BlanketOverlayWithPopup title="Edit EDM/Campaign" width="1200" v-on:close="closeCancel">
		<template v-slot:main>
			<div class="">
				<span class="edmlbl">Title (internal)</span><input v-model="editCampOb.title" class="usaver edminwide" type="text" /><br/>
				<span class="edmlbl">Email Subject</span><input v-model="editCampOb.subject" class="usaver edminwide" type="text" /><br/>
				<div>
					<span class="edmlbl">List ID</span><span style="margin-right: 20px;"><input type="text" readonly class="usaver" :value="getListNameFromId(editCampOb.listId)" /></span>
					<span class="edmlbl">Status</span>
					<select class="usaver" v-model="editCampOb.status">
						<option value=""></option>
						<option value="working">Working</option>
						<option value="scheduled">Scheduled</option>
						<option value="sending">Sending</option>
						<option value="sent">Sent</option>
					</select>
					<span class="edmlbl">Theme Name</span>
					<select class="usaver" v-model="editCampOb.themeName">
						<option value=""></option>
						<option value="BauerHits">BauerHits</option>
						<option value="BauerAbsolute">BauerAbsolute</option>
						<option value="BauerMagic">BauerMagic</option>
					</select>
				</div>
				<div>
					<span class="edmlbl edmclblcursor">Survey ID</span>
					<select class="usaver" v-model="editCampOb.surveyId">
						<option value="0"></option>
						<option v-for="l in availSurvsNewToOld" :value="l.id" :key="l.id">{{l.name}}</option>
					</select>
					<span class="edmlbl">Template Filename</span>
					<select id="templatefilename" class="usaver">
						<option value="">Default (edm-email-wrapper.php)</option>
					</select>
					<span class="edmlbl edmclblcursor">Segment ID</span>
					<select class="usaver" v-model="editCampOb.segmentId">
						<option value="0"></option>
						<option v-for="l in availsegs" :value="l.id" :key="l.id">{{l.name}}</option>
					</select>
				</div>
				<div>
					<span class="edmlbl">Show CTA Button</span>
					<select class="usaver" v-model="editCampOb.showCTAButton">
						<option :value="1">Yes</option>
						<option :value="0">No</option>
					</select>
					<span class="edmlbl">CTA Button Text</span><input class="usaver" v-model="editCampOb.ctaButtonText" type="text" />
					<span class="edmlbl" title="For stations within network etc">Allow Theme Override</span>
					<select class="usaver" v-model="editCampOb.allowThemeOverride">
						<option :value="0">No</option>
						<option :value="1">Yes</option>
					</select>
				</div>
				<span class="edmlbl">CTA URL</span><input class="usaver edminwide" v-model="editCampOb.ctaButtonLink" type="text" /><br/>
				<span class="edmlbl">Preview Text</span><input class="usaver edminwide" v-model="editCampOb.previewText" type="text" /><br/>
				<div>
					<span class="edmlbl">Send Date (Melb)</span>
					<date-picker v-model:value="editCampOb.sendDate" valueType="format" format="DD-MM-YYYY"></date-picker>
					<span style="margin-left: 20px;" class="edmlbl">Send Time HH:MM 24h (Melb)</span><input class="usaver" v-model="editCampOb.sendTimeHHMM" type="text" />
					<span class="edmlbl">Deleted</span>
					<select class="usaver" v-model="editCampOb.deleted">
						<option :value="0">No</option>
						<option :value="1">Yes</option>
					</select>
				</div>

				<span class="label">Body Content</span>
				<div style="margin: 10px 0;">
					<ckeditor :editor="editorBody" v-model="editCampOb.body"></ckeditor>
				</div>

				<span class="label">Body Content 2</span>
				<div style="margin: 10px 0;">
					<ckeditor :editor="editorBodySec" v-model="editCampOb.bodySec"></ckeditor>
				</div>

				<span class="label">Body Content 3</span>
				<div style="margin: 10px 0;">
					<ckeditor :editor="editorBodyTer" v-model="editCampOb.bodyTer"></ckeditor>
				</div>

				<span class="label">Footer Content</span>
				<div style="margin: 10px 0;">
					<ckeditor :editor="editorFooter" v-model="editCampOb.footerContent"></ckeditor>
				</div>
			</div>
		</template>
		<template v-slot:buttons>
			<button type="button" class="option-button colbtn" @click="saveCampaignInfo">Save Info</button>
			<button type="button" class="option-button colbtn" @click="sendTestEmail">Test Email</button>
			<button type="button" class="option-button" @click="closeCancel">Cancel</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
	name: "EditEDMScreen",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
		DatePicker,
	},
	props: {
		campob: {
			type: [Object, null],
			default: null,
		},
		incominglistid: Number,
		availlists: Array,
		availsurvs: Array,
		availsegs: Array,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			editCampOb: {
				id: null,
				listId: null,
				allowThemeOverride: 1,
				body: '',
				bodySec: '',
				bodyTer: '',
				ctaButtonLink: '',
				ctaButtonText: '',
				footerContent: '',
				previewText: '',
				segmentId: 0,
				sendDate: '',
				sendTimeHHMM: '',
				showCTAButton: 1,
				status: null,
				subject: '',
				surveyId: null,
				templateFilename: '',
				themeName: '',
				title: '',
				deleted: 0,
			},

			editorBody: ClassicEditor,
			editorBodySec: ClassicEditor,
			editorBodyTer: ClassicEditor,
			editorFooter: ClassicEditor,

			availSurvsNewToOld: [],
		}
	},
	computed: {

	},
	methods: {
		closeCancel() {
			this.$emit('close', false);
		},
		saveCampaignInfo() {
			if (this.isFetching === false && this.editCampOb.listId && this.editCampOb.listId > 0) {
				this.store.showLoader = true
				let targ = this.store.urlroot + "central/post-vue/campaigns/save-campaign-info";
				let sendob = {campob: this.editCampOb}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.store.showLoader = false
					if(ret.error) {
						this.showKalertError(ret.error)
					}
					else this.$emit('close', true)
				}.bind(this));
			}
		},
		getListNameFromId(listId) {
			if(!listId) return ''
			let listob = this.availlists.find(item => item.id === listId)
			if(listob) return listob.name
			return ''
		},
		sendTestEmail() {
			let p = window.prompt('Please enter user email address','alan@walkcreative.com.au');
			if(p.length > 0 && p.includes("@")) {
				let emval = p.toLowerCase().trim();
				this.store.showLoader = true
				let targ = this.store.urlroot + "central/post-vue/campaigns/send-test";
				let sendob = {edmid: this.editCampOb.id, emailAddress: emval}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.store.showLoader = false
					if(ret.error) this.showKalertError(ret.error)
					if(ret.success) this.showKalertSuccess({message: 'Sent successfully'})
					else this.$emit('close', true)
				}.bind(this));
			}
			else {
				alert("Please enter a valid email address")
			}
		},
	},
	watch: {

	},
	mounted() {
		if(this.campob) {
			this.editCampOb = JSON.parse(JSON.stringify(this.campob))
		}
		else {
			this.editCampOb.listId = this.incominglistid
		}
		if(this.availsurvs) this.availSurvsNewToOld = JSON.parse(JSON.stringify(this.availsurvs)).reverse()
	},
}
</script>

<style scoped>
.saver {
	width: 200px;
}
table tr td:nth-of-type(2) {
	padding-right: 50px;
}
.wl-popup select {
	width: 216px;
}
.mx-datepicker {
	width: 216px;
}
.edmlbl {
	padding-right: 10px;
	width: 135px;
}
input, select {
	margin-bottom: 10px;
	margin-right: 20px;
}
input.edminwide {
	width: 80.2%;
}
#cke_body, #cke_bodysec, #cke_bodyter, #cke_footercontent {
	margin-top: 10px;
}
</style>