<template>
	<div>

		<FreqUserGroupEditPopup v-if="editGroupPopupShown" :grobin="selectedGroupOb" v-on:close="editGroupPopupShown = false" v-on:closerefresh="closePopupAfterSaveAndRefresh"></FreqUserGroupEditPopup>

		<div class="altable-holder">
			<div style="display: flex; align-items: center; justify-content: flex-start; padding: 5px 0;">
				<div class="" style="margin: 5px 0;">
					<input type="text" style="width: 300px;" class="wl-input-text wl-input-padding-large" v-model="filterText" placeholder="Search groups..." />
				</div>
				<button @click="addNewGroup" style="margin-left: 15px;" type="button" class="option-button colbtn">Add New Group</button>
			</div>
			<br/>
			<table class="altbl noborderv">
				<thead>
					<tr>
						<td v-for="col in cols" :key="col.title" class="headingtext" @click="sortColumn(col.fldid)" data-f-bold="true">
							<span>{{col.title}}</span>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="rowitem in rowsForPage" :key="rowitem.id" @click="editGroup(rowitem)" style="cursor: pointer;" :class="{boldme : rowitem.isSystemGroup}">
						<td>{{rowitem.id}}</td>
						<td>{{rowitem.name}}</td>
						<td>{{getUserNameFromUid(rowitem.managers)}}</td>
						<td class="ctr">{{rowitem.mobLicIds.length}}</td>
						<td class="ctr">{{rowitem.desktopIds.length}}</td>
						<td class="ctr">{{rowitem.toplineIds.length}}</td>
						<td class="ctr">{{rowitem.userMemberIds.length}}</td>
					</tr>
				</tbody>
			</table>

			<div style="margin: 15px 0; display: flex; align-items: center;">
				<ALTablePaginator
						:paginationpage="paginationPage"
						:paginatedrowscount="paginatedRows.length"
						v-on:nextpage="nextpage" v-on:prevpage="prevpage"
						v-on:setpage="paginationPage = $event"
				></ALTablePaginator>
			</div>

		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import FreqUserGroupEditPopup from "@/components/freq-usrmgr/FreqUserGroupEditPopup";
import ALTablePaginator from "@/components/ALTablePaginator";

export default {
	name: "FreqUserGroupContent",
	mixins: [globalMixin],
	components: {
		ALTablePaginator,
		FreqUserGroupEditPopup
	},
	props: {},
	data: function () {
		return {
			store: store,

			// table stuff
			filterText: '',
			sortColName: null,
			sortColOrder: 'desc',
			paginationRowsPerPage: 20,
			paginationPage: 1,
			cols: [
				{title: 'ID', sortable: true, fldid: 'id'},
				{title: 'Name', sortable: true, fldid: 'name'},
				{title: 'Owner', sortable: true, fldid: ''},
				{title: 'Mob Licenses', sortable: false, fldid: ''},
				{title: 'Full Licenses', sortable: false, fldid: ''},
				{title: 'Topline Licenses', sortable: false, fldid: ''},
				{title: 'Members', sortable: false, fldid: ''},
			],
			//end table stuff

			editGroupPopupShown: false,
			selectedGroupOb: {},

		}
	},
	computed: {
		//table stuff
		rows() {
			let rows = this.store.rrUserGroups
			return this.tblPrepareFilteredRows(rows, this.filterText, this.sortColName, this.sortColOrder)
		},
		paginatedRows() {
			return this.tblGetPaginatedRows(this.rows, this.paginationRowsPerPage)
		},
		rowsForPage() {
			if(!this.paginationRowsPerPage || !this.paginationPage) return [this.rows]
			return this.paginatedRows[this.paginationPage-1]
		},
		//end table stuff
	},
	methods: {
		//table stuff
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
		prevpage() {
			if(this.paginationPage > 1) this.paginationPage--
		},
		nextpage() {
			if(this.paginationPage < this.paginatedRows.length) this.paginationPage++
		},
		//end table stuff
		addNewGroup() {
			let p = window.prompt('Please enter new group name');
			if(p) {
				this.selectedGroupOb = {
					id: 0,
					name: p,
				}
				this.editGroupPopupShown = true;
			}
			else {
				alert("Please enter a valid group name")
			}
		},
		editGroup(groupob) {
			this.selectedGroupOb = groupob;
			this.editGroupPopupShown = true;
		},
		closePopupAfterSaveAndRefresh() {
			this.selectedGroupOb = null;
			this.editGroupPopupShown = false;
			this.getRRAdminInfo(); //from mixin - refreshes admin content for lists etc
		},
	},
	watch: {
		rows() { //whenever filter text or sort is changed, go back to Page 1 in pagination
			this.paginationPage = 1
		},
	},
	mounted() {

	}
}
</script>

<style scoped>

</style>