<template>
	<div>
		<div class="altable-holder">
			<table class="altbl noborderv">
				<thead>
				<tr>
					<td class="headingtext" @click="sortColumn('artist')" data-f-bold="true">
						<span>Artist</span>
					</td>
					<td class="headingtext" @click="sortColumn('title')" data-f-bold="true">
						<span>Title</span>
					</td>
					<td class="headingtext" @click="sortColumn('year')" data-f-bold="true">
						<span>Year</span>
					</td>
					<td class="headingtext" @click="sortColumn('popindex')" data-f-bold="true">
						<span>Score</span>
					</td>
					<td class="headingtext" @click="sortColumn('totpos')" data-f-bold="true">
						<span>%Pos</span>
					</td>
					<td class="headingtext" @click="sortColumn('love')" data-f-bold="true">
						<span>%Love</span>
					</td>
					<td class="headingtext" @click="sortColumn('like')" data-f-bold="true">
						<span>%Like</span>
					</td>
					<td class="headingtext" @click="sortColumn('ok')" data-f-bold="true">
						<span>%OK</span>
					</td>
					<td class="headingtext" @click="sortColumn('dislike')" data-f-bold="true">
						<span>%Dislike</span>
					</td>
					<td class="headingtext" @click="sortColumn('hate')" data-f-bold="true">
						<span>%Hate</span>
					</td>
					<td class="headingtext" @click="sortColumn('tired')" data-f-bold="true">
						<span>%Tired</span>
					</td>
					<td class="headingtext" @click="sortColumn('unfam')" data-f-bold="true">
						<span>%Unfam</span>
					</td>
					<td class="headingtext" data-f-bold="true">
						<span>Hist</span>
					</td>
				</tr>
				</thead>
				<tbody>
				<tr v-for="rowitem in rows" :key="rowitem.hookid" style="cursor: default;">
					<td>{{rowitem.artist}}</td>
					<td>{{rowitem.title}}</td>
					<td>{{rowitem.year}}</td>
					<td class="ctr">{{numdisplayNdp(rowitem.popindex,2)}}</td>
					<td class="ctr">{{numdisplayNdp(rowitem.totpos,2)}}</td>
					<td class="ctr">{{numdisplayNdp(rowitem.love,2)}}</td>
					<td class="ctr">{{numdisplayNdp(rowitem.like,2)}}</td>
					<td class="ctr">{{numdisplayNdp(rowitem.ok,2)}}</td>
					<td class="ctr">{{numdisplayNdp(rowitem.dislike,2)}}</td>
					<td class="ctr">{{numdisplayNdp(rowitem.hate,2)}}</td>
					<td class="ctr">{{numdisplayNdp(rowitem.tired,2)}}</td>
					<td class="ctr">{{numdisplayNdp(rowitem.unfam,2)}}</td>
					<td class="ctr">
						<span class="action-icon" title="View history chart" @click.stop="$emit('hookhistory',rowitem.hookid)"><i class="mdi mdi-chart-line"></i></span>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
import {globalMixin} from "@/mixins";
import {store} from "@/store";

export default {
	name: "DashMusicTable",
	mixins: [globalMixin],
	components: {

	},
	props: {
		results: {
			type: Array,
			default: () => []
		},
	},
	data: function () {
		return {
			store: store,

			// table stuff
			filterText: '',
			sortColName: null,
			sortColOrder: 'desc',
			//end table stuff
		}
	},
	computed: {
		//table stuff
		rows() {
			return this.tblPrepareFilteredRows(this.results, this.filterText, this.sortColName, this.sortColOrder)
		},
		//end table stuff
	},
	methods: {
		//table stuff
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
		//end table stuff
	},
	watch: {

	},
	created() {

	},
}
</script>
<style scoped></style>