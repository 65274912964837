<template>
	<BlanketOverlayWithPopup v-if="survobin && survobin.id >= 0" title="Survey Permissions" width="800" :hastabs="true" v-on:close="closeCancel">
		<template v-slot:tabs>
			<p style="font-weight: 500; font-size: 12px; margin: -15px 0 15px;">{{survobin.name}}</p>
			<div class="popup-tabs">
				<div class="popup-tab" :class="{active : activeTab === 'users'}" @click="activeTab = 'users'">Users</div>
				<div class="popup-tab" :class="{active : activeTab === 'groupperms'}" @click="activeTab = 'groupperms'">Groups</div>
			</div>
		</template>
		<template v-slot:main>
			<div v-if="activeTab === 'users'" class="contained-popup-content" style="position: relative; height: 500px; width: calc(800px - 60px);">
				<div style="display: flex; justify-content: space-between; position: fixed; width: inherit; background-color: #FFF;">
					<div style="width: 49%; height: 20px;">
						<p class="selectable-header">
							Available Users
							<input type="text" class="selectable-searchbox" v-model="usersrchtext" />
						</p>
					</div>
					<div style="width: 49%; height: 20px;">
						<p class="selectable-header">Assigned To Survey</p>
					</div>
				</div>
				<div style="display: flex; justify-content: space-between; margin-top: 30px;">
					<div style="width: 49%">
						<div v-for="gr in usersNotAssigned" :key="gr.id" class="selectable-item">
							<span>{{gr.email}}</span>
							<span class="add" @click="toggleMembership('add', 'user', gr.id)">Add</span>
						</div>
					</div>
					<div style="width: 49%">
						<div v-for="gr in usersAssigned" :key="gr.id" class="selectable-item">
							<span>{{gr.email}}</span>
							<span class="rem" @click="toggleMembership('rem', 'user', gr.id)">X</span>
						</div>
					</div>
				</div>
			</div>
			<div v-if="activeTab === 'groupperms'" class="contained-popup-content" style="position: relative; height: 500px; width: calc(800px - 60px);">
				<div style="display: flex; justify-content: space-between; position: fixed; width: inherit; background-color: #FFF;">
					<div style="width: 49%; height: 20px;">
						<p class="selectable-header">
							Available Groups
							<input type="text" class="selectable-searchbox" v-model="grsrchtext" />
						</p>
					</div>
					<div style="width: 49%; height: 20px;">
						<p class="selectable-header">Assigned To Survey</p>
					</div>
				</div>
				<div style="display: flex; justify-content: space-between; margin-top: 30px;">
					<div style="width: 49%">
						<div v-for="gr in groupsNotAssigned" :key="gr.id" class="selectable-item">
							<span :class="{boldme : gr.isSystemGroup === 1}">{{gr.name}}</span>
							<span class="add" @click="toggleMembership('add', 'group', gr.id)">Add</span>
						</div>
					</div>
					<div style="width: 49%">
						<div v-for="gr in groupsAssigned" :key="gr.id" class="selectable-item">
							<span :class="{boldme : gr.isSystemGroup === 1}">{{gr.name}}</span>
							<span class="rem" @click="toggleMembership('rem', 'group', gr.id)">X</span>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-slot:buttons>
<!--			<button type="button" class="option-button colbtn" @click="saveInfo">Save Info</button>-->
			<button type="button" class="option-button" @click="closeCancel">Cancel</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'

export default {
	name: "FreqSurvUserAssignPopup",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		survobin: Object,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			activeTab: 'users',

			grsrchtext: '',
			usersrchtext: '',

			// survEditOb: {},

			userIdsAssigned: [],
			groupIdsAssigned: [],
		}
	},
	computed: {
		groupsAssigned() {
			return this.store.rrUserGroups.filter(item => this.groupIdsAssigned.includes(item.id))
		},
		groupsNotAssigned() {
			if(this.grsrchtext.length === 0) {
				return this.store.rrUserGroups.filter(item => !this.groupIdsAssigned.includes(item.id))
			}
			else {
				return this.store.rrUserGroups.filter(item => !this.groupIdsAssigned.includes(item.id) && item.name.toLowerCase().includes(this.grsrchtext.toLowerCase()))
			}
		},
		usersAssigned() {
			return this.store.rrUsers.filter(item => this.userIdsAssigned.includes(item.id))
		},
		usersNotAssigned() {
			if(this.usersrchtext.length === 0) {
				return this.store.rrUsers.filter(item => !this.userIdsAssigned.includes(item.id))
			}
			else {
				return this.store.rrUsers.filter(item => !this.userIdsAssigned.includes(item.id) && item.email.toLowerCase().includes(this.usersrchtext.toLowerCase()))
			}
		},
	},
	methods: {
		toggleMembership(action, type, itemid) {
			//action=add,rem, type=user,group
			let targ = this.store.urlroot + "central/post-vue/regional-ratings-admin/save-survey-membership"
			let sendob = {
				suid: this.survobin.id,
				action: action,
				type: type,
				itemid: itemid,
			}
			this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
				this.isFetching = false
				this.populateContent()
			}.bind(this));
		},
		closeCancel() {
			this.$emit('close');
		},
		populateContent() {
			if(this.survobin) {
				let targ = this.store.urlroot + "central/post-vue/regional-ratings-admin/get-rrusers-for-survey"
				let sendob = {suid: this.survobin.id}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					this.store.showLoader = false
					if(ret.userIds) this.userIdsAssigned = ret.userIds
					if(ret.groupIds) this.groupIdsAssigned = ret.groupIds
				}.bind(this));
			}
		},
	},
	watch: {
		survobin() {
			this.populateContent()
		},
	},
	mounted() {
		this.populateContent()
	},
}
</script>

<style scoped>
.saver {
	width: 200px;
}
table tr td:nth-of-type(2) {
	padding-right: 50px;
}
.selector {
	width: 216px;
}
.mx-datepicker {
	width: 216px;
}
</style>