<template>
	<BlanketOverlayWithPopup title="Manage Matrix Flows" width="670" v-on:close="closeCancel">
		<template v-slot:main>
			<div class="">
				<table>
					<tr class="boldme">
						<td>Matrix ID</td>
						<td>Case</td>
						<td>ParentQ</td>
						<td>Option</td>
						<td>Edit</td>
						<td>Del</td>
					</tr>
					<tr v-for="mf in matrixFlows" :key="mf.flowid">
						<td>{{mf.matrixID}}</td>
						<td>{{mf.caseQID}}</td>
						<td>{{mf.parentID}}</td>
						<td>{{mf.parentOpt}}</td>
						<td class="clickable" @click="$emit('editmatrixflow', mf)">Edit</td>
						<td class="clickable" @click="deleteMatrixFlow(mf.flowid)">Del</td>
					</tr>
				</table>

			</div>
		</template>
		<template v-slot:buttons>
			<button type="button" class="option-button colbtn" @click="$emit('createnew')">New</button>
			<button type="button" class="option-button" @click="closeCancel">Close</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup.vue";

export default {
	name: 'MatrixFlowManager',
	components: {BlanketOverlayWithPopup},
	mixins: [globalMixin],
	props: {
		survob: {
			type: Object,
			required: false,
			default: null,
		},
	},
	data() {
		return {
			store: store,
			isFetching: false,

		}
	},
	computed: {
		matrixFlows() {
			if(this.survob && this.survob.matrixFlows) {
				return this.survob.matrixFlows
			}
			return []
		},
	},
	methods: {
		closeCancel() {
			this.$emit('close');
		},
		deleteMatrixFlow(flowid) {
			if(flowid && !this.isFetching) {
				let c = confirm("You are about to delete this Matrix Flow, are you sure?");
				if (c === true) {
					let targ = this.store.urlroot + "central/post-vue/questions/delete-matrixflow"
					let sendob = {
						survid: this.survob.survid,
						mflowid: flowid,
					}
					this.isFetching = true
					this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
						this.isFetching = false
						this.$emit('update', ret)
					}.bind(this))
				}
			}
		},
	},
	watch: {

	},
	mounted() {

	},
}
</script>

<style scoped>
.clickable {
	cursor: pointer;
}
tr td {
	padding: 3px 10px 3px 0;
}
</style>
