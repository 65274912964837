<template>
	<div class="bgpage" style="min-height: 100vh;">
		<div id="app-container">

			<div style="padding-top: 50px;">

				<div id="logo-holder">
					<a href="https://app.yourwavelength.com">
						<img src="@/assets/wavelength_logo.png" alt="Logo" />
					</a>
				</div>
				<div id="form-wrapper">
					<p class="wl-main-headline">{{headerNotes}}</p>
					<p v-if="mainText.length > 0" class="wl-sub-headline">{{mainText}}</p>
				</div>

			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: 'ErrorPage',
	components: {

	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

			errcode: null,
		}
	},
	computed: {
		headerNotes() {
			if(this.errcode === 'no-access') return 'No Access'
			return '404 Error'
		},
		mainText() {
			if(this.errcode === 'no-access') return 'You do not have access to this page.'
			return 'The page you are looking for does not exist'
		},
	},
	methods: {

	},
	watch: {

	},
	props: {

	},
	mounted() {
		document.title = "Wavelength - Error";
	},
	created() {
		let urlparams = this.$route.params
		if(urlparams.errorid !== undefined) {
			this.errcode = urlparams.errorid
		}
	},
}
</script>

<style scoped>
.bgpage {
	background-image: url('~@/assets/feature-background.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
#app-container {
	width: 100%;
	max-width: 500px;
	margin: 0 auto;
}
#logo-holder {
	text-align: center;
	background-color: #0f75a5;
	padding: 30px 0;
}
#logo-holder img {
	height: 60px;
}
#form-wrapper {
	padding: 60px;
	margin-bottom: 80px;
	box-shadow: 10px 0 40px 6px rgba(0,0,0,.08);
	background: #FFFFFF;
	text-align: left;
}

</style>
