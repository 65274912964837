import axios from "axios";

export const globalMixin = {
	methods: {
		showKalert(ob, autoclose = false) {
			this.store.kalert.message = ob.message;
			this.store.kalert.type = ob.type;
			this.store.kalert.shown = true;
			if(autoclose === true) {
				setTimeout(function() {
					this.store.kalert.shown = false;
				}.bind(this), 3000);
			}
		},
		showKalertError(ob, autoclose = false) {
			this.store.kalert.message = ob.message;
			this.store.kalert.type = 'danger';
			this.store.kalert.shown = true;
			if(autoclose === true) {
				setTimeout(function() {
					this.store.kalert.shown = false;
				}.bind(this), 3000);
			}
		},
		showKalertSuccess(ob, autoclose = false) {
			this.store.kalert.message = ob.message;
			this.store.kalert.type = 'success';
			this.store.kalert.shown = true;
			if(autoclose === true) {
				setTimeout(function() {
					this.store.kalert.shown = false;
				}.bind(this), 3000);
			}
		},
		closeAllMenus() {
			this.store.forceMenusClose++;
		},
		simplePost(targ, sendob, alertOnError = true) {
			sendob.uid = this.store.user.id;
			sendob.wlsesstoken = this.store.wlsesstoken;
			return axios.post(targ, JSON.stringify(sendob)).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (alertOnError === true && ret.error) {
					this.showKalertError(ret.error);
				}
				return ret;
			}.bind(this)).catch(function (error) {
				console.log(error);
			});
		},
		clearLoginCredsAndSession() {
			window.localStorage.removeItem('wlSessToken');
			window.localStorage.removeItem('wlUserId');
			this.store.user = {
				id: 0,
				email: '',
			};
		},
		getRRAdminInfo() {
			let targ = this.store.urlroot + "central/post-vue/freq/get-admin-meta";
			let sendob = {};
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				if(ret.surveys) this.store.rrSurveys = ret.surveys
				if(ret.mobilelicenses) this.store.rrMobileLicenses = ret.mobilelicenses
				if(ret.rrusergroups) this.store.rrUserGroups = ret.rrusergroups
				if(ret.rrusers) this.store.rrUsers = ret.rrusers
				if(ret.markets) this.store.rrMarkets = ret.markets
				if(ret.rrstations) {
					let stns = []
					for(let s of ret.rrstations) {
						let mkid = s.mktid
						let mkob = this.store.rrMarkets.find(item => item.id === mkid)
						if(mkob) s.marketName = mkob.name
						stns.push(s)
					}
					this.store.rrStations = stns
				}
				this.store.showLoader = false
			}.bind(this));
		},
		intToYes: function (str, noval = false) {
			if (str) {
				if (parseInt(str) === 1) return "Yes";
			}
			if(noval === true) return 'No'
			return '';
		},
		ucfirst: function (str) {
			return str[0].toUpperCase() + str.substring(1);
		},
		genRandomString(string_length = 8) {
			let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
			let randomstring = '';
			for (var i=0; i<string_length; i++) {
				let rnum = Math.floor(Math.random() * chars.length);
				randomstring += chars.substring(rnum,rnum+1);
			}
			return randomstring
		},
		getUserNameFromUid(uid) {
			let uob = this.store.rrUsers.find(item => item.id === uid)
			if(uob) {
				return uob.email.split('@')[0]
			}
			return 'System'
		},
		boolToYes(val) {
			if(val === true) return 'Yes'
			return ''
		},
		//tables stuff
		tblPrepareFilteredRows(rows, filterText = '', sortColName = null, sortColOrder = 'desc') {
			if(filterText.length > 0) { //add any filter by text input here
				let newer = []
				let sboxval = filterText.toLowerCase()
				let sboxarr = sboxval.split(' ')
				for (let r of rows) {
					let hasMatches= []
					for(let sbox of sboxarr) {
						let thisMatch = 0
						for (let fld in r) {
							if(typeof(r[fld]) === 'string' && (isNaN(r[fld]) || parseFloat(r[fld]) != r[fld])) {
								if (r[fld] && r[fld].toLowerCase().indexOf(sbox) > -1) {
									thisMatch = 1
								}
							}
						}
						hasMatches.push(thisMatch)
					}
					if(!hasMatches.includes(0)) {
						newer.push(r);
					}
				}
				rows = newer;
			}
			if(sortColName) {
				let useStrings = false;
				if(isNaN(rows[0][sortColName]) || parseFloat(rows[0][sortColName]) != rows[0][sortColName]) useStrings = true;
				if(sortColOrder === 'asc') {
					if(useStrings === true) {
						rows = rows.sort((a, b) => (a[sortColName].toLowerCase() > b[sortColName].toLowerCase() ? 1 : -1))
					}
					else rows = rows.sort((a, b) => (a[sortColName] > b[sortColName]) ? 1 : -1)
				}
				else {
					if(useStrings === true) rows = rows.sort((a, b) => (a[sortColName].toLowerCase() > b[sortColName].toLowerCase() ? -1 : 1))
					else rows = rows.sort((a, b) => (a[sortColName] > b[sortColName]) ? -1 : 1)
				}
			}
			return rows;
		},
		tblGetPaginatedRows(rows, paginationRowsPerPage = 25) {
			if(!paginationRowsPerPage) return [rows]
			else {
				let tempArr = []
				let tempSubArr = []
				for(let it of rows) {
					tempSubArr.push(it)
					if(tempSubArr.length === paginationRowsPerPage) {
						tempArr.push(tempSubArr)
						tempSubArr = []
					}
				}
				if(tempSubArr.length > 0) tempArr.push(tempSubArr)
				return tempArr
			}
		},
		//end tables stuff
		numdisplay: function (num) {
			if(num == 0) return 0
			if(!num) return null
			return num.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0});
		},
		numdisplayNdp: function (num, numberOfDecplc) {
			if(!num) return null
			if(num == 0) return 0
			return num.toLocaleString('en-US', {minimumFractionDigits: numberOfDecplc, maximumFractionDigits: numberOfDecplc});
		},
		numdisplayKeepExisting: function(num, nDecimals = null) {
			if(!num && num !== 0) return null
			if(this.store.viewPreferences.commasInData === true) {
				if(nDecimals !== null) {
					return num.toLocaleString('en-US', {minimumFractionDigits: nDecimals, maximumFractionDigits: nDecimals});
				}
				return num.toLocaleString('en-US');
			}
			else if(num && !isNaN(num) && nDecimals !== null) { //no commas but need decimals
				return num.toFixed(nDecimals)
			}
			return num
		},
		numOrNull(val) {
			if(!val || val && val.length === 0) val = 0
			val = parseFloat(val)
			if(val === 0) return null
			return val
		},
		numOrZero(val) {
			if(!val || val && val.length === 0) val = 0
			val = parseFloat(val)
			if(val === 0) return 0
			return val
		},
		cleanMSWord(text) {
			var s = text;
			s = s.replace(/[\u2018|\u2019|\u201A]/g, "'"); // smart single quotes and apostrophe
			s = s.replace(/[\u201C|\u201D|\u201E]/g, "'"); // smart double quotes - make single to avoid breaking json
			s = s.replace(/\u2026/g, "..."); // ellipsis
			s = s.replace(/[\u2013|\u2014]/g, "-"); // dashes
			s = s.replace(/\u02C6/g, "^"); // circumflex
			s = s.replace(/\u2039/g, ""); // open angle bracket
			s = s.replace(/\u2022/g, "-"); // replace bullet with dash
			s = s.replace(/[\u02DC|\u00A0]/g, " "); // spaces
			s = s.replace(/</g, ""); // less than
			s = s.replace(/>/g, ""); // greater than
			// s = s.replace(/\n/g, "\\n"); //line break
			return s;
		},
		//analysis admin
		getAnalysisList() {
			let targ = this.store.urlroot + "central/post-vue/analysis/get-list-admin"
			let sendob = {}
			this.isFetching = true
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				if(ret.analyses) this.store.analysesList = ret.analyses
			}.bind(this))
		},

	}

}