import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import VueApexCharts from "vue3-apexcharts";
import CKEditor from '@ckeditor/ckeditor5-vue';

import LoginPage from "@/components/pages/LoginPage";
import LogoutPage from "@/components/pages/LogoutPage";
import ErrorPage from "@/components/pages/ErrorPage";
import CollectionHomePage from "@/components/pages/CollectionHomePage";
import AnalysisPage from "@/components/pages/AnalysisPage";
import AnalysisAdminPage from "@/components/pages/AnalysisAdminPage";
import CATILaunchPage from "@/components/pages/CATILaunchPage";
import ContactListsPage from "@/components/pages/ContactListsPage";
import ContactsPage from "@/components/pages/ContactsPage";
import CustomHooksPage from "@/components/pages/CustomHooksPage";
import DashboardPage from "@/components/pages/DashboardPage";
import EDMsPage from "@/components/pages/EDMsPage";
import FrequencyUserManagerPage from "@/components/pages/FrequencyUserManagerPage";
import HookcentralAdminPage from "@/components/pages/HookcentralAdminPage";
import HookSelectPage from "@/components/pages/HookSelectPage";
import HooklistsPage from "@/components/pages/HooklistsPage";
import ManageScreenersPage from "@/components/pages/ManageScreenersPage";
import QuestionBuilderPage from "@/components/pages/QuestionBuilderPage";
import RegionalRatingsAdmin from "@/components/pages/RegionalRatingsAdmin";
import SongReportsPage from "@/components/pages/SongReportsPage";
import WavelengthUserManagerPage from "@/components/pages/WavelengthUserManagerPage";

//routing
const routes = [
	{path: '/', component: LoginPage},
	{path: '/home', component: CollectionHomePage},
	{path: '/logout', component: LogoutPage},
	{path: '/analysis', component: AnalysisPage},
	{path: '/analysis/:analysisid', component: AnalysisPage},
	{path: '/analysis-admin', component: AnalysisAdminPage},
	{path: '/cati-launch', component: CATILaunchPage},
	{path: '/cati-launch/:surveyid', component: CATILaunchPage},
	{path: '/contact-lists', component: ContactListsPage},
	{path: '/contacts', component: ContactsPage},
	{path: '/contacts/:contactlistid', component: ContactsPage},
	{path: '/custom-hooks', component: CustomHooksPage},
	{path: '/dashboard', component: DashboardPage},
	{path: '/dashboard/:surveyid', component: DashboardPage},
	{path: '/edms', component: EDMsPage},
	{path: '/frequency-user-manager', component: FrequencyUserManagerPage},
	{path: '/hookcentral-admin', component: HookcentralAdminPage},
	{path: '/hook-lists', component: HooklistsPage},
	{path: '/hook-select', component: HookSelectPage},
	{path: '/hook-select/:hooklistid', component: HookSelectPage},
	{path: '/manage-screeners', component: ManageScreenersPage},
	{path: '/question-builder', component: QuestionBuilderPage},
	{path: '/question-builder/:surveyid', component: QuestionBuilderPage},
	{path: '/regional-ratings-admin', component: RegionalRatingsAdmin},
	{path: '/reports', component: SongReportsPage},
	{path: '/user-admin', component: WavelengthUserManagerPage},

	{path: '/error/:errorid', component: ErrorPage},
	{path: '/error', component: ErrorPage},
	{path: '/*', component: ErrorPage},
	{path: '/:notFound', component: ErrorPage},
]

const router = createRouter({
	hashbang: false,
	routes: routes,
	history: createWebHistory(),
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		return {x: 0, y: 0};
	},
});

const app = createApp(App)
app.use(router);
app.use(VueApexCharts);
app.use(CKEditor);
app.mount('#app')
