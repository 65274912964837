<template>
	<BlanketOverlayWithPopup title="New Hook List" width="420" :hastabs="false" v-on:close="closeCancel">
		<template v-slot:main>
			<div v-if="activeTab === 'main'" class="contained-popup-content">
				<br/><br/>
				<table>
					<tbody>
					<tr>
						<td><span class="settlbl">List Name</span></td>
						<td>
							<input type="text" class="saver" v-model="hookListEditOb.nickname">
						</td>
					</tr>
					<tr>
						<td><span class="settlbl">Station</span></td>
						<td>
							<select class="saver selector" v-model="hookListEditOb.station">
								<option v-for="cl in stationnamelist" :key="cl" :value="cl">{{cl}}</option>
							</select>
						</td>
					</tr>
					<tr>
						<td><span class="settlbl">Type</span></td>
						<td>
							<select class="saver selector" v-model="hookListEditOb.type">
								<option value="general">General (DMT or Callout)</option>
								<option value="asis">As-Is Hour (for DMT actual hours)</option>
							</select>
						</td>
					</tr>
					<tr>
						<td><span class="settlbl">Randomise Hooks?</span></td>
						<select class="saver selector" v-model="hookListEditOb.randomise">
							<option value="yes">Yes</option>
							<option value="no">No</option>
						</select>
					</tr>
					</tbody>
				</table>
			</div>

		</template>
		<template v-slot:buttons>
			<button type="button" class="option-button colbtn" @click="saveHookListInfo">Create</button>
			<button type="button" class="option-button" @click="closeCancel">Cancel</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'

export default {
	name: "HookListEditorPopup",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		clonefromhooklistob: Object,
		stationnamelist: Array,
		onbehalfofuserid: {
			type: Number,
			default: 0,
		},
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			activeTab: 'main',

			hookListEditOb: {},
		}
	},
	computed: {

	},
	methods: {
		closeCancel() {
			this.$emit('close');
		},
		saveHookListInfo() {
			if (this.isFetching === false) {
				if(this.hookListEditOb.nickname.length === 0) {
					alert('Please add a name for your new hook list');
					return;
				}
				this.store.showLoader = true
				this.isFetching = true
				let clonefromhooklistid = 0
				if(this.clonefromhooklistob && this.clonefromhooklistob.id > 0) {
					clonefromhooklistid = this.clonefromhooklistob.id
				}
				let targ = this.store.urlroot + "central/post-vue/hooks/create-new-list"
				let sendob = {
					hookListOb: this.hookListEditOb,
					cloneFromHooklistId: clonefromhooklistid,
					onBehalfOfUser: this.onbehalfofuserid,
				}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					this.store.showLoader = false
					this.$emit('close', ret.newlistid)
				}.bind(this));
			}
		},
		populateContent() {
			if(this.stationnamelist && this.stationnamelist.length > 0 && this.clonefromhooklistob && this.clonefromhooklistob.id > 0) {
				this.hookListEditOb = {
					id: 0,
					nickname: '',
					station: this.clonefromhooklistob.station,
					type: this.clonefromhooklistob.type,
					randomise: this.clonefromhooklistob.randomise,
				}
			}
			else if(this.stationnamelist && this.stationnamelist.length > 0 && this.hookListEditOb.station === '') {
				this.hookListEditOb = {
					id: 0,
					nickname: '',
					station: this.stationnamelist[0],
					type: 'general',
					randomise: 'yes',
				}
			}
			else {
				this.hookListEditOb = {
					id: 0,
					nickname: '',
					station: '',
					type: 'general',
					randomise: 'yes',
				}
			}
		},
	},
	watch: {
		clonefromhooklistob() {
			this.populateContent()
		},
	},
	mounted() {
		this.populateContent()
	},
}
</script>

<style scoped>
.saver {
	width: 200px;
}
table tr td:nth-of-type(2) {
	padding-right: 50px;
}
.selector {
	width: 216px;
}

</style>