<template>
	<div>

		<div class="altable-holder">
			<table v-if="rowsForPage && rowsForPage.length > 0" class="altbl noborderv">
				<thead>
					<tr>
						<td v-for="col in cols" :key="col.title" class="headingtext" @click="sortColumn(col.fldid)" data-f-bold="true">
							<span>{{col.title}}</span>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="rowitem in rowsForPage" :key="rowitem.id" style="cursor: pointer;">
						<td>{{rowitem.id}}</td>
						<td>{{rowitem.userEmail}}</td>
						<td>{{rowitem.nickname}}</td>
						<td>{{rowitem.nHooks}}</td>
						<td>
							<span v-if="rowitem.status === 'open'" class="text-link" @click="finaliseList(rowitem.id)" title="Finalise list">{{ucfirst(rowitem.status)}}</span>
							<span v-else-if="rowitem.status === 'complete'" class="text-link" @click="reopenList(rowitem.id)" title="Reopen List">{{ucfirst(rowitem.status)}}</span>
							<span v-else>{{ucfirst(rowitem.status)}}</span>
						</td>
						<td>{{ucfirst(rowitem.randomise)}}</td>
						<td>
							<a :href="store.urlroot+'central/post-vue/hooks/print-hooklist-to-excel?uid='+store.user.id+'&wlsesstoken='+store.wlsesstoken+'&listid='+rowitem.id" title="Export to Excel">
								<i class="mdi mdi-file-excel"></i>
							</a>
						</td>
					</tr>
				</tbody>
			</table>

			<div v-if="rowsForPage && rowsForPage.length > 0" style="margin: 15px 0; display: flex; align-items: center;">
				<ALTablePaginator
						:paginationpage="paginationPage"
						:paginatedrowscount="paginatedRows.length"
						v-on:nextpage="nextpage" v-on:prevpage="prevpage"
						v-on:setpage="paginationPage = $event"
				></ALTablePaginator>
			</div>

		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import ALTablePaginator from "@/components/ALTablePaginator";

export default {
	name: "HooksAdminUserLists",
	mixins: [globalMixin],
	components: {
		ALTablePaginator,

	},
	props: {},
	data: function () {
		return {
			store: store,

			// table stuff
			filterText: '',
			sortColName: null,
			sortColOrder: 'desc',
			paginationRowsPerPage: 20,
			paginationPage: 1,
			cols: [
				{title: 'ID', sortable: true, fldid: 'id'},
				{title: 'Email', sortable: true, fldid: ''},
				{title: 'List Name', sortable: true, fldid: ''},
				{title: 'Hooks', sortable: true, fldid: ''},
				{title: 'Status', sortable: true, fldid: ''},
				{title: 'Rand', sortable: false, fldid: ''},
				{title: 'Download', sortable: false, fldid: null},
			],
			//end table stuff
			rows: [],

		}
	},
	computed: {
		//table stuff
		paginatedRows() {
			return this.tblGetPaginatedRows(this.rows, this.paginationRowsPerPage)
		},
		rowsForPage() {
			if(!this.paginationRowsPerPage || !this.paginationPage) return [this.rows]
			return this.paginatedRows[this.paginationPage-1]
		},
		//end table stuff
	},
	methods: {
		//table stuff
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
		prevpage() {
			if(this.paginationPage > 1) this.paginationPage--
		},
		nextpage() {
			if(this.paginationPage < this.paginatedRows.length) this.paginationPage++
		},
		//end table stuff
		getUserLists() {
			let targ = this.store.urlroot + "central/post-vue/hooks/admin-get-user-lists"
			let sendob = {}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.rows = ret.hooklists
			}.bind(this));
		},
		finaliseList(listid) {
			let c = confirm("Are you sure you want to FINALISE this list?")
			if(c === true) {
				let targ = this.store.urlroot + "central/post-vue/hooks/finalise-list-admin"
				let sendob = {listid: listid}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					let updatedList = ret.hookList
					let listIndex = this.rows.findIndex(x => x.id === updatedList.id)
					this.rows.splice(listIndex, 1, updatedList)
				}.bind(this));
			}
		},
		reopenList(listid) {
			let c = confirm("Are you sure you want to RE-OPEN this list?")
			if(c === true) {
				let targ = this.store.urlroot + "central/post-vue/hooks/reopen-list-admin"
				let sendob = {listid: listid}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					let updatedList = ret.hookList
					let listIndex = this.rows.findIndex(x => x.id === updatedList.id)
					this.rows.splice(listIndex, 1, updatedList)
				}.bind(this));
			}
		},
	},
	watch: {
		rows() { //whenever filter text or sort is changed, go back to Page 1 in pagination
			this.paginationPage = 1
		},
	},
	mounted() {
		this.getUserLists()
	}
}
</script>

<style scoped>

</style>