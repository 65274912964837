<template>
	<div>

		<div class="altable-holder">
			<table v-if="rowsForPage && rowsForPage.length > 0" class="altbl noborderv">
				<thead>
					<tr>
						<td v-for="col in cols" :key="col.title" class="headingtext" @click="sortColumn(col.fldid)" data-f-bold="true">
							<span>{{col.title}}</span>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="rowitem in rowsForPage" :key="rowitem.id" style="cursor: pointer;">
						<td>{{rowitem.source_user}}</td>
						<td>{{rowitem.dest_user}}</td>
					</tr>
				</tbody>
			</table>

			<div v-if="rowsForPage && rowsForPage.length > 0" style="margin: 15px 0; display: flex; align-items: center;">
				<ALTablePaginator
						:paginationpage="paginationPage"
						:paginatedrowscount="paginatedRows.length"
						v-on:nextpage="nextpage" v-on:prevpage="prevpage"
						v-on:setpage="paginationPage = $event"
				></ALTablePaginator>
			</div>

		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import ALTablePaginator from "@/components/ALTablePaginator";

export default {
	name: "HooksAdminUserLinks",
	mixins: [globalMixin],
	components: {
		ALTablePaginator,

	},
	props: {},
	data: function () {
		return {
			store: store,

			// table stuff
			filterText: '',
			sortColName: null,
			sortColOrder: 'desc',
			paginationRowsPerPage: 20,
			paginationPage: 1,
			cols: [
				{title: 'Source User', sortable: true, fldid: 'source_user'},
				{title: 'Dest User', sortable: true, fldid: 'dest_user'},
			],
			//end table stuff
			rows: [],

		}
	},
	computed: {
		//table stuff
		paginatedRows() {
			return this.tblGetPaginatedRows(this.rows, this.paginationRowsPerPage)
		},
		rowsForPage() {
			if(!this.paginationRowsPerPage || !this.paginationPage) return [this.rows]
			return this.paginatedRows[this.paginationPage-1]
		},
		//end table stuff
	},
	methods: {
		//table stuff
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
		prevpage() {
			if(this.paginationPage > 1) this.paginationPage--
		},
		nextpage() {
			if(this.paginationPage < this.paginatedRows.length) this.paginationPage++
		},
		//end table stuff
		getLinkedUsers() {
			let targ = this.store.urlroot + "central/post-vue/hooks/admin-get-linked-users"
			let sendob = {}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.rows = ret.userlinks
			}.bind(this));
		},
	},
	watch: {
		rows() { //whenever filter text or sort is changed, go back to Page 1 in pagination
			this.paginationPage = 1
		},
	},
	mounted() {
		this.getLinkedUsers()
	}
}
</script>

<style scoped>

</style>