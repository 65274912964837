<template>
	<div>
		<BlanketOverlayWithPopup v-if="editOb && editOb.id >= 0 && hasPopulated === true" title="Edit Demogroup" width="1000" :hastabs="false" v-on:close="closeCancel">
			<template v-slot:main>
				<div class="contained-popup-content">
					<span class="label">Demogroup ID</span><input class="usaver" v-model="editOb.id" readonly type="text" /><br/>
					<span class="label">Title</span><input class="usaver" v-model="editOb.title" type="text" /><br/>
					<span class="label">Data Location</span><input class="usaver" v-model="editOb.survid" type="text" /><br/>
					<span class="label">Enabled</span>
					<select class="usaver" v-model="editOb.enabled">
						<option :value="false">No</option>
						<option :value="true">Yes</option>
					</select><br/>
					<span class="label">Reviewed</span>
					<select class="usaver" v-model="editOb.reviewed">
						<option :value="false">No</option>
						<option :value="true">Yes</option>
					</select><br/>
					<span class="label">Deleted</span>
					<select class="usaver" v-model="editOb.deleted">
						<option :value="false">No</option>
						<option :value="true">Yes</option>
					</select><br/>
					<div id="listSelectorAn" class="search-parent">
						<div>
							<div class="searchhold">
								Search <input type="text" v-model="filterText" />
							</div>
						</div>
						<div>
							<div class="available-list">
								<div id="availselect-ajax-an">
									<div v-for="dg in filteredUnusedDemos" :key="dg.id" class="analyitem">
										{{dg.title}}
										<span class="listadder" @click="addDemo(dg)">Add</span>
									</div>
								</div>
							</div>
							<div class="active-list">
								<div id="activeselect-ajax-an">
									<draggable v-model="editOb.demoObs" item-key="demo">
										<template #item="{element, index}">
											<div class="analyitem" :title="index">
												{{element.title}}
												<span class="listadder" @click="removeDemo(element)">Del</span>
											</div>
										</template>
									</draggable>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:buttons>
				<button type="button" class="option-button colbtn" @click="saveInfo">Save Info</button>
				<button type="button" class="option-button" @click="closeCancel">Cancel</button>
			</template>
		</BlanketOverlayWithPopup>
	</div>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'
import draggable from "vuedraggable";
export default {
	name: "DemogroupEditPopup",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
		draggable,
	},
	props: {
		dgob: {
			type: Object,
		},
		demos: {
			type: Array,
			default: function() {
				return []
			}
		},
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			hasPopulated: false,
			editOb: {
				id: 0,
				title: '',
				survid: 0,
				enabled: false,
				reviewed: false,
				deleted: false,
				demoIds: [], //just for initial retrieval from db, not for editing
				demoObs: [],
			},

			filterText: '',
		}
	},
	computed: {
		unusedDemos() {
			return this.demos.filter(item => !this.editOb.demoObs.includes(item))
		},
		filteredUnusedDemos() {
			if(this.filterText.length > 0) { //either already selected or matching text
				return this.unusedDemos.filter(item => item.title.toLowerCase().includes(this.filterText.toLowerCase()))
			}
			return this.unusedDemos
		},
	},
	methods: {
		populateContent() {
			if (this.dgob) {
				this.editOb = {demoObs: []}
				for (let pr in this.dgob) {
					this.editOb[pr] = this.dgob[pr]
				}
				let arr = this.demos.filter(item => this.editOb.demoIds.includes(item.id))
				let arrOrdered = []
				for(let dgid of this.editOb.demoIds) {
					let found = arr.find(item => item.id === dgid)
					if(found) arrOrdered.push(found)
				}
				this.editOb.demoObs = arrOrdered
				this.hasPopulated = true
			}
			else { //will be a new one
				this.hasPopulated = true
			}
		},
		closeCancel() {
			this.$emit('close', false);
		},
		saveInfo() {
			if (this.isFetching === false) {
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/analysis/save-demogroup"
				let dgorder = []
				for(let dg of this.editOb.demoObs) {
					dgorder.push(dg.id)
				}
				this.editOb.demoIds = dgorder
				let sendob = {
					demogroup: this.editOb,
				};
				this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
					this.isFetching = false
					this.$emit('close', true)
				}.bind(this));
			}
		},
		addDemo(dgob) {
			this.editOb.demoObs.push(dgob)
		},
		removeDemo(dgob) {
			let ind = this.editOb.demoObs.findIndex(item => item.id === dgob.id)
			if(ind > -1) this.editOb.demoObs.splice(ind, 1)
		},
	},
	watch: {

	},
	mounted() {
		this.populateContent()
	},
}
</script>
<style scoped>
#listSelector .available-list, #listSelector .active-list,
#listSelectorAn .available-list, #listSelectorAn .active-list {
	width: 47%;
	border: 1px solid #CCC;
	display: inline-block;
	height: 400px;
	padding: 1%;
	overflow-y: auto;
}
#listSelector .available-list, #listSelectorAn .available-list {
	margin-right: 1%;
	float: left;
}
#listSelector .analyitem, #listSelectorAn .analyitem {
	padding: 7px 8px 6px;
	width: 93%;
	background: #EEE;
	margin: 2px 0;
}
#listSelector .active-list .analyitem, #listSelectorAn .active-list .analyitem {
	cursor: move;
}
#listSelector .analyitem .listadder, #listSelectorAn .analyitem .listadder,
#listSelectorAn .analyitem .listediter {
	cursor: pointer;
	color: #00BC6C;
	float: right;
	margin: 0 5px;
}
#listSelector .active-list .analyitem .listadder,
#listSelectorAn .active-list .analyitem .listadder {
	color: #F00;
}
.label {
	width: 185px;
	display: inline-block;
}
.usaver {
	margin-bottom: 3px;
	width: 400px;
}
select.usaver {
	width: 416px;
}
</style>