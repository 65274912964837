<template>
	<div>
		<BlanketOverlayWithPopup v-if="stnEditOb && stnEditOb.stid >= 0 && hasPopulated === true" title="Edit Station" width="500" :hastabs="false" v-on:close="closeCancel">
			<template v-slot:main>
				<div class="contained-popup-content">
					<div style="padding: 10px 0 10px 0;">
						<span class="settlbl">Station ID: {{stnEditOb.stid}}</span>
					</div>
					<table>
						<tbody>
						<tr>
							<td><span class="settlbl">Name</span></td>
							<td><input type="text" class="saver" v-model="stnEditOb.name"></td>
						</tr>
						<tr>
							<td><span class="settlbl">Market</span></td>
							<td><select class="saver selector" v-model="stnEditOb.mktid">
								<option value=""></option>
								<option v-for="mkt in store.rrMarkets" :key="mkt.id" :value="mkt.id">{{mkt.name}} ({{mkt.id}})</option>
							</select></td>
						</tr>
						<tr>
							<td><span class="settlbl">Community</span></td>
							<td><select class="saver selector" v-model="stnEditOb.community">
								<option :value="0">No</option>
								<option :value="1">Yes</option>
							</select></td>
						</tr>
						<tr>
							<td><span class="settlbl">Other</span></td>
							<td><select class="saver selector" v-model="stnEditOb.isOther">
								<option :value="0">No</option>
								<option :value="1">Yes</option>
							</select></td>
						</tr>
						<tr>
							<td><span class="settlbl">Commercial</span></td>
							<td><select class="saver selector" v-model="stnEditOb.isCommercial">
								<option :value="0">No</option>
								<option :value="1">Yes</option>
							</select></td>
						</tr>
						<tr>
							<td><span class="settlbl">Outside Market</span></td>
							<td><select class="saver selector" v-model="stnEditOb.outsidemkt">
								<option :value="0">No</option>
								<option :value="1">Yes</option>
							</select></td>
						</tr>
						<tr>
							<td><span class="settlbl">Band</span></td>
							<td><input type="text" class="saver" v-model="stnEditOb.band"></td>
						</tr>
						<tr>
							<td><span class="settlbl">Net Group Own</span></td>
							<td><input type="text" class="saver" v-model="stnEditOb.netgroupown"></td>
						</tr>
						<tr>
							<td><span class="settlbl">Net Group Own</span></td>
							<td><input type="text" class="saver" v-model="stnEditOb.subnetown"></td>
						</tr>
						<tr>
							<td><span class="settlbl">Rep Net Group</span></td>
							<td><input type="text" class="saver" v-model="stnEditOb.repnetgroup"></td>
						</tr>
						<tr>
							<td><span class="settlbl">Rep Sub Net</span></td>
							<td><input type="text" class="saver" v-model="stnEditOb.repsubnet"></td>
						</tr>
						<tr>
							<td><span class="settlbl">DAB Network</span></td>
							<td><input type="text" class="saver" v-model="stnEditOb.dabnetwork"></td>
						</tr>
						</tbody>
					</table>
				</div>
			</template>
			<template v-slot:buttons>
				<button type="button" class="option-button colbtn" @click="saveInfo">Save Info</button>
				<button type="button" class="option-button" @click="closeCancel">Cancel</button>
			</template>
		</BlanketOverlayWithPopup>
	</div>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'
export default {
	name: "StationEditPopup",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		stnobin: Object,
		forcenewstation: {
			type: Boolean,
			default: false,
		},
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			hasPopulated: false,
			stnEditOb: null,
		}
	},
	computed: {

	},
	methods: {
		populateContent() {
			if (this.stnobin && this.forcenewstation === false) {
				this.stnEditOb = {}
				for (let pr in this.stnobin) {
					this.stnEditOb[pr] = this.stnobin[pr];
				}
			}
			else if(this.forcenewstation === true) {
				this.stnEditOb = {
					stid: 0,
					name: '',
					mktid: 0,
					community: 0,
					isOther: 0,
					isCommercial: 0,
					outsidemkt: 0,
					band: '',
					netgroupown: '',
					subnetown: '',
					repnetgroup: '',
					repsubnet: '',
					dabnetwork: '',
				}
			}
			this.hasPopulated = true
		},
		closeCancel() {
			this.$emit('close', false);
		},
		saveInfo() {
			if (this.isFetching === false) {
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/regional-ratings-admin/save-station-details";
				let sendob = {stnob: this.stnEditOb};
				this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
					this.isFetching = false
					this.$emit('close', true)
				}.bind(this));
			}
		},
	},
	watch: {
		stnobin() {
			this.populateContent()
		},
	},
	mounted() {
		this.populateContent()
	},
}
</script>
<style scoped>
.saver {
	width: 200px;
}
.selector {
	width: 216px;
}
</style>