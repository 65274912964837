<template>
	<div>

		<div class="altable-holder">
			<div style="display: flex; align-items: center; justify-content: flex-start; padding: 5px 0;">
				<div class="" style="margin: 5px 0;">
					<input @keyup.enter="searchHooks" type="text" style="width: 300px;" class="wl-input-text wl-input-padding-large" v-model="filterText" placeholder="Search hooks..." />
				</div>
				<button @click="searchHooks" style="margin-left: 15px;" type="button" class="option-button colbtn">Search</button>
				<div class="mainplayer">
					<audio id="player" controls="controls" autoplay><source :src="fileFullUrl" type="audio/mpeg" /></audio>
				</div>
				<button @click="newHook" style="margin-left: 15px;" type="button" class="option-button colbtn">Upload New</button>
			</div>
			<br/>
			<table v-if="rowsForPage && rowsForPage.length > 0" class="altbl noborderv">
				<thead>
					<tr>
						<td v-for="col in cols" :key="col.title" class="headingtext" @click="sortColumn(col.fldid)" data-f-bold="true">
							<span>{{col.title}}</span>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="rowitem in rowsForPage" :key="rowitem.id" style="cursor: pointer;">
						<td>{{rowitem.id}}</td>
						<td>{{rowitem.artist}}</td>
						<td>{{rowitem.title}}</td>
						<td>{{rowitem.year}}</td>
						<td>
							<span class="action-icon" title="Play" @click.stop="playHook(rowitem)"><i class="mdi mdi-play"></i></span>
							<span class="action-icon" title="Download hook">
								<a style="color: #444;" :href="'https://yourwavelength.com/hooklib/'+rowitem.id+'.mp3'" target="_blank" rel="noopener noreferrer" :download="rowitem.id+'.mp3'">
									<i class="mdi mdi-download"></i>
								</a>
							</span>
							<span class="action-icon" title="Edit hook" @click.stop="editHook(rowitem)"><i class="mdi mdi-pencil"></i></span>
							<span class="action-icon" title="Delete hook" @click.stop="deleteHook(rowitem)"><i class="mdi mdi-delete"></i></span>
						</td>
					</tr>
				</tbody>
			</table>

			<div v-if="rowsForPage && rowsForPage.length > 0" style="margin: 15px 0; display: flex; align-items: center;">
				<ALTablePaginator
						:paginationpage="paginationPage"
						:paginatedrowscount="paginatedRows.length"
						v-on:nextpage="nextpage" v-on:prevpage="prevpage"
						v-on:setpage="paginationPage = $event"
				></ALTablePaginator>
			</div>

		</div>

		<HookEditorPopup v-if="hookEditorViewShown && activeHookOb" :hookobin="activeHookOb" v-on:close="closeHookEditor($event)" :allowupload="true" :artists="artists"></HookEditorPopup>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import ALTablePaginator from "@/components/ALTablePaginator";
import HookEditorPopup from "@/components/hooks-admin/HookEditorPopup.vue";

export default {
	name: "HooksAdminSearchContent",
	mixins: [globalMixin],
	components: {
		HookEditorPopup,
		ALTablePaginator,

	},
	props: {},
	data: function () {
		return {
			store: store,

			// table stuff
			filterText: '',
			sortColName: null,
			sortColOrder: 'desc',
			paginationRowsPerPage: 20,
			paginationPage: 1,
			cols: [
				{title: 'ID', sortable: true, fldid: 'id'},
				{title: 'Artist', sortable: true, fldid: 'artist'},
				{title: 'Title', sortable: true, fldid: 'title'},
				{title: 'Year', sortable: true, fldid: 'year'},
				{title: 'Functions', sortable: false, fldid: ''},
			],
			//end table stuff
			rows: [],

			activeHookOb: null,
			hookEditorViewShown: false,
			artists: [],

		}
	},
	computed: {
		//table stuff
		paginatedRows() {
			return this.tblGetPaginatedRows(this.rows, this.paginationRowsPerPage)
		},
		rowsForPage() {
			if(!this.paginationRowsPerPage || !this.paginationPage) return [this.rows]
			return this.paginatedRows[this.paginationPage-1]
		},
		//end table stuff
		fileFullUrl() {
			if(this.activeHookOb) {
				return "https://yourwavelength.com/hooklib/" + this.activeHookOb.id + ".mp3";
			}
			return ''
		},
	},
	methods: {
		//table stuff
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
		prevpage() {
			if(this.paginationPage > 1) this.paginationPage--
		},
		nextpage() {
			if(this.paginationPage < this.paginatedRows.length) this.paginationPage++
		},
		//end table stuff
		searchHooks() {
			let targ = this.store.urlroot + "central/post-vue/hooks/hook-search";
			let sendob = {searchtext: this.filterText};
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.rows = ret.hooks
			}.bind(this));
		},
		playHook(rowitem) {
			this.activeHookOb = rowitem
			let a = document.getElementById('player')
			a.src = this.fileFullUrl
			a.play()
		},
		newHook() {
			this.activeHookOb = {
				id: 0,
				artist: '',
				title: '',
				year: '',
			}
			this.hookEditorViewShown = true
		},
		editHook(rowitem) {
			this.activeHookOb = rowitem
			this.hookEditorViewShown = true
		},
		closeHookEditor(ev) {
			this.hookEditorViewShown = false
			if(ev && ev.id) { //replace this.rows item with returned hook object
				let ind = this.rows.findIndex(item => item.id === ev.id)
				if(ind !== -1) this.rows[ind] = ev
			}
		},
		deleteHook(rowitem) {
			let r = confirm("This will permanently delete this hook from the library - are you sure???") //confirm box before deleting
			if(r === true) {
				let targ = this.store.urlroot + "central/post-vue/hooks/delete-hook";
				let sendob = {deleteid: rowitem.id};
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.rows = this.rows.filter(item => item.id !== ret.deleted_id)
				}.bind(this));
			}
		},
		getInitialData() {
			let targ = this.store.urlroot + "central/post-vue/hooks/custom-hook-prep"
			let sendob = {};
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				if(ret.artists) this.artists = ret.artists
				this.store.showLoader = false
			}.bind(this));
		},
	},
	watch: {
		rows() { //whenever filter text or sort is changed, go back to Page 1 in pagination
			this.paginationPage = 1
		},
	},
	mounted() {
		this.getInitialData()
	}
}
</script>

<style scoped>

</style>