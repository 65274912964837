<template>
	<div id="leftmenu">
		<div class="wl-logo-holder">
			<img src="@/assets/wavelength_logo.png" style="height: 40px; padding: 15px 10px 13px" />
		</div>
		<a @click='transpose' title='Toggle menu width' id='transposeA'>
			<i id='transposeimgrt' class='mdi mdi-chevron-right'></i>
			<i id='transposeimg' class='mdi mdi-chevron-left'></i>
		</a>
		<div>
			<router-link v-if="user.superUser === 1 || (user.perms.dash && user.perms.dash === 1) || (user.perms.CATIUser && user.perms.CATIUser === 1)" to="/home" title="Home / Collection"
				class="lmenu" :class="{activelink : store.activeLmenuLink === 'home'}"
				@click="store.clearSearchAndReloadCollection = true"
			>
				<i class="mdi mdi-file-tree falmenu"></i><span>Collection</span>
			</router-link>
			<router-link v-if="user.superUser !== 1 && (user.perms.panelUser && user.perms.panelUser === 1)" to="/home" title="Manage Panel" class="lmenu" :class="{activelink : store.activeLmenuLink === 'home'}">
				<i class="mdi mdi-file-tree falmenu"></i><span>Manage Panel</span>
			</router-link>
<!--			<a v-if="user.superUser === 1 || (user.perms.analysis && user.perms.analysis === 1)" :href="centurl+'/analysis'" title="Analysis" class="lmenu" :class="{activelink : store.activeLmenuLink === 'analysis'}">-->
<!--				<i class="mdi mdi-chart-line falmenu"></i><span>Analysis</span>-->
<!--			</a>-->
			<router-link v-if="user.superUser === 1 || (user.perms.analysis && user.perms.analysis === 1)" to="/analysis" title="Analysis" class="lmenu" :class="{activelink : store.activeLmenuLink === 'analysis'}">
				<i class="mdi mdi-chart-line falmenu"></i><span>Analysis</span>
			</router-link>
			<router-link v-if="user.superUser === 1 || (user.perms.hookcentral && user.perms.hookcentral === 1)" to="/hook-lists" title="Hook Lists" class="lmenu" :class="{activelink : store.activeLmenuLink === 'hooks'}">
				<i class="mdi mdi-music falmenu"></i><span>My Hook Lists</span>
			</router-link>
			<router-link v-if="user.superUser === 1" to="/hookcentral-admin" title="Hooks Admin" class="lmenu" :class="{activelink : store.activeLmenuLink === 'hooksadmin'}">
				<i class="mdi mdi-music-note-eighth falmenu"></i><span>Hooks Admin</span>
			</router-link>
			<router-link v-if="user.superUser !== 1 && (user.perms.hookcentral && user.perms.hookcentral === 1)" to="/custom-hooks" title="Custom Hooks" class="lmenu" :class="{activelink : store.activeLmenuLink === 'customhooks'}">
				<i class="mdi mdi-music-note-eighth falmenu"></i><span>Custom Hooks</span>
			</router-link>
			<router-link v-if="user.superUser !== 1 && (user.perms.dash && user.perms.dash === 1)" to="/dashboard" title="Dashboard" class="lmenu" :class="{activelink : store.activeLmenuLink === 'dashboard'}">
				<i class="mdi mdi-gauge falmenu"></i><span>Dashboard</span>
			</router-link>
			<router-link v-if="user.superUser === 1 || (user.perms.contactlists && user.perms.contactlists === 1)" to="/contact-lists" title="Contact Lists" class="lmenu" :class="{activelink : store.activeLmenuLink === 'contact-lists'}">
				<i class="mdi mdi-book-open-blank-variant falmenu"></i><span>Contact Lists</span>
			</router-link>
			<router-link v-if="user.superUser !== 1 && (user.perms.contacts && user.perms.contacts === 1)" to="/contacts" title="Contacts" class="lmenu" :class="{activelink : store.activeLmenuLink === 'contacts'}">
				<i class="mdi mdi-card-account-mail-outline falmenu"></i><span>Contacts</span>
			</router-link>
			<router-link v-if="user.superUser === 1 || (user.perms.edms && user.perms.edms === 1)" to="/edms" title="EDMs" class="lmenu" :class="{activelink : store.activeLmenuLink === 'edms'}">
				<i class="mdi mdi-email-open falmenu"></i><span>EDMs</span>
			</router-link>
			<router-link v-if="user.superUser === 1 || (user.perms.siteAdmin && user.perms.siteAdmin === 1) || (user.perms.CATISuper && user.perms.CATISuper === 1)" to="/user-admin" title="Wavelength User Admin" class="lmenu" :class="{activelink : store.activeLmenuLink === 'wlusers'}">
				<i class="mdi mdi-account falmenu"></i><span>Manage Users</span>
			</router-link>
			<router-link v-if="user.superUser === 1" to="/regional-ratings-admin" title="Regional Ratings Admin" class="lmenu" :class="{activelink : store.activeLmenuLink === 'ratingsAdmin'}">
				<i class="mdi mdi-radio-tower falmenu"></i><span>Ratings Admin</span>
			</router-link>
			<a v-if="user.superUser === 1 || (user.rruserid && user.rruserid > 0)" title="Frequency" href="https://frequency.regionalratings.com.au" target="_blank" class="lmenu"  id="rrlmenu">
				<img class="almenu" src="@/assets/frequency_icon.png" style="margin: 0 4px 0 16px;" /><span>Frequency</span>
			</a>
			<router-link v-if="user.superUser === 1 || (user.perms.rrusersOwned === 1 || user.perms.rrusersFull === 1)" to="/frequency-user-manager" title="Frequency Users" class="lmenu" :class="{activelink : store.activeLmenuLink === 'frequsers'}">
				<i class="mdi mdi-account-supervisor falmenu"></i><span>Frequency Users</span>
			</router-link>
			<router-link v-if="user.superUser === 1 || (user.perms.hookcentral && user.perms.hookcentral === 1)" to="/reports" title="Song Reports" class="lmenu" :class="{activelink : store.activeLmenuLink === 'songreports'}">
				<i class="mdi mdi-chart-bar falmenu"></i><span>Song Reports</span>
			</router-link>
		</div>
		<div title="Log Out" @click="logout" class="lmenu"><i class="mdi mdi-logout falmenu fallogout"></i><span>Log out</span></div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
export default {
	name: "LeftMenuWL",
	components: {},
	mixins: [globalMixin],
	props: {

	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		user() {
			return this.store.user;
		},
		leftmenuhidden() {
			return this.store.leftmenuhidden;
		},
		centurl() {
			return this.store.urlroot+'central';
		},
	},
	methods: {
		transpose() {
			let newval = true;
			if(this.leftmenuhidden) newval = false;
			this.store.leftmenuhidden = newval;
		},
		logout() {
			this.clearLoginCredsAndSession()
			//window.location = this.store.urlroot+'central/logout' //to clear the PHP session too
			this.$router.push('/?loggedout=1')  //for future when it's all vue
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
#leftmenu {
	width: 200px;
	height: 100%;
	padding: 0px 0 0;
	position: fixed;
	top: 0;
	background-color: #0b1b3f;
	z-index: 980;
	text-align: left;
}
.lmenu {
	text-decoration: none;
	color: #EEE;
	font-size: 13px;
	text-align: left;
	line-height: 1.3;
	border-bottom: 1px solid transparent;
	border-top: 1px solid transparent;
	position: relative;
	width: 200px;
	height: 39px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	transition: all 0.2s linear;
	overflow: hidden;
	cursor: pointer;
}
#transposeimg, #transposeimgrt {
	float: right;
	font-size: 26px;
	color: #FFF;
	margin: 10px 15px 10px 5px;
}
#transposeimg {
	margin-right: 0;
	padding-top: 1px;
}
#transposeimgrt {
	display: none;
	margin-right: 10px;
	padding-top: 1px;
}
#transposeA {
	display: block;
	overflow: hidden;
	position: absolute;
	z-index: 9;
	top: 12px;
	right: 15px;
}
#transposeA:hover i {
	color: #888;
}
.pageLeftMenuTransposed #leftmenu {
	width: 60px;
}
.pageLeftMenuTransposed #leftmenu .lmenu {
	padding-left: 4px;
	width: 60px;
}
.pageLeftMenuTransposed #leftmenu .lmenu span,
.pageLeftMenuTransposed #leftmenu #transposeimg {
	display: none;
}
.pageLeftMenuTransposed #leftmenu .wl-logo-holder img { /** hide the wavelength logo **/
	visibility: hidden;
	opacity: 0;
}
.pageLeftMenuTransposed #leftmenu #transposebars {
	margin-right: 0;
}
#leftmenu .lmenu span.finalHide {
	display: none;
}
.pageLeftMenuTransposed #bg {
	margin-left: 60px;
}
.pageLeftMenuTransposed #transposeimgrt {
	display: block;
}
.lmenu:nth-of-type(1) {
	border-top: 1px solid transparent;
}
.lmenu span {
	display: inline-block;
	margin-left: 10px;
	font-family: "Montserrat";
	font-weight: bold;
	text-transform: uppercase;
	vertical-align: middle;
	font-size: 11px;
}
.lmenu:hover {
	background-color: #0A0C19;
	z-index: 1250;
}
.activelink:hover {
	background-color: #23A6EE;
}
#leftmenu .falmenu {
	font-size: 25px;
	margin-left: 10px;
	color: #0d76a5;
	display: inline-block;
	width: 30px;
	text-align: center;
}
#leftmenu .fallogout {
	color: #7C8493;
}
#leftmenu .almenu {
	height: 19px;
	display: inline-block;
	margin: 0;
	text-align: center;
}
#leftmenu .activelink .falmenu {
	color: #FFF;
}
.leftmenuNextHold {
	width: 20px;
	float: left;
	margin-left: 180px;
	z-index: 975;
	position: absolute;
	background: #E2E7EB;
	height: 100%;
	box-shadow: 0 0 12px 2px #444;
}
.activelink {
	background-color: #23A6EE;
	color: #FFF;
	background-image: linear-gradient(to bottom, #33B6FE 0px, #23A6EE 100%);
}
</style>