<template>
	<BlanketOverlayWithPopup title="Manage Branch" width="800" v-on:close="closeCancel">
		<template v-slot:main>
			<div class="brancheditor">
				<p>Attach to Question</p>
				<div class="select-holder">
					<select v-model="branchParentQid" class="selector">
						<option v-for="q in survob.questions" :key="q.qid" :value="q.qid">
							{{q.qid}}: {{q.qtext_stripped}}
						</option>
					</select>
				</div>
				<p>If criteria not met, skip to question</p>
				<div>
					<select v-model="branchSkipTo" class="selector">
						<option v-for="q in survob.questions" :key="q.qid" :value="q.qid">
							{{q.qid}}: {{q.qtext_stripped}}
						</option>
					</select>
				</div>
				<p style="margin-top: 10px;">Logic can be decimal eg prob='0.25' where 25% of visitors see it, or code eg (AnsQ3=4 OR AnsQ3=7) AND AnsQ4!=5</p>
				<p>Test operators are == or =, !=, >, &lt;, >=, &lt;= </p>
				<p>Two single response questions can be tested eg AnsQ3=AnsQ2 or AnsQ3!=AnsQ2 or AnsQ3>AnsQ2</p>
				<p>Use quote marks for prob eg prob='0.25', do not use quote marks for int check eg AnsQ3=2. Tested by JavaScript (not MySQL so no need for LIKE '%1|' etc)</p>
				<span>Show this branch if following criteria is met...</span>
				<input type="text" v-model="branchLogic" style="width:460px;" /><br/>

			</div>
		</template>
		<template v-slot:buttons>
			<button type="button" class="option-button colbtn" @click="saveBranch">Submit</button>
			<button type="button" class="option-button" @click="deleteBranch">Delete</button>
			<button type="button" class="option-button" @click="closeCancel">Close</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup.vue";

export default {
	name: 'BranchEditor',
	components: {BlanketOverlayWithPopup},
	mixins: [globalMixin],
	props: {
		survob: {
			type: Object,
			required: false,
			default: null,
		},
		editingbranch: {
			type: Object,
			required: false,
			default: null,
		},
	},
	data() {
		return {
			store: store,

			branchParentQid: null,
			branchSkipTo: null,
			branchLogic: '',

			isFetching: false,
		}
	},
	computed: {

	},
	methods: {
		closeCancel() {
			this.$emit('close')
		},
		saveBranch() {
			if(this.branchParentQid && this.branchSkipTo && this.branchLogic.length > 0 && !this.isFetching) {
				let targ = this.store.urlroot + "central/post-vue/questions/save-branch"
				let sendob = {
					survid: this.survob.survid,
					branchParentQid: this.branchParentQid,
					branchSkipTo: this.branchSkipTo,
					branchLogic: this.branchLogic,
				}
				this.isFetching = true
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					this.$emit('update', ret)
				}.bind(this))
			}
		},
		deleteBranch() {
			if(this.branchParentQid && this.branchSkipTo && this.branchLogic.length > 0 && !this.isFetching) {
				let targ = this.store.urlroot + "central/post-vue/questions/delete-branch"
				let sendob = {
					survid: this.survob.survid,
					branchParentQid: this.branchParentQid,
				}
				this.isFetching = true
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					this.$emit('update', ret)
				}.bind(this))
			}
		},
	},
	watch: {

	},
	mounted() {
		if(this.editingbranch) {
			this.branchParentQid = this.editingbranch.attachToQID
			this.branchSkipTo = this.editingbranch.skipToQIDOnFail
			this.branchLogic = this.editingbranch.showIfRaw
		}
	},
}
</script>

<style scoped>
.brancheditor p {
	line-height: 1.3;
}
.select-holder {
	margin: 5px 0 10px;
}
.selector {
	width: 100%;
}
</style>
