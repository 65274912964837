<template>
	<div>
		<div class="altable-holder">
			<table class="altbl noborderv" id="songreport">
				<thead>
				<tr>
					<td class="headingtext" @click="sortColumn('artist')" data-f-bold="true">
						<span>Artist</span>
					</td>
					<td class="headingtext" @click="sortColumn('title')" data-f-bold="true">
						<span>Title</span>
					</td>
					<td class="headingtext" @click="sortColumn('year')" data-f-bold="true">
						<span>Year</span>
					</td>
					<td v-for="c in cols" :key="c.id" class="headingtext" @click="sortColumn(c.id)" data-f-bold="true">
						<span>{{c.name}}</span>
					</td>
					<td class="headingtext">Latest</td>
					<td class="headingtext">Average</td>
					<td v-if="showchartcol" class="headingtext" data-f-bold="true">
						<span>Chart</span>
					</td>
				</tr>
				</thead>
				<tbody>
				<tr v-for="rowitem in rows" :key="rowitem.hookid" style="cursor: default;">
					<td>{{rowitem.artist}}</td>
					<td>{{rowitem.title}}</td>
					<td>{{rowitem.year}}</td>
					<td v-for="s in surveys" :key="s.id" class="ctr">
						{{getValBySurvHook(s.id, rowitem.hookid)}}
					</td>
					<td>{{numdisplayNdp(rowitem.resultMostRecent,1)}}</td>
					<td>{{numdisplayNdp(rowitem.resultAverage,1)}}</td>
					<td v-if="showchartcol">
						<span class="action-icon" title="View history chart" @click.stop="$emit('hookhistory',rowitem.hookid)"><i class="mdi mdi-chart-line"></i></span>
					</td>
				</tr>
				</tbody>
			</table>
			<div>
				<span style='margin: 20px 0;' class='wl-icon-btn wl-btn-border-grey' @click='exportToExcel'><i class='mdi mdi-file-excel'></i> Export this report</span>
			</div>
		</div>
	</div>
</template>
<script>
import {globalMixin} from "@/mixins";
import {store} from "@/store";
import TableToExcel from "@linways/table-to-excel";

export default {
	name: "DashMusicHistoryTable",
	mixins: [globalMixin],
	components: {

	},
	props: {
		results: {
			type: Array,
			default: () => []
		},
		surveys: {
			type: Array,
			default: () => []
		},
		showchartcol: {
			type: Boolean,
			default: true
		},
	},
	data: function () {
		return {
			store: store,

			// table stuff
			filterText: '',
			sortColName: null,
			sortColOrder: 'desc',
			//end table stuff
		}
	},
	computed: {
		//table stuff
		rows() {
			return this.tblPrepareFilteredRows(this.results, this.filterText, this.sortColName, this.sortColOrder)
		},
		//end table stuff
		cols() {
			let cols = []
			for(let s of this.surveys) {
				cols.push({id: s.id, name: s.name})
			}
			return cols
		},
	},
	methods: {
		exportToExcel() {
			TableToExcel.convert(document.getElementById('songreport'), {
				name: "HistoryReport.xlsx",
				sheet: {
					name: 'Sheet 1'
				}
			});
		},
		getValBySurvHook(sid, hid) {
			let val = ''
			let hobr = this.results.find(item => item.hookid === hid)
			if(hobr) {
				if(hobr.resultsBySurveyId[sid]) val = hobr.resultsBySurveyId[sid]
			}
			return this.numdisplayNdp(val,1)
		},
		//table stuff
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
		//end table stuff
	},
	watch: {

	},
	created() {

	},
}
</script>
<style scoped></style>