<template>
	<BlanketOverlayWithPopup v-if="hookobin && hookobin.id >= 0" title="Hook Editor" width="400" :hastabs="false" v-on:close="closeCancel">
		<template v-slot:main>
			<div v-if="activeTab === 'main'" class="contained-popup-content">
				<div style="padding: 10px 0 10px 0;">
					<span v-if="hookobin.id > 0" class="settlbl">{{hookobin.artist}} - {{hookobin.title}} ({{hookobin.year}})</span>
					<span v-else class="settlbl">New Hook</span>
				</div>
				<table>
					<tbody>
					<tr>
						<td><span class="settlbl">Artist</span></td>
						<td>
<!--							<input type="text" class="saver" v-model="hookEditOb.artist">-->
							<AutocompleterStringOnly v-if="artists" :searchlist="artists" :incomingval="hookobin.artist"
								idfldname="name" textfldname="name" v-on:update="hookEditOb.artist = $event['name']"
							></AutocompleterStringOnly>
						</td>
					</tr>
					<tr>
						<td><span class="settlbl">Title</span></td>
						<td><input type="text" class="saver" v-model="hookEditOb.title"></td>
					</tr>
					<tr>
						<td><span class="settlbl">Year</span></td>
						<td><input type="text" class="saver" v-model="hookEditOb.year"></td>
					</tr>
					<tr v-if="allowupload">
						<td colspan="2">
							<br/>
							<UploadFile @file-changed="file_changed" />
						</td>
					</tr>
					</tbody>
				</table>
			</div>

		</template>
		<template v-slot:buttons>
			<button type="button" class="option-button colbtn" @click="saveHookInfo">Save Info</button>
			<button type="button" class="option-button" @click="closeCancel">Cancel</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'
import UploadFile from "@/components/UploadFile.vue";
import axios from "axios";
import AutocompleterStringOnly from "@/components/AutocompleterStringOnly.vue";

export default {
	name: "HookEditorPopup",
	mixins: [globalMixin],
	components: {
		AutocompleterStringOnly,
		UploadFile,
		BlanketOverlayWithPopup,
	},
	props: {
		artists: Array,
		hookobin: Object,
		allowupload: {
			type: Boolean,
			default: false,
		},
		isnewhook: {
			type: Boolean,
			default: false,
		},
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			activeTab: 'main',

			hookEditOb: {},
			uploadfile: null,
		}
	},
	computed: {

	},
	methods: {
		closeCancel() {
			this.$emit('close');
		},
		file_changed( value ) {
			this.uploadfile = value
		},
		saveHookInfo() {
			if (this.isFetching === false) {
				if(this.hookEditOb.artist.length === 0 || this.hookEditOb.title.length === 0 || this.hookEditOb.year.length === 0) {
					alert('All fields must be completed');
					return;
				}
				if(this.hookEditOb.artist.match(/[<>!#$%^*,|]+/i) || this.hookEditOb.title.match(/[<>!#$%^*,|]+/i)) { //validation for illegal characters
					alert("Responses cannot contain any of the following: [ & % $ * ^ / \\ ! ] # | .  Please adjust your entry.");
					return false;
				}
				if(parseInt(this.hookEditOb.year) > 2050 || parseInt(this.hookEditOb.year) < 1950) {
					alert("Please enter a valid four digit year for this hook, eg 1987 or 2010.");
					return false;
				}
				let self = this
				let form_data = new FormData()
				form_data.append('uid', this.store.user.id)
				form_data.append('wlsesstoken', this.store.wlsesstoken)
				form_data.append('hookEditOb', JSON.stringify(this.hookEditOb))
				if(this.uploadfile) {
					form_data.append('uploadfile', this.uploadfile)
				}
				if(this.hookEditOb.id === 0 && !this.uploadfile) {
					alert("Please select an MP3 hook file.");
					return false;
				}
				this.store.showLoader = true
				this.isFetching = true
				axios({
					url: this.store.urlroot + "central/post-vue/hooks/update-hook-details",
					method: 'POST',
					data: form_data
				}, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(function (response) {
					self.store.showLoader = false
					self.isFetching = false
					let ret = response.data //response.data is returned info
					if(ret.error) {
						self.showKalertError(ret.error);
					}
					else {
						self.$emit('close', ret.hookObject)
						self.uploadfile = null
					}
				}.bind(this)).catch(function (error) {
					console.log(error)
				});
			}
		},
		populateContent() {
			if(this.hookobin) {
				for(let pr in this.hookobin) {
					this.hookEditOb[pr] = this.hookobin[pr];
				}
			}
		},
	},
	watch: {
		userobin() {
			this.populateContent()
		},
	},
	mounted() {
		this.populateContent()
	},
}
</script>

<style scoped>
.saver {
	width: 200px;
}
table tr td:nth-of-type(2) {
	padding-right: 50px;
}
.selector {
	width: 216px;
}

</style>