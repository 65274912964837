<template>
	<div class="wl-admin-container">

		<div>
			<div style="margin-bottom: 20px;">
				<select v-model="activeAnalysisOb" class="selector">
					<option v-for="a in store.analysesList" :key="a.id" :value="a">{{a.title}}</option>
				</select>
				<input type="text" placeholder="Search by name" v-model="filterText" class="wl-input-text wl-input-padding-large" />
				<button @click="addNewDemo" style="margin-left: 15px;" type="button" class="option-button colbtn">New</button>
				<button @click="editSelected" style="margin-left: 5px;" type="button" class="option-button colbtn">Edit Selected</button>
				<button @click="cloneMulti('samedataloc')" style="margin-left: 5px;" type="button" class="option-button colbtn" title="Clone selected demos to the same survey">Clone (Same)</button>
				<button @click="cloneMulti('newdataloc')" style="margin-left: 5px;" type="button" class="option-button colbtn" title="Clone selected demos to a different survey">Clone (Diff)</button>
			</div>
			<table class="altbl noborderv" style="max-width: 1000px;">
				<thead>
					<tr class='headrow'>
						<td class="headingtext"></td>
						<td class="headingtext">ID</td>
						<td class="headingtext ctr">DataLoc</td>
						<td class="headingtext" style="width: 300px">Title</td>
						<td class="headingtext">DString</td>
						<td class="headingtext ctr">Reviewed</td>
						<td class="headingtext ctr">Functions</td>
					</tr>
				</thead>
				<tbody v-if="this.demos.length > 0">
					<tr v-for="a in filteredDemos" :key="a.id">
						<td><input type="checkbox" v-model="selectedDemoIds" :value="a.id" /></td>
						<td>{{a.id}}</td>
						<td class="ctr">{{a.survid}}</td>
						<td>{{a.title}}</td>
						<td>{{a.dstring}}</td>
						<td class="ctr">{{boolToYes(a.reviewed)}}</td>
						<td class="ctr">
							<i class="icon mdi mdi-pencil-outline"
								@click.stop="editDemos([a.id])"
								title="Edit demo"
							></i>
							<i class="icon mdi mdi-content-copy"
								@click.stop="cloneDemos('samedataloc', [a.id])"
								title="Clone (to same Survey)"
							></i>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "DemosList",
	components: {

	},
	props: {},
	mixins: [globalMixin],
	data: function() {
		return {
			store: store,
			filterText: '',

			activeAnalysisOb: null,
			demos: [],
			selectedDemoIds: [],
		}
	},
	computed: {
		filteredDemos() {
			if(this.filterText.length > 0) { //either already selected or matching text
				return this.demos.filter(item => item.title.toLowerCase().includes(this.filterText.toLowerCase()))
			}
			return this.demos
		},
	},
	methods: {
		addNewDemo() {
			let title = prompt("Please enter a title");
			if(title != null) {
				let targ = this.store.urlroot + "central/post-vue/analysis/create-new-blank-demo"
				let sendob = {
					survid: this.activeAnalysisOb.survid,
					title: title,
				}
				return this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					if(ret.newdemoId > 0) {
						this.editDemos([ret.newdemoId])
					}
				}.bind(this))
			}
		},
		editDemos(idArr) {
			this.$emit('editdemos', idArr)
		},
		editSelected() {
			if(this.selectedDemoIds.length > 0) {
				this.editDemos(this.selectedDemoIds)
			}
		},
		cloneMulti(type='samedataloc') {
			if(this.selectedDemoIds.length > 0) {
				this.cloneDemos(type, this.selectedDemoIds)
			}
		},
		cloneDemos(type='samedataloc', demoIdArr = []) {
			let targ = this.store.urlroot + "central/post-vue/analysis/clone-demos"
			let sendob = {
				demoIds: demoIdArr,
				type: type,
			}
			if(type === "newdataloc") { //prompt for new dataloc
				let newdataloc = prompt("Please enter the DATALOC of the new Demo/s");
				if (newdataloc.length > 0) {
					sendob.destdataloc = newdataloc
				}
			}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				if(ret.newDemoIds && ret.newDemoIds.length > 0) {
					this.editDemos(ret.newDemoIds)
				}
			}.bind(this))
		},
		getDemosForSurvid() {
			let targ = this.store.urlroot + "central/post-vue/analysis/get-demos-admin"
			let sendob = {
				survid: this.activeAnalysisOb.survid
			}
			return this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.demos = ret.demos
			}.bind(this))
		},
	},
	watch: {
		activeAnalysisOb() {
			this.getDemosForSurvid()
		},
	},
	mounted() {
		if(this.store.analysesList.length > 0) {
			this.activeAnalysisOb = this.store.analysesList[0]
		}
	},
}
</script>

<style scoped>
.icon.mdi {
	cursor: pointer;
	font-size: 16px;
	margin-right: 8px;
}
.selector {
	padding: 7px 10px;
	font-size: 15px;
	margin: 0 10px 0 0;
}
.altbl td {
	padding: 5px 8px;
	vertical-align: middle;
	overflow: hidden;
}
</style>