<template>
	<BlanketOverlayWithPopup title="Station List Updater" width="670" v-on:close="closeCancel">
		<template v-slot:main>
			<div class="seldiv">
				<p>The following questions are listed as Station List questions:</p>
				<p>
					<span v-for="q in stationListQobs" :key="q.qid">
						{{q.qid}},
					</span>
				</p>
				<div v-if="stationListQobs.length > 0" style="margin-top: 10px;">
					<p>The following options exist.  Please check any you DO NOT wish to be replaced</p>
					<div v-for="stnname in stationOptions" :key="stnname">
						<label>
							<input type="checkbox" v-model="selectedStationNames" :value="stnname">
							<span style="margin-left: 3px;">{{stnname}}</span>
						</label>
					</div>
				</div>
				<div>
					<textarea v-model="newStationNamesList"></textarea>
				</div>
			</div>
		</template>
		<template v-slot:buttons>
			<button type="button" class="option-button colbtn" @click="saveStationListChanges">Save</button>
			<button type="button" class="option-button" @click="closeCancel">Cancel</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup.vue";

export default {
	name: 'StationListUpdater',
	components: {BlanketOverlayWithPopup},
	mixins: [globalMixin],
	props: {
		survob: {
			type: Object,
			required: false,
			default: null,
		},
	},
	data() {
		return {
			store: store,

			selectedStationNames: [],
			newStationNamesList: '',
		}
	},
	computed: {
		stationListQobs() {
			return this.survob.questions.filter(q => q.isStationListQ === true)
		},
		stationOptions() {
			let ret = []
			for(let q of this.stationListQobs) {
				for(let opt of q.options) {
					if(!ret.includes(opt.opttext)) {
						ret.push(opt.opttext)
					}
				}
			}
			return ret
		},
	},
	methods: {
		closeCancel() {
			this.$emit('close');
		},
		saveStationListChanges() {
			let newNameArr = this.newStationNamesList.split('\n')
			let dontChangeNames = []
			for(let sn of this.stationOptions) {
				if(this.selectedStationNames.includes(sn)) {
					dontChangeNames.push(sn)
				}
			}
			if(newNameArr.length > 0) {
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/questions/update-station-list"
				let sendob = {
					survid: this.survob.survid,
					dontChangeNames: dontChangeNames,
					newNames: newNameArr,
				}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					this.$emit('update', ret)
					setTimeout(function() {
						this.$emit('close')
					}.bind(this), 100)
				}.bind(this))
			}
		},
	},
	watch: {

	},
	mounted() {

	},
}
</script>

<style scoped>
.seldiv {
	padding: 20px 0 10px;
}
.seldiv p {
	margin-bottom: 5px;
}
.seldiv label {
	display: flex;
	align-items: center;
}
.seldiv textarea {
	width: 600px;
	height: 300px;
	margin-top: 10px;
}
</style>
