<template>
	<div class="top-menu-group" @mouseleave="showDropdown = false">
		<div class="top-menu-label" @mouseover="showDropdown = true" @click="closeDropdownFromTop" :class="hovclass">
			{{label}}
			<i v-if="hasdropdown" class="mdi mdi-chevron-down" style="margin-left: 4px; font-size: 18px;"></i>
		</div>
		<div v-if="showDropdown" class="top-menu-dropdowns">
			<slot v-on:clicked="showDropdown = false"></slot>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'

export default {
	name: "TopMenuGroup",
	components: {},
	props: {
		label: String,
		hasdropdown: Boolean,
	},
	data: function () {
		return {
			showDropdown: false,
			store: store,
		}
	},
	computed: {
		forceMenusClose() {
			return this.store.forceMenusClose;
		},
		hovclass() {
			if(this.hasdropdown === true) return ''
			else return 'hovclass'
		}
	},
	methods: {
		closeDropdownFromTop() {
			if(this.hasdropdown === false) this.showDropdown = false
		},
	},
	watch: {
		forceMenusClose() {
			this.showDropdown = false;
		},
	},
	mounted() {

	}
}
</script>

<style scoped>
.top-menu-group {
	cursor: pointer;
	position: absolute;
}
.top-menu-label {
	color: #FFF;
	text-transform: uppercase;
	height: 70px;
	font-size: 11px;
	font-weight: 500;
	display: flex;
	align-items: center;
	cursor: default;
	padding: 0 10px;
}
.top-menu-label.hovclass {
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}
.top-menu-label.hovclass:hover {
	background-color: #23A6EE;
}
.top-menu-dropdowns {
	background-color: #0f75a5;
	height: auto;
}
</style>