<template>
	<BlanketOverlayWithPopup v-if="groupEditOb" title="Frequency Group Editor" width="800" :hastabs="true" v-on:close="closeCancel">
		<template v-slot:tabs>
			<p style="font-weight: 500; font-size: 12px; margin: -15px 0 15px;">{{groupEditOb.name}}</p>
			<div class="popup-tabs">
				<div class="popup-tab" :class="{active : activeTab === 'main'}" @click="activeTab = 'main'">Main</div>
				<div class="popup-tab" :class="{active : activeTab === 'members'}" @click="activeTab = 'members'">Members</div>
				<div class="popup-tab" :class="{active : activeTab === 'fullsurvs'}" @click="activeTab = 'fullsurvs'">Desktop Surveys</div>
				<div class="popup-tab" :class="{active : activeTab === 'mobsurvs'}" @click="activeTab = 'mobsurvs'">Mobile Licenses</div>
				<div class="popup-tab" :class="{active : activeTab === 'toplinesurvs'}" @click="activeTab = 'toplinesurvs'">Topline Surveys</div>
			</div>
		</template>
		<template v-slot:main>
			<div v-if="activeTab === 'main'" class="contained-popup-content">
				<div style="padding: 10px 0 10px 0;">
					<span class="settlbl">Group ID: {{groupEditOb.id}}</span>
				</div>
				<table>
					<tbody>
					<tr>
						<td><span class="settlbl">Name</span></td>
						<td><input type="text" class="saver" v-model="groupEditOb.name"></td>
					</tr>
					<tr v-if="store.user.superUser === 1 || store.user.perms.rrusersFull === 1">
						<td><span class="settlbl">Owner</span></td>
						<td><select class="saver selector" v-model="groupEditOb.managers">
							<option value="0"></option>
							<option v-for="cl in store.rrUsers" :key="cl.id" :value="cl.id">{{cl.email}}</option>
						</select></td>
					</tr>
					<tr v-if="store.user.superUser === 1">
						<td><span class="settlbl">Deleted</span></td>
						<td><select class="saver selector" v-model="groupEditOb.deleted">
							<option v-for="cl in yesNos" :key="cl.val" :value="cl.val">{{ucfirst(cl.name)}}</option>
						</select></td>
					</tr>

					</tbody>
				</table>
			</div>
			<div v-if="activeTab === 'members'" class="contained-popup-content" style="position: relative; height: 500px; width: calc(800px - 60px);">
				<div style="display: flex; justify-content: space-between; position: fixed; width: inherit; background-color: #FFF;">
					<div style="width: 49%; height: 20px;">
						<p class="selectable-header">
							Available Users
							<input type="text" class="selectable-searchbox" v-model="usersrchtext" />
						</p>
					</div>
					<div style="width: 49%; height: 20px;">
						<p class="selectable-header">Currently Assigned Members</p>
					</div>
				</div>
				<div style="display: flex; justify-content: space-between; margin-top: 30px;">
					<div style="width: 49%">
						<div v-for="gr in usersWhoAreNotMembers" :key="gr.id" class="selectable-item">
							<span>{{gr.email}}</span>
							<span class="add" @click="addMember(gr.id)">Add</span>
						</div>
					</div>
					<div style="width: 49%">
						<div v-for="gr in usersWhoAreMembers" :key="gr.id" class="selectable-item">
							<span>{{gr.email}}</span>
							<span class="rem" @click="delMember(gr.id)">X</span>
						</div>
					</div>
				</div>
			</div>
			<div v-if="activeTab === 'fullsurvs'" class="contained-popup-content" style="position: relative; height: 500px; width: calc(800px - 60px);">
				<div style="display: flex; justify-content: space-between; position: fixed; width: inherit; background-color: #FFF;">
					<div style="width: 49%; height: 20px;">
						<p class="selectable-header">
							Available Desktop Surveys
							<input type="text" class="selectable-searchbox" v-model="desktopsrchtext" />
						</p>
					</div>
					<div style="width: 49%; height: 20px;">
						<p class="selectable-header">Assigned To User</p>
					</div>
				</div>
				<div style="display: flex; justify-content: space-between; margin-top: 30px;">
					<div style="width: 49%">
						<div v-for="gr in desktopSurvsNotAssigned" :key="gr.id" class="selectable-item">
							<span>{{gr.name}}</span>
							<span class="add" @click="addToDesktops(gr.id)">Add</span>
						</div>
					</div>
					<div style="width: 49%">
						<div v-for="gr in desktopSurvsAssigned" :key="gr.id" class="selectable-item">
							<span>{{gr.name}}</span>
							<span class="rem" @click="delFromDesktops(gr.id)">X</span>
						</div>
					</div>
				</div>
			</div>
			<div v-if="activeTab === 'mobsurvs'" class="contained-popup-content" style="position: relative; height: 500px; width: calc(800px - 60px);">
				<div style="display: flex; justify-content: space-between; position: fixed; width: inherit; background-color: #FFF;">
					<div style="width: 49%; height: 20px;">
						<p class="selectable-header">
							Available Mobile Licenses
							<input type="text" class="selectable-searchbox" v-model="moblicsrchtext" />
						</p>
					</div>
					<div style="width: 49%; height: 20px;">
						<p class="selectable-header">Assigned To User</p>
					</div>
				</div>
				<div style="display: flex; justify-content: space-between; margin-top: 30px;">
					<div style="width: 49%">
						<div v-for="gr in moblicNotAssigned" :key="gr.id" class="selectable-item">
							<span>{{gr.name}}</span>
							<span class="add" @click="addToMoblics(gr.id)">Add</span>
						</div>
					</div>
					<div style="width: 49%">
						<div v-for="gr in moblicAssigned" :key="gr.id" class="selectable-item">
							<span>{{gr.name}}</span>
							<span class="rem" @click="delFromMoblics(gr.id)">X</span>
						</div>
					</div>
				</div>
			</div>
			<div v-if="activeTab === 'toplinesurvs'" class="contained-popup-content" style="position: relative; height: 500px; width: calc(800px - 60px);">
				<div style="display: flex; justify-content: space-between; position: fixed; width: inherit; background-color: #FFF;">
					<div style="width: 49%; height: 20px;">
						<p class="selectable-header">
							Available Topline Surveys
							<input type="text" class="selectable-searchbox" v-model="toplinesrchtext" />
						</p>
					</div>
					<div style="width: 49%; height: 20px;">
						<p class="selectable-header">Assigned To User</p>
					</div>
				</div>
				<div style="display: flex; justify-content: space-between; margin-top: 30px;">
					<div style="width: 49%">
						<div v-for="gr in toplineSurvsNotAssigned" :key="gr.id" class="selectable-item">
							<span>{{gr.name}}</span>
							<span class="add" @click="addToToplines(gr.id)">Add</span>
						</div>
					</div>
					<div style="width: 49%">
						<div v-for="gr in toplineSurvsAssigned" :key="gr.id" class="selectable-item">
							<span>{{gr.name}}</span>
							<span class="rem" @click="delFromToplines(gr.id)">X</span>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-slot:buttons>
			<button type="button" class="option-button colbtn" @click="saveGroupInfo">Save Info</button>
			<button type="button" class="option-button" @click="closeCancel">Cancel</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'

export default {
	name: "FreqUserGroupEditPopup",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		grobin: Object,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			activeTab: 'main',

			usersrchtext: '',
			desktopsrchtext: '',
			toplinesrchtext: '',
			moblicsrchtext: '',
			groupEditOb: {},
			yesNos: [
				{val: 0, name: 'No'},
				{val: 1, name: 'Yes'},
			],
		}
	},
	computed: {
		usersWhoAreMembers() {
			return this.store.rrUsers.filter(item => this.groupEditOb.userMemberIds.includes(item.id))
		},
		usersWhoAreNotMembers() {
			if(this.usersrchtext.length === 0) {
				return this.store.rrUsers.filter(item => !this.groupEditOb.userMemberIds.includes(item.id))
			}
			else {
				return this.store.rrUsers.filter(item => !this.groupEditOb.userMemberIds.includes(item.id) && item.email.toLowerCase().includes(this.usersrchtext.toLowerCase()))
			}
		},
		desktopSurvsAssigned() {
			return this.store.rrSurveys.filter(item => this.groupEditOb.desktopIds.includes(item.id))
		},
		desktopSurvsNotAssigned() {
			if(this.desktopsrchtext.length === 0) {
				return this.store.rrSurveys.filter(item => !this.groupEditOb.desktopIds.includes(item.id))
			}
			else {
				return this.store.rrSurveys.filter(item => !this.groupEditOb.desktopIds.includes(item.id) && item.name.toLowerCase().includes(this.desktopsrchtext.toLowerCase()))
			}
		},
		toplineSurvsAssigned() {
			return this.store.rrSurveys.filter(item => this.groupEditOb.toplineIds.includes(item.id))
		},
		toplineSurvsNotAssigned() {
			if(this.toplinesrchtext.length === 0) {
				return this.store.rrSurveys.filter(item => !this.groupEditOb.toplineIds.includes(item.id))
			}
			else {
				return this.store.rrSurveys.filter(item => !this.groupEditOb.toplineIds.includes(item.id) && item.name.toLowerCase().includes(this.toplinesrchtext.toLowerCase()))
			}
		},
		moblicAssigned() {
			return this.store.rrMobileLicenses.filter(item => this.groupEditOb.mobLicIds.includes(item.id))
		},
		moblicNotAssigned() {
			if(this.moblicsrchtext.length === 0) {
				return this.store.rrMobileLicenses.filter(item => !this.groupEditOb.mobLicIds.includes(item.id))
			}
			else {
				return this.store.rrMobileLicenses.filter(item => !this.groupEditOb.mobLicIds.includes(item.id) && item.name.toLowerCase().includes(this.moblicsrchtext.toLowerCase()))
			}
		},
	},
	methods: {
		addMember(uid) {
			if(!this.groupEditOb.userMemberIds.includes(uid)) this.groupEditOb.userMemberIds.push(uid)
		},
		delMember(uid) {
			this.groupEditOb.userMemberIds = this.groupEditOb.userMemberIds.filter(item => item !== uid)
		},
		addToDesktops(suid) {
			if(!this.groupEditOb.desktopIds.includes(suid)) this.groupEditOb.desktopIds.push(suid)
		},
		delFromDesktops(suid) {
			this.groupEditOb.desktopIds = this.groupEditOb.desktopIds.filter(item => item !== suid)
		},
		addToToplines(suid) {
			if(!this.groupEditOb.toplineIds.includes(suid)) this.groupEditOb.toplineIds.push(suid)
		},
		delFromToplines(suid) {
			this.groupEditOb.toplineIds = this.groupEditOb.toplineIds.filter(item => item !== suid)
		},
		addToMoblics(licid) {
			if(!this.groupEditOb.mobLicIds.includes(licid)) this.groupEditOb.mobLicIds.push(licid)
		},
		delFromMoblics(licid) {
			this.groupEditOb.mobLicIds = this.groupEditOb.mobLicIds.filter(item => item !== licid)
		},
		closeCancel() {
			this.$emit('close');
		},
		saveGroupInfo() {
			if (this.isFetching === false) {
				this.store.showLoader = true;
				this.isFetching = true;
				let targ = this.store.urlroot + "central/post-vue/freq/save-rrusergroup";
				let myob = {};
				for(let pr in this.groupEditOb) {
					myob[pr] = this.groupEditOb[pr]
				}
				let sendob = {editedgrid: this.groupEditOb.id, groupob: myob};
				this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
					this.isFetching = false;
					this.$emit('closerefresh');
				}.bind(this));
			}
		},
		populateContent() {
			if(this.grobin) {
				for(let pr in this.grobin) {
					this.groupEditOb[pr] = this.grobin[pr];
				}
			}
			if(this.grobin === null || this.grobin.id === 0) { //blank item
				let myname = '';
				if(this.grobin && this.grobin.name && this.grobin.name.length > 0) myname = this.grobin.name
				this.groupEditOb = {
					id: 0,
					name: myname,
					deleted: 0,
					managers: 0,
					userMemberIds: [],
					mobLicIds: [],
					desktopIds: [],
					toplineIds: [],
				}
			}
		},
	},
	watch: {
		grobin() {
			this.populateContent()
		},
	},
	mounted() {
		this.populateContent()
	},
}
</script>

<style scoped>
.saver {
	width: 200px;
}
table tr td:nth-of-type(2) {
	padding-right: 50px;
}
.selector {
	width: 216px;
}
#survid {
	padding-left: 13px;
}
.mx-datepicker {
	width: 216px;
}
</style>