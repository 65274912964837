<template>
	<BlanketOverlayWithPopup v-if="userobin && userobin.id >= 0" title="Frequency User Editor" width="800" :hastabs="true" v-on:close="closeCancel">
		<template v-slot:tabs>
			<p style="font-weight: 500; font-size: 12px; margin: -15px 0 15px;">{{userobin.email}}</p>
			<div class="popup-tabs">
				<div class="popup-tab" :class="{active : activeTab === 'main'}" @click="activeTab = 'main'">Main</div>
				<div class="popup-tab" :class="{active : activeTab === 'groupperms'}" @click="activeTab = 'groupperms'">Groups</div>
				<div class="popup-tab" :class="{active : activeTab === 'fullsurvs'}" @click="activeTab = 'fullsurvs'">Desktop Surveys</div>
				<div class="popup-tab" :class="{active : activeTab === 'mobsurvs'}" @click="activeTab = 'mobsurvs'">Mobile Licenses</div>
				<div class="popup-tab" :class="{active : activeTab === 'toplinesurvs'}" @click="activeTab = 'toplinesurvs'">Topline Surveys</div>
			</div>
		</template>
		<template v-slot:main>
			<div v-if="activeTab === 'main'" class="contained-popup-content">
				<div style="padding: 10px 0 10px 0;">
					<span class="settlbl">User ID: {{userobin.id}}</span>
				</div>
				<table>
					<tbody>
					<tr>
						<td><span class="settlbl">Email</span></td>
						<td><input type="text" class="saver" v-model="userEditOb.email"></td>
					</tr>
					<tr>
						<td><span class="settlbl">Market</span></td>
						<td><input type="text" class="saver" v-model="userEditOb.market"></td>
					</tr>
					<tr>
						<td><span class="settlbl">Status</span></td>
						<td><select class="saver selector" v-model="userEditOb.status">
							<option value=""></option>
							<option v-for="cl in statuses" :key="cl" :value="cl">{{ucfirst(cl)}}</option>
						</select></td>
					</tr>
					<tr>
						<td><span class="settlbl" title="User can't see results until 11am on Frequency Desktop">Delayed Release User</span></td>
						<td><select class="saver selector" v-model="userEditOb.lateReleaseUser">
							<option v-for="cl in yesNos" :key="cl.val" :value="cl.val">{{ucfirst(cl.name)}}</option>
						</select></td>
					</tr>
					<tr v-if="store.user.superUser === 1 || store.user.perms.rrusersFull === 1">
						<td><span class="settlbl" title="Eg Jen at SCA, can assign sub-users and create new users">Manager (can have sub-users)</span></td>
						<td><select class="saver selector" v-model="userEditOb.manager">
							<option v-for="cl in yesNos" :key="cl.val" :value="cl.val">{{ucfirst(cl.name)}}</option>
						</select></td>
					</tr>
					<tr v-if="store.user.superUser === 1">
						<td><span class="settlbl">View Community Stns?</span></td>
						<td><select class="saver selector" v-model="userEditOb.viewCommunities">
							<option v-for="cl in yesNos" :key="cl.val" :value="cl.val">{{ucfirst(cl.name)}}</option>
						</select></td>
					</tr>
					<tr>
						<td><span class="settlbl">Agency User</span></td>
						<td><select class="saver selector" v-model="userEditOb.agencyUser">
							<option v-for="cl in yesNos" :key="cl.val" :value="cl.val">{{ucfirst(cl.name)}}</option>
						</select></td>
					</tr>
					<tr v-if="store.user.superUser === 1">
						<td><span class="settlbl" title="For Xtra users only">Early Release User</span></td>
						<td><select class="saver selector" v-model="userEditOb.earlyRelease">
							<option v-for="cl in yesNos" :key="cl.val" :value="cl.val">{{ucfirst(cl.name)}}</option>
						</select></td>
					</tr>
					<tr v-if="store.user.superUser === 1 || store.user.perms.rrusersFull === 1">
						<td><span class="settlbl">IP Whitelist (comma sep)</span></td>
						<td><input type="text" class="saver" v-model="userEditOb.ipWhitelist"></td>
					</tr>
					<tr>
						<td colspan="2">
							<div>
								<p class="boldme" style="margin-top: 10px;margin-bottom: 5px;">Token</p>
								<p>{{userobin.token}}</p>
								<p class="boldme" style="margin-top: 10px;margin-bottom: 5px;">Desktop Link</p>
								<p>https://regionalratings.com.au/rrLand?email={{userobin.email}}&token={{userobin.token}}&mobile=0</p>
								<p class="boldme" style="margin-top: 10px;margin-bottom: 5px;">Mobile Link</p>
								<p>https://regionalratings.com.au/rrMLand?email={{userobin.email}}&token={{userobin.token}}&mobile=1</p>
							</div>
						</td>
					</tr>

					</tbody>
				</table>
			</div>
			<div v-if="activeTab === 'groupperms'" class="contained-popup-content" style="position: relative; height: 500px; width: calc(800px - 60px);">
				<div style="display: flex; justify-content: space-between; position: fixed; width: inherit; background-color: #FFF;">
					<div style="width: 49%; height: 20px;">
						<p class="selectable-header">
							Available Groups
							<input type="text" class="selectable-searchbox" v-model="grsrchtext" />
						</p>
					</div>
					<div style="width: 49%; height: 20px;">
						<p class="selectable-header">User Is Member of Groups</p>
					</div>
				</div>
				<div style="display: flex; justify-content: space-between; margin-top: 30px;">
					<div style="width: 49%">
						<div v-for="gr in groupsNotMemberOf" :key="gr.id" class="selectable-item">
							<span :class="{boldme : gr.isSystemGroup === 1}">{{gr.name}}</span>
							<span class="add" @click="addToGroups(gr.id)">Add</span>
						</div>
					</div>
					<div style="width: 49%">
						<div v-for="gr in groupsMemberOf" :key="gr.id" class="selectable-item">
							<span :class="{boldme : gr.isSystemGroup === 1}">{{gr.name}}</span>
							<span class="rem" @click="delFromGroups(gr.id)">X</span>
						</div>
					</div>
				</div>
			</div>
			<div v-if="activeTab === 'fullsurvs'" class="contained-popup-content" style="position: relative; height: 500px; width: calc(800px - 60px);">
				<div style="display: flex; justify-content: space-between; position: fixed; width: inherit; background-color: #FFF;">
					<div style="width: 49%; height: 20px;">
						<p class="selectable-header">
							Available Desktop Surveys
							<input type="text" class="selectable-searchbox" v-model="desktopsrchtext" />
						</p>
					</div>
					<div style="width: 49%; height: 20px;">
						<p class="selectable-header">Assigned To User</p>
					</div>
				</div>
				<div style="display: flex; justify-content: space-between; margin-top: 30px;">
					<div style="width: 49%">
						<div v-for="gr in desktopSurvsNotAssigned" :key="gr.id" class="selectable-item">
							<span>{{gr.name}}</span>
							<span class="add" @click="addToDesktops(gr.id)">Add</span>
						</div>
					</div>
					<div style="width: 49%">
						<div v-for="gr in desktopSurvsAssigned" :key="gr.id" class="selectable-item">
							<span>{{gr.name}}</span>
							<span class="rem" @click="delFromDesktops(gr.id)">X</span>
						</div>
					</div>
				</div>
			</div>
			<div v-if="activeTab === 'mobsurvs'" class="contained-popup-content" style="position: relative; height: 500px; width: calc(800px - 60px);">
				<div style="display: flex; justify-content: space-between; position: fixed; width: inherit; background-color: #FFF;">
					<div style="width: 49%; height: 20px;">
						<p class="selectable-header">
							Available Mobile Licenses
							<input type="text" class="selectable-searchbox" v-model="moblicsrchtext" />
						</p>
					</div>
					<div style="width: 49%; height: 20px;">
						<p class="selectable-header">Assigned To User</p>
					</div>
				</div>
				<div style="display: flex; justify-content: space-between; margin-top: 30px;">
					<div style="width: 49%">
						<div v-for="gr in moblicNotAssigned" :key="gr.id" class="selectable-item">
							<span>{{gr.name}}</span>
							<span class="add" @click="addToMoblics(gr.id)">Add</span>
						</div>
					</div>
					<div style="width: 49%">
						<div v-for="gr in moblicAssigned" :key="gr.id" class="selectable-item">
							<span>{{gr.name}}</span>
							<span class="rem" @click="delFromMoblics(gr.id)">X</span>
						</div>
					</div>
				</div>
			</div>
			<div v-if="activeTab === 'toplinesurvs'" class="contained-popup-content" style="position: relative; height: 500px; width: calc(800px - 60px);">
				<div style="display: flex; justify-content: space-between; position: fixed; width: inherit; background-color: #FFF;">
					<div style="width: 49%; height: 20px;">
						<p class="selectable-header">
							Available Topline Surveys
							<input type="text" class="selectable-searchbox" v-model="toplinesrchtext" />
						</p>
					</div>
					<div style="width: 49%; height: 20px;">
						<p class="selectable-header">Assigned To User</p>
					</div>
				</div>
				<div style="display: flex; justify-content: space-between; margin-top: 30px;">
					<div style="width: 49%">
						<div v-for="gr in toplineSurvsNotAssigned" :key="gr.id" class="selectable-item">
							<span>{{gr.name}}</span>
							<span class="add" @click="addToToplines(gr.id)">Add</span>
						</div>
					</div>
					<div style="width: 49%">
						<div v-for="gr in toplineSurvsAssigned" :key="gr.id" class="selectable-item">
							<span>{{gr.name}}</span>
							<span class="rem" @click="delFromToplines(gr.id)">X</span>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-slot:buttons>
			<button type="button" class="option-button colbtn" @click="saveUserInfo">Save Info</button>
			<button type="button" class="option-button" @click="closeCancel">Cancel</button>
		</template>
	</BlanketOverlayWithPopup>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'

export default {
	name: "FreqUserEditPopup",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		userobin: Object,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			activeTab: 'main',

			grsrchtext: '',
			desktopsrchtext: '',
			toplinesrchtext: '',
			moblicsrchtext: '',
			userEditOb: {},
			statuses: ['active','deleted','disabled'],
			yesNos: [
				{val: 0, name: 'No'},
				{val: 1, name: 'Yes'},
			],
		}
	},
	computed: {
		groupsMemberOf() {
			return this.store.rrUserGroups.filter(item => this.userEditOb.groupIds.includes(item.id))
		},
		groupsNotMemberOf() {
			if(this.grsrchtext.length === 0) {
				return this.store.rrUserGroups.filter(item => !this.userEditOb.groupIds.includes(item.id))
			}
			else {
				return this.store.rrUserGroups.filter(item => !this.userEditOb.groupIds.includes(item.id) && item.name.toLowerCase().includes(this.grsrchtext.toLowerCase()))
			}
		},
		desktopSurvsAssigned() {
			return this.store.rrSurveys.filter(item => this.userEditOb.desktopIds.includes(item.id))
		},
		desktopSurvsNotAssigned() {
			if(this.desktopsrchtext.length === 0) {
				return this.store.rrSurveys.filter(item => !this.userEditOb.desktopIds.includes(item.id))
			}
			else {
				return this.store.rrSurveys.filter(item => !this.userEditOb.desktopIds.includes(item.id) && item.name.toLowerCase().includes(this.desktopsrchtext.toLowerCase()))
			}
		},
		toplineSurvsAssigned() {
			return this.store.rrSurveys.filter(item => this.userEditOb.toplineIds.includes(item.id))
		},
		toplineSurvsNotAssigned() {
			if(this.toplinesrchtext.length === 0) {
				return this.store.rrSurveys.filter(item => !this.userEditOb.toplineIds.includes(item.id))
			}
			else {
				return this.store.rrSurveys.filter(item => !this.userEditOb.toplineIds.includes(item.id) && item.name.toLowerCase().includes(this.toplinesrchtext.toLowerCase()))
			}
		},
		moblicAssigned() {
			return this.store.rrMobileLicenses.filter(item => this.userEditOb.mobLicIds.includes(item.id))
		},
		moblicNotAssigned() {
			if(this.moblicsrchtext.length === 0) {
				return this.store.rrMobileLicenses.filter(item => !this.userEditOb.mobLicIds.includes(item.id))
			}
			else {
				return this.store.rrMobileLicenses.filter(item => !this.userEditOb.mobLicIds.includes(item.id) && item.name.toLowerCase().includes(this.moblicsrchtext.toLowerCase()))
			}
		},
	},
	methods: {
		addToGroups(grid) {
			if(!this.userEditOb.groupIds.includes(grid)) this.userEditOb.groupIds.push(grid)
		},
		delFromGroups(grid) {
			this.userEditOb.groupIds = this.userEditOb.groupIds.filter(item => item !== grid)
		},
		addToDesktops(suid) {
			if(!this.userEditOb.desktopIds.includes(suid)) this.userEditOb.desktopIds.push(suid)
		},
		delFromDesktops(suid) {
			this.userEditOb.desktopIds = this.userEditOb.desktopIds.filter(item => item !== suid)
		},
		addToToplines(suid) {
			if(!this.userEditOb.toplineIds.includes(suid)) this.userEditOb.toplineIds.push(suid)
		},
		delFromToplines(suid) {
			this.userEditOb.toplineIds = this.userEditOb.toplineIds.filter(item => item !== suid)
		},
		addToMoblics(licid) {
			if(!this.userEditOb.mobLicIds.includes(licid)) this.userEditOb.mobLicIds.push(licid)
		},
		delFromMoblics(licid) {
			this.userEditOb.mobLicIds = this.userEditOb.mobLicIds.filter(item => item !== licid)
		},
		closeCancel() {
			this.$emit('close');
		},
		saveUserInfo() {
			if (this.isFetching === false) {
				this.store.showLoader = true;
				this.isFetching = true;
				let targ = this.store.urlroot + "central/post-vue/freq/save-rruser";
				let myob = {};
				for(let pr in this.userEditOb) {
					myob[pr] = this.userEditOb[pr]
				}
				let sendob = {editedrruid: this.userEditOb.id, userob: myob};
				this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
					this.isFetching = false;
					this.$emit('closerefresh');
				}.bind(this));
			}
		},
		populateContent() {
			if(this.userobin) {
				for(let pr in this.userobin) {
					this.userEditOb[pr] = this.userobin[pr];
				}
			}
			if((this.userobin === null || this.userobin.id === 0) && this.userobin.isClone !== true) { //blank item
				let myemail = '';
				if(this.userobin.email.length > 0) myemail = this.userobin.email
				this.userEditOb = {
					id: 0,
					email: myemail,
					token: '',
					market: '',
					status: 'active',
					lateReleaseUser: 0,
					manager: 0,
					viewCommunities: 0,
					agencyUser: 0,
					earlyRelease: 0,
					ipWhitelist: '',
					groupIds: [],
					mobLicIds: [],
					desktopIds: [],
					toplineIds: [],
				}
			}
		},
	},
	watch: {
		userobin() {
			this.populateContent()
		},
	},
	mounted() {
		this.populateContent()
	},
}
</script>

<style scoped>
.saver {
	width: 200px;
}
table tr td:nth-of-type(2) {
	padding-right: 50px;
}
.selector {
	width: 216px;
}
.mx-datepicker {
	width: 216px;
}
</style>