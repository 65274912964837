<template>
	<div v-if="store.sessionOk">
		<div id="container" :class="{pageLeftMenuTransposed : store.leftmenuhidden}">
			<LeftMenuWL></LeftMenuWL>
			<div id="bg">
				<WLUserPopup v-if="editUserPopupShown" :userobin="selectedUserOb"
					:clientsandpanels="clientsandpanels" :survsforperm="survsforperm" :contactlists="contactlists"
					@close="handleClose($event)"
				></WLUserPopup>
				<div id="maincontent" class="noTopMenuBar">
					<div class="wl-admin-container">
						<p class="wl-main-headline" style="margin-bottom: 0;">Wavelength User Management</p>
						<div class="altable-holder">
							<div style="display: flex; align-items: center; justify-content: flex-start; padding: 5px 0;">
								<div class="" style="margin: 5px 0;">
									<input type="text" style="width: 300px;" class="wl-input-text wl-input-padding-large" v-model="filterText" placeholder="Search users..." />
								</div>
								<button @click="addNewUser" style="margin-left: 15px;" type="button" class="option-button colbtn">Add New User</button>
							</div>
							<br/>
							<table class="altbl noborderv">
								<thead>
								<tr>
									<td v-for="col in cols" :key="col.title" class="headingtext" @click="sortColumn(col.fldid)" data-f-bold="true">
										<span>{{col.title}}</span>
									</td>
								</tr>
								</thead>
								<tbody>
								<tr v-for="rowitem in rowsForPage" :key="rowitem.id">
									<td>{{rowitem.id}}</td>
									<td>{{rowitem.email}}</td>
									<td>{{rowitem.fname}} {{rowitem.lname}}</td>
									<td>{{rowitem.station}}</td>
									<td>{{ucfirst(rowitem.status)}}</td>
									<td>
										<span class="action-icon" title="Edit User" @click.stop="editUser(rowitem)"><i class="mdi mdi-pencil"></i></span>
										<span class="action-icon" title="Send password reminder email" @click.stop="emailPassRemind(rowitem)"><i class="mdi mdi-at"></i></span>
										<span class="action-icon" title="Clone user" @click.stop="cloneUserToNew(rowitem)"><i class="mdi mdi-content-copy"></i></span>
									</td>
								</tr>
								</tbody>
							</table>
							<div style="margin: 15px 0; display: flex; align-items: center;">
								<ALTablePaginator
									:paginationpage="paginationPage"
									:paginatedrowscount="paginatedRows.length"
									v-on:nextpage="nextpage" v-on:prevpage="prevpage"
									v-on:setpage="paginationPage = $event"
								></ALTablePaginator>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import LeftMenuWL from "@/components/LeftMenuWL";
import {globalMixin} from "@/mixins";
import ALTablePaginator from "@/components/ALTablePaginator.vue";
import WLUserPopup from "@/components/wl-usrmgr/WLUserPopup.vue";

export default {
	name: "WavelengthUserManagerPage",
	mixins: [globalMixin],
	components: {
		WLUserPopup,
		ALTablePaginator,
		LeftMenuWL
	},
	props: {},
	data: function () {
		return {
			store: store,

			// table stuff
			filterText: '',
			sortColName: null,
			sortColOrder: 'desc',
			paginationRowsPerPage: 20,
			paginationPage: 1,
			cols: [
				{title: 'ID', sortable: true, fldid: 'id'},
				{title: 'Email', sortable: true, fldid: 'email'},
				{title: 'Name', sortable: true, fldid: ''},
				{title: 'Site', sortable: true, fldid: ''},
				{title: 'Status', sortable: true, fldid: 'status'},
				{title: 'Functions', sortable: false, fldid: ''},
			],
			//end table stuff

			userlist: [],
			selectedUserOb: null,
			editUserPopupShown: false,

			clientsandpanels: [],
			survsforperm: [],
			contactlists: [],
		}
	},
	computed: {
		//table stuff
		rows() {
			return this.tblPrepareFilteredRows(this.userlist, this.filterText, this.sortColName, this.sortColOrder)
		},
		paginatedRows() {
			return this.tblGetPaginatedRows(this.rows, this.paginationRowsPerPage)
		},
		rowsForPage() {
			if(!this.paginationRowsPerPage || !this.paginationPage) return [this.rows]
			return this.paginatedRows[this.paginationPage-1]
		},
		//end table stuff
	},
	methods: {
		handleClose(usrob) {
			this.editUserPopupShown = false
			if(usrob && usrob.id) {
				let idx = this.userlist.findIndex(x => x.id === usrob.id)
				if(idx > -1) this.userlist[idx] = usrob
				else this.userlist.push(usrob)
			}
		},
		//table stuff
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
		prevpage() {
			if(this.paginationPage > 1) this.paginationPage--
		},
		nextpage() {
			if(this.paginationPage < this.paginatedRows.length) this.paginationPage++
		},
		//end table stuff
		getWLUserList() {
			let targ = this.store.urlroot + "central/post-vue/wavelength-user-admin/get-user-list"
			let sendob = {};
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false;
				if(ret.users) this.userlist = ret.users
				if(ret.clientsAndPanels) this.clientsandpanels = ret.clientsAndPanels
				if(ret.survsForPerm) this.survsforperm = ret.survsForPerm
				if(ret.contactlists) this.contactlists = ret.contactlists
				this.store.showLoader = false
			}.bind(this));
		},
		editUser(rowitem) {
			this.selectedUserOb = rowitem
			this.editUserPopupShown = true
		},
		emailPassRemind(rowitem) {
			let targ = this.store.urlroot + "central/post-vue/send-wluser-pwd"
			let sendob = {email: rowitem.email, confirm: 1}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				if(ret.error) {
					this.showKalertError(ret.error)
				}
				else if(ret.success) {
					this.showKalertSuccess(ret.success)
					this.showingForgetPwd = false
				}
				this.store.showLoader = false
			}.bind(this));
		},
		cloneUserToNew(rowitem) {
			rowitem = JSON.parse(JSON.stringify(rowitem))
			let p = window.prompt('Please enter user email address');
			if(p.length > 0 && p.includes("@")) {
				let emval = p.toLowerCase().trim();
				let matches = this.userlist.find(item => item.email.toLowerCase() === emval);
				if(!matches) {
					this.selectedUserOb = {
						id: 0,
						email: emval,
						password: this.genRandomString(8),
						fname: rowitem.fname,
						lname: rowitem.lname,
						station: rowitem.station,
						phone: rowitem.phone,
						status: 'active',

						perms: rowitem.perms,
						permArr: rowitem.permArr,
						surveyPermArr: rowitem.surveyPermArr,
						contactListsAllowed: rowitem.contactListsAllowed,

						permStr: rowitem.permStr,
						surveyPermStr: rowitem.surveyPermStr,
						contactListsAllowedStr: rowitem.contactListsAllowedStr,
					}
					this.editUserPopupShown = true
				}
				else alert("Email address already has an account")
			}
			else {
				alert("Please enter a valid email address")
			}
		},
		addNewUser() {
			let p = window.prompt('Please enter user email address');
			if(p.length > 0 && p.includes("@")) {
				let emval = p.toLowerCase().trim();
				let matches = this.userlist.find(item => item.email.toLowerCase() === emval);
				if(!matches) {
					this.selectedUserOb = {
						id: 0,
						email: emval,
						password: this.genRandomString(8),
						fname: '',
						lname: '',
						station: '',
						phone: '',
						status: 'active',

						perms: {
							qb: 0,
							dash: 0,
							hookcentral: 0,
							CATIUser: 0,
							CATISuper: 0,
							siteAdmin: 0,
							analysis: 0,
							edms: 0,
							contactlists: 0,
							contacts: 0,
						},
						permArr: [],
						surveyPermArr: [],
						contactListsAllowed: [],

						permStr: '',
						surveyPermStr: '',
						contactListsAllowedStr: '',
					}
					this.editUserPopupShown = true
				}
				else alert("Email address already has an account")
			}
			else {
				alert("Please enter a valid email address")
			}
		},
	},
	watch: {
		'store.sessionOk'() {
			if(store.sessionOk && store.user.superUser !== 1 && store.user.perms.siteAdmin !== 1 && store.user.perms.CATISuper !== 1) {
				this.$router.push('/error/no-access');
			}
		},
		rows() { //whenever filter text or sort is changed, go back to Page 1 in pagination
			this.paginationPage = 1
		},
	},
	mounted() {
		if(store.sessionOk && store.user.superUser !== 1 && store.user.perms.siteAdmin !== 1 && store.user.perms.CATISuper !== 1) {
			this.$router.push('/error/no-access');
		}
		this.store.showLoader = true
		this.getWLUserList()
	},
	created() {
		document.title = "Manage Users";
		this.store.activeLmenuLink = 'wlusers';
	}
}
</script>

<style scoped>

</style>