<template>
	<div class="bgpage" style="min-height: 100vh;">
		<div id="app-container">

			<div style="padding-top: 50px;">

				<div id="logo-holder">
					<a href="https://app.yourwavelength.com">
						<img src="@/assets/wavelength_logo.png" alt="Logo" />
					</a>
				</div>
				<div id="form-wrapper">

					<div class="form-input-group">
						<input type='email' v-model="email" placeholder="Email Address" />
						<div class="form-input-fail" :class="{shown : emailState === false}">Please enter a valid email address.</div>
					</div>
					<div v-if="showingForgetPwd === false" class="form-input-group" style="margin-bottom: 30px;">
						<input type='password' v-model="pass" placeholder="Password" @keyup.enter="submitForm" />
					</div>

					<button type="button" v-if="showingForgetPwd === false" class="login-button" v-on:click="submitForm" v-bind:class="{'button-is-working':(form1working === true)}">
						<span>Submit</span>
						<WorkingButtonInsert v-if="form1working === true"></WorkingButtonInsert>
					</button>

					<button v-if="showingForgetPwd === true" class="login-button" v-on:click="forgetPwd" v-bind:class="{'button-is-working':(form1working === true)}">
						<span>Resend Password</span>
						<WorkingButtonInsert v-if="form1working === true"></WorkingButtonInsert>
					</button>

					<p v-if="showingForgetPwd === false" @click="showingForgetPwd = true" class="text-link">I've forgotten my password</p>
					<p v-if="showingForgetPwd" @click="showingForgetPwd = false" class="text-link">Cancel</p>

				</div>

			</div>
		</div>

	</div>
</template>

<script>
import axios from "axios";
import {store} from '@/store.js'
import WorkingButtonInsert from "@/components/WorkingButtonInsert";
import {globalMixin} from "@/mixins";

export default {
	name: 'LoginPage',
	components: {
		WorkingButtonInsert
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			headerNotes: "",
			requestedLink: "", //for use after successful login

			showingForgetPwd: false,

			email: '',
			pass: '',

			checksTriggered: false,
			form1working: false,
		}
	},
	computed: {
		emailState() {
			if(this.checksTriggered === false && this.email.length >= 0 && /.+@.+\..+/.test(this.email) === false) {
				return null;
			}
			else if(this.email.length >= 0 && /.+@.+\..+/.test(this.email)) {
				return true;
			}
			return false;
		},
	},
	methods: {
		submitForm() {
			if(this.form1working === false) { //only proceed if not already waiting on callback
				this.store.kalert.shown = false
				this.checksTriggered = true; //check for validations
				let checkitems = [this.emailState];
				for(let i=0; i<checkitems.length; i++) {
					if(checkitems[i] === false) return false;
				}
				this.form1working = true; //make button working class after pulse effect is finished
				let self = this;
				axios.post(
					self.store.urlroot + "central/post-vue/do-wavelength-login",
					JSON.stringify({
						email: this.email,
						pass: this.pass,
					})
				).then(function(response) {
					let ret = response.data; //response.data is returned info
					if(ret.error) {
						self.form1working = false;
						self.showKalertError(ret.error);
					}
					else if(ret.wlsesstoken) {
						self.store.wlsesstoken = ret.wlsesstoken
						self.store.user = ret.user
						window.localStorage.setItem('wlSessToken', ret.wlsesstoken)
						window.localStorage.setItem('wlUserId', ret.user.id)
						self.getUserData().then(function(rets) {
							if(rets.sessionok === true) {
								self.store.user = rets.user
								self.store.sessionOk = true
							}
							// //transfer to WL interstitial to set PHP session ID for the old domain
							// let newloc = self.store.urlroot+"central/login-wlvue-int?uid="+ret.user.id+"&wlsesstoken="+ret.wlsesstoken;
							// if(self.requestedLink && self.requestedLink.length > 1) {
							// 	newloc += "&ref="+self.requestedLink
							// }
							// window.location = newloc

							//the following is for when the new Vue app is everything (no old WL PHP session required)
							if (self.requestedLink.length > 0) self.$router.push(self.requestedLink);
							else self.$router.push("/home");
						});
					}
				}).catch(function(error) {
					console.log(error)
				});
			}
		},
		getUserData() {
			let self = this;
			return axios.post(
				self.store.urlroot + "central/post-vue/sesscheck",
				JSON.stringify({
					uid: this.store.user.id,
					wlsesstoken: this.store.wlsesstoken,
				})
			).then(function (response) {
				return response.data; //response.data is returned info
			}).catch(function (error) {
				console.log(error);
				return false;
			});
		},
		forgetPwd() {
			if(this.form1working === false) { //only proceed if not already waiting on callback
				this.checksTriggered = true; //check for validations
				let checkitems = [this.emailState];
				for(let i=0; i<checkitems.length; i++) {
					if(checkitems[i] === false) return false;
				}
				this.form1working = true; //make button working class after pulse effect is finished
				let self = this;
				axios.post(
					self.store.urlroot + "central/post-vue/send-wluser-pwd",
					JSON.stringify({
						email: this.email,
						confirm: 1,
					})
				).then(function(response) {
					let ret = response.data; //response.data is returned info
					self.form1working = false;
					if(ret.error) {
						self.showKalertError(ret.error);
					}
					else if(ret.success) {
						self.showKalertSuccess(ret.success);
						self.showingForgetPwd = false;
					}
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
	},
	watch: {

	},
	props: {

	},
	mounted() {
		document.title = "Wavelength Login";
	},
	created() {
		//handle incoming GET parameters
		let urlparams = this.$route.query;
		if(urlparams.activated !== undefined && parseInt(urlparams.activated) === 1) {
			this.headerNotes = "Thanks for activating your account - you can now log in!";
		}
		if(urlparams.passreset !== undefined && parseInt(urlparams.passreset) === 1) {
			this.headerNotes = "Your password has been changed - you can now log in!";
		}
		if(urlparams.ref !== undefined && urlparams.ref.length > 0 && !urlparams.ref.includes('logout')) {
			this.requestedLink = urlparams.ref;
		}
	},
}
</script>

<style scoped>
.bgpage {
	background-image: url('~@/assets/feature-background.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
#app-container {
	width: 100%;
	max-width: 500px;
	margin: 0 auto;
}
#logo-holder {
	text-align: center;
	background-color: #0f75a5;
	padding: 30px 0;
}
#logo-holder img {
	height: 60px;
}
#form-wrapper {
	padding: 60px;
	margin-bottom: 80px;
	box-shadow: 10px 0 40px 6px rgba(0,0,0,.08);
	background: #FFFFFF;
	text-align: left;
}
#form-wrapper input {
	border: 1px solid #BBB;
	width: calc(100% - 20px);
	display: block;
	padding: 10px;
	font-size: 18px;
}
.form-input-fail {
	padding: 5px 0;
	visibility: hidden;
}
.form-input-fail.shown {
	visibility: visible;
}
.login-button {
	margin-bottom: 20px;
	background: #0f75a5;
	color: #fff;
	font-size: 15px;
	border: none;
	text-transform: uppercase;
	letter-spacing: 0.08em;
	font-weight: bold;
	transition: ease-in-out 0.5s;
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 15px 0;
	cursor: pointer;
	position: relative;
}
.login-button:hover {
	background: #1d1f38;
}

</style>
