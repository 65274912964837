<template>
	<div>
		<BlanketOverlayWithPopup v-if="hasPopulated === true" title="Edit Weighting" width="500" :hastabs="false" v-on:close="closeCancel">
			<template v-slot:main>
				<div class="contained-popup-content">
					<span class="label">Type of Weighting</span>
					<select class="saver selector" v-model="weightInfo.type">
						<option value="targets">Demo Targets to calculate (BPR collect)</option>
						<option value="supplied">Supplied weights to assign (no calc required)</option>
						<option value="fromCATI">Use CATI population quotas calc (WL CATI)</option>
					</select>
					<br/>
					<span class="label">Male 10-17</span><input v-model="weightInfo.d1" id="d1" type="text" /><br/>
					<span class="label">Male 18-24</span><input v-model="weightInfo.d2" id="d2" type="text" /><br/>
					<span class="label">Male 25-39</span><input v-model="weightInfo.d3" id="d3" type="text" /><br/>
					<span class="label">Male 40-54</span><input v-model="weightInfo.d4" id="d4" type="text" /><br/>
					<span class="label">Male 55+</span><input v-model="weightInfo.d5" id="d5" type="text" /><br/>
					<br/>
					<span class="label">Female 10-17</span><input v-model="weightInfo.d6" id="d6" type="text" /><br/>
					<span class="label">Female 18-24</span><input v-model="weightInfo.d7" id="d7" type="text" /><br/>
					<span class="label">Female 25-39</span><input v-model="weightInfo.d8" id="d8" type="text" /><br/>
					<span class="label">Female 40-54</span><input v-model="weightInfo.d9" id="d9" type="text" /><br/>
					<span class="label">Female 55+</span><input v-model="weightInfo.d10" id="d10" type="text" /><br/>
					<br/>
					<span class="label">Area 1</span><input v-model="weightInfo.a1" id="a1" type="text" /><br/>
					<span class="label">Area 2</span><input v-model="weightInfo.a2" id="a2" type="text" /><br/>
					<span class="label">Area 3</span><input v-model="weightInfo.a3" id="a3" type="text" /><br/>
					<span class="label">Area 4</span><input v-model="weightInfo.a4" id="a4" type="text" /><br/>
					<span class="label">Area 5</span><input v-model="weightInfo.a5" id="a5" type="text" /><br/>
				</div>
			</template>
			<template v-slot:buttons>
				<button type="button" class="option-button colbtn" @click="saveWeightInfo">Save Info</button>
				<button type="button" class="option-button" @click="closeCancel">Cancel</button>
			</template>
		</BlanketOverlayWithPopup>
	</div>
</template>

<script>
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup";
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'
export default {
	name: "WeightingEditPopup",
	mixins: [globalMixin],
	components: {
		BlanketOverlayWithPopup,
	},
	props: {
		survobin: Object,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,

			hasPopulated: false,
			weightInfo: {
				type: 'fromCATI',
				d1: null,
				d2: null,
				d3: null,
				d4: null,
				d5: null,
				d6: null,
				d7: null,
				d8: null,
				d9: null,
				d10: null,
				a1: null,
				a2: null,
				a3: null,
				a4: null,
				a5: null,
			},
		}
	},
	computed: {

	},
	methods: {
		closeCancel() {
			this.$emit('close', false);
		},
		getInfo() {
			let targ = this.store.urlroot + "central/post-vue/home/get-weighting-info"
			let sendob = {survid: this.survobin.dataloc}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				this.store.showLoader = false
				let retdata = null
				if(ret.data && ret.data.type) {
					retdata = ret.data
					this.weightInfo = retdata
				}
				this.hasPopulated = true
			}.bind(this));
		},
		saveWeightInfo() {
			if (this.isFetching === false) {
				this.store.showLoader = true
				this.isFetching = true
				let targ = this.store.urlroot + "central/post-vue/regional-ratings-admin/save-weighting-info";
				let sendob = {survid: this.survobin.dataloc, wtob: this.weightInfo};
				this.simplePost(targ, sendob).then(function () { //use ret in function as returned data
					this.isFetching = false
					this.store.showLoader = false
					this.$emit('close', true)
				}.bind(this));
			}
		},
	},
	watch: {

	},
	mounted() {
		this.getInfo()
	},
}
</script>
<style scoped>
.saver {
	width: 200px;
}
.selector {
	width: 216px;
	padding: 4px 3px;
}
.label {
	display: block;
	float: left;
	width: 110px;
	margin-top: 5px;
}
.contained-popup-content input {
	padding: 4px 3px;
	margin: 2px 0;
	border: none;
}
</style>