<template>
	<div v-if="store.sessionOk">
		<div id="container" :class="{pageLeftMenuTransposed : store.leftmenuhidden}">
			<LeftMenuWL></LeftMenuWL>
			<div id="bg">
				<div id="maincontent" class="noTopMenuBar">
					<div class="tabdiv">
						<div class="tab" :class="{active : activetab === 'UserLists'}" @click="activetab = 'UserLists'">User Lists</div>
						<div class="tab" :class="{active : activetab === 'EditHooks'}" @click="activetab = 'EditHooks'">Edit Hooks</div>
						<div class="tab" :class="{active : activetab === 'HooksPending'}" @click="activetab = 'HooksPending'">Hooks Pending</div>
						<div class="tab" :class="{active : activetab === 'LinkedUsers'}" @click="activetab = 'LinkedUsers'">Linked Users</div>
						<div v-if="store.user.superUser === 1" class="tab" :class="{active : activetab === 'ImportHooks'}" @click="activetab = 'ImportHooks'">Import</div>
					</div>

					<div v-if="activetab === 'UserLists'" class="wl-admin-container">
						<HooksAdminUserLists></HooksAdminUserLists>
					</div>
					<div v-if="activetab === 'EditHooks'" class="wl-admin-container">
						<HooksAdminSearchContent></HooksAdminSearchContent>
					</div>
					<div v-if="activetab === 'HooksPending'" class="wl-admin-container">
						<HooksAdminPendingHooks></HooksAdminPendingHooks>
					</div>
					<div v-if="activetab === 'LinkedUsers'" class="wl-admin-container">
						<HooksAdminUserLinks></HooksAdminUserLinks>
					</div>

					<div v-if="activetab === 'ImportHooks'" class="wl-admin-container">
						<button @click="processFTPHooks" class="option-button colbtn">Process FTP Hooks</button>
						<button @click="handleDropbox" style="margin-left: 20px;" class="option-button colbtn">Handle Dropbox</button>

						<div style="margin: 20px 0;" v-html="ajaxReturn"></div>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import LeftMenuWL from "@/components/LeftMenuWL";
import {globalMixin} from "@/mixins";
import HooksAdminSearchContent from "@/components/hooks-admin/HooksAdminSearchContent.vue";
import HooksAdminPendingHooks from "@/components/hooks-admin/HooksAdminPendingHooks.vue";
import HooksAdminUserLinks from "@/components/hooks-admin/HooksAdminUserLinks.vue";
import HooksAdminUserLists from "@/components/hooks-admin/HooksAdminUserLists.vue";

export default {
	name: "HookcentralAdminPage",
	mixins: [globalMixin],
	components: {
		HooksAdminUserLists,
		HooksAdminUserLinks,
		HooksAdminPendingHooks,
		HooksAdminSearchContent,
		LeftMenuWL
	},
	props: {},
	data: function () {
		return {
			store: store,
			activetab: 'UserLists',

			//hook search table stuff
			filterText: '',
			sortColName: null,
			sortColOrder: 'desc',
			paginationRowsPerPage: 20,
			paginationPage: 1,
			cols: [
				{title: 'ID', sortable: true, fldid: 'id'},
				{title: 'Artist', sortable: true, fldid: 'artist'},
				{title: 'Title', sortable: true, fldid: 'title'},
				{title: 'Year', sortable: true, fldid: 'year'},
				{title: 'Functions', sortable: false, fldid: ''},
			],
			//end table stuff

			ajaxReturn: null,
		}
	},
	computed: {},
	methods: {
		//table stuff
		rows() {
			return this.tblPrepareFilteredRows(this.store.rrUsers, this.filterText, this.sortColName, this.sortColOrder)
		},
		paginatedRows() {
			return this.tblGetPaginatedRows(this.rows, this.paginationRowsPerPage)
		},
		rowsForPage() {
			if(!this.paginationRowsPerPage || !this.paginationPage) return [this.rows]
			return this.paginatedRows[this.paginationPage-1]
		},
		//end table stuff
		handleDropbox() {
			let targ = this.store.urlroot + "central/post-vue/hooks/handle-upload-from-dropbox"
			this.simplePost(targ, {}).then(function (ret) { //use ret in function as returned data
				this.ajaxReturn = ret.ajaxReturn
			}.bind(this));
		},
		processFTPHooks() {
			let targ = this.store.urlroot + "central/post-vue/hooks/import-ftp-hooks"
			this.simplePost(targ, {}).then(function (ret) { //use ret in function as returned data
				this.ajaxReturn = ret.ajaxReturn
			}.bind(this));
		},
	},
	watch: {
		'store.sessionOk'() {
			if(store.sessionOk && store.user.superUser !== 1) {
				this.$router.push('/error/no-access');
			}
		},
	},
	mounted() {
		if(store.sessionOk && store.user.superUser !== 1) {
			this.$router.push('/error/no-access');
		}
	},
	created() {
		document.title = "Hook Administration Page";
		this.store.activeLmenuLink = 'hooksadmin';
	}
}
</script>

<style scoped>

</style>