<template>
	<div class="wl-admin-container">

		<div>
			<div class="changeboxes">
				<input type="text" v-model="searchTitle" placeholder="Search Title" />
				<input type="text" v-model="replaceTitle" placeholder="Replace Title" />
				<input type="text" v-model="searchString" placeholder="Search String" />
				<input type="text" v-model="replaceString" placeholder="Replace String" />
				<i class="mdi mdi-close" @click="searchReplaceClear"></i>
				<i class="mdi mdi-thumb-up" @click="doSearchReplace"></i>
			</div>

			<div class="demoitems">
				<div class="demo-item" v-for="d in demos" :key="d.id" :class="getDemoClass(d)">
					<div class="oldvals">
						<div class="demo-item-title">{{d.title}}</div>
						<div class="demo-item-dstring">{{d.dstring}}</div>
					</div>
					<div class="newvals">
						<div class="demo-item-title">
							<input type="text" v-model="d.title_new" />
						</div>
						<div class="demo-item-dstring">
							<input type="text" v-model="d.dstring_new" />
						</div>
						<div class="funcs">
							<i class="mdi mdi-trash-can" title="Revert" @click="revertDemo(d)"></i>
							<i class="mdi mdi-content-save-all-outline" title="Save" @click="saveDemos([d])"></i>
						</div>
					</div>
				</div>
			</div>

			<button @click="saveAll" style="margin-top: 40px;" type="button" class="option-button colbtn">Save All</button>

		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "DemoEditor",
	components: {

	},
	props: {
		demoids: Array,
	},
	mixins: [globalMixin],
	data: function() {
		return {
			store: store,
			filterText: '',

			searchTitle: '',
			replaceTitle: '',
			searchString: '',
			replaceString: '',


			demos: [],

			freshlySavedIds: [],

		}
	},
	computed: {

	},
	methods: {
		getDemoClass(d) {
			let c = ''
			if(d.title !== d.title_new || d.dstring !== d.dstring_new) c = 'changed'
			if(this.freshlySavedIds.includes(d.id)) c += ' saved'
			return c
		},
		searchReplaceClear() {
			this.searchTitle = ''
			this.replaceTitle = ''
			this.searchString = ''
			this.replaceString = ''
		},
		doSearchReplace() {
			for(let d of this.demos) {
				d.title_new = d.title_new.replace(this.searchTitle, this.replaceTitle)
				d.dstring_new = d.dstring_new.replace(this.searchString, this.replaceString)
			}
		},
		revertDemo(demoOb) {
			demoOb.title_new = demoOb.title
			demoOb.dstring_new = demoOb.dstring
		},
		saveAll() {
			this.saveDemos(this.demos)
		},
		saveDemos(demoObArr) {
			console.log(demoObArr)
			let targ = this.store.urlroot + "central/post-vue/analysis/save-demos-admin"
			let sendob = {
				demos: demoObArr
			}
			return this.simplePost(targ, sendob).then(function (ret) {
				if(ret.changed) {
					this.handleRemoveSavedIds(ret.changed)
					this.getDemos()
				}
			}.bind(this))
		},
		handleRemoveSavedIds(ids) {
			this.freshlySavedIds = ids
			setTimeout(function() {
				this.freshlySavedIds = []
			}.bind(this), 2000)
		},
		getDemos() {
			let targ = this.store.urlroot + "central/post-vue/analysis/get-demos-admin"
			let sendob = {
				demoIds: this.demoids
			}
			return this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.demos = []
				let mydems = []
				if(ret.demos) {
					for(let d of ret.demos) {
						let mydem = d
						mydem.title_new = d.title
						mydem.dstring_new = d.dstring
						mydems.push(mydem)
					}
				}
				this.demos = mydems
			}.bind(this))
		},

	},
	watch: {
		demoids() {
			if(this.demoids.length > 0) this.getDemos()
		},
	},
	mounted() {
		if(this.demoids.length > 0) this.getDemos()
	},
}
</script>

<style scoped>
.changeboxes {
	display: flex;
	gap: 10px;
	align-items: center;
}
.changeboxes input {
	border: 1px solid #CCC;
	width: 250px;
}
.changeboxes .mdi {
	font-size: 20px;
	cursor: pointer;
}
.oldvals, .newvals {
	display: flex;
	gap: 10px;
}
.oldvals {
	margin-bottom: 10px;
}
.demo-item-title {
	width: 250px;
}
.demo-item-dstring {
	width: 800px;
}
.demoitems {
	margin-top: 20px;
}
.demo-item {
	padding: 20px 10px;
	border-bottom: 1px solid #CCC;
}
.demo-item.changed {
	background: #FFCCCC;
}
.demo-item.saved {
	background: #DDFFDD;
}
.newvals input {
	font-family: arial;
	resize: none;
	padding: 5px 8px;
	border: 1px solid transparent;
	background: #f1f2f6;
	width: 90%;
}
.funcs .mdi {
	cursor: pointer;
	font-size: 20px;
	margin-right: 10px;
}
</style>