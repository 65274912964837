<template>
	<div v-if="store.sessionOk">
		<div id="container" :class="{pageLeftMenuTransposed : store.leftmenuhidden}">
			<LeftMenuWL></LeftMenuWL>
			<div id="bg">
				<div id="maincontent" class="noTopMenuBar">
					<div v-if="historyView === true" class="wl-admin-container">
						<a @click="historyView = false" class="wl-paginate-btn"><i class="fa fa-chevron-left"></i> Back</a>
						<p class="wl-main-headline">Recent Contact List Activity</p>
						<table class="altbl noborderv">
							<thead>
							<tr>
								<td v-for="col in cols" :key="col.title" class="headingtext" @click="sortColumn(col.fldid)" data-f-bold="true">
									<span>{{col.title}}</span>
								</td>
							</tr>
							</thead>
							<tbody>
							<tr v-for="rowitem in rowsForPage" :key="rowitem.id" :class="{boldme : rowitem.status === 'open'}">
								<td>{{rowitem.timestamp}}</td>
								<td>{{rowitem.event}}</td>
								<td>{{rowitem.email}}</td>
								<td>{{rowitem.notes}}</td>
							</tr>
							</tbody>
						</table>
						<div style="margin: 15px 0; display: flex; align-items: center;">
							<ALTablePaginator
								:paginationpage="paginationPage"
								:paginatedrowscount="paginatedRows.length"
								v-on:nextpage="nextpage" v-on:prevpage="prevpage"
								v-on:setpage="paginationPage = $event"
							></ALTablePaginator>
						</div>
					</div>

					<div v-else class="wl-admin-container">
						<p class="wl-main-headline">Contact Lists</p>
						<select class="wl-input-select" v-model="chosenListId">
							<option v-for="c in contactlistlist" :value="c.id" :key="c.id">{{c.name}}</option>
						</select>
						<div style="margin: 20px 0;">
							<p style='font-size: 18px;'>
								{{nActiveSubs}} active subscribers now
								<span v-if="store.user.superUser === 1 || store.user.perms.edms === 1" title="Contact List History" style="margin-left:8px;">
									<i class="mdi mdi-timer-sand" style="cursor: pointer;" @click="getMailgunHistory"></i>
								</span>
								<span v-if="store.user.superUser === 1" title='View subscribers' style='margin-left:10px;'>
									<i class="mdi mdi-magnify" style="cursor: pointer;" @click="viewContactList"></i>
								</span>
							</p>
						</div>
						<div style="padding: 30px; border: 1px solid #CCC; margin: 20px 0 20px; height: 350px; max-width: 700px;">
							<ApexLineTrend :chartdata="chartData" filename="Subscribers" :fetching="isFetching" :forcetocategory="true"></ApexLineTrend>
						</div>
						<div class="dash-html" v-html="dashHtml"></div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import LeftMenuWL from "@/components/LeftMenuWL";
import {globalMixin} from "@/mixins";
import ApexLineTrend from "@/components/charts/ApexLineTrend.vue";
import ALTablePaginator from "@/components/ALTablePaginator.vue";
export default {
	name: "ContactListsPage",
	mixins: [globalMixin],
	components: {
		ALTablePaginator,
		ApexLineTrend,
		LeftMenuWL
	},
	props: {},
	data: function () {
		return {
			store: store,
			isFetching: false,

			contactlistlist: [],
			chosenListId: null,

			dashHtml: '',
			nActiveSubs: null,
			chartData: [],

			mailgunhistory: [],
			historyView: false,

			// table stuff
			filterText: '',
			sortColName: null,
			sortColOrder: 'desc',
			paginationRowsPerPage: 20,
			paginationPage: 1,
			cols: [
				{title: 'Time', fldid: 'timestamp'},
				{title: 'Event', fldid: 'event'},
				{title: 'Email', fldid: 'email'},
				{title: 'Notes', fldid: 'notes'},
			],
			//end table stuff
		}
	},
	computed: {
		//table stuff
		rows() {
			return this.tblPrepareFilteredRows(this.mailgunhistory, this.filterText, this.sortColName, this.sortColOrder)
		},
		paginatedRows() {
			return this.tblGetPaginatedRows(this.rows, this.paginationRowsPerPage)
		},
		rowsForPage() {
			if(!this.paginationRowsPerPage || !this.paginationPage) return [this.rows]
			return this.paginatedRows[this.paginationPage-1]
		},
		//end table stuff
	},
	methods: {
		//table stuff
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
		prevpage() {
			if(this.paginationPage > 1) this.paginationPage--
		},
		nextpage() {
			if(this.paginationPage < this.paginatedRows.length) this.paginationPage++
		},
		//end table stuff
		getAvailableLists() {
			let targ = this.store.urlroot + "central/post-vue/contact-lists/get-contactlists"
			let sendob = {}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				if(ret.contactlists) this.contactlistlist = ret.contactlists
				this.store.showLoader = false
				if(this.chosenListId === null && this.contactlistlist.length > 0) {
					this.chosenListId = this.contactlistlist[0].id
				}
			}.bind(this))
		},
		getListDashboard() {
			if(this.isFetching === false && this.chosenListId) {
				this.isFetching = true
				this.store.showLoader = true
				let targ = this.store.urlroot + "central/post-vue/contact-lists/get-dashboard"
				let sendob = {
					listId: this.chosenListId,
				}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					if(ret.dashHtml) this.dashHtml = ret.dashHtml
					if(ret.nActiveSubs) this.nActiveSubs = ret.nActiveSubs
					if(ret.chartData) this.chartData = ret.chartData
					this.store.showLoader = false
				}.bind(this))
			}
		},
		getMailgunHistory() {
			if(this.isFetching === false && this.chosenListId) {
				this.isFetching = true
				this.store.showLoader = true
				let targ = this.store.urlroot + "central/post-vue/contact-lists/get-mailgun-history"
				let sendob = {
					lookup_id: this.chosenListId,
					type: 'contactlist',
				}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					if(ret.mailgunhistory) this.mailgunhistory = ret.mailgunhistory
					this.historyView = true
					this.store.showLoader = false
				}.bind(this))
			}
		},
		viewContactList() {
			this.$router.push('/contacts/' + this.chosenListId)
		},
	},
	watch: {
		'store.sessionOk'() {
			if(store.sessionOk && store.user.superUser !== 1 && store.user.perms.contactlists !== 1) {
				this.$router.push('/error/no-access')
			}
		},
		chosenListId() {
			if(this.chosenListId) this.getListDashboard()
		},
	},
	mounted() {
		if(store.sessionOk && store.user.superUser !== 1 && store.user.perms.contactlists !== 1) {
			this.$router.push('/error/no-access')
		}
		this.getAvailableLists()
	},
	created() {
		document.title = "Contact Lists";
		this.store.activeLmenuLink = 'contact-lists'
	}
}
</script>

<style scoped>
.dash-html {
	line-height: 1.6;
}
</style>
