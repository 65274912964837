<template>
	<div v-if="store.sessionOk">
		<div id="container" :class="{pageLeftMenuTransposed : store.leftmenuhidden}">
			<LeftMenuWL></LeftMenuWL>
			<div id="bg">
				<div id="maincontent" class="noTopMenuBar">
					<div class="tabdiv">
						<div class="tab" :class="{active : activetab === 'contacts'}" @click="activetab = 'contacts'">Contacts</div>
						<div class="tab" :class="{active : activetab === 'segments'}" @click="activetab = 'segments'">Segments</div>
					</div>

					<div v-if="activetab === 'segments'" class="wl-admin-container">
						<EditSegmentScreen v-if="editSegmentScreenShown"
							:incominglistid="chosenListId" :incomingsegob="activeSegmentOb"
							@close="closeSegmentEditor($event)"
						></EditSegmentScreen>
						<div>
							<select class="wl-input-select" v-model="chosenListId">
								<option v-for="c in contactlistlist" :value="c.id" :key="c.id">{{c.name}}</option>
							</select>
							<span class="wl-icon-btn wl-btn-border-grey" @click="editSegment(null)" style="margin-right: 10px;"><i class="mdi mdi-plus"></i>Add New</span>
						</div>
						<table class="altbl noborderv" style="margin-top: 20px;">
							<thead>
							<tr>
								<td class="headingtext" data-f-bold="true"><span>ID</span></td>
								<td class="headingtext" data-f-bold="true"><span>Title</span></td>
								<td class="headingtext" data-f-bold="true"><span>String</span></td>
								<td class="headingtext" data-f-bold="true"><span>Size</span></td>
								<td class="headingtext" data-f-bold="true"><span>Functions</span></td>
							</tr>
							</thead>
							<tbody>
							<tr v-for="rowitem in segments" :key="rowitem.id">
								<td>{{rowitem.id}}</td>
								<td>{{rowitem.title}}</td>
								<td>{{rowitem.sqlFilterString}}</td>
								<td>{{rowitem.numContacts}}</td>
								<td>
									<i class="mdi mdi-pencil" style="cursor: pointer; margin-right: 5px; font-size: 14px;" @click="editSegment(rowitem)"></i>
								</td>
							</tr>
							</tbody>
						</table>
					</div>

					<div v-if="activetab === 'contacts'" class="wl-admin-container">
						<EditContactScreen v-if="editContactShown"
							:contactob="activeContactOb" :incominglistid="chosenListId"
							@close="closeContactEditor($event)"
						></EditContactScreen>
						<div>
							<select class="wl-input-select" v-model="chosenListId">
								<option v-for="c in contactlistlist" :value="c.id" :key="c.id">{{c.name}}</option>
							</select>
							<input placeholder="Search contacts..." class="wl-input-text wl-input-padding-large" type="text" v-model="filterText">
							<span class="wl-icon-btn wl-btn-border-grey" @click="getContacts" style="margin: 0 10px;">Search</span>
							<span class="wl-icon-btn wl-btn-border-grey" @click="editContact(null)" style="margin-right: 10px;"><i class="mdi mdi-plus"></i>Add New</span>
							<span class="wl-icon-btn wl-btn-border-grey" @click="cleanList"><i class="mdi mdi-broom"></i>Clean</span>
						</div>

						<div style="margin: 20px 0;">
							<div v-if="showingContactHistory && activeContactOb">
								<span class="wl-paginate-btn" @click="closeHistory"><i class="mdi mdi-chevron-left"> Back</i></span>
								<p class="boldme" style="margin: 20px 0 10px;">{{activeContactOb.email}}</p>
								<div v-for="(h, ind) in historyEvents" :key="ind" class="event-item">{{h}}</div>
							</div>
							<div v-else>
								<p>{{numActiveInList}} active subscribers</p>
								<table class="altbl noborderv" style="margin-top: 20px;">
									<thead>
									<tr>
										<td v-for="col in cols" :key="col.title" class="headingtext" @click="sortColumn(col.fldid)" data-f-bold="true">
											<span>{{col.title}}</span>
										</td>
									</tr>
									</thead>
									<tbody>
									<tr v-for="rowitem in rowsForPage" :key="rowitem.id">
										<td>{{rowitem.id}}</td>
										<td>{{rowitem.email}}</td>
										<td>{{rowitem.name}}</td>
										<td>{{rowitem.city}}</td>
										<td class="ctr">{{rowitem.age}}</td>
										<td class="ctr">{{genderToMF(rowitem.gender)}}</td>
										<td class="ctr">{{rowitem.segmentId}}</td>
										<td>{{ucfirst(rowitem.status)}}</td>
										<td>{{rowitem.optinDate}}</td>
										<td>{{rowitem.lastOpenDate}}</td>
										<td>{{rowitem.lastClickDate}}</td>
										<td>{{rowitem.lastCompleteDate}}</td>
										<td>
											<i class="mdi mdi-pencil" style="cursor: pointer; margin-right: 5px; font-size: 14px;" @click="editContact(rowitem)"></i>
											<i class="mdi mdi-timer-sand" style="cursor: pointer; font-size: 14px;" @click="contactHistory(rowitem)"></i>
										</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>

					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import LeftMenuWL from "@/components/LeftMenuWL";
import {globalMixin} from "@/mixins";
import EditContactScreen from "@/components/contacts/EditContactScreen.vue";
import EditSegmentScreen from "@/components/contacts/EditSegmentScreen.vue";

export default {
	name: "ContactsPage",
	mixins: [globalMixin],
	components: {
		EditSegmentScreen,
		EditContactScreen,
		LeftMenuWL
	},
	props: {},
	data: function () {
		return {
			store: store,
			isFetching: false,
			activetab: 'contacts', //segments

			contactlistlist: [],
			chosenListId: null,
			numActiveInList: null,

			contacts: [],
			segments: [],

			activeContactOb: null,
			historyEvents: [],
			showingContactHistory: false,
			editContactShown: false,

			activeSegmentOb: null,
			editSegmentScreenShown: false,

			// table stuff
			filterText: '',
			sortColName: null,
			sortColOrder: 'desc',
			paginationRowsPerPage: 50,
			paginationPage: 1,
			cols: [
				{title: 'ID', fldid: 'id'},
				{title: 'Email', sortable: true, fldid: 'email'},
				{title: 'Name', sortable: true, fldid: 'name'},
				{title: 'City', sortable: true, fldid: 'city'},
				{title: 'Age', sortable: true, fldid: 'age'},
				{title: 'Gdr', sortable: true, fldid: 'gender'},
				{title: 'Seg', sortable: true, fldid: 'segmentId'},
				{title: 'Status', sortable: true, fldid: 'status'},
				{title: 'OptInDate', sortable: true, fldid: 'optinDate'},
				{title: 'Open', sortable: true, fldid: 'lastOpenDate'},
				{title: 'Click', sortable: true, fldid: 'lastClickDate'},
				{title: 'Last Complete', sortable: true, fldid: 'lastCompleteDate'},
				{title: 'Functions', fldid: null},
			],
			//end table stuff
		}
	},
	computed: {
		//table stuff
		rows() {
			return this.tblPrepareFilteredRows(this.contacts, this.filterText, this.sortColName, this.sortColOrder)
		},
		paginatedRows() {
			return this.tblGetPaginatedRows(this.rows, this.paginationRowsPerPage)
		},
		rowsForPage() {
			if(!this.paginationRowsPerPage || !this.paginationPage) return [this.rows]
			return this.paginatedRows[this.paginationPage-1]
		},
		//end table stuff
	},
	methods: {
		genderToMF(intval) {
			if(intval === null) return ''
			if(intval === 1) return 'M'
			if(intval === 2) return 'F'
			return ''
		},
		//table stuff
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
		prevpage() {
			if(this.paginationPage > 1) this.paginationPage--
		},
		nextpage() {
			if(this.paginationPage < this.paginatedRows.length) this.paginationPage++
		},
		//end table stuff
		getAvailableLists() {
			let targ = this.store.urlroot + "central/post-vue/contact-lists/get-contactlists"
			let sendob = {}
			this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
				this.isFetching = false
				if(ret.contactlists) this.contactlistlist = ret.contactlists
				this.store.showLoader = false
				if(this.chosenListId === null && this.contactlistlist.length > 0) {
					this.chosenListId = this.contactlistlist[0].id
				}
			}.bind(this))
		},
		getContacts() {
			if(this.isFetching === false && this.chosenListId) {
				this.isFetching = true
				this.store.showLoader = true
				let targ = this.store.urlroot + "central/post-vue/contacts/get-contacts-admin"
				let sendob = {
					listId: this.chosenListId,
					lookup: this.filterText,
				}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					if(ret.contacts) this.contacts = ret.contacts
					if(ret.numActiveInList) this.numActiveInList = ret.numActiveInList
					this.store.showLoader = false
				}.bind(this))
			}
		},
		contactHistory(contactOb) {
			if(this.isFetching === false && this.chosenListId) {
				this.isFetching = true
				this.store.showLoader = true
				let targ = this.store.urlroot + "central/post-vue/contacts/get-contact-history"
				let sendob = {
					listId: this.chosenListId,
					contactId: contactOb.id,
					contactEmail: contactOb.email,
				}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					this.activeContactOb = contactOb
					if(ret.historyEvents) this.historyEvents = ret.historyEvents
					this.showingContactHistory = true
					this.store.showLoader = false
				}.bind(this))
			}
		},
		closeHistory() {
			this.showingContactHistory = false
			this.historyEvents = []
			this.activeContactOb = null
		},
		editContact(contactob) {
			this.activeContactOb = contactob
			this.editContactShown = true
		},
		closeContactEditor(doRefresh) {
			this.editContactShown = false
			this.activeContactOb = null
			if(doRefresh === true) this.getContacts()
		},
		cleanList() {
			if(this.isFetching === false && this.chosenListId) {
				let p = prompt("Clean if no activity in previous n days", "120")
				if(p) {
					this.isFetching = true
					this.store.showLoader = true
					let targ = this.store.urlroot + "central/post-vue/contacts/clean-list"
					let sendob = {
						listId: this.chosenListId,
						days: p,
					}
					this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
						this.isFetching = false
						if(ret.message) alert(ret.message)
						this.getContacts()
					}.bind(this))
				}
			}
		},
		getSegmentsList() {
			if(this.isFetching === false && this.chosenListId) {
				this.isFetching = true
				this.store.showLoader = true
				let targ = this.store.urlroot + "central/post-vue/contacts/get-segments"
				let sendob = {
					listId: this.chosenListId,
				}
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.isFetching = false
					if(ret.segments) this.segments = ret.segments
					this.store.showLoader = false
				}.bind(this))
			}
		},
		editSegment(segob) {
			this.activeSegmentOb = segob
			this.editSegmentScreenShown = true
		},
		closeSegmentEditor(doRefresh) {
			this.editSegmentScreenShown = false
			this.activeSegmentOb = null
			if(doRefresh === true) this.getSegmentsList()
		},
	},
	watch: {
		'store.sessionOk'() {
			if(store.sessionOk && store.user.superUser !== 1 && store.user.perms.contacts !== 1) {
				this.$router.push('/error/no-access')
			}
		},
		chosenListId() {
			if(this.chosenListId && this.chosenListId > 0) {
				this.$router.push('/contacts/'+this.chosenListId)
				if(this.activetab === 'contacts') this.getContacts()
				else if(this.activetab === 'segments') this.getSegmentsList()
			}
		},
		activetab() {
			if(this.activetab === 'contacts') this.getContacts()
			else if(this.activetab === 'segments') this.getSegmentsList()
		},
	},
	mounted() {
		if(store.sessionOk && store.user.superUser !== 1 && store.user.perms.contacts !== 1) {
			this.$router.push('/error/no-access')
		}
		this.getAvailableLists()
	},
	created() {
		document.title = "Contacts Admin"
		this.store.activeLmenuLink = 'contacts'
		let urlparams = this.$route.params
		if(urlparams.contactlistid !== undefined) {
			this.chosenListId = parseInt(urlparams.contactlistid)
		}
	}
}
</script>

<style scoped>
.event-item {
	line-height: 1.4;
}
</style>