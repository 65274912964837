<template>
	<div class="wl-admin-container">

		<MarketEditPopup v-if="(activeEditMarket || forceNewMarket === true) && showEditMarketPopup === true"
			:mktobin="activeEditMarket" :forcenewmarket="forceNewMarket" v-on:close="closeEditMarketPopup($event)"
		></MarketEditPopup>

		<div>
			<div style="margin-bottom: 20px;">
				<input type="text" placeholder="Search by name" v-model="filterText" class="wl-input-text wl-input-padding-large" />
				<button @click="addNewMarket" style="margin-left: 15px;" type="button" class="option-button colbtn">Add New Market</button>
			</div>
			<table class="altbl noborderv">
				<thead>
				<tr class='headrow'>
					<td class="headingtext">ID</td>
					<td class="headingtext">Market Name</td>
					<td class="headingtext">Shortcode</td>
					<td class="headingtext">State</td>
					<td class="headingtext">Population</td>
					<td class="headingtext">Surveyed</td>
				</tr>
				</thead>
				<tbody>
				<tr v-for="mkt in filteredMarkets" :key="mkt.id" @click="editMarket(mkt)">
					<td>{{mkt.id}}</td>
					<td>{{mkt.name}}</td>
					<td>{{mkt.shortcode}}</td>
					<td>{{mkt.state}}</td>
					<td>{{mkt.indpop}}</td>
					<td>{{intToYes(mkt.surveyed)}}</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import MarketEditPopup from "@/components/regional-ratings-admin/MarketEditPopup.vue";
export default {
	name: "MarketsPage",
	components: {MarketEditPopup},
	props: {},
	mixins: [globalMixin],
	data: function() {
		return {
			store: store,
			filterText: '',

			activeEditMarket: null,
			showEditMarketPopup: false,
			forceNewMarket: false,
		}
	},
	computed: {
		filteredMarkets() {
			if(this.filterText.length > 0) { //either already selected or matching text
				return this.store.rrMarkets.filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()))
			}
			return this.store.rrMarkets
		},
	},
	methods: {
		addNewMarket() {
			this.forceNewMarket = true
			this.activeEditMarket = null
			this.showEditMarketPopup = true
		},
		editMarket(mktob) {
			this.forceNewMarket = false
			this.activeEditMarket = mktob
			this.showEditMarketPopup = true
		},
		closeEditMarketPopup(newmktob) {
			this.showEditMarketPopup = false
			if(newmktob && newmktob !== false) {
				let ind = this.store.rrMarkets.findIndex(item => item.id === newmktob.id)
				if(ind > -1) this.store.rrMarkets[ind] = newmktob
			}
		},
	},
	mounted() {
		this.getRRAdminInfo()
	},
}
</script>

<style scoped>

</style>