<template>
	<div class="menuItem" @click="$emit('clicked')">
		<router-link v-if="routerlink && routerlink.length > 0" :to="routerlink">
			<span>{{label}}</span>
		</router-link>
		<a v-else-if="extlink && extlink.length > 0" :target="extTargBlank" :href="extlink"><span>{{label}}</span></a>
		<span v-else>{{label}}</span>
	</div>
</template>

<script>
export default {
	name: "TopMenuItem",
	components: {},
	props: {
		label: String,
		routerlink: String,
		extlink: String,
		targblank: Boolean,
	},
	data: function () {
		return {}
	},
	computed: {
		extTargBlank() { //by default open in blank target/new tab unless overridden by incoming prop
			if(this.targblank === false) return '_self';
			return '_blank';
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.menuItem {
	width: calc(100% - 30px);
	padding-right: 30px;
	text-decoration: none;
	color: #FFF;
	transition: background 0.3s ease;
	cursor: pointer;
}
.menuItem a {
	color: #FFF;
	text-decoration: none;
}
.menuItem:hover {
	background-color: #23A6EE;
	color: #FFF;
	text-shadow: none;
}
.menuItem span {
	display: block;
	padding: 10px;
}
</style>