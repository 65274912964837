<template>
	<div class="menubar">
		<div class="menu-inside">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "TopMenuBar",
	components: {},
	props: {},
	data: function () {
		return {}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.menubar {
	background: #0f75a5;
	border-bottom: 1px solid #CCC;
	box-shadow: 0 0 10px 0px rgba(0,0,0,0.2);
	margin: 0;
	font-size: 13px;
	top: 0;
	z-index: 998;
	position: fixed;
	width: 100%;
	height: 70px;
	text-align: left;
	font-family: Roboto, Arial;
	font-weight:normal;
}
.menu-inside {
	padding: 0 50px;
	display: flex;
	justify-content: flex-start;
}
</style>