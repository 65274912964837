<template>
	<div v-if="campaign && campaign.id > 0 && analyticsdata && analyticsdata.delivered" class="main">
		<div class="third">
			<div class="chart-section">
				<ApexPie :chartdata="deliveryData.series" :labels="deliveryData.labels"></ApexPie>
			</div>
			<div>
				<p>
					<span class="boldme">Total Attempted:</span>
					{{analyticsdata.totalAttempted}}. {{analyticsdata.delivered}} ({{analyticsdata.deliveredPct}}% delivered, {{analyticsdata.bouncedPct}}% bounced).
				</p>
			</div>
		</div>
		<div class="third">
			<div class="chart-section">
				<ApexPie :chartdata="openData.series" :labels="openData.labels"></ApexPie>
			</div>
			<div>
				<p>
					<span class="boldme">Opens:</span>
					{{analyticsdata.opens}} ({{analyticsdata.opensPct}}%)
				</p>
			</div>
		</div>
		<div class="third">
			<div class="chart-section">
				<ApexPie :chartdata="completeData.series" :labels="completeData.labels"></ApexPie>
			</div>
			<div>
				<p>
					<span class="boldme">Opens:</span> {{analyticsdata.opens}} ({{analyticsdata.opensPct}}%)<br/>
					<span class="boldme">Clicks:</span> {{analyticsdata.clicks}} ({{analyticsdata.clicksPctAll}}% total, {{analyticsdata.clicksPctOpens}}% of opens)<br/>
					<span class="boldme">Completed Surveys:</span> {{analyticsdata.completes}} ({{analyticsdata.completesPctDelivered}}% total, {{analyticsdata.completesPctClicks}}% of clicks)<br/>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import {globalMixin} from "@/mixins";
import {store} from '@/store.js'
import ApexPie from "@/components/charts/ApexPie.vue";

export default {
	name: "CampaignAnalytics",
	mixins: [globalMixin],
	components: {
		ApexPie

	},
	props: {
		campaign: Object,
		analyticsdata: Object,
	},
	data: function () {
		return {
			store: store,
			isFetching: false,



		}
	},
	computed: {
		deliveryData() {
			return {
				labels: ['Delivered', 'Bounced'],
				series: [this.analyticsdata.delivered, this.analyticsdata.bounced]
			}
		},
		openData() {
			let notopened = this.analyticsdata.delivered - this.analyticsdata.opens
			return {
				labels: ['Opened','Delivered but not opened'],
				series: [this.analyticsdata.opens, notopened]
			}
		},
		completeData() {
			return {
				labels: ['Survey completed','Clicked but not completed','Opened but not clicked'],
				series: [this.analyticsdata.completes, this.analyticsdata.clickedButNotCompleted, this.analyticsdata.opens - this.analyticsdata.openedButNotClicked]
			}
		},
	},
	methods: {

	},
	watch: {

	},
	mounted() {

	},
}
</script>

<style scoped>
.main {
	display: flex;
	gap: 30px;
}
.third {
	width: 30%;
}
.chart-section {
	height: 450px;
	position: relative;
}
</style>